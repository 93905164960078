import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link, useLocation, useParams } from "react-router-dom";
import Breadcrum from '../../../../common/breadcrum/breadcrum';
import ReportTable from '../../../common/report_table';
import { serverLink } from '../../../../util/url';
import Loader from '../../../../common/loader';
import JoditEditor from 'jodit-react';
import Modal from '../../../common/modal';
import Swal from 'sweetalert2';
import { CheckPermission, formatAMPM, formatDateAndTime, schoolName, sendEmail, timeConvert } from '../../../../util/constants';
import { showAlert } from "../../../../common/sweetalert/sweetalert";
import Select from "react-select";
import Select2 from "react-select-2/lib/Select2";
import { Try } from '@mui/icons-material';



function InterviewPanelRequest(props) {
    const location = useLocation();
    const allow = props.permissionsList.length > 0 ? props.permissionsList.filter(x => x.Designation === 'superadmin' || x.Designation === 'hr').length > 0 ? true : false : false;
    CheckPermission(allow);

    const [data, setData] = useState(props.staffList.length > 0 ? props.staffList : []);
    const [isLoading, setIsLoading] = useState(true);
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [generalDatatable, setGeneralDatatable] = useState([]);
    const [jobs, setJobs] = useState([]);
    const columns = ["SN", "Staff ID", "Name", "Email", "Designation", "Department", "Gender", "Type", "Action"];
    const [staff_list, setstaff_list] = useState([]);

    const getData = async () => {
        setIsLoading(true)
        let stf = []

        await axios.get(`${serverLink}/enrollment/interview/job/openings`).then((res) => {
            if (res.data.length > 0) {
                setJobs(res.data)
            }
        })
            .catch((err) => {
                setIsLoading(false);
                toast.error("NETWORK ERROR. Please try again!");
            });


        await axios.get(`${serverLink}/enrollment/get_all_staff`).then((res) => {
            let rows = []
            if (res.data.length > 0) {
                stf = res.data;
                res.data.map((x, i) => {
                    rows.push({ value: x.StaffID, label: x.StaffID + " -- " + x.StaffName + " -- " +x.Department, email: x.EmailAddress, staff_name: x.StaffName })
                })
                setstaff_list(rows)
            }
        })
            .catch((err) => {
                setIsLoading(false);
                toast.error("NETWORK ERROR. Please try again!");
            });

        let rows = [];
        const dt = data.length > 0 ? data : stf
        if (dt.length > 0) {
            dt.map((item, index) => {
                rows.push([
                    index + 1,
                    item.StaffID,
                    item.StaffName,
                    item.OfficialEmailAddress,
                    item.Designation,
                    item.Department,
                    <span className={item.Gender === "Male" ? "badge badge-primary" : "badge badge-secondary"}>
                        {item.Gender === 1 ? "Male" : "Female"}
                    </span>,
                    <span className={item.IsAcademicStaff === 1 ? "badge badge-success" : "badge badge-secondary"}>
                        {item.IsAcademicStaff === 1 ? "Academic" : "Non Academic"}
                    </span>,
                    (
                        <>
                            <button data-toggle="modal" data-target="#email-modal"
                                className="btn btn-sm btn-main"
                                onClick={() => {
                                    setFormData({
                                        ...formData,
                                        ismulti: false,
                                        StaffID: item.StaffID,
                                        msgTo: item.OfficialEmailAddress,
                                        staff_name: `${item.Gender === "Male" ? "Mr. " : "Mrs. "}` + item.StaffName,
                                        Job: "",
                                        msgTitle: "Interview Panel Member Invitation",
                                        msgSubject: "Interview Panel Member Invitation",
                                        msgBody: "",
                                        InterViewDate: "",
                                        InterviewTime: "",
                                        InterviewVenue: "",
                                    })
                                }}
                            >
                                <i className="fa fa-reply" />
                            </button>

                        </>
                    ),
                ]);
            });
        }
        setGeneralDatatable(rows);
        setIsLoading(false);
    }

    useEffect(() => {
        getData();
    }, [location.pathname]);

    const [formData, setFormData] = useState({
        Job: "",
        StaffID: "",
        msgTitle: "Interview Panel Member Invitation",
        msgSubject: "Interview Panel Member Invitation",
        msgTo: "",
        msgBody: "",
        staff_name: "",
        InterViewDate: "",
        InterviewTime: "",
        InterviewVenue: "",
        multiStaff: [],
        ismulti: false
    })
    const resetFormData = () => {
        return setFormData({
            ...formData,
            Job: "",
            StaffID: "",
            msgTitle: "Interview Panel Member Invitation",
            msgSubject: "Interview Panel Member Invitation",
            msgTo: "",
            msgBody: "",
            staff_name: "",
            InterViewDate: "",
            InterviewTime: "",
            InterviewVenue: "",
            multiStaff: [],
            ismulti: false,
        });
    }

    const onMultiEdit = (e) => {
        setFormData({
            ...formData,
            multiStaff: e,
        })
    }

    const onEdit = (e) => {
        if (e.target.id === "Job") {
            document.getElementById("body").style.display = "none"
            if (e.target.value !== "") {
                let index = e.nativeEvent.target.selectedIndex;
                let date = e.nativeEvent.target[index].getAttribute('date');
                let time = e.nativeEvent.target[index].getAttribute('time');
                let venue = e.nativeEvent.target[index].getAttribute('venue');
                let position = e.nativeEvent.target[index].getAttribute('position');
                let dept = e.nativeEvent.target[index].getAttribute('dept');

                setFormData({
                    ...formData,
                    InterviewVenue: venue,
                    InterViewDate: date,
                    InterviewTime: time,
                    Job: e.target.value,
                    msgBody: `<div>I am directed to inform you that you have been nominated and invited to sit on the interview panel for non-academic recruitment slated for :
                                                      <br/>
                                                      Position: <b>${position+" -- "+dept}</b><br/>
                                                      Date: <b>${formatDateAndTime(date, 'date')}</b><br/>
                                                      Time: <b>${timeConvert(time)}</b><br/>
                                                      Venue: <b>${venue}</b><br/><br/>
                                                      
                                                      Best Regards.<br/><br/>
                                                      Human Resource Department <br/>
                                                      ${schoolName},<br/>
                                                      Abuja,<br/>
                                                      Nigeria.<br/>
                                                      </div>`
                })
                document.getElementById("body").style.display = "block"
            }
        } else {
            setFormData({
                ...formData,
                [e.target.id]: e.target.value
            })
        }

    }
    const onContact = async (e) => {
        if (formData.ismulti === true) {
            if (formData.multiStaff.length === 0) {
                showAlert("EMPTY FIELD", "Please select staff", "error");
                return false;
            }
        } else {
            if (formData.StaffID.toString().trim() === "") {
                showAlert("EMPTY FIELD", "Please select staff", "error");
                return false;
            }
            if (formData.msgTo.toString().trim() === "") {
                showAlert("EMPTY FIELD", "staff has no email address", "error");
                return false;
            }
        }


        if (formData.Job.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select job", "error");
            return false;
        }
        if (formData.msgBody.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Message content can not be empty", "error");
            return false;
        }
        setIsFormLoading(true);
        e.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: "proceed to send invitation?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Submit'
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (formData.ismulti === true) {
                    onMultiContact();
                } else {
                    await axios.post(`${serverLink}/enrollment/interview/panel/request`, formData).then((result) => {
                        if (result.data.message === "success") {
                            setIsFormLoading(false);
                            toast.success("Invitation sent Successfully");
                            sendEmail(
                                formData.msgTo,
                                formData.msgSubject,
                                formData.msgTitle,
                                formData.staff_name,
                                formData.msgBody,
                                ''
                            )
                            resetFormData()
                            document.getElementById("modal-close").click();
                        } else if (result.data.message === "exist") {
                            setIsFormLoading(false);
                            showAlert(
                                "DUPLICATE",
                                "Invitation already sent to the selected staff and job",
                                "error"
                            );
                        } else {
                            setIsFormLoading(false);
                            showAlert(
                                "ERROR",
                                "Something went wrong. Please try again!",
                                "error"
                            );
                        }
                    }).catch((error) => {
                        setIsFormLoading(false);
                        showAlert(
                            "NETWORK ERROR",
                            "Please check your connection and try again!",
                            "error"
                        );
                    });
                }
            } else {
                setIsFormLoading(false);
            }
        })

    }

    const onMultiContact = () => {
        
        try {
            formData.multiStaff.map(async (item, index) => {
                const fdt = {
                    ...formData,
                    StaffID : item.value,
                    staff_name: item.staff_name
                }
                await axios.post(`${serverLink}/enrollment/interview/panel/request`, fdt).then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Invitation sent Successfully");
                        sendEmail(
                            item.email,
                            formData.msgSubject,
                            formData.msgTitle,
                            item.staff_name,
                            formData.msgBody,
                            ''
                        )
                    } else if (result.data.message === "exist") {
                        sendEmail(
                            item.email,
                            formData.msgSubject,
                            formData.msgTitle,
                            item.staff_name,
                            formData.msgBody,
                            ''
                        )

                    } else {
                        setIsFormLoading(false);
                    }


                })

                if (formData.multiStaff.length - 1 === index) {
                    setIsFormLoading(false);
                    resetFormData()
                    document.getElementById("modal-close").click();
                }
            })
        } catch (error) {
            setIsFormLoading(false);
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        } finally {
            setIsFormLoading(false)
        }


    }

    return (
        isLoading ? <Loader />
            :
            <div>
                <div className="container-fluid" style={{ marginLeft: '-10px' }}>
                    <Breadcrum title={"Interview Panel Member Invitation Request"} />
                    <div className="d-flex justify-content-end" >
                        <button type="button"
                            data-toggle="modal" data-target="#email-modal"
                            className="btn btn-sm btn-main"
                            onClick={() => {
                                resetFormData();
                                setFormData({
                                    ...formData,
                                    ismulti: true
                                })
                            }}>
                            Invite multiple
                        </button>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <ReportTable title={data.length + " Records"} columns={columns} data={generalDatatable} height="700px" />
                        </div>
                    </div>

                    <Modal id="email-modal" title={`Invite ${formData.staff_name}`}  >
                        <div className='row'>
                            <div className='col-md-12'>
                                <form>

                                    <label className='form-label' >Subject</label>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control form-control-sm" placeholder='enter email subject' onChange={onEdit} id="msgSubject" value={formData.msgSubject} required />
                                    </div>

                                    <label className='form-label' >To</label>
                                    {
                                        formData.ismulti === true ?
                                            <div className="form-group mb-3">
                                                <Select
                                                    className="form-control"
                                                    id="StaffID"
                                                    name="StaffID"
                                                    isMulti={true}
                                                    options={staff_list}
                                                    onChange={onMultiEdit}
                                                    placeholder="select staff"
                                                />
                                            </div> :
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control form-control-sm" onChange={onEdit} id="msgTo" value={formData.msgTo} required disabled />
                                            </div>

                                    }



                                    <label className='form-label' >Jobs</label>
                                    <div className="form-group mb-3">
                                        <select className="form-control" name="Job" id="Job" onChange={onEdit} value={formData.Job}>
                                            <option value="">Select Job</option>
                                            {
                                                jobs.length > 0 && jobs.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.RequisitionID} date={item.InterViewDate} time={item.InterviewTime} venue={item.InterviewVenue} position={item.Position} dept={item.Department} >{item.Position + " -- " + item.Department}</option>
                                                    )
                                                })
                                            }

                                        </select>
                                    </div>
                                    <br />

                                    <div className="form-group" id="body" style={{ display: 'none' }}>
                                        {
                                            formData?.msgBody !== "" ?
                                                <>
                                                    <label className='form-label' >Email Body</label>
                                                    <JoditEditor
                                                        value={formData.msgBody}
                                                        tabIndex={1}
                                                        onChange={(e) => {
                                                            setFormData({
                                                                ...formData, msgBody: e
                                                            })
                                                        }}
                                                    />
                                                </>
                                                :
                                                <></>
                                        }

                                    </div>

                                    <hr />
                                    {
                                        IsFormLoading ?
                                            <button className="btn btn-success float-right w-100" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                                Loading...
                                            </button>
                                            :
                                            <div className="input-group mb-3">
                                                <button type='button' className='btn btn-md btn-success w-100' onClick={onContact}>
                                                    Invite
                                                </button>
                                            </div>
                                    }
                                </form>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
    );
}

const mapStateToProps = (state) => {
    return {
        AdminLoginData: state.AdminLoginData,
        AllJobApplications: state.AllJobApplications,
        staffList: state.staffList,
        permissionsList: state.permissionsList
    };
};



export default connect(mapStateToProps, null)(InterviewPanelRequest);


