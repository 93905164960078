
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import {
    activeCohortReducer,
    AdminJobApplicationsReducer,
    AdminReducer,
    AppliedJobsReducer, assessmentSettingsReducer,
    dashboarddataReducer,
    departmentListReducer,
    facultyListReducer,
    HrDesignationsReducer, intervieweeReducer,
    JobAdminLoginReducer,
    JobApplicantLoginReducer,
    JobListReducer, NotificationsReducer, panelDetailsReducer,
    panelJobListReducer,
    permissionsReducer,
    SingleJobApplicationsReducer,
    staffListReducer,
    usersListReducer
} from "./detailedReducers";
import { shortCode } from "../util/constants";

const CombinedReducers = combineReducers({
    JobApplicantData: JobApplicantLoginReducer,
    JobList: JobListReducer,
    AppliedJobs: AppliedJobsReducer,
    AdminLoginData: JobAdminLoginReducer,
    AllJobApplications: AdminJobApplicationsReducer,
    SingleJobApplication: SingleJobApplicationsReducer,
    staffList: staffListReducer,
    hrDesignations: HrDesignationsReducer,
    departmentList: departmentListReducer,
    facultyList: facultyListReducer,
    usersList: usersListReducer,
    panelJobList: panelJobListReducer,
    assessmentSettings: assessmentSettingsReducer,
    interviewee: intervieweeReducer,
    panelDetails: panelDetailsReducer,
    Admin: AdminReducer,
    Notifications: NotificationsReducer,
    permissionsList : permissionsReducer,
    dashboardData: dashboarddataReducer,
    activeCohort: activeCohortReducer
})

const persistConfig = {
    key: shortCode,
    storage,
};

export default persistReducer(persistConfig, CombinedReducers);