
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { setAdminLoginDetails, setApplicantLoginDetails, setAppliedJobs } from '../../../../actions/actions';
import { projectLogo, encryptData, formatDateAndTime, MailTemplates, onGeneralEdit, sendEmail, shortCode } from '../../../../util/constants';
import { serverLink } from '../../../../util/url';
import ApplicantProfile from './profile';
import naijaStateLocalGovernment from 'naija-state-local-government';
import Loader from '../../../../common/loader';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import CUOfferLetter from '../../../admin/pages/job-applications/offer-letters/cu-offer-letter';
import { useReactToPrint } from 'react-to-print';


function ApplicantDashboard(props) {
    const componentRef = useRef();

    const [isLoading, setIsLoading] = useState(true);
    const [stateList, setStateList] = useState(naijaStateLocalGovernment.states())
    const [lgaList, setLgaList] = useState([])
    const [qualificationList, setQualificationList] = useState([])

    const [JobList, setJobList] = useState(props.JobList.length > 0 ? props.JobList : []);
    const [jobApplications, setJobApplications] = useState([])
    const [applicant, setapplicant] = useState(props.JobApplicant.length > 0 ? props.JobApplicant : [])
    const [ContinueApplication, setContinueApplication] = useState([])

    const [showDescr, setshowDescr] = useState(false);
    const [data, setData] = useState({
        FirstName: "",
        MiddleName: "",
        Surname: "",
        Phone: "",
        Email: "",
        AlternativeEmail: "",
        Nationality: "",
        StateOfOrigin: "",
        Gender: "",
        MaritalStatus: "",
        Disability: "",
        DisabilityType: "",
        LGA: "",
        DateOfBirth: "",
        AlternativePhone: "",
        HighestQualification: "",
        Address: "",
        Passport: "",
        CV: "",
        ImageFile: "",
        CVFile: "",

        DisabilityStatus: "",
        Disabilities: [],
        DisabilityDescription: "",
        Facebook: "",
        LinkedIn: "",
        Scholar: "",
        ResearchGate: "",
        Academia: "",
        Orcid: ""

    })
    const [disableUpload, setDisableUpload] = useState(true)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const active_cohort = props.activeCohort[0];

    const controller = new AbortController();

    const getOpenings = async () => {
        const { signal } = controller.signal
        try {
            await axios.get(`${serverLink}/job_applications/current_job_openings?cohort=${active_cohort?.CohortCode}`, { signal }).then((res) => {
                if (res.data.length > 0) {
                    setJobList(res.data)
                }
            })

            getApplicantData();


            await axios.get(`${serverLink}/job_applications/get_job_applications/${applicant[0]?.Email}`, { signal }).then((res) => {
                if (res.data.length > 0) {
                    setJobApplications(res.data);
                    props.setOnAppliedJobs(res.data);
                }
                setIsLoading(false)
            })

        } catch (e) {
            console.log(e)
            toast.error("newtwork error")
        }
    }

    const getApplicantData = async () => {
        const { signal } = controller.signal
        const { data } = await axios.get(`${serverLink}/enrollment/get_social_media/email/${applicant[0]?.Email}`);
        const values = data[0];

        await axios.get(`${serverLink}/job_applications/applicant_profile/${applicant[0]?.Email}`, { signal }).then((res) => {
            if (res.data.length > 0) {
                let dt = res.data[0];
                props.setOnJobApplicantData(res.data);

                const checkState = naijaStateLocalGovernment.all().filter(x => x.state === dt?.StateOfOrigin)
                const lga = naijaStateLocalGovernment.all().filter(x => x.state === dt?.StateOfOrigin)[0]?.lgas;
                setLgaList(checkState.length > 0 ? lga : []);
                setData({
                    ...data,
                    FirstName: dt?.FirstName,
                    MiddleName: dt?.MiddleName,
                    Surname: dt?.Surname,
                    Phone: dt?.Phone,
                    AlternativePhone: dt.AlternativePhone !== null ? dt.AlternativePhone : "",
                    Email: dt?.Email,
                    AlternativeEmail: dt.AlternativeEmail !== null ? dt.AlternativeEmail : "",
                    Nationality: dt?.Nationality,
                    StateOfOrigin: dt.StateOfOrigin,
                    Gender: dt?.Gender,
                    MaritalStatus: dt?.MaritalStatus,
                    DisabilityStatus: dt?.DisabilityStatus,
                    // Disability: dt.Disability !== null && dt.Disability.split(",").length > 0 ? "Yes" : "No",
                    Disabilities: dt.Disabilities === null || dt.Disabilities === "" ? [] : dt.Disabilities.length > 0 ? dt.Disabilities.split(",") : [],
                    DisabilityDescription: dt?.DisabilityType,
                    LGA: dt?.LGA,
                    DateOfBirth: dt?.DateOfBirth,
                    HighestQualification: dt?.HighestQualification,
                    Address: dt?.Address,
                    Passport: `${serverLink}/public/uploads/${shortCode}/staff_enrollment/passports/${dt?.Passport}`,
                    CV: `${serverLink}/public/uploads/${shortCode}/staff_enrollment/cv/${dt?.CurriculumVitae}`,
                    Facebook: values?.Facebook,
                    LinkedIn: values?.LinkedIn,
                    Scholar: values?.Scholar,
                    ResearchGate: values?.ResearchGate,
                    Academia: values?.Academia,
                    Orcid: values?.Orcid

                })
                setshowDescr(dt.DisabilityStatus === "Yes" && dt?.Disabilities.length > 0 && dt?.Disabilities.includes("Others") > 0 ? true : false)

                //setJobList(res.data)
            }
        })
    }

    useEffect(() => {
        getOpenings();

        return () => { controller.abort() }
    }, [])

    const onImageUpload = async (url) => {
        toast.info("please wait... ")
        setIsSubmitting(true)
        const dt = new FormData();
        dt.append("photo", data.ImageFile)
        try {

            axios.put(`${serverLink}/job_applications/update_passport/${applicant[0]?.Email}`, dt).then((data) => {
                if (data.data.message === "success") {
                    toast.success('passport updated succesfully');
                    setDisableUpload(true)
                    setData({
                        ...data,
                        Passport: dt.Passport
                    })
                    getApplicantData();

                } else {
                    toast.error('error updating, please, try again', 'error')
                }
            })
        } catch (e) {
            toast.error("network error")
        } finally {
            setIsSubmitting(false)
        }

    }

    const onUpdateProfile = async (e) => {
        e.preventDefault();
        setIsSubmitting(true)
        toast.info("please wait...")
        try {
            await axios.put(`${serverLink}/job_applications/update_applicant_profile`, data).then((data) => {
                if (data.data.status === 200) {
                    getApplicantData();
                    toast.success("Your Profile have been updated successfully.");
                    if (window.sessionStorage.getItem("jID")) {
                        const jID = window.sessionStorage.getItem("jID")
                        const job_details = props.JobList.length > 0 ? props.JobList.filter(x => x.RequisitionID === jID) : []
                        setContinueApplication(job_details);
                    }

                    setTimeout(() => {
                        window.location.reload()
                    }, 500)
                } else {
                    toast.error('Update failed, please try again!!!', 'error')
                }
            })
        } catch (e) {
            toast.error("network error")
        } finally {
            setIsSubmitting(false)
        }
    }

    const onEdit = (e) => {
        onGeneralEdit(e, data, setData);
        if (e.target.id === "StateOfOrigin") {
            const checkState = naijaStateLocalGovernment.all().filter(x => x.state === e.target.value)
            const lga = naijaStateLocalGovernment.all().filter(x => x.state === e.target.value)[0]?.lgas;
            setLgaList(checkState.length > 0 ? lga : [])
            return
        }
        if (e.target.id === "DisabilityStatus") {
            if (e.target.value === "No") {
                setData({
                    ...data,
                    DisabilityStatus: "No",
                    Disabilities: [],
                    DisabilityDescription: ""
                })
            } else {
                onGeneralEdit(e, data, setData);
            }
            return
        }
    }
    const onImageChange = (e) => {
        if (e.target.files[0].size > 2000000) {
            setDisableUpload(true)
            toast.error("File size must not be greater than 2MB");
            return false;
        }
        else {
            if (e.target.files[0].type.includes("image")) {
                setDisableUpload(false)
                setData({
                    ...data,
                    [e.target.id]: e.target.files[0],
                    //Passport: URL.createObjectURL(e.target.files[0])
                })
            } else {
                setDisableUpload(true)
                toast.error("Only Image file is allowed");
            }
        }
    }

    const onCVChange = (e) => {
        if (e.target.files[0].size > 2000000) {
            setDisableUpload(true)
            toast.error("File size must not be greater than 2MB");
            return false;
        }
        else {
            if (e.target.files[0].type.includes("pdf")) {
                setDisableUpload(false)
                setData({
                    ...data,
                    [e.target.id]: e.target.files[0],
                })
            } else {
                setDisableUpload(true)
                toast.error("Only pdf file is allowed");
            }
        }
    }

    const onDisabilityChange = (e) => {
        if (e.target.checked === true) {
            setData({
                ...data,
                Disabilities: [...data.Disabilities, e.target.value]
            })
        } else {
            var index = data.Disabilities.indexOf(e.target.value);
            if (index > -1) {
                data.Disabilities.splice(index, 1)
            }
        }

        if (e.target.value === "Others") {
            if (e.target.checked === true) {
                setshowDescr(true)
            } else {
                setshowDescr(false);
                setData({
                    ...data,
                    DisabilityDescription: ""
                })
            }
        }
    }

    const onCvUpload = () => {
        toast.info("please wait... ")
        setIsSubmitting(true)
        setDisableUpload(true)
        const dt = new FormData();
        dt.append("file", data.CVFile);

        try {
            axios.put(`${serverLink}/job_applications/applicant_add_cv/${applicant[0]?.Email}`, dt).then((data) => {
                if (data.data.message === "success") {

                    toast.success('CV updated successfully');
                    getApplicantData();
                    setDisableUpload(true)
                    setTimeout(() => {
                        window.location.reload()
                    }, 500)

                } else {
                    toast.error('Error uploading')
                }
            })
        } catch (e) {
            toast.error("network error")
        }
        finally {
            setIsSubmitting(false)
        }

    }

    const onWithdrwaApplication = (id, Position) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Application would no longer be considered for Review",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Submit'
        }).then((result) => {
            if (result.isConfirmed) {
                toast.info("please wait...")
                axios.put(`${serverLink}/job_applications/withdraw_application/${id}`).then((res) => {
                    if (res.data.message === "success") {
                        const tt = {
                            ...data,
                            Position: Position
                        }
                        const email = MailTemplates('withdraw application', tt);

                        sendEmail(data.Email, email.subject, email.title, data.FirstName, email.body, '');
                        getOpenings();
                        getApplicantData();
                        toast.success("Application withdrawn successfully'")
                    }
                    else {
                        toast.error("Error, Try Again")
                    }
                })
            } else {

            }
        })
    }

    const onAcceptOffer = (e, val) => {
        e.preventDefault();

        Swal.fire({
            title: 'Are you sure?',
            text: "Proceed to accept offer?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Submit'
        }).then((result) => {
            if (result.isConfirmed) {
                toast.info("please wait...")
                axios.put(`${serverLink}/job_applications/accept_offer/${val.EntryID}`).then((res) => {
                    if (res.data.message === "success") {
                        getOpenings();
                        getApplicantData();
                        toast.success("Offer accepted, please proceed to enrol")
                    }
                    else {
                        toast.error("Error, Try Again")
                    }
                })
            } else {

            }
        })
    }

    const onLogout = () => {
        props.setOnAppliedJobs([]);
        props.setOnJobApplicantData([]);
        props.setOnAdminLoginData([])
    }

    const navigate = useNavigate()
    const onLoginToAdmin = () => {
        props.setOnAdminLoginData(props.Admin);
        props.setOnAppliedJobs([]);
        props.setOnJobApplicantData([]);
        //props.setOnAdminLoginData(window.sessionStorage.getItem("LoginFromAdmin"))
        //navigate(`/jobs/applicant/${applicant[0]?.ApplicantID}/${applicant[0]?.JobID}`);
        setTimeout(() => {
            window.location.href = `/jobs/applicant/${applicant[0]?.ApplicantID}/${applicant[0]?.JobID}`
        }, 200);


    }

    const [showOfferLetter, setShowOfferLetter] = useState(false)

    const handleAdmissionPrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const printOfferLetter = () => {
        setShowOfferLetter(true);

        setTimeout(() => {
            handleAdmissionPrint();
            setShowOfferLetter(false);
        }, 100);

    }

    return isLoading ? (<Loader />) : (

        <div className="auth-wrapper d-flex no-block"
            style={{ backgroundColor: '#f5f5f5', }}>

            <div className='container-fluid mt-3'>
                <div className='row' >
                    <div className="col-md-12">
                        <div className="card" style={{ minHeight: "1700px" }}>
                            <div className="card-body">
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <h4 className="card-title">Welcome, {data.FirstName}</h4>
                                        <h6 className="fw-bold">{data.Address} </h6>
                                    </div>
                                    <div>
                                        <Link to={`/`} onClick={onLogout} className='btn btn-sm btn-success'>
                                            Logout
                                        </Link>
                                    </div>

                                </div>
                                {/* Nav tabs */}
                                <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active show" data-toggle="tab" href="#home" role="tab" aria-selected="true" id="t_home">
                                            <span className="hidden-sm-up"><i className="ti-home" />
                                            </span>
                                            <span className="hidden-xs-down">Home</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" href="#profile" role="tab" aria-selected="false" id="t_profile">
                                            <span className="hidden-sm-up">
                                                <i className="ti-user" />
                                            </span>
                                            <span className="hidden-xs-down">Profile</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" href="#curriculumVitae" role="tab" aria-selected="false" id="t_cv">
                                            <span className="hidden-sm-up">
                                                <i className="ti-folder" />
                                            </span>
                                            <span className="hidden-xs-down">Curriculum Vitae</span>
                                        </a>
                                    </li>

                                </ul>
                                {/* Tab panes */}
                                <div className="tab-content tabcontent-border">
                                    <div className="tab-pane active show" id="home" role="tabpanel">
                                        <div className='row'>
                                            <div className='col-md-8'>
                                                <div className='d-flex justify-content-center'>

                                                    <div className="row col-md-12 p-20">
                                                        <div className='col-md-6'>
                                                            <h3>Let's start here</h3>
                                                            <ul>
                                                                <li>Begin by updating your passport using the file upload below</li>
                                                                <li>Use the tab to navigate to profile and update your profile</li>
                                                                <li>Navigate to the CV tab and update your Curriculum Vitae</li>
                                                                <li className='text-success'>Select a job opening and proceed to apply</li>
                                                            </ul>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <h3>Update Your Passport</h3><br />
                                                            <small className='text-danger'>
                                                                <strong>
                                                                    Only image files are allowed, max. file size is 2MB.
                                                                </strong>

                                                            </small>

                                                            <div className="" style={{ borderRadius: '5px' }}>
                                                                <div>
                                                                    <img width={150} src={data.Passport} className="img-thumbnail" />
                                                                </div>
                                                            </div>
                                                            <input type='file' className='form-control' id="ImageFile" onChange={onImageChange} />
                                                            <button className='btn btn-md btn-success mt-2' disabled={disableUpload || isSubmitting === true} type='button' onClick={onImageUpload}>
                                                                {
                                                                    isSubmitting === true ? "please wait..." : "Update Passport"
                                                                }
                                                            </button>
                                                        </div>
                                                    </div>



                                                </div>
                                                <div className='text-success text-center'>
                                                    <br />
                                                    <h6>Please endeavour to always keep your CV upto-date</h6>
                                                </div>
                                                <hr />

                                                <h3>Application Records</h3>
                                                {
                                                    jobApplications.length > 0 ?
                                                        jobApplications.map((x, i) => {
                                                            const printData = { ...applicant[0], ...x }
                                                            return (
                                                                <div className="card mb-3" style={{ backgroundColor: "#f5f6f7" }} key={i}>
                                                                    <div className="card-body">
                                                                        <div className='row'>
                                                                            <div className='col-sm-12 col-md-1 col-lg-1'>
                                                                                <img className="m-r-15 img-fluid " src={projectLogo} style={{ width: '50px' }} alt="Generic placeholder image" />
                                                                            </div>
                                                                            <div className='col-sm-12 col-md-11 col-lg-11'>
                                                                                <h5 className="mt-0 fw-bolder">
                                                                                    <strong>{x.Position}</strong>
                                                                                </h5>
                                                                                Department : {x.Department}
                                                                                <h4>Application Date: {formatDateAndTime(x.InsertDate, "date")}</h4>
                                                                            </div>
                                                                            <div className='col-sm-12 col-md-12 col-lg-12'>
                                                                                <div className='float-right'>
                                                                                    {
                                                                                        x.ApplicationStatus === '0' &&
                                                                                        <button className='btn btn-sm btn-danger' onClick={() => { onWithdrwaApplication(x.EntryID, x.Position) }} >
                                                                                            Cancel application
                                                                                        </button>
                                                                                    }

                                                                                    {
                                                                                        x.ApplicationStatus === '2' &&
                                                                                        <button className='btn btn-sm btn-success'
                                                                                            disabled={x.OfferAcceptance === '1' ? true : false}
                                                                                            onClick={(e) => {
                                                                                                onAcceptOffer(e, x)
                                                                                            }}>
                                                                                            Accept Offer
                                                                                        </button>
                                                                                    }

                                                                                    {
                                                                                        x.ApplicationStatus === '2' && x.OfferAcceptance === '1' &&
                                                                                        <button className='btn btn-sm btn-success'
                                                                                            disabled={x.EnrolmentStatus !== '0' ? true : false}
                                                                                            onClick={() => {
                                                                                                navigate(`/applicant/enrolment/${encryptData(x.JobID)}`)
                                                                                            }}>
                                                                                            Proceed to Enrol
                                                                                        </button>
                                                                                    }
                                                                                    {printData?.OfferAcceptance === "1" &&
                                                                                        <>
                                                                                            <button className='btn btn-sm btn-success'
                                                                                                disabled={x.EnrolmentStatus !== "1"}
                                                                                                onClick={printOfferLetter} >
                                                                                                Print Offer Letter
                                                                                            </button>
                                                                                            {
                                                                                                showOfferLetter === true && x.EnrolmentStatus === "1" &&
                                                                                                < CUOfferLetter
                                                                                                    componentRef={componentRef}
                                                                                                    printData={printData}
                                                                                                />
                                                                                            }
                                                                                        </>

                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-sm-12 col-md-12 col-lg-12'>
                                                                                <div className={x.ApplicationStatus === '0' ? 'row alert alert-primary' :
                                                                                    x.ApplicationStatus === '1' ? 'row alert alert-info' :
                                                                                        x.ApplicationStatus === '2' ? 'row alert alert-success' :
                                                                                            x.ApplicationStatus === '3' ? 'row alert alert-danger' :
                                                                                                'row alert alert-danger'} >

                                                                                    {x.ApplicationStatus === '0' && <span>Pending |&emsp;</span>}
                                                                                    {x.ApplicationStatus === '1' && <span>Invited for Interview |&emsp;</span>}
                                                                                    {x.ApplicationStatus === '2' && <span>Job Offered |&emsp;</span>}
                                                                                    {x.ApplicationStatus === '3' && <span>Rejected |&emsp;</span>}
                                                                                    {x.ApplicationStatus === '4' && <span>Application Withdrawn | &emsp;</span>}
                                                                                    {x.OfferAcceptance === "1" && <span>Offer Accepted |&emsp;</span>}
                                                                                    {x.EnrolmentStatus === "1" && <span>Enrolment Submitted |&emsp;</span>}
                                                                                    {x.EnrolmentStatus === "2" && <span>Enrolment Confirmed &emsp;</span>}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        <div>
                                                            <h3>No records</h3>
                                                        </div>

                                                }

                                            </div>
                                            <div className='col-md-4'>
                                                <div className="p-20">
                                                    <h4>
                                                        <Link to="/jobs" className=''>
                                                            Find other Positions
                                                        </Link>
                                                    </h4>
                                                    {/* <div className='d-flex justify-content-end'>
                                                        <input type={"text"} placeholder="search positions" style={{ width: '150px', height: '30px' }} className="form-control" />

                                                    </div> */}
                                                </div>

                                                <div className="col-md-12 p-20" style={{ maxHeight: '600px', overflowY: 'auto' }} >

                                                    <ul className="list-group list-group-full">
                                                        {
                                                            JobList.length > 0 ?
                                                                JobList.map((x, i) => {
                                                                    return (
                                                                        <li className="list-group-item m-1" key={i}>
                                                                            <div className='row'>
                                                                                <div className='col-md-10 col-sm-10'>
                                                                                    {x.Position}
                                                                                    <div><strong>Department</strong>: {x.Department} |<br />  <strong>Closing Date : {formatDateAndTime(x.ClosingDate, "date")}</strong></div>
                                                                                </div>
                                                                                <div className='col-md-2 col-sm-2'>
                                                                                    <Link to={`/job/${encryptData(x.RequisitionID)}`} className="btn btn-xs btn-success">Apply</Link>
                                                                                </div>

                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                                :
                                                                <div>
                                                                    No Openings
                                                                </div>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="tab-pane p-20" id="profile" role="tabpanel">
                                        <div className='container'>
                                            <div className="row">
                                                <div className="col-12">
                                                    <form className="form-horizontal m-t-20" onSubmit={onUpdateProfile} >
                                                        <ApplicantProfile
                                                            data={data} onEdit={onEdit}
                                                            stateList={stateList}
                                                            lgaList={lgaList}
                                                            qualificationList={qualificationList}
                                                            onDisabilityChange={onDisabilityChange}
                                                            showDescr={showDescr}
                                                            getApplicantData={getApplicantData}
                                                        />

                                                        <div className='row'>
                                                            <button className='btn bt-md btn-success w-100' disabled={isSubmitting === true}>
                                                                {
                                                                    isSubmitting === true ? "please wait..." : "Update Profile"
                                                                }
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane p-20" id="curriculumVitae" role="tabpanel">
                                        <div className="p-20">
                                            <h3>Update Your CV</h3>
                                            <small className='text-danger'>
                                                <strong>Max. file size is 2MB</strong><br />
                                                <strong>Only pdf files are accepted</strong>
                                            </small>
                                            <br />
                                            <strong>Remember to always keep your CV updated</strong>
                                            <br />

                                            {/* <SimpleFileUpload
                                                apiKey={simpleFileUploadAPIKey}
                                                tag={simpleFileUploadFolderName + `-applicants-document`}
                                                onSuccess={onCvUpload}
                                                accepted=".pdf"
                                                maxFileSize={1}
                                                preview={"false"}
                                                width={"100%"}
                                                height={"100"}
                                            /> */}
                                            <input type='file' className='form-control mt-5' id="CVFile" onChange={onCVChange} />
                                            <button className='btn btn-md btn-success mt-2 w-100' disabled={disableUpload === true || isSubmitting === true} type='button' onClick={onCvUpload}>
                                                {
                                                    isSubmitting === true || disableUpload === true ? "please wait..." : "Update CV"
                                                }
                                            </button>
                                            <hr />
                                            <br />
                                            <a href={data.CV} target={"_blank"}>Click to view saved CV</a>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div >

        </div >

    )
}

const mapStateToProps = (state) => {
    return {
        JobApplicant: state.JobApplicantData,
        JobList: state.JobList,
        Admin: state.Admin,
        activeCohort: state.activeCohort


    };
};
const mapDisptachToProps = (dispatch) => {
    return {
        setOnJobApplicantData: (p) => {
            dispatch(setApplicantLoginDetails(p))
        },
        setOnAppliedJobs: (p) => {
            dispatch(setAppliedJobs(p))
        },
        setOnAdminLoginData: (p) => {
            dispatch(setAdminLoginDetails(p))
        }
    }
}

export default connect(mapStateToProps, mapDisptachToProps)(ApplicantDashboard);


