import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useLocation, useParams } from "react-router-dom";
import Breadcrum from '../../../../common/breadcrum/breadcrum';
import ReportTable from '../../../common/report_table';
import { serverLink } from '../../../../util/url';
import Loader from '../../../../common/loader';
import Modal from '../../../common/modal';
import { setUsersListAction } from '../../../../actions/actions';
import { CheckPermission } from '../../../../util/constants';



function SettingsStaffList(props) {
    const allow = props.permissionsList.length > 0 ? props.permissionsList.filter(x => x.Designation === 'superadmin' || x.Designation === 'hr').length > 0 ? true : false : false;
    CheckPermission(allow);

    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [generalDatatable, setGeneralDatatable] = useState([]);
    const columns = ["SN", "StaffID", "Name", "Email", "Designation", "Department", "Action"];

    const getData = async () => {
        let stf = []
        await axios.get(`${serverLink}/enrollment/get_all_staff`).then((res) => {
            if (res.data.length > 0) {
                stf = res.data
            }
        })
            .catch((err) => {
                setIsLoading(false);
                toast.error("NETWORK ERROR. Please try again!");
            });

        let rows = [];
        const dt = data.length > 0 ? data : stf
        if (dt.length > 0) {
            dt.map((item, index) => {
                rows.push([
                    index + 1,
                    item.StaffID,
                    item.StaffName,
                    item.OfficialEmailAddress,
                    item.Designation,
                    item.Department,
                    (
                        <>
                            <button data-toggle="modal" data-target="#permission-modal"
                                className="btn btn-sm btn-main"
                                onClick={() => {
                                    setData({
                                        StaffName: item.StaffName + " -- " + item.Department,
                                        StaffID: item.StaffID,
                                        Department: item.Department,
                                        staff_name: item.StaffName
                                    })
                                }}
                            >
                                <i className="fa fa-pencil-alt" />
                            </button>

                        </>
                    ),
                ]);
            });
        }
        setGeneralDatatable(rows);
        setIsLoading(false);
    }

    useEffect(() => {

        getData();
    }, [location.pathname]);


    const [data, setData] = useState({
        Permission: "",
        StaffID: "",
        StaffName: "",
        Department: "",
        staff_name: "",
        InsertedBy: props.AdminLoginData[0]?.StaffID,
    })

    const onEdit = (e) => {
        setData({
            ...data,
            [e.target.id]: e.target.value
        })
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${serverLink}/enrollment/add-permissions`, { ...data, InsertedBy: props.AdminLoginData[0]?.StaffID }).then(async (res) => {
                if (res.data.message === "success") {
                    toast.success("permission added successfully");
                    document.getElementById("permission-close").click();

                } else if (res.data.message === "exists") {
                    toast.warning("permission already assigned")
                }
                else {
                    toast.error("failed to assign, pleas etry again!")
                }
            })

        } catch (e) {
            toast.error("network error");
        }


    }
    return (
        isLoading ? <Loader />
            :
            <div>
                <div className="container-fluid" style={{ marginLeft: '-10px' }}>
                    <Breadcrum title={"Staff List"} />
                    <div className="row mt-3">
                        <div className="col-12">
                            <ReportTable title={props.staffList.length + " Records"} columns={columns} data={generalDatatable} height="700px" />
                        </div>

                        <Modal id="permission-modal" title={`Assign Permission`} close={"permission-close"}  >
                            <div className='row'>
                                <div className='col-md-12'>
                                    <form onSubmit={onSubmit} >
                                        <label className='form-label' >Permission</label>
                                        <div className="input-group mb-3">
                                            <select type="text"
                                                className="form-control form-control-lg"
                                                onChange={onEdit} id="Permission"
                                                value={data.Permission} required >
                                                <option value={""} >--select--</option>
                                                <option value={"superadmin"} >Super Admin</option>
                                                <option value={"hr"} >HR</option>
                                                <option value={"panelist"} >Panelist</option>
                                                <option value={"service desk"} >Service Desk</option>
                                            </select>
                                        </div>
                                        <br />
                                        <label className='form-label' >Staff</label>
                                        <div className="input-group mb-3">
                                            <input type={"text"}
                                                disabled
                                                className="form-control" id="NetworkEmailSubject"
                                                value={data.StaffName} onChange={onEdit} />
                                        </div>

                                        <div className="input-group mb-3">
                                            <button type='submit' className='btn btn-md btn-main w-100'>
                                                Assign
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>
    );
}

const mapStateToProps = (state) => {
    return {
        AdminLoginData: state.AdminLoginData,
        AllJobApplications: state.AllJobApplications,
        staffList: state.staffList,
        permissionsList: state.permissionsList
    };
};

const mapDisptachToProps = (dispatch) => {
    return {
        setOnUsersList: (p) => {
            dispatch(setUsersListAction(p))
        }
    }
}


export default connect(mapStateToProps, mapDisptachToProps)(SettingsStaffList);


