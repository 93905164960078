import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { serverLink } from '../../../../../util/url';
import { useFormik } from 'formik';
import { EmplymentHistoryValidationScehma } from '../../../../formik/validationSchema';
import { toast } from 'react-toastify';
import Loader from '../../../../../common/loader';
import JoditEditor from "jodit-react";
import * as DOMPurify from 'dompurify';


export default function EnrolmentEmploymentHistory(props) {
    const [isLoading, setIsLoading] = useState(true)
    const applicant = props.applicant
    const [HrQualifications, setHrQualifications] = useState([])
    const [EmpHistory, setEmpHistory] = useState([]);
    const controller = new AbortController();
    const [showForm, setShowForm] = useState(false);
    const [data, setData] = useState({
        Duties: ""
    })


    const getData = async () => {
        const { signal } = controller.signal
        axios.get(`${serverLink}/enrollment/get_employment_History/${applicant[0]?.EntryID}`, { signal }).then((res) => {
            if (res.data.length > 0) {
                setEmpHistory(res.data)
            }
            setIsLoading(false)
        })
    }

    useEffect(() => {
        getData();

        return () => {
            controller.abort();
        }
    }, [])


    const RemoveEmploymentHistory = async (e) => {
        try {
            await axios.delete(`${serverLink}/enrollment/remove_employment_history/${e}`).then((res) => {
                if (res.data.message === "success") {
                    getData();
                    toast.success('Record removed')
                } else {
                    toast.error('Error removing record')
                }
            })
        } catch (e) {
            toast.error("network error!")
        }
    }

    const submitEmpHistory = (values, helpers) => {
        if (values.EntryID === "") {
            const vals = {
                ...values,
                Duties: data.Duties
            }
            if (data.Duties === "") {
                toast.error("please enter duties");
                return false
            }
            try {

                axios.post(`${serverLink}/enrollment/add_employment_history`, vals).then((res) => {
                    if (res.data.message === "success") {
                        setData({
                            ...data,
                            Duties: ""
                        })
                        formik.resetForm();
                        ResetValues();
                        toast.success('emplyment history successfully Added');
                        getData();
                    } else {
                        toast.error("failed to add emplyment history, please try again")
                    }
                })
            } catch (e) {
                toast.error("network error!")
            }
        }
    }


    const formik = useFormik({
        initialValues: {
            EntryID: "",
            ApplicantID: applicant[0]?.EntryID,
            Organization: "",
            Position: "",
            StartDate: "",
            EndDate: "",
            Duties: ""
        },
        validationSchema: EmplymentHistoryValidationScehma,
        onSubmit: (values, helpers) => { submitEmpHistory(values, helpers) }
    })

    const ResetValues = () => {
        formik.setValues({
            ...formik.values,
            EntryID: "",
            QualificationID: "",
            Organization: "",
            Position: "",
            StartDate: "",
            EndDate: "",
            Duties: ""
        });
    }

    const toggleForm = () => {
        if (showForm === true) {
            setShowForm(false)
        } else {
            setShowForm(true)
        }

    }

    return isLoading ? (<Loader />) : (
        <div>

            <div className='row justify-content-between'>
                <div>
                    <h2>Employment History (Optional)</h2>
                    <small>toggle the button to show or hide the form</small>
                </div>
                <div>
                    <button className='btn btn-sm btn-get-started' onClick={toggleForm}>
                        {showForm === true ? "Hide Form" : "Show Form"}
                    </button>
                </div>
            </div>
            <br />
            {
                showForm &&
                <div className='row'>
                    <div className='col-lg-8 offset-lg-2 col-sm-12'>
                        <form className="m-t-30 needs-validation" onSubmit={formik.handleSubmit} noValidate >
                            <div className="form-group">
                                <label htmlFor="Organization">Organization</label>
                                <input type="text" id="Organization" name="Organization" placeholder="Organization" onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.Organization}
                                    className={formik.errors.Organization && formik.touched.Organization ? 'form-control is-invalid' : 'form-control'} required
                                />
                                {formik.errors.Organization && formik.touched.Organization &&
                                    <div className='invalid-feedback'>{formik.errors.Organization}
                                    </div>
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="Position">Position</label>
                                <input type="text" id="Position" name="Position" placeholder="Position"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.Position}
                                    className={formik.errors.Position && formik.touched.Position ? 'form-control is-invalid' : 'form-control'} required
                                />
                                {formik.errors.Position && formik.touched.Position &&
                                    <div className='invalid-feedback'>{formik.errors.Position}
                                    </div>
                                }
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-group">
                                        <label htmlFor="StartDate">Start Date</label>
                                        <input type="date" id="StartDate" name="StartDate" placeholder="e.g 2001"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.StartDate}
                                            className={formik.errors.StartDate && formik.touched.StartDate ? 'form-control is-invalid' : 'form-control'} required
                                        />
                                        {formik.errors.StartDate && formik.touched.StartDate &&
                                            <div className='invalid-feedback'>{formik.errors.StartDate}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group">
                                        <label htmlFor="EndDate">Start Date</label>
                                        <input type="date" id="EndDate" name="EndDate" placeholder="e.g 2001"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.EndDate}
                                            className={formik.errors.EndDate && formik.touched.EndDate ? 'form-control is-invalid' : 'form-control'} required
                                        />
                                        {formik.errors.EndDate && formik.touched.EndDate &&
                                            <div className='invalid-feedback'>{formik.errors.EndDate}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="Duties">Duties</label>

                                <JoditEditor
                                    value={data.Duties}
                                    tabIndex={1}
                                    id="Duties"
                                    name="Duties"
                                    required
                                    onChange={(e) => {
                                        setData({
                                            ...data,
                                            Duties: e
                                        });
                                    }}
                                />

                            </div>


                            <button type="submit" disabled={formik.isValid === true ? false : true} className="btn btn-get-started w-100">Submit</button>
                        </form>
                    </div>
                </div>
            }

            <br />
            <div className='table-responsive'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>SN</th>
                            <th>Organization </th>
                            <th>Position</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th className='w-25'>Duties</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            EmpHistory.length > 0 ?
                                EmpHistory.map((x, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{x.Organization}</td>
                                            <td>{x.Position}</td>
                                            <td>{x.StartDate}</td>
                                            <td>{x.EndDate}</td>
                                            <td><span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(x.Duties) }} /></td>
                                            <td>
                                                <button className='btn btn-sm btn-danger' onClick={() => RemoveEmploymentHistory(x.EntryID)}>
                                                    <i className='ti-trash' />
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <tr >
                                    <td colSpan={5} >
                                        No Data
                                    </td>
                                </tr>

                        }
                    </tbody>

                </table>
            </div>
        </div>
    )
}
