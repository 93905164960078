import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { setAdminLoginDetails, setPanelJobListAction } from '../../../../actions/actions';
import { serverLink } from '../../../../util/url';
import Breadcrum from "../../../../common/breadcrum/breadcrum";
import Modal from "../../../common/modal";
import { showAlert } from "../../../../common/sweetalert/sweetalert";
import { calculateAge, CheckPermission } from "../../../../util/constants";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../../common/loader";
import Select from "react-select";

function InterviewPanelAssessment(props) {
    const allow = props.permissionsList.length > 0 ? props.permissionsList.filter(x => x.Designation === 'panelist' || x.Designation === 'hr' || x.Designation === 'superadmin').length > 0 ? true : false : false;
    CheckPermission(allow);

    const navigate = useNavigate();
    const [IsHR, setIsHR] = useState(props.IsHR);
    const [StaffID, setStaffID] = useState(props.PanelDetails?.staff_id);
    const [isLoading, setIsLoading] = useState(true);
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [totalScore, setTotalScore] = useState(0);
    const [staffTotalScore, setStaffTotalScore] = useState(0);
    const [assessmentTotalScore, setAssessmentTotalScore] = useState(0);
    const [applicantRecommendation, setApplicantRecommendation] = useState([]);
    const [applicantScore, setApplicantScore] = useState([]);
    const [positions, setPositions] = useState([]);
    const [hrDesignations, setHrDesignations] = useState(props.hrDesignations.length > 0 ? props.hrDesignations : [])
   
    const [formData, setFormData] = useState({
        EntryID: "",
        ApplicantID: props.Interviewee.EntryID,
        JobID: props.JobData.RequisitionID,
        PanelRecommendation: "",
        HRRecommendation: "",
        PositionOffered: "",
        PositionOffered2: "",
        InsertedBy: `${props.AdminLoginData[0]?.StaffID}`,
    })

    const resetFormData = () => {
        return setFormData({
            ...formData,
            EntryID: "",
            PanelRecommendation: "",
            HRRecommendation: "",
            PositionOffered: "",
            PositionOffered2: "",
        });
    }
    const [sectionsList, setSectionsList] = useState([]);

    const getData = async () => {

        const sections = await axios.get(`${serverLink}/enrollment/interview/assessment/section-settings`);

        setSectionsList([{ EntryID: 0, section: "Personal Information" }, ...sections.data]);


        await axios.get(`${serverLink}/enrollment/interview/applicant/assessment/data/${props.Interviewee?.EntryID}/${IsHR ? StaffID : props.AdminLoginData[0]?.StaffID}/${props.JobData?.RequisitionID}`,).then((res) => {
            const data = res.data;
            if (data.Recommendation.length > 0) {
                setApplicantScore(data.Score);
                setStaffTotalScore(data.Score.length > 0 && data.Score.filter(e => parseFloat(e.Score, 10) && e.Section.toString() !== "1").map(j => parseFloat(j.Score)).reduce((a, b) => a + b, 0))
                setApplicantRecommendation(data.Recommendation);

                let rows = [];
                hrDesignations.length > 0 &&
                    hrDesignations.filter(e => e.DesignationName === data.Recommendation[0]?.PanelPositionRecommend).map((x, i) => {
                        rows.push({ value: x.DesignationName, label: x.DesignationName });
                    })
                setPositions(rows)

                setFormData({
                    ...formData,
                    PanelRecommendation: data.Recommendation[0]?.PanelRecommendation,
                    HRRecommendation: data.Recommendation[0]?.HRRecommendation,
                    PositionOffered: data.Recommendation[0]?.PanelPositionRecommend,
                    PositionOffered2: [...rows],
                });
            }
            setIsLoading(false);
        })
            .catch((err) => {
                toast.error("NETWORK ERROR. Please try again!");
            });
    }

    useEffect(() => {
        getData();
        let sum = props.InterviewSettings.length > 0 && props.InterviewSettings.map(j => parseFloat(j.ItemScore)).reduce((a, b) => a + b, 0);
        setTotalScore(sum)

        let rows = [];
        hrDesignations.length > 0 &&
            hrDesignations.map((x, i) => {
                rows.push({ value: x.DesignationName, label: x.DesignationName });
            })
        setPositions(rows)
    }, [])

    const onEdit = (e) => {
        let id = e.target.id;
        let value = e.target.value;
        let score = e.target.getAttribute('score');

        if (parseInt(value) > parseInt(score)) {
            showAlert("ERROR", `Score/Point for the selected field can not be more than ${score.toString()}`, "error");
            document.getElementById(id.toString()).value = "";
            return false;
        }

        if (id === "PanelRecommendation") {
            setFormData({
                ...formData,
                PanelRecommendation: value
            });
        }
        if (id === "HRRecommendation") {
            setFormData({
                ...formData,
                HRRecommendation: value
            });
        }

        let localScoreArray = [];
        let input = document.getElementsByClassName("score");

        for (let i = 0; i < input.length; i++) {
            if (input[i].value !== "") {
                let num = parseFloat(input[i].value)
                localScoreArray.push(num)
            }
        }
        let sum = localScoreArray.map(j => j).reduce((a, b) => a + b, 0)
        setAssessmentTotalScore(sum)
    };

    const handlePositionOffered = (e) => {
        setFormData({
            ...formData,
            PositionOffered: e.value,
            PositionOffered2: e
        })
    }

    const onBiodataChange = (e) => {
        let id = e.target.id;
        let value = e.target.value;
        setFormData({
            ...formData,
            [e.target.id]: value
        });
    };

    const onSubmit = async () => {

        let scoreArray = [];
        let assessmentArray = [];
        let input = document.getElementsByClassName("score");
        let biodataInput = document.getElementsByClassName("bio");

        for (let i = 0; i < input.length; i++) {
            if (input[i].value.toString() === "") {
                showAlert("Empty Field", "All score fields are required", "error");
                return false;
                break;
            }
            scoreArray.push(input[i].value)
            assessmentArray.push(input[i].getAttribute("assessment_id"))
        }

        for (let i = 0; i < biodataInput.length; i++) {
            if (biodataInput[i].value.toString() === "") {
                showAlert("Empty Field", "All bio-data fields are required", "error");
                return false;
                break;
            }
            scoreArray.push(biodataInput[i].value)
            assessmentArray.push(biodataInput[i].getAttribute("settings_id"))
        }

        if (formData.PanelRecommendation === "") {
            showAlert("Empty Field", "Panel Recommendation is required", "error");
            return false;
        }

        if (formData.PositionOffered === "") {
            showAlert("Empty Field", "Position Recommend is required", "error");
            return false;
        }

        let sendData = { ...formData, Score: scoreArray, AssessmentID: assessmentArray }

        setIsFormLoading(true);
        if (IsHR) {
            await axios.post(`${serverLink}/enrollment/interview/assessment/hr`, sendData).then((result) => {
                if (result.data.message === "success") {
                    toast.success("Interview Assessment Submitted Successfully");
                    setIsFormLoading(false);
                    getData();
                    resetFormData()

                } else {
                    setIsFormLoading(false);
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                }
            })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        } else {
            await axios.post(`${serverLink}/enrollment/interview/assessment/panel`, sendData).then((result) => {
                if (result.data.message === "success") {
                    toast.success("Interview Assessment Submitted Successfully");
                    setIsFormLoading(false);
                    getData();
                    resetFormData()
                    setTimeout(() => {
                        navigate("/interview/panel");
                    }, 3000)
                } else {
                    setIsFormLoading(false);
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                }
            })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }

    const getIntervieweeData = (data, interviewee) => {
        if (data.toLowerCase().includes('name')) {
            return interviewee.FirstName + " " + interviewee.MiddleName + " " + interviewee.Surname;
        } else if (data.toLowerCase().includes('position')) {
            return props.JobData?.Position;
        } else if (data.toLowerCase().includes('marital') || data.toLowerCase().includes('children')) {
            return interviewee.MaritalStatus;
        } else if (data.toLowerCase().includes('age') || data.toLowerCase().includes('birth')) {
            return calculateAge(interviewee.DateOfBirth);
        } else {
            return "";
        }
    }

    return (
        isLoading ?
            <Loader /> :
            <div>
                <div className="container-fluid" style={{ marginLeft: '-10px' }}>
                    <Breadcrum title={IsHR ? `Panel Assessment Form 
                    (${applicantRecommendation[0]?.StaffName} -- ${applicantRecommendation[0]?.PanelInsertedBy})` : `Panel Assessment Form `} />
                    <div className="row mt-3">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="align-items-between" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <h4 className="card-title">Job Position: {props.JobData?.Position} -- {props.JobData?.Department}</h4>
                                    </div>
                                    <hr />
                                    {
                                        props.InterviewSettings?.length > 0 ?

                                            <div className="table-responsive">
                                                <div className="p-3" style={{ backgroundColor: '#eeeeee', fontWeight: 'bold' }}><h4><b>Applicant Bio-data</b></h4></div>
                                                <table className="table table-bordered">
                                                    <tbody>
                                                        {
                                                            props.InterviewSettings.length > 0 ?
                                                                props.InterviewSettings.filter(e => e.Section.toString() === "0").map((x, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <th width={300}>{x.ItemName}</th>
                                                                            <td><input type="text" name="input[]" disabled={IsHR || applicantScore.length > 0} className="form-control bio" id={`user${x.EntryID}`} settings_id={x.EntryID} defaultValue={applicantScore.length > 0 ? applicantScore.filter(e => e.AssessmentID.toString() === x.EntryID.toString())[0]?.Score : getIntervieweeData(x.ItemName, props.Interviewee)} onChange={onBiodataChange} style={{ width: '450px' }} /></td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                :
                                                                <></>
                                                        }
                                                    </tbody>
                                                </table>
                                                <table className="table   table-bordered w-100">
                                                    <thead className="fs-6 fw-semibold">
                                                        <tr>
                                                            <th className="fs-4 fw-bold">Area of Scoring</th>
                                                            <th className="fs-4 fw-bold" width="200">Maximum Scores/Point</th>
                                                            <th className="fs-4 fw-bold" width="200">Scores/Points</th>
                                                        </tr>
                                                    </thead>
                                                    {
                                                        sectionsList.filter(e => e.EntryID.toString() !== "0" &&
                                                            props.InterviewSettings.map(j => j.Section.toString()).includes(e.EntryID.toString())).map((item, int) => {
                                                                return (
                                                                    <tbody key={int} className="fs-6 fw-semibold">
                                                                        <tr>
                                                                            <td colSpan={3} className="" style={{ backgroundColor: '#eeeeee', fontWeight: 'bold' }}>
                                                                                <h4><b>
                                                                                    {item.SectionName}
                                                                                </b>
                                                                                </h4>
                                                                            </td>
                                                                        </tr>

                                                                        {
                                                                            props.InterviewSettings.length > 0 ?
                                                                                props.InterviewSettings.filter(e => e.Section.toString() !== "0" && e.Section.toString() === item.EntryID.toString()).map((x, i) => {
                                                                                    return (<tr key={i}>
                                                                                        <td className="font-14">{x.ItemName}</td>
                                                                                        <td className="min-w-250px fs-4 ">{x.ItemScore}</td>
                                                                                        <td className="w-125px">
                                                                                            <input
                                                                                                type="number"
                                                                                                disabled={IsHR || applicantScore.length > 0}
                                                                                                className="form-control score form-control-solid"
                                                                                                name="input[]"
                                                                                                defaultValue={applicantScore.length > 0 ? applicantScore.filter(e => e.AssessmentID.toString() === x.EntryID.toString())[0]?.Score : ""}
                                                                                                id={`input${x.EntryID}`}
                                                                                                max={x.ItemScore}
                                                                                                min={0}
                                                                                                score={x.ItemScore}
                                                                                                assessment_id={x.EntryID}
                                                                                                placeholder=" Enter Score"
                                                                                                onChange={onEdit}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                    )
                                                                                })
                                                                                :
                                                                                <tr>
                                                                                    <td colSpan={5}>No Record Found</td>
                                                                                </tr>
                                                                        }

                                                                    </tbody>
                                                                )
                                                            })
                                                    }

                                                    <tbody>
                                                        <tr style={{ fontSize: '22px', fontWeight: 'bold' }}>
                                                            <td className="text-end">Total:</td>
                                                            <td>{totalScore}</td>
                                                            <td>{staffTotalScore > 0 ? staffTotalScore : assessmentTotalScore}</td>
                                                        </tr>
                                                    </tbody>

                                                </table>
                                            </div>
                                            :
                                            <div className='alert alert-info text-center'>
                                                <h3>Interview Score Settings Not Added</h3>
                                            </div>
                                    }
                                    <div className="row mt-5">
                                        <div className="form-group col-md-6">
                                            <label>Panel Recommendation</label>
                                            <select className="form-control" name="PanelRecommendation" id="PanelRecommendation" disabled={IsHR || applicantScore.length > 0} onChange={onEdit} value={formData.PanelRecommendation}>
                                                <option value="">Select Recommendation</option>
                                                <option value="Recommended for the job">Recommended for the job</option>
                                                <option value="Not recommended for the job">Not recommended for the job</option>
                                            </select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Position Recommend</label>
                                            <Select
                                                id="PositionRecommend"
                                                className="form-select form-select"
                                                value={formData.PositionOffered2}
                                                onChange={handlePositionOffered}
                                                options={positions}
                                                placeholder="Position Recommend"
                                                isDisabled={IsHR || applicantScore.length > 0}
                                            />
                                        </div>
                                    </div>

                                    {
                                        IsHR ?
                                            <></>
                                            :
                                            applicantRecommendation.length < 1 ?
                                                <div className="form-group col-md-4 offset-sm-4 mt-5 mb-4">
                                                    {
                                                        IsFormLoading ?
                                                            <button className="btn btn-main float-right" type="button" disabled>
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                                                Loading...
                                                            </button>
                                                            :
                                                            <button type="button" className="btn btn-main form-control" onClick={onSubmit}>SUBMIT</button>
                                                    }

                                                </div>
                                                :
                                                <></>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
}

const mapStateToProps = (state) => {
    return {
        AdminLoginData: state.AdminLoginData,
        Interviewee: state.interviewee,
        JobData: state.panelJobList,
        PanelDetails: state.panelDetails,
        InterviewSettings: state.assessmentSettings,
        hrDesignations: state.hrDesignations,
        permissionsList: state.permissionsList
    };
};
const mapDisptachToProps = (dispatch) => {
    return {
        setOnPanelJobData: (p) => {
            dispatch(setPanelJobListAction(p))
        },
    }
}

export default connect(mapStateToProps, mapDisptachToProps)(InterviewPanelAssessment);


