import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { setAssessmentSettingsAction, setPanelDetailsAction } from '../../../../../actions/actions';
import Loader from '../../../../../common/loader';
import { serverLink } from '../../../../../util/url';
import Breadcrum from "../../../../../common/breadcrum/breadcrum";
import ReportTable from "../../../../common/report_table";
import Modal from "../../../../common/modal";
import { showAlert } from "../../../../../common/sweetalert/sweetalert";
import { Link, useNavigate } from "react-router-dom";
import Charts from "../../../../common/chart";
import JoditEditor from "jodit-react";
import { CheckPermission, MailTemplates, currentDate, formatDate, salaryScale, sendEmail } from "../../../../../util/constants";
import Select from "react-select";

function InterviewHRAssessment(props) {
    const allow = props.permissionsList.length > 0 ? props.permissionsList.filter(x => x.Designation === 'superadmin' || x.Designation === 'hr').length > 0 ? true : false : false;
    CheckPermission(allow);
    const navigate = useNavigate();
    const controller = new AbortController();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [generalDatatable, setGeneralDatatable] = useState([]);
    const [scales, setScales] = useState([])
    const [HRRecommended, setHRRecommended] = useState([]);
    const [Recommended, setRecommended] = useState([]);
    const [NotRecommended, setNotRecommended] = useState([]);
    const [ScoreDistribution, setScoreDistribution] = useState([]);
    const [applicationStatus, setApplicationStatus] = useState([]);
    const [positions, setPositions] = useState([]);
    const [positions2, setPositions2] = useState([]);
    const [premium] = useState([]);
    const [hrDesignations, setHrDesignations] = useState(props.hrDesignations.length > 0 ? props.hrDesignations : [])
    const columns = ["S/N", "StaffID", "Staff Name", "Designation", "Department", "Scores Assessed", "Recommendation", "Action"];
    const [insightsCheck, setInsightCheck] = useState({ accepted: 0, invited: 0 })
    const [insights, setInsights] = useState([])


    const [formData, setFormData] = useState({
        EntryID: "",
        ApplicantID: props.Interviewee.EntryID,
        Name: props.Interviewee.FirstName,
        Title: "",
        EmailAddress: props.Interviewee.Email,
        JobID: props.JobData.RequisitionID,
        JobType: props.JobData.JobType,
        Position: props.JobData.Position,
        Department: props.JobData.Department,
        InterViewDate: props.JobData.InterViewDate,
        InterviewTime: props.JobData.InterviewTime,
        InterviewVenue: props.JobData.InterviewVenue,
        HRRecommendation: "",
        ResponseType: "",
        EmailObject: "",
        EmailSubject: "",
        EmailContentBody: "",
        EntryScale: "",
        EntryScale2: "",
        EntryStep: "",
        EntryStep2: "",
        PositionOffered: "",
        PositionOffered2: "",
        HRPositionRecommend: "",
        HRPositionRecommend2: "",
        Premium: "",
        GrossPay: 0,
        ApptDate: formatDate(currentDate),
        InsertedBy: `${props.AdminLoginData[0]?.StaffID}`,
    })

    const resetFormData = () => {
        return setFormData({
            ...formData,
            EntryID: "",
            Title: "",
            EmailSubject: "",
            ResponseType: "",
            EmailContentBody: "",
            EmailObject: "",
            EntryScale: "",
            EntryScale2: "",
            EntryStep: "",
            EntryStep2: "",
            PositionOffered: "",
            PositionOffered2: "",
            HRPositionRecommend: "",
            HRPositionRecommend2: "",
            Premium: "",
        });
    }

    const handleEntryScale = (e) => {
        setFormData({
            ...formData,
            EntryScale: e.value,
            EntryScale2: e
        })
    }
    const handleEntryStep = (e) => {
        setFormData({
            ...formData,
            EntryStep: e.value,
            EntryStep2: e
        })
    }
    const handlePositionOffered = (e) => {
        setFormData({
            ...formData,
            PositionOffered: e.value,
            PositionOffered2: e
        })
    }

    const handleHRPositionRecommend = (e) => {
        setFormData({
            ...formData,
            HRPositionRecommend: e.value,
            HRPositionRecommend2: e
        })
    }

    const getData = async () => {
        const { data } = await axios.get(`${serverLink}/enrollment/get_job_application/by-job/${formData?.JobID}`);
        const all = data.length
        const invited = data?.filter(x => x.ApplicationStatus === "1").length
        const accepted = data?.filter(x => x.ApplicationStatus === "2").length
        const rejected = data?.filter(x => x.ApplicationStatus === "3").length
        setInsightCheck({ ...insightsCheck, accepted: accepted, invited: invited });
        const enrolled = data?.filter(x => x.ApplicationStatus === "2" && x.EnrolmentStatus === "1").length;
        let tt = [["Type", "Count"], ["No of Openings", parseInt(data[0]?.NoOfOpenings)], ["All Applications", all], ["Accepted Applications", accepted], ["Rejected Applications", rejected], ["Enrolled Applications", enrolled]];
        setInsights(tt)


        await axios.get(`${serverLink}/enrollment/interview/hr/applicant/assessment/data/${formData?.ApplicantID}/${formData?.JobID}/${formData?.JobType}`,).then((res) => {
            const Panels = res.data.Panels;
            const Recommendation = res.data.Recommendation;
            const Scores = res.data.Scores;
            const PanelID = res.data.PanelID;
            const HRRecommendation = res.data.HRRecommendation;
            const ApplicationStatus = res.data.ApplicationStatus;
            let settingsData = res.data.Settings;
            let rows = [];

            props.setOnInterviewSettingsData(settingsData);

            setHRRecommended(!HRRecommendation[0]?.HRRecommendation ? [] : HRRecommendation)
            setApplicationStatus(ApplicationStatus)
            setRecommended(Recommendation.filter(e => e.PanelRecommendation === "Recommended for the job"))
            setNotRecommended(Recommendation.filter(e => e.PanelRecommendation === "Not recommended for the job"))

            if (!(!Recommendation[0]?.HRPositionRecommend)) {
                let rows2 = [];
                hrDesignations.length > 0 &&
                    hrDesignations.filter(e => e.DesignationName === Recommendation[0]?.HRPositionRecommend).map((x, i) => {
                        rows2.push({ value: x.DesignationName, label: x.DesignationName });
                    })
                setPositions2(rows2)

                setFormData({
                    ...formData,
                    HRRecommendation: HRRecommendation[0]?.HRRecommendation,
                    HRPositionRecommend: Recommendation[0]?.HRPositionRecommend,
                    HRPositionRecommend2: [...rows2],
                })
            }


            let score_data = [];
            let staff = "";
            for (let i = 0; i < Panels.length; i++) {
                let scores = 0;
                staff = Panels[i].staff_name;
                Scores.filter(e => e.InsertedBy === Panels[i].staff_id).length > 0 && Scores.filter(e => e.InsertedBy === Panels[i].staff_id).map((x, j) => {
                    if (Panels[i].staff_id === x.InsertedBy) {
                        if (x.Section.toString() !== "1") scores += parseInt(x.Score);
                    }
                })
                score_data.push([staff, scores])
            }

            let score_analytics = [["Panel Members", "Score"], ...score_data]
            setScoreDistribution(score_analytics)

            if (Panels.length > 0) {
                setData(Panels);
                Panels.map((item, index) => {
                    rows.push([
                        index + 1,
                        item.staff_id,
                        item.staff_name,
                        item.designation,
                        item.DepartmentName,
                        Scores.filter(e => e.InsertedBy === item.staff_id && e.ApplicantID.toString() === formData?.ApplicantID.toString() && e.JobID.toString() === formData?.JobID.toString() && e.Section.toString() !== "1").map(j => parseFloat(j.Score)).reduce((a, b) => a + b, 0),
                        Recommendation.filter(e => e.PanelInsertedBy === item.staff_id && e.ApplicantID.toString() === formData?.ApplicantID.toString() && e.JobID.toString() === formData?.JobID.toString())[0]?.PanelRecommendation,
                        Recommendation.filter(e => e.PanelInsertedBy === item.staff_id && e.ApplicantID.toString() === formData?.ApplicantID.toString() && e.JobID.toString() === formData?.JobID.toString()).length > 0 ? (
                            <>

                                <Link disabled={true} to={`/interview/hr/panel/assessment`}
                                    className="btn btn-sm btn-main"
                                    onClick={() => {
                                        props.setOnPanelDetails(item);
                                    }}
                                >
                                    <i className="fa fa-eye" />
                                </Link>

                            </>
                        ) : "--",
                    ]);
                });
            }
            setGeneralDatatable(rows);
            setIsLoading(false);
        })
            .catch((err) => {
                setIsLoading(false);
                toast.error("NETWORK ERROR. Please try again!");
            });
    }

    useEffect(() => {
        getData();
        let rows = [];
        let rows2 = [];
        salaryScale.length > 0 &&
            salaryScale.map((x, i) => {
                rows2.push({ value: x.val, label: x.text });
            })

        hrDesignations.length > 0 &&
            hrDesignations.map((x, i) => {
                rows.push({ value: x.DesignationName, label: x.DesignationName });
            })
        setPositions(rows)
        setPositions2(rows)
        setScales(rows2)
        getPremium()
        return () => { controller.abort() }
    }, []);

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const getPremium = () => {
        for (let i = 1; i <= 100; i++) {
            premium.push(i);
        }
    }

    const handleHRRecommendation = async () => {

        if (formData.HRRecommendation.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select recommendation", "error");
            return false;
        }

        if (formData.HRPositionRecommend.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select position recommend", "error");
            return false;
        }

        let sendData = { ...formData }
        const { signal } = controller.signal;

        setIsFormLoading(true);
        await axios
            .post(`${serverLink}/enrollment/interview/assessment/HR`, sendData, { signal })
            .then((result) => {
                if (result.data.message === "success") {
                    toast.success("Recommendation Submitted Successfully");
                    setIsFormLoading(false);
                    getData();
                    resetFormData()

                } else {
                    setIsFormLoading(false);
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                }
            })
            .catch((error) => {
                controller.abort();
                setIsFormLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });

    }

    const onSubmit = async () => {

        if (formData.EmailAddress.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Applicant email can not be empty", "error");
            return false;
        }

        if (formData.EmailObject.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Email Content can not be empty", "error");
            return false;
        }

        if (formData.ResponseType === "Approved") {
            if (formData.PositionOffered.toString().trim() === "") {
                showAlert("EMPTY FIELD", "Please select position offered", "error");
                return false;
            }

            if (formData.EntryScale.toString().trim() === "") {
                showAlert("EMPTY FIELD", "Please select salary scale (buss)", "error");
                return false;
            }

            if (formData.EntryStep.toString().trim() === "") {
                showAlert("EMPTY FIELD", "Please select step", "error");
                return false;
            }

            if (formData.Premium.toString().trim() === "") {
                showAlert("EMPTY FIELD", "Please select premium percentage", "error");
                return false;
            }
            if (parseInt(formData.GrossPay) === 0) {
                showAlert("EMPTY FIELD", "Please enter gross pay", "error")
                return false;
            }
            if (formData.ApptDate === 0) {
                showAlert("EMPTY FIELD", "Please select appointment date", "error")
                return false;
            }

            if ((parseInt(insightsCheck.invited) + parseInt(insightsCheck.accepted)) >= parseInt(props.JobData?.NoOfOpenings)) {
                toast.error("The maximum number of openings has been reached, if you wish to accept more applicants, kindly increase the number of openings for this job ")
                return false;
            }
        }

        let sendData = { ...formData }
        const { signal } = controller.signal;

        setIsFormLoading(true);
        await axios
            .post(`${serverLink}/enrollment/interview/assessment/HR/response`, sendData, { signal })
            .then((result) => {
                if (result.data.message === "success") {
                    toast.success("Email Sent Successfully");
                    setIsFormLoading(false);
                    getData();
                    document.getElementById("modal-close").click();
                    resetFormData()
                    sendEmail(formData.EmailAddress, formData.EmailObject.subject, formData.Title, formData.Name, formData.EmailContentBody, '')
                } else {
                    setIsFormLoading(false);
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                }
            })
            .catch((error) => {
                controller.abort();
                setIsFormLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });


    }

    return (
        isLoading ? <Loader />
            :
            <div>
                <div className="container-fluid" style={{ marginLeft: '-10px' }}>
                    <Breadcrum title={`HR Interview Assessment`} />
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body text-uppercase">
                                    <div className="row">
                                        <div className="col-md-4"> <h5> <b>Applicant:</b>  {props.Interviewee?.FirstName} {props.Interviewee?.MiddleName} {props.Interviewee?.Surname}</h5></div>
                                        <div className="col-md-8"> <h5> <b>Position:</b>  {props.JobData?.Position} </h5></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <div>
                                        <h5 className="card-title">Recommendations By Panel Members</h5>
                                    </div>
                                    <div className='mt-3'>
                                        <div className="row">
                                            <div className="p-3 shadow col-md-6">
                                                <h1 className="font-bold">
                                                    {
                                                        Recommended?.length
                                                    }
                                                </h1>
                                                <span className='h6'>Recommended</span>
                                            </div>
                                            <div className="p-3 shadow col-md-6">
                                                <h1 className="font-bold">
                                                    {
                                                        NotRecommended?.length
                                                    }
                                                </h1>
                                                <span className='h6'>Not Recommended</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="p-3 mt-2 shadow col-md-12">
                                            {
                                                HRRecommended?.length > 0 ?
                                                    HRRecommended[0].HRRecommendation === "Recommended for the job" ?
                                                        <h5 className="font-bold text-success text-center  alert alert-success">
                                                            {
                                                                HRRecommended[0].HRRecommendation
                                                            }
                                                        </h5>
                                                        :
                                                        <h5 className="font-bold text-danger text-center  alert alert-danger">
                                                            {
                                                                HRRecommended[0].HRRecommendation
                                                            }
                                                        </h5>
                                                    :
                                                    <h5 className="font-bold text-info text-center alert alert-info">
                                                        Recommendation Not Submitted
                                                    </h5>
                                            }
                                            <div className='h5 text-center p-2'>HR Recommendation</div>
                                        </div>
                                        <div className="p-3 mt-2 shadow col-md-12">
                                            {
                                                applicationStatus?.length > 0 ?
                                                    applicationStatus[0].ApplicationStatus.toString() === "2" ?
                                                        <h5 className="font-bold text-success text-center  alert alert-success">
                                                            {
                                                                "Application Approved"
                                                            }
                                                        </h5>
                                                        :
                                                        applicationStatus[0].ApplicationStatus.toString() === "3" ?
                                                            <h5 className="font-bold text-danger text-center  alert alert-danger">
                                                                {
                                                                    "Application Rejected"
                                                                }
                                                            </h5>
                                                            :
                                                            <h5 className="font-bold text-info text-center  alert alert-info">
                                                                {
                                                                    'Application Pending'
                                                                }
                                                            </h5>
                                                    :
                                                    <h5 className="font-bold text-warning text-center  alert alert-warning">
                                                        {
                                                            "No Application"
                                                        }
                                                    </h5>
                                            }

                                            <div className='h5 text-center p-2'>Application Status</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-12 order-lg-2 order-md-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <h4 className="card-title">Interview Assessment Scores By Panel Members</h4>
                                        </div>
                                    </div>

                                    <Charts title="Interview Assessment Scores By Panel"
                                        pieHole={"0.5"}
                                        height={"400px"}
                                        type="Bar"
                                        data={ScoreDistribution} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">

                        <div className="col-lg-12 col-xlg-12 col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className='m-l-4'>Insights for <strong>{props.JobData?.Position + ` (${props.JobData?.RequisitionID})`} --- {props.JobData?.Department} </strong></h4>
                                    {
                                        insights.length > 0 ?
                                            <Charts title={props.JobData?.Position + `(${props.JobData?.RequisitionID})`}
                                                height={"300px"}
                                                type="Bar"
                                                data={insights} />
                                            :
                                            <span className='text-center mt-5'>
                                                <i className='fa fa-spin fa-spinner fa-5x' />
                                            </span>
                                    }

                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <ReportTable title={"Interview Panel"} columns={columns} data={generalDatatable} height={"250px"} />
                            <div className="card mt-3">
                                <div className="card-body">
                                    <div className="row">
                                        {
                                            <>
                                                <div className="form-group col-md-3">
                                                    <label>HR Recommendation</label>
                                                    <select className="form-control" name="HRRecommendation" id="HRRecommendation" disabled={HRRecommended.length > 0} onChange={onEdit} value={formData.HRRecommendation}>
                                                        <option value="">Select Recommendation</option>
                                                        <option value="Recommended for the job">Recommended for the job</option>
                                                        <option value="Not recommended for the job">Not recommended for the job</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label>Position Recommend</label>
                                                    <Select
                                                        id="PositionRecommend"
                                                        className="form-select form-select"
                                                        value={formData.HRPositionRecommend2}
                                                        onChange={handleHRPositionRecommend}
                                                        options={positions2}
                                                        placeholder="Position Recommend"
                                                        isDisabled={HRRecommended.length > 0}
                                                    />
                                                </div>
                                            </>
                                        }
                                        {
                                            HRRecommended.length > 0 ?
                                                <>
                                                    <div className="form-group col-md-2">
                                                        <label>&nbsp;</label>
                                                        <button
                                                            disabled={applicationStatus[0]?.ApplicationStatus?.toString() === "2"}
                                                            onClick={(e) => {
                                                                const email = MailTemplates("2", formData);
                                                                setFormData({
                                                                    ...formData,
                                                                    EmailObject: email,
                                                                    ResponseType: "Approved",
                                                                    Title: "OFFER OF APPOINTMENT",
                                                                    EmailSubject: email.subject,
                                                                    EmailContentBody: email.body
                                                                })
                                                            }} className="form-control btn btn-success" type="button" data-toggle="modal" data-target=".bs-example-modal-lg"> <span className="fa fa-check-circle" /> Offer Job</button>
                                                    </div>
                                                    <div className="form-group col-md-2">
                                                        <label>&nbsp;</label>
                                                        <button disabled={applicationStatus[0]?.ApplicationStatus?.toString() === "3" || applicationStatus[0]?.ApplicationStatus?.toString() === "2"} onClick={(e) => {
                                                            const email = MailTemplates("3", formData);
                                                            setFormData({
                                                                ...formData,
                                                                EmailObject: email,
                                                                ResponseType: "Rejected",
                                                                Title: "NOTICE OF REJECTION",
                                                                EmailSubject: email.subject,
                                                                EmailContentBody: email.body
                                                            })
                                                        }} className="form-control btn btn-danger" type="button" data-toggle="modal" data-target=".bs-example-modal-lg"> <span className="fa fa-ban" /> Reject Job</button>
                                                    </div>
                                                    <div className="form-group col-md-2">
                                                        <label>&nbsp;</label>
                                                        <button onClick={() => {
                                                            navigate("/interview/hr/print/assessment")
                                                        }} className="form-control btn btn-main" type="button" > <span className="fa fa-print" />  Print Interview Score Sheet </button>
                                                    </div>
                                                </>
                                                :
                                                <div className="form-group col-md-6">
                                                    <label>&nbsp;</label>
                                                    {
                                                        IsFormLoading ?
                                                            <button className="btn btn-success float-right w-100" type="button" disabled>
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                                                Loading...
                                                            </button>
                                                            :
                                                            <div className="input-group mb-3">
                                                                <button type='button' className='btn btn-md btn-success w-100' onClick={handleHRRecommendation}>
                                                                    Submit
                                                                </button>
                                                            </div>
                                                    }
                                                </div>


                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal id="open" title="HR Response Form">
                    <form className="m-t-5">
                        <div className="row">
                            <div className="form-group col-md-6 mb-3">
                                <label className='form-label' >Applicant Name</label>
                                <input type="text" className="form-control" value={props.Interviewee?.FirstName + " " + props.Interviewee?.MiddleName + " " + props.Interviewee?.Surname} disabled />
                            </div>
                            <div className="form-group col-md-6 mb-3">
                                <label className='form-label' >Position Applied</label>
                                <input type="text" className="form-control" value={props.JobData?.Position} disabled />
                            </div>
                            {
                                formData.ResponseType === "Approved" ?
                                    <>
                                        <div className="form-group col-md-12 mb-3">
                                            <label htmlFor="Position Offered">Position Offered</label>
                                            <Select
                                                id="PositionOffered"
                                                className="form-select form-select"
                                                value={formData.PositionOffered2}
                                                onChange={handlePositionOffered}
                                                options={positions}
                                                placeholder="Position Offered"
                                            />
                                        </div>
                                        <div className="col-md-6 fv-row mb-3">
                                            <label className="required fs-5 fw-bold mb-2">BUSS</label>
                                            <Select
                                                id="EntryScale"
                                                className="form-select form-select"
                                                value={formData.EntryScale2}
                                                onChange={handleEntryScale}
                                                options={scales}
                                                placeholder="Select Buss"
                                            />
                                        </div>

                                        <div className="col-md-6 fv-row mb-3">
                                            <label className="required fs-5 fw-bold mb-2">Step</label>
                                            <Select
                                                id="EntryStep"
                                                className="form-select form-select"
                                                value={formData.EntryStep2}
                                                onChange={handleEntryStep}
                                                options={scales}
                                                placeholder="Select Step"
                                            />
                                        </div>

                                        <div className="form-group col-md-12 mb-3">
                                            <label htmlFor="Premium">Premium</label>
                                            <select className='form-control form-select form-control-solid'
                                                onChange={onEdit}
                                                id="Premium"
                                                value={formData.Premium}
                                                required>
                                                <option value={''}>Select Premium</option>
                                                {
                                                    premium.length > 0 &&
                                                    premium.map((x, i) => {
                                                        return (
                                                            <option key={i} value={x} > {x}%</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group col-md-6 mb-3">
                                            <label htmlFor="GrossPay">Gross Pay</label>
                                            <div className="input-group mb-3">
                                                <input type="number" step={0.00} className="form-control form-control-lg" onChange={onEdit} id="GrossPay"
                                                    value={formData.GrossPay} required />
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6 mb-3">
                                            <label htmlFor="ApptDate">Date of Appointment</label>
                                            <div className="input-group mb-3">
                                                <input type="date" value={formData.ApptDate} className="form-control form-control-lg" onChange={onEdit} id="ApptDate"
                                                    required />
                                            </div>
                                        </div>

                                    </>
                                    :
                                    <></>
                            }
                            <div className="form-group col-md-12 mb-3">
                                <label className='form-label' >Subject</label>
                                <input type="text" className="form-control" value={formData?.EmailSubject} disabled />
                            </div>
                            <div className="form-group col-md-12 mb-3">
                                <label className='form-label' >Email Body</label>
                                <JoditEditor
                                    value={formData.EmailContentBody}
                                    tabIndex={1}
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            EmailContentBody: e
                                        })
                                    }}
                                />
                                <hr />
                                {
                                    IsFormLoading ?
                                        <button className="btn btn-success float-right w-100" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                            Loading...
                                        </button>
                                        :
                                        <div className="input-group mb-3">
                                            <button type='button' className='btn btn-md btn-success w-100' onClick={onSubmit}>
                                                Submit
                                            </button>
                                        </div>
                                }

                            </div>

                        </div>
                    </form>
                </Modal>
            </div>
    );
}

const mapStateToProps = (state) => {
    return {
        AdminLoginData: state.AdminLoginData,
        Interviewee: state.interviewee,
        JobData: state.panelJobList,
        hrDesignations: state.hrDesignations,
        permissionsList: state.permissionsList
    };
};
const mapDisptachToProps = (dispatch) => {
    return {
        setOnPanelDetails: (p) => {
            dispatch(setPanelDetailsAction(p))
        },
        setOnInterviewSettingsData: (p) => {
            dispatch(setAssessmentSettingsAction(p))
        },
    }
}

export default connect(mapStateToProps, mapDisptachToProps)(InterviewHRAssessment);


