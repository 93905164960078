import React from 'react'

export default function Modal(props) {
    return (
        <div className="modal fade bs-example-modal-lg" id={props.id} tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel"
            style={{ display: 'none', }} aria-hidden="true">
            <div className="modal-dialog modal-lg"  >
                <div className="modal-content" >
                    <div className="modal-header">
                        <h4 className="modal-title" id="myLargeModalLabel">{props.title}</h4>
                        <button type="button" id={props.close !== undefined ? props.close : "modal-close"} className="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        {props.children}
                    </div>

                </div>
            </div>
        </div>

    )
}
