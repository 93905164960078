import { projectCode } from "../util/constants";

export const JobApplicantLoginReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${projectCode}_APPLICANT_LOGIN_DATA`:
            return action.payload;

        default:
            return state;
    }
};

export const JobListReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${projectCode}_JOB_LIST`:
            return action.payload;

        default:
            return state;
    }
};

export const AppliedJobsReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${projectCode}_APPLIED_JOBS`:
            return action.payload;

        default:
            return state;
    }
};

export const JobAdminLoginReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${projectCode}_ADMIN_LOGIN_DATA`:
            return action.payload;

        default:
            return state;
    }
};

export const AdminJobApplicationsReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${projectCode}_ADMIN_JOB_APPLICATIONS`:
            return action.payload;

        default:
            return state;
    }
};

export const SingleJobApplicationsReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${projectCode}_SINGLE_JOB_APPLICATION`:
            return action.payload;

        default:
            return state;
    }
};

export const staffListReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${projectCode}_STAFF_LIST`:
            return action.payload;

        default:
            return state;
    }
};

export const HrDesignationsReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${projectCode}_HR_DESIGNATIONS`:
            return action.payload;

        default:
            return state;
    }
};

export const departmentListReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${projectCode}_DEPARTMENT_LIST`:
            return action.payload;

        default:
            return state;
    }
};

export const facultyListReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${projectCode}_FACULTY_LIST`:
            return action.payload;

        default:
            return state;
    }
};


export const usersListReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${projectCode}_USERS_LIST`:
            return action.payload;

        default:
            return state;
    }
};

export const panelJobListReducer = (state = {}, action) => {
    switch (action.type) {
        case `SET_${projectCode}_PANEL_JOB_LIST`:
            return action.payload;

        default:
            return state;
    }
};

export const assessmentSettingsReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${projectCode}_ASSESSMENT_SETTINGS`:
            return action.payload;

        default:
            return state;
    }
};

export const intervieweeReducer = (state = {}, action) => {
    switch (action.type) {
        case `SET_${projectCode}_INTERVIEWEE`:
            return action.payload;

        default:
            return state;
    }
};

export const panelDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case `SET_${projectCode}_PANEL_DETAIL`:
            return action.payload;

        default:
            return state;
    }
};


export const AdminReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${projectCode}_ADMIN`:
            return action.payload;

        default:
            return state;
    }
}

export const NotificationsReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${projectCode}_NOTIFICATION`:
            return action.payload;

        default:
            return state;
    }
}


export const permissionsReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${projectCode}_PERMISSIONS`:
            return action.payload;

        default:
            return state;
    }
}

export const dashboarddataReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${projectCode}_DASHBAORD_DATA`:
            return action.payload;

        default:
            return state;

    }
}

export const activeCohortReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${projectCode}_ACTIVE_COHORT`:
            return action.payload;

        default:
            return state;

    }
}