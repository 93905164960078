import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

//Chart Types
// Bar
// PieChart
// LineChart

export default function Charts(props) {
  const options = {
    title: "",
    subtitle: !props.subtitle ? "" : props.subtitle,
    is3D: !props.is3D ? false : true,
    pieHole: !props.pieHole ? "" : props.pieHole,
    curveType: "function",
    legend: !props.legend ? { position: "bottom" } : { position: props.legend },
    animation: {
      duration: 1000,
      easing: "out",
      startup: true,
    }
  };


  const width = !props.width ? "100%" : props.width;
  const height = !props.height ? "165px" : props.height;
  return (
    <div>
      <Chart
        chartType={props.type}
        width={width}
        height={height}
        data={props.data}
        options={options}
      />
    </div>
  );
}
