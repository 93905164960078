import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setJobListAction } from '../../../../actions/actions';
import { projectLogo, decryptData, encryptData, formatDateAndTime, LoginBgIm, projectLogoage, schoolName, projectAddress, projectPhone, projectEmail, ProjectDomain, AboutUs, Admission, projectFacebook, projectTwitter, projectLinkedIn, projectYoutube } from '../../../../util/constants';
import { serverLink } from '../../../../util/url';
import * as DOMPurify from 'dompurify';
import Loader from '../../../../common/loader';


function JobDetails(props) {
    const navigate = useNavigate();

    const { slug } = useParams();
    const [isLoading, setIsLoading] = useState(true)
    const [JobDetails, setJobDetails] = useState(props.JobList.length > 0 ? props.JobList.filter(x => x.RequisitionID === decryptData(slug)) : [])

    const [jobApplications, setJobApplications] = useState(props.AppliedJobs.length > 0 ? props.AppliedJobs : [])
    const controller = new AbortController();
    const active_cohort = props.activeCohort[0];


    const getData = async () => {
        const { signal } = controller.signal
        try {
            await axios.get(`${serverLink}/job_applications/current_job_openings?cohort=${active_cohort?.CohortCode}`, { signal }).then((res) => {
                if (res.data.length > 0) {
                    setJobDetails(res.data.filter(x => x.RequisitionID === decryptData(slug)));
                }
            })

            props.JobApplicant.length > 0 &&
                await axios.get(`${serverLink}/job_applications/get_job_applications/${props.JobApplicant[0]?.Email}`, { signal }).then((res) => {
                    if (res.data.length > 0) {
                        setJobApplications(res.data)
                    }
                })
            setIsLoading(false)
        } catch (e) {
            toast.error("network error, please try again")
            setIsLoading(false)
        }

    }

    const onApply = () => {
        checkPrevApplication(decryptData(slug))
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        getData();
        return () => {
            controller.abort()
        }
    }, [])

    const checkPrevApplication = (new_jobID) => {
        if (props.JobApplicant.length > 0) {
            if (props.JobApplicant[0]?.Status === "2") {
                if (jobApplications.length > 0) {
                    const applied_jobs = (jobApplications.filter(x => x.JobID === new_jobID));
                    if (applied_jobs.length > 0) {
                        if (applied_jobs[0]?.ApplicationStatus === "0") {
                            toast.error("Sorry, you have already applied for this job and awaiting response. You can apply for another job");
                            return false;
                        }

                        if (applied_jobs[0]?.ApplicationStatus === "1") {
                            toast.error("Sorry, you have already applied for this job and have been invited for interview. You can apply for another job");
                            return false;
                        }

                        if (applied_jobs[0]?.ApplicationStatus === "2") {
                            toast.success("You have already been accepted, you cannot apply for this job again");

                            return false;
                        }
                        if (applied_jobs[0]?.ApplicationStatus === "4") {
                            toast.error("Sorry, you had withdrawn this application, you cannot apply for this job again");
                            return false;
                        }
                        return false;
                    }
                    navigate(`/apply/${encryptData(new_jobID)}`)

                }
                navigate(`/apply/${encryptData(new_jobID)}`)
            } else {
                toast.warning("please complete your profile before proceeding to apply, redirecting please wait...");
                setTimeout(() => {
                    navigate(`/applicant/dashboard`)
                }, 2000);
            }
        } else {
            navigate(`/applicant/login`)
        }
    }



    return isLoading ? (<Loader />) : (
        <div className="container-fluid">
            <div className="landing-box">
                <div className="landing-body">
                    <div className="">


                        <div className='text-center mb-3'>
                            <img src={projectLogo} width="90px" />
                            <h1>{schoolName} Job Portal</h1>
                            <Link to={`/jobs`} className="btn btn-md btn-get-started mt-4 m-r-10"  >View all openings</Link>
                            <Link to={`/applicant/dashboard`} className="btn btn-md btn-get-started mt-4"  >Login to Portal</Link>
                        </div>


                        <div className="card col-lg-10 offset-lg-1">
                            <div className="card-body bg-light " style={{ fontSize: '15px' }}>
                                <div className='card-body'>
                                    <div className='row'>
                                        <h3>{JobDetails[0]?.Position}</h3>
                                        <div className='col-md-12'>
                                            <strong>Closing Date : {formatDateAndTime(JobDetails[0]?.ClosingDate, "date")} </strong><br />
                                            <button className='btn btn-sm btn-success' onClick={onApply} >Apply Now</button>
                                        </div>
                                    </div>
                                    <br />
                                    <h3>Job Description</h3>
                                    <hr />
                                    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(JobDetails[0]?.Description) }} />
                                    <br /><br />
                                    <h3>Job Requirements</h3>
                                    <hr />
                                    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(JobDetails[0]?.Requirements) }} />

                                    <br />
                                    <br />
                                    <hr />
                                    <div className='row'>
                                        <div>
                                            <strong>Closing Date : {formatDateAndTime(JobDetails[0]?.ClosingDate, "date")} </strong><br />
                                            <button className='btn btn-sm btn-success' onClick={onApply} >Apply Now</button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>



                        <footer id="footer">
                            <hr />
                            <div className="footer-top">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 footer-contact">
                                            <h3>{schoolName}<span>.</span></h3>
                                            <p>
                                                {projectAddress}
                                                <strong>Phone:</strong> {projectPhone}<br />
                                                <strong>Email:</strong> {projectEmail}<br />
                                            </p>
                                        </div>
                                        <div className="col-lg-4 col-md-6 footer-links">
                                            <h4>Useful Links</h4>
                                            <ul>
                                                <li><i className="bx bx-chevron-right" /> <a href={ProjectDomain} target={"_blank"} >Website</a></li>
                                                <li><i className="bx bx-chevron-right" /> <a href={AboutUs} target={"_blank"} >About us</a></li>
                                                <li><i className="bx bx-chevron-right" /> <a href={Admission} target={"_blank"} >Admission</a></li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-6 footer-links">
                                            <h4>Our Social Networks</h4>
                                            <div className="social-links mt-3">
                                                <a href={projectFacebook} target="_blank" className="facebook">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" /></svg>
                                                </a>
                                                <a href={projectTwitter} target="_blank" className="instagram">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c-.002 -.249 1.51 -2.772 1.818 -4.013z" /></svg>
                                                </a>
                                                <a href={projectLinkedIn} target="_blank" className="linkedin">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16"> <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" /> </svg>
                                                </a>
                                                <a href={projectYoutube} target="_blank" className="facebook">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><rect x={3} y={5} width={18} height={14} rx={4} /><path d="M10 9l5 3l-5 3z" /></svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </footer>

                    </div>
                </div>

            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        JobList: state.JobList,
        JobApplicant: state.JobApplicantData,
        AppliedJobs: state.AppliedJobs,
        activeCohort: state.activeCohort


    };
};
const mapDisptachToProps = (dispatch) => {
    return {
        setOnJobList: (p) => {
            dispatch(setJobListAction(p))
        }
    }
}

export default connect(mapStateToProps, mapDisptachToProps)(JobDetails);
