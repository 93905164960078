
import axios from 'axios';
import React, { useState } from 'react'
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setAdmin, setAdminLoginDetails, setApplicantLoginDetails } from '../../../actions/actions';
import { projectLogo, decryptData, encryptData, LoginBgImage, MailTemplates, onGeneralEdit, sendEmail, projectLogoail } from '../../../util/constants';
import { serverLink } from '../../../util/url';
import { passwordStrength } from 'check-password-strength'




const ApplicantLogin = (props) => {
  const [isLoggingIn, setIsloggingIn] = useState(false)
  const navigate = useNavigate();
  const [formType, setformType] = useState(0)

  const [data, setData] = useState({
    Email: "",
    FirstName: "",
    MiddleName: "",
    Surname: "",
    Phone: "",
    Pstrength: "",
    Password: "",
    CPassword: "",
    btnDisabled: true

  })


  const onLogin = async (e) => {
    e.preventDefault();
    setIsloggingIn(true)
    const dt = {
      Email: data.Email,
      Password: encryptData(data.Password)
    }

    try {
      await axios.post(`${serverLink}/job_applications/job_applicant_login`, dt).then((res) => {
        if (res.data.length > 0) {
          props.setOnAdminLoginData([]);
          props.setOnAdmin([])
          props.setOnJobApplicantData(res.data);
          toast.success("login successful, please wait!");
          navigate('/applicant/dashboard')
        } else {
          toast.error("wrong credentials")
        }
        setIsloggingIn(false)
      })
    } catch (error) {
      toast.error("network error, please try again!")
    } finally {
      setIsloggingIn(false)
    }

  }

  const onCreateAccount = async (e) => {
    e.preventDefault();
    setIsloggingIn(true)
    if (data.Password !== data.CPassword) {
      toast.error("password do not match");
      setIsloggingIn(false)
      return false;
    }

    const dt = {
      Email: data.Email,
      Password: encryptData(data.Password),
      FirstName: data.FirstName,
      MiddleName: data.MiddleName,
      Surname: data.Surname,
      Phone: data.Phone,
    }
    const email = MailTemplates("registration", data);

    try {
      await axios.post(`${serverLink}/job_applications/create_job_account`, dt).then((res) => {
        if (res.data.message === "success") {
          setformType(0);
          toast.success("account created successfully, please proceed to login");

          sendEmail(data.Email, email.subject, email.title, data.FirstName, email.body, '')
          onReset();
        } else if (res.data.message === "exists") {
          toast.error("email or phone already registered!")
        }
        setIsloggingIn(false)
      })
    } catch (e) {
      toast.error("network error, please try again!")
    } finally {
      setIsloggingIn(false)
    }

  }

  const onForgetPassword = async (e) => {
    e.preventDefault();
    setIsloggingIn(true)
    try {
      await axios.get(`${serverLink}/job_applications/forget_password/${data.Email}`).then((res) => {
        if (res.data.message === "success") {
          toast.success("If your email is registered, you would recieve guidelines on how to proceed!")
          setformType(0)
          const email = MailTemplates('forgot password', { Token: res.data.Token });
          sendEmail(data.Email, email.subject, email.title, data.Email, email.body, '');

        } else if (res.data.message === "not found") {
          toast.error("The email you have provided is not registered!")
          return
        } else {

        }
        setIsloggingIn(false)
      })
    } catch (error) {
      toast.error("network error, please try again!")
    } finally {
      setIsloggingIn(false)
    }

  }

  const StrnghtCaption = (e) => {

    if (e.value === "Too weak") {
      return (<div className='text-danger'> Password strength is too weak</div>)
    } else if (e.value === "Weak") {
      return (<div className='text-warning'> Password strength is weak</div>)
    } else if (e.value === "Medium" && e.length >= 8) {
      setData({
        ...data,
        btnDisabled: false
      })
      return (<div className='text-success'> Strong Password!</div>)
    } else if (e.value === "Strong" && e.length >= 8) {
      setData({
        ...data,
        btnDisabled: false
      })
      return (<div className='text-success'> Strong Password!</div>)
    } else {
      setData({
        ...data,
        btnDisabled: false
      })
      return (<div className='text-success'> Strong Password!</div>)
    }


  }

  const Allow = (e) => {
    if (e.value === "Medium" && e.length >= 8) {
      return false

    } else if (e.value === "Strong" && e.length >= 8) {
      return false

    } else {
      return true
    }

  }


  const onEdit = (e) => {
    setData({
      ...data,
      [e.target.id]: e.target.value
    })
    if (e.target.id === "Password") {
      const str = passwordStrength(e.target.value);
      setData({
        ...data,
        [e.target.id]: e.target.value,
        Pstrength: StrnghtCaption(str),
        btnDisabled: Allow(str)
      })
    }


    // onGeneralEdit(e, data, setData);
  }

  const onShowrecover = (val) => {
    setformType(val)
  }

  const onReset = () => {
    setData({
      ...data,
      Email: "",
      FirstName: "",
      MiddleName: "",
      Surname: "",
      Phone: "",
      Password: "",
      CPassword: "",
    })
  }


  return (
    <div className="main-wrapper">

      <div className="preloader" style={{ display: 'none' }}>
        <div className="lds-ripple">
          <div className="lds-pos" />
          <div className="lds-pos" />
        </div>
      </div>

      <div className="auth-wrapper d-flex no-block justify-content-center align-items-center"
        style={{ background: `url(${LoginBgImage}) no-repeat center center` }}>

        {

          formType === 0 &&
          <div className="auth-box" style={{ maxWidth: 400 }} >
            <div id="loginform">
              <div className="logo">
                <span className="db"><img src={projectLogo} width="70px" alt="logo" /></span>
                <h5 className="font-medium m-b-20">Signin to Continue</h5>
              </div>

              <div className="row">
                <div className="col-12">
                  <form className="form-horizontal m-t-20" onSubmit={onLogin}>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1"><i className="ti-user" /></span>
                      </div>
                      <input type="text" className="form-control form-control-lg" onChange={onEdit} style={{ fontSize: '14px' }} id="Email" placeholder="Email" aria-label="Email" required />
                    </div>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon2"><i className="ti-key" /></span>
                      </div>
                      <input type="password" className="form-control form-control-lg" id="Password" onChange={onEdit} placeholder="Password" aria-label="Password" required />
                    </div>
                    <div className="form-group row">
                      <div className="col-md-12">
                        <div className="custom-control custom-checkbox">

                          <span style={{ cursor: 'pointer' }} onClick={() => { onShowrecover(1) }} className="text-dark float-right"><i className="fa fa-lock m-r-5" /> Forgot Password?</span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group text-center">
                      <div className="col-xs-12 p-b-20">
                        <button disabled={isLoggingIn} className="btn btn-block btn-lg btn-get-started" type="submit">
                          {isLoggingIn ? <span><i className='fa fa-spin fa-spinner' />Please wait...</span> : "Log In"}
                        </button>
                      </div>
                    </div>

                    <div className="form-group m-b-0 m-t-10">
                      <div className="col-sm-12 text-center">
                        Don't have an account? <span style={{ cursor: 'pointer' }} onClick={() => { onShowrecover(2) }} className="text-info m-l-5">
                          <b>Sign Up</b></span>
                      </div>
                    </div>
                    <div className="form-group m-b-0 m-t-10">
                      <div className="col-sm-12 text-center">
                        <Link to="/jobs" >Back to Jobs</Link>
                      </div>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        }
        {
          formType === 1 &&
          <div className="auth-box" style={{ maxWidth: 400 }}>
            <div >
              <div className="logo">
                <span className="db"><img src={projectLogo} width="70px" alt="logo" /></span>
                <h5 className="font-medium m-b-20">Recover Password</h5>
                <span>Enter your Email and instructions will be sent to you!</span>
              </div>
              <div className="row m-t-20">
                {/* Form */}
                <form className="col-12" onSubmit={onForgetPassword}>
                  {/* email */}
                  <div className="form-group row">
                    <div className="col-12">
                      <input className="form-control form-control-lg" type="email" style={{ fontSize: '14px' }} onChange={onEdit} id="Email" required placeholder="Email" />
                    </div>
                  </div>
                  {/* pwd */}
                  <div className="row m-t-20">
                    <div className="col-12">
                      <button disabled={isLoggingIn} className="btn btn-block btn-lg btn-danger" type="submit" name="action">
                        {isLoggingIn ? <span><i className='fa fa-spin fa-spinner' />Please wait...</span> : "Reset"}
                      </button>
                    </div>
                  </div>

                  <span style={{ cursor: 'pointer' }} onClick={() => { onShowrecover(0) }} className="text-dark float-right"><i className="fa fa-lock m-r-5" /> Back to login</span>
                </form>
              </div>
            </div>
          </div>
        }

        {
          formType === 2 &&
          <div className="auth-box" style={{ maxWidth: 800, marginTop: 40 }}>
            <div id="loginform" >
              <div className="logo">
                <span className="db"><img src={projectLogo} width="70px" alt="logo" /></span>
                <h5 className="font-medium m-b-20">Create Account</h5>
              </div>

              <div className="row">
                <div className="col-12">
                  <form className="form-horizontal m-t-20" onSubmit={onCreateAccount} >
                    <label className='form-label' >First Name</label>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1"><i className="ti-user" /></span>
                      </div>
                      <input type="text" className="form-control form-control-lg" value={data.FirstName} onChange={onEdit} id="FirstName" placeholder="First Name" aria-label="FirstName" required />
                    </div>

                    <label className='form-label' >Middle Name</label>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon2"><i className="ti-user" /></span>
                      </div>
                      <input type="text" className="form-control form-control-lg" id="MiddleName" value={data.MiddleName} onChange={onEdit} placeholder="Middle Name" aria-label="MiddleName" />
                    </div>

                    <label className='form-label' >Surname</label>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon2"><i className="ti-user" /></span>
                      </div>
                      <input type="text" className="form-control form-control-lg" id="Surname" value={data.Surname} onChange={onEdit} placeholder="Surname" aria-label="Surname" required />
                    </div>

                    <label className='form-label' >Email Address</label>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon2"><i className="ti-email" /></span>
                      </div>
                      <input type="email" className="form-control form-control-lg" id="Email" value={data.Email} onChange={onEdit} placeholder="Email" aria-label="Email" required />
                    </div>

                    <label className='form-label' >Phone Number</label>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon2"><i className="ti-mobile" /></span>
                      </div>
                      <input type="text" className="form-control form-control-lg" id="Phone" value={data.Phone} onChange={onEdit} placeholder="Phone" aria-label="Phone" required />
                    </div>


                    <div className='row'>
                      <div className='col-md-12 mb-3 text-center'>
                        <small>
                          <strong className='text-danger'>
                            password must be minimum of 8 characters, contains lowaer case, upper case, number and a special character
                          </strong>
                        </small>
                      </div>
                      <div className='col-md-6'>
                        <label className='form-label' >Password</label>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon2"><i className="ti-key" /></span>
                          </div>
                          <input type="password" className="form-control form-control-lg" id="Password" value={data.Password} onChange={onEdit} placeholder="Password" aria-label="Password" required />
                        </div>
                      </div>

                      <div className='col-md-6'>
                        <label className='form-label w-100' >Confirm password</label>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon2"><i className="ti-key" /></span>
                          </div>
                          <input type="password" className="form-control form-control-lg" id="CPassword" value={data.CPassword} onChange={onEdit} placeholder="Confirm Password" aria-label="Password" required />
                        </div>
                      </div>
                      <div className='col-md-12'>
                        <div className='text-center'>
                          <div className='input-group mb-3'>
                            {data.Password !== "" &&
                              data.Pstrength
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                    <br />
                    <div className="form-group text-center">
                      <div className="col-xs-12 p-b-20">
                        <button className="btn btn-block btn-lg btn-get-started" disabled={data.btnDisabled} type="submit">
                          {isLoggingIn ?
                            <span>
                              <i className='fa fa-spin fa-spinner' />Please wait...</span> : "Create Account"}
                        </button>
                      </div>
                    </div>

                    <div className="form-group m-b-0 m-t-10">
                      <div className="col-sm-12 text-center">
                        Already have an account? <span style={{ cursor: 'pointer' }}
                          onClick={() => { onShowrecover(0) }} className="text-info m-l-5"><b>Sign In</b></span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    JobApplicants: state.JobApplicantData,

  };
};
const mapDisptachToProps = (dispatch) => {
  return {
    setOnJobApplicantData: (p) => {
      dispatch(setApplicantLoginDetails(p))
    },
    setOnAdminLoginData: (p) => {
      dispatch(setAdminLoginDetails(p))
    },
    setOnAdmin: (p) => {
      dispatch(setAdmin(p))
    }
  }
}

export default connect(mapStateToProps, mapDisptachToProps)(ApplicantLogin);


