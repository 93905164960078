import React, { useEffect, useState } from 'react'
import { projectLogo, decryptData, MailTemplates, schoolName, sendEmail, shortCode } from '../../../util/constants';
import JoditEditor from "jodit-react";
import SimpleFileUpload from "react-simple-file-upload";
import { simpleFileUploadAPIKey, simpleFileUploadFolderName } from '../../../util/constants';
import { connect } from 'react-redux';
import { serverLink } from '../../../util/url';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router';
import jobDetails from './job-board/job-details';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';


function ApplyJob(props) {
    const applicant = props.JobApplicant;
    const { slug } = useParams();
    const navigate = useNavigate();

    const [showCv, setshowCv] = useState(false);
    const [JobDetails, setJobDetails] = useState(props.JobList.length > 0 ? props.JobList.filter(x => x.RequisitionID === decryptData(slug)) : [])
    const controller = new AbortController();

    const [data, setData] = useState({
        CoverLetter: "",
        Email: applicant[0]?.Email,
        ApplicantID: applicant[0]?.EntryID,
        JobID: JobDetails[0]?.RequisitionID,
        CurrcilumVitae: `${serverLink}/public/uploads/${shortCode}/staff_enrollment/cv/${applicant[0]?.CurriculumVitae}`,
        FirstName: applicant[0]?.FirstName,
        Position: JobDetails[0]?.Position,
        cvUploaded: applicant[0]?.CurriculumVitae !== null ? true : false,
        CVFile: "",
        JobType: JobDetails[0]?.JobType,
    })
    const [socials, setSocials] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const active_cohort = props.activeCohort[0];

    const getData = async () => {
        const { signal } = controller.signal

        await axios.get(`${serverLink}/job_applications/current_job_openings?cohort=${active_cohort?.CohortCode}`, { signal }).then((res) => {
            if (res.data.length > 0) {
                setJobDetails(res.data.filter(x => x.RequisitionID === decryptData(slug)));
            }
        })

        const { data } = await axios.get(`${serverLink}/enrollment/get_social_media/email/${applicant[0]?.Email}`);
        setSocials(data)
    }

    useEffect(() => {
        getData();
        return () => {
            controller.abort()
        }
    }, [])

    const submitApplication = (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        if (data.JobType === "Academics") {
            if (socials.length > 0) {
                if (socials[0]?.Facebook === "") {
                    toast.error("please update facebook social profile in your bio data page");
                    setIsSubmitting(false);
                    return false;
                }
                if (socials[0]?.LinkedIn === "") {
                    toast.error("please update linkedin social profile in your bio data page");
                    setIsSubmitting(false);
                    return false;
                }
                if (socials[0]?.Scholar === "") {
                    toast.error("please update google scholar social profile in your bio data page");
                    setIsSubmitting(false);
                    return false;
                }
                if (socials[0]?.ResearchGate === "") {
                    toast.error("please update research gate social profile in your bio data page");
                    setIsSubmitting(false);
                    return false;
                }
                if (socials[0]?.Academia === "") {
                    toast.error("please update academia social profile in your bio data page");
                    setIsSubmitting(false);
                    return false;
                }
                if (socials[0]?.Orcid === "") {
                    toast.error("please update ORCID social profile in your bio data page");
                    setIsSubmitting(false);
                    return false;
                }
                Apply();
            } else {
                toast.error("please complete your socials profile");
                setTimeout(() => {
                    navigate('/applicant/dashboard')
                }, 2500);
            }

        } else {
            Apply();
        }
    }

    const Apply = () => {
        if (data.CoverLetter === "") {
            toast.error("please type in your cover letter");
            setIsSubmitting(false)
            return false;
        }
        if (data.cvUploaded !== true) {
            toast.error("please upload your cv");
            setIsSubmitting(false)
            return false;
        }

        try {
            Swal.fire({
                title: 'Are you sure?',
                text: "Please ensure all entries are correct before you submit",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Submit'
            }).then((result) => {
                if (result.isConfirmed) {
                    setIsSubmitting(true)
                    toast.info("please wait...");
                    axios.post(`${serverLink}/job_applications/submit_job_application`, data).then((res) => {

                        if (res.data.status === 200) {

                            const email = MailTemplates("submit application", data);
                            sendEmail(data.Email, email.subject, email.title, applicant[0]?.FirstName, email.body, '')
                            setTimeout(() => {
                                Swal.fire(
                                    'success!',
                                    'Application submitted successfully, please check your email',
                                    'success'
                                ).then(() => {
                                    navigate(`/applicant/dashboard`)
                                })
                            }, 500);

                        }
                        else {
                            Swal.fire(
                                'Error, Try Again!',
                                'Netwrok error, try again',
                                'error'
                            )
                        }
                    })
                }
            })
        } catch (e) {
            Swal.fire("Error", "Network error", "error")
        } finally {
            setIsSubmitting(false)
        }
    }

    const onCvUpload = (url) => {

        toast.info("please wait... ");
        setIsSubmitting(true)
        const dt = new FormData();
        dt.append("file", data.CVFile);

        try {
            axios.put(`${serverLink}/job_applications/applicant_add_cv/${applicant[0]?.Email}`, dt).then((data) => {
                if (data.data.message === "success") {
                    setData({
                        ...data,
                        CurrcilumVitae: `${serverLink}/public/uploads/${shortCode}/staff_enrollment/cv/${data.data.path}`,
                        cvUploaded: true
                    })
                    toast.success('CV updated successfully');

                } else {
                    toast.error('Error uploading')
                }
            })
        } catch (e) {
            toast.error("network error")
        } finally {
            setIsSubmitting(false)
        }
    }
    const [disableUpload, setDisableUpload] = useState(true)
    const onCVChange = (e) => {
        if (e.target.files[0].size > 2000000) {
            setDisableUpload(true)
            toast.error("File size must not be greater than 2MB");
            return false;
        }
        else {
            if (e.target.files[0].type.includes("pdf")) {
                setDisableUpload(false)
                setData({
                    ...data,
                    [e.target.id]: e.target.files[0],
                })
            } else {
                setDisableUpload(true)
                toast.error("Only pdf file is allowed");
            }
        }
    }

    const toggleCv = () => {
        if (showCv === true) {
            setshowCv(false)
        } else {
            setshowCv(true)
        }
    }

    return (
        <div className="auth-wrapper d-flex no-block"
            style={{ backgroundColor: '#f5f5f5', }}>

            <div className='container-fluid mt-3'>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-lg-8 offset-lg-2">
                        <div className="card">
                            <div className="card-body">
                                <div className='text-center'>
                                    <img src={projectLogo} width="105" />
                                    <h4 className="card-title font-bold">{schoolName}</h4>
                                    <h6 className="font-20"> <Link to='/applicant/dashboard' className='btn btn-md btn-get-started' >Back to Portal</Link></h6>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">Applicant Info</h4>
                                    </div>
                                    <div className="card-body bg-light">
                                        <div className="row text-justify">
                                            <div className="col-md-6 m-t-10 m-b-10">
                                                <h5 className="p-t-20">Applicant</h5>
                                                <div className='font-bold font-18' >{applicant[0]?.FirstName} {applicant[0]?.MiddleName} {applicant[0]?.Surname}</div>
                                                <h5 className="m-t-30">Poisiton</h5>
                                                <div className='font-bold font-18' >{JobDetails[0]?.Position}</div>
                                                <h5 className="m-t-30">Department</h5>
                                                <div className='font-bold font-18' >{JobDetails[0]?.Department}</div>
                                                <br />
                                                <div className='row' >
                                                    <a className='btn btn-link' href={data.CurrcilumVitae} target="_blank"  >
                                                        View my curriculum vitae
                                                    </a>
                                                    <button className='btn btn-sm btn-add-todo btn-success' onClick={toggleCv} >
                                                        Update My Cv
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-md-6 m-t-10 m-b-10">
                                                <div className='d-flex justify-content-end'>
                                                    <div className="profile-pic m-b-20 m-t-20">
                                                        <img src={`${serverLink}/public/uploads/${shortCode}/staff_enrollment/passports/${applicant[0]?.Passport}`} width={150} className="rounded-circle" alt="user" />
                                                        <div className='text-center'>
                                                            <a href={`mailto: ${applicant[0]?.Email}`} className='btn btn-link' >{applicant[0]?.Email}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <form className="m-t-30" onSubmit={submitApplication} >
                                    {
                                        showCv === true &&
                                        <div className="form-group">
                                            <label htmlFor="">Update My Cv</label><br />
                                            <small className='text-danger'>
                                                <strong>Max. file size is 2MB</strong><br />
                                                <strong>Only pdf files are accepted</strong>
                                            </small>

                                            <input type='file' className='form-control mt-5' id="CVFile" onChange={onCVChange} />
                                            <button className='btn btn-md btn-success mt-2 w-25' disabled={disableUpload || isSubmitting === true} type='button' onClick={onCvUpload}>
                                                Update CV
                                            </button>
                                        </div>
                                    }
                                    <div className="form-group">
                                        <label htmlFor="">Cover Letter</label>
                                        <JoditEditor
                                            value={data.CoverLetter}
                                            tabIndex={1}
                                            onChange={(e) => {
                                                setData({
                                                    ...data, CoverLetter: e
                                                })
                                            }}
                                        />
                                    </div>
                                    <hr />
                                    <button type="submit" className="btn btn-success w-100" disabled={isSubmitting === true ? true : false}>
                                        {
                                            isSubmitting === true ?
                                                "please wait" : "Submit"
                                        }
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        JobList: state.JobList,
        JobApplicant: state.JobApplicantData,
        AppliedJobs: state.AppliedJobs,
        activeCohort: state.activeCohort


    };
};

export default connect(mapStateToProps, null)(ApplyJob);
