
import React from 'react'
import { CountryList } from './country-list';

export default function ApplicantProfile(props) {
    const checkDisability = (e) => {
        let val = [];
        if (props.data.Disabilities.length > 0) {
            val = props.data.Disabilities.filter(x => x === e);
            return val.length > 0 ? true : false
        } else {
            return false;
        }
    }
    return (
        <div className='row'>
            <div className='col-md-4'>
                <label className='form-label' >First Name</label>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1"><i className="ti-user" /></span>
                    </div>
                    <input type="text" disabled className="form-control form-control-lg" onChange={props.onEdit} id="FirstName" value={props.data.FirstName} placeholder="First Name" aria-label="FirstName" required />
                </div>
            </div>
            <div className='col-md-4'>
                <label className='form-label' >Middle Name</label>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1"><i className="ti-user" /></span>
                    </div>
                    <input type="text" disabled className="form-control form-control-lg" onChange={props.onEdit} id="MiddleName" value={props.data.MiddleName} placeholder="Middle Name" aria-label="FirstName" required />
                </div>
            </div>
            <div className='col-md-4'>
                <label className='form-label' >Surname</label>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1"><i className="ti-user" /></span>
                    </div>
                    <input type="text" disabled className="form-control form-control-lg" onChange={props.onEdit} id="Surname" value={props.data.Surname} placeholder="Surname" aria-label="Surname" required />
                </div>
            </div>
            <div className='col-md-6'>
                <label className='form-label' >Email Address</label>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1"><i className="ti-user" /></span>
                    </div>
                    <input type="text" disabled className="form-control form-control-lg" onChange={props.onEdit} id="Email" value={props.data.Email} placeholder="Email Address" aria-label="Email" required />
                </div>
            </div>
            <div className='col-md-6'>
                <label className='form-label' >Alternative Email Address</label>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1"><i className="ti-user" /></span>
                    </div>
                    <input type="text" className="form-control form-control-lg" onChange={props.onEdit} id="AlternativeEmail" value={props.data.AlternativeEmail} placeholder="Alternative Email Address" aria-label="AlternativeEmail" />
                </div>
            </div>
            <div className='col-md-6'>
                <label className='form-label' >Phone</label>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1"><i className="ti-mobile" /></span>
                    </div>
                    <input type="text" disabled className="form-control form-control-lg" onChange={props.onEdit} id="Phone" value={props.data.Phone} placeholder="Phone" aria-label="Phone" required />
                </div>
            </div>
            <div className='col-md-6'>
                <label className='form-label' >Alternative Phone</label>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1"><i className="ti-mobile" /></span>
                    </div>
                    <input type="text" className="form-control form-control-lg" onChange={props.onEdit} id="AlternativePhone" value={props.data.AlternativePhone} placeholder="Alternative Phone" aria-label="AlternativePhone" />
                </div>
            </div>
            <div className='col-md-6'>
                <label className='form-label' >Nationality</label>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <label className="input-group-text" htmlFor="inputGroupSelect01">Nationality</label>
                    </div>
                    <select className="custom-select" id="Nationality" value={props.data.Nationality} onChange={props.onEdit} required>
                        <option value={""}>Choose...</option>
                        <option value={"Nigerian"}>Nigerian</option>
                        {
                            CountryList.filter(x => x !== "Nigeria").map((x, i) => {
                                return (
                                    <option key={i} value={x}>{x}</option>
                                )
                            })
                        }
                        <option value={"Others"}>Others</option>
                    </select>
                </div>
            </div>
            {
                props.data.Nationality === "Nigerian" &&
                <>
                    <div className='col-md-6'>
                        <label className='form-label' >State Of Origin</label>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <label className="input-group-text" htmlFor="inputGroupSelect01">State Of Origin</label>
                            </div>
                            <select className="custom-select" id="StateOfOrigin" value={props.data.StateOfOrigin} onChange={props.onEdit} required>
                                <option value={""}>Choose...</option>
                                {
                                    props.stateList.length > 0 &&
                                    props.stateList.map((x, i) => {
                                        return (
                                            <option value={x} key={i} >{x}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <label className='form-label' >LGA</label>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <label className="input-group-text" htmlFor="inputGroupSelect01">LGA</label>
                            </div>
                            <select className="custom-select" id="LGA" value={props.data.LGA} onChange={props.onEdit} required>
                                <option value={""}>Choose...</option>
                                {
                                    props.lgaList.length > 0 &&
                                    props.lgaList.map((x, i) => {
                                        return (
                                            <option value={x} key={i} >{x}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </>
            }

            <div className='col-md-6'>
                <label className='form-label' >Gender</label>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <label className="input-group-text" htmlFor="inputGroupSelect01">Gender</label>
                    </div>
                    <select className="custom-select" id="Gender" value={props.data.Gender} onChange={props.onEdit} required>
                        <option value={""}>Choose...</option>
                        <option value={"Male"}>Male</option>
                        <option value={"Female"}>Female</option>
                    </select>
                </div>
            </div>

            <div className='col-md-6'>
                <label className='form-label' >Marital Status</label>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <label className="input-group-text" htmlFor="inputGroupSelect01">MaritalStatus</label>
                    </div>
                    <select className="custom-select" id="MaritalStatus" value={props.data.MaritalStatus} onChange={props.onEdit} required>
                        <option value={""}>Choose...</option>
                        <option value={"Single"}>Single</option>
                        <option value={"Married"}>Married</option>
                        <option value={"Divorced"}>Divorced</option>
                    </select>
                </div>
            </div>


            <div className='col-md-6'>
                <label className='form-label' >Date Of Birth</label>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1"><i className="ti-mobile" /></span>
                    </div>
                    <input type="date" className="form-control form-control-lg" onChange={props.onEdit} max={'2008-01-01'} id="DateOfBirth" value={props.data.DateOfBirth} placeholder="DOB" aria-label="DateOfBirth" required />
                </div>
            </div>


            <div className='col-md-6'>
                <label className='form-label' >Highest Qualification</label>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1"><i className="ti-mobile" /></span>
                    </div>
                    {/* <input type="text" className="form-control form-control-lg" onChange={props.onEdit} id="HighestQualification" value={props.data.HighestQualification} placeholder="Highest Qualification" aria-label="HighestQualification" required /> */}
                    <select className="custom-select" id="HighestQualification" value={props.data.HighestQualification} onChange={props.onEdit} required>
                        <option value={""}>Choose...</option>
                        <option value={"B.Eng."}>B.Eng.</option>
                        <option value={"B.Tech."}>B.Tech.</option>
                        <option value={"B.A."}>B.A.</option>
                        <option value={"B.Sc"}>B.Sc</option>
                        <option value={"Msc"}>Msc</option>
                        <option value={"M.Tech"} >M.Tech</option>
                        <option value={"MA"}>MA</option>
                        <option value={"MEd"}>MEd</option>
                        <option value={"OND"}>OND</option>
                        <option value={"HND"}>HND</option>
                        <option value={"ND"}>ND</option>
                        <option value={"MBA"}>MBA</option>
                        <option value={"MPA"}>MPA</option>
                        <option value={"MPH"}>MPH</option>
                        <option value={"MTech"}>MTech</option>
                        <option value={"MPhil"}>MPhil</option>
                        <option value={"PhD"}>PhD</option>
                        <option value={"SSCE"}>SSCE</option>
                        <option value={"Certificate"}>Certificate</option>
                        <option value={"LLB"}>LLB</option>
                        <option value={"LLM"}>LLM</option>
                        <option value={"LLD"}>LLD</option>
                        <option value={"M.Eng"}>M. Engr</option>
                        <option value={"N C E"}>N C E</option>
                        <option value={"PGD"}>PGD</option>
                        <option value={"Diploma"}>Diploma</option>
                        <option value={"MBBS"}>MBBS</option>
                        <option value={"PGDE"}>PGDE</option>
                        <option value={"Others"}>Others</option>
                    </select>
                </div>
            </div>
            <div className='col-md-6'>
                <label className='form-label' >Do you have any disability ?</label>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <label className="input-group-text" htmlFor="inputGroupSelect01">Disability</label>
                    </div>
                    <select className="custom-select" id="DisabilityStatus" value={props.data.DisabilityStatus} onChange={props.onEdit} required>
                        <option value={""}>Choose...</option>
                        <option value={"Yes"}>Yes</option>
                        <option value={"No"}>No</option>
                    </select>
                </div>
            </div>

            {
                props.data.DisabilityStatus === "Yes" &&
                <div className='col-md-4'>
                    <div className="form-group MB-3">
                        <div className="form-check form-control-lg form-check-solid">
                            <input className="form-check-input" defaultChecked={checkDisability("Neorodivergent")} type="checkbox" value={"Neorodivergent"} id="Neorodivergent" onChange={props.onDisabilityChange} />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Neurodivergent
                            </label>
                        </div>

                        <div className="form-check form-control-lg form-check-solid">
                            <input className="form-check-input" defaultChecked={checkDisability("Physical")} type="checkbox" value={"Physical"} id="Physical" onChange={props.onDisabilityChange} />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Physical Impairment
                            </label>
                        </div>

                        <div className="form-check form-control-lg form-check-solid">
                            <input className="form-check-input" defaultChecked={checkDisability("Visual Impairment")} type="checkbox" value={"Visual Impairment"} id="VisialImpairment" onChange={props.onDisabilityChange} />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Visual Impairment
                            </label>
                        </div>

                        <div className="form-check form-control-lg form-check-solid">
                            <input className="form-check-input" defaultChecked={checkDisability("Others")} type="checkbox" value={"Others"} id="Others" onChange={props.onDisabilityChange} />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Others
                            </label>
                        </div>
                    </div>
                </div>
            }

            {
                props?.data?.Disabilities?.includes("Others") && props?.showDescr === true && props?.data?.DisabilityStatus === "Yes" &&
                <div className='col-md-8'>
                    <label className='form-label' >Disability Description</label>
                    <div className="input-group mb-3">
                        <textarea type="text" className="form-control form-control-lg" onChange={props.onEdit} id="DisabilityDescription" value={props.data.DisabilityDescription} placeholder="Disability Description..." required rows={4}  ></textarea>
                    </div>
                </div>
            }

            <div className='col-md-12'>
                <label className='form-label' >Address</label>
                <div className="input-group mb-3">
                    <textarea type="text" className="form-control form-control-lg" onChange={props.onEdit} id="Address" value={props.data.Address} placeholder="Address..." aria-label="Address" required rows={4}  ></textarea>
                </div>
            </div>

            <div className='col-md-12 m-t-10'>
                <div className="alert alert-info">
                    <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                    >
                        {" "}
                        <span aria-hidden="true">×</span>{" "}
                    </button>
                    <h3 className="text-info">
                        <i className="fa fa-exclamation-circle" /> Information
                    </h3>{" "}
                    Applicants applying to academic positions are highly encouraged to fill the digital and academic competitiveness form below.
                </div>

            </div>
            <div className='col-md-12'>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                            <i className="ti-facebook" />
                            &nbsp;<a href={props.data.Facebook} target='_blank'>Facebook</a>&emsp;&emsp;
                        </span>
                    </div>
                    <input type="text" style={{ fontStyle: "italic", fontSize: "13px" }} className="form-control form-control-lg" onChange={props.onEdit} id="Facebook" value={props.data.Facebook} placeholder="facebook profile link" />
                </div>

                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1"><i className="ti-linkedin" />
                            &nbsp;<a href={props.data.LinkedIn} target='_blank'>LinkedIn</a>&emsp;&emsp;&nbsp;&nbsp;
                        </span>
                    </div>
                    <input type="text" style={{ fontStyle: "italic", fontSize: "13px" }} className="form-control form-control-lg" onChange={props.onEdit} id="LinkedIn" value={props.data.LinkedIn} placeholder="LinkedIn profile link" />
                </div>

                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1"><i className="ti-link" />
                            &nbsp;<a href={props.data.Academia} target='_blank'>Academia</a>&emsp;&emsp;</span>
                    </div>
                    <input type="text" style={{ fontStyle: "italic", fontSize: "13px" }} className="form-control form-control-lg" onChange={props.onEdit} id="Academia" value={props.data.Academia} placeholder="Academia profile link" />
                </div>

                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1"><i className="ti-link" />
                            &nbsp;<a href={props.data.Scholar} target='_blank'>Scholar</a>&emsp;&emsp;&emsp;</span>
                    </div>
                    <input type="text" style={{ fontStyle: "italic", fontSize: "13px" }} className="form-control form-control-lg" onChange={props.onEdit} id="Scholar" value={props.data.Scholar} placeholder="Scholar profile link" />
                </div>

                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1"><i className="ti-link" />
                            <a href={props.data.ResearchGate} target='_blank'>Research Gate</a></span>
                    </div>
                    <input type="text" style={{ fontStyle: "italic", fontSize: "13px" }} className="form-control form-control-lg" onChange={props.onEdit} id="ResearchGate" value={props.data.ResearchGate} placeholder="Research Gate profile link" />
                </div>

                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1"><i className="ti-link" />&nbsp;
                        <a href={props.data.Orcid} target='_blank'>Orcid</a>&emsp;&emsp;&emsp;&emsp;</span>
                    </div>
                    <input type="text" style={{ fontStyle: "italic", fontSize: "13px" }} className="form-control form-control-lg" onChange={props.onEdit} id="Orcid" value={props.data.Orcid} placeholder="Orcid profile link" />
                </div>
            </div>

        </div>
    )
}
