import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setAdminLoginDetails, setDashboardData, setDepartmentsList, setFacultyList, setHrdesignations, setJobListAction, setNotifcationsAction, setSingleJobApplication, setStaffList, setUsersListAction } from '../../../actions/actions';
import { formatDateAndTime, shortCode, Today } from '../../../util/constants';
import { serverLink } from '../../../util/url';
import Charts from '../../common/chart';


function AdminDashboard(props) {
    const slug = window.location.href?.split("/")[5];
    const [isLoading, setisLoading] = useState(true)
    const staff = props.AdminLoginData;
    const [JobApplications, setJobApplications] = useState(props.AllJobApplications.length > 0 ? props.AllJobApplications : []);
    const [staffList, setStaffList] = useState(props.staffList.length > 0 ? props.staffList : []);
    const [FacultyList, setFacultyList] = useState(props.facultyList.length > 0 ? props.facultyList : [])
    const [DepartmentList, setDepartmentList] = useState(props.departmentList.length > 0 ? props.departmentList : [])
    const [JobList, setJobList] = useState(props.JobList.length > 0 ? props.JobList : []);
    const [usersList, setUsersList] = useState(props.usersList.length > 0 ? props.usersList : [])
    const [genderChart, setGenderChart] = useState([])
    const active_cohort = props.activeCohort[0];

    const [byGender, setByGender] = useState({
        male: 0,
        female: 0
    })
    const [staffDistr, setStaffDistr] = useState([])
    const [panelists, setPanelists] = useState([])
    const [interViewed, setInterviewed] = useState(0)
    const controller = new AbortController()


    const getData = async () => {
        const { signal } = controller.signal

        try {
            let applications = []
            await axios.get(`${serverLink}/enrollment/get_job_applications?cohort=${active_cohort?.CohortCode}`, { signal }).then((res) => {
                if (res.data.length > 0) {
                    applications = res.data;
                    setJobApplications(res.data);
                }
            })

            let by_gender, by_gender_chart;
            await axios.get(`${serverLink}/enrollment/get_job_applications/by_gender`, { signal }).then((res) => {
                if (res.data.length > 0) {
                    setByGender(res.data);
                    const male = res.data.filter(x => x.gender === "Male");
                    const female = res.data.filter(x => x.gender === "Female");
                    let job_apply = [["Type", "Count"], ["Male", male.length], ["Female", female.length]];
                    setByGender({
                        ...byGender,
                        male: male.length,
                        female: female.length
                    })
                    setGenderChart(job_apply);


                    by_gender = { male: male.length, female: female.length }
                    by_gender_chart = job_apply;
                }
            })

            let staff_distribution = []
            await axios.get(`${serverLink}/enrollment/staff_distribution`, { signal }).then((res) => {
                if (res.data.length > 0) {
                    let staff_distr = [];
                    res.data.length > 0 && res.data.map((x, i) => {
                        staff_distr.push([x.DepartmentName, x.Count])
                    })
                    let tt = [["Type", "Count"], ...staff_distr]
                    setStaffDistr(tt)
                    staff_distribution = tt
                }
            })

            let openings_list = []
            await axios.get(`${serverLink}/enrollment/get_openings`, { signal }).then((res) => {
                if (res.data.length > 0) {
                    openings_list = res.data;
                    setJobList(res.data);
                }
            })

            let faculty_list;
            await axios.get(`${serverLink}/enrollment/facultyList`, { signal }).then((res) => {
                if (res.data.length > 0) {
                    faculty_list = res.data;
                    setFacultyList(res.data)
                    props.setOnFacultyList(res.data)
                }
            })

            await axios.get(`${serverLink}/enrollment/departmentList`, { signal }).then((res) => {
                if (res.data.length > 0) {
                    setDepartmentList(res.data)
                    props.setOnDepartmentList(res.data)
                }
            })

            await axios.get(`${serverLink}/enrollment/hrdesignations`, { signal }).then((res) => {
                if (res.data.length > 0) {
                    props.setOnHrDesignations(res.data)
                }
            })

            await axios.get(`${serverLink}/enrollment/get_all_staff`, { signal }).then((res) => {
                if (res.data.length > 0) {
                    setStaffList(res.data)
                    props.setOnStaffList(res.data)
                }
            })

            await axios.get(`${serverLink}/enrollment/portal/users`, { signal }).then((res) => {
                if (res.data.length > 0) {
                    setUsersList(res.data);
                    setPanelists(res.data?.filter(x => x.Designation === "panelist"))
                    props.setOnUsersList(res.data)
                }
                setisLoading(false)
            })


            await axios.get(`${serverLink}/enrollment/get_notifications`, { signal }).then((res) => {
                if (res.data.length > 0) {
                    props.setOnNotifications(res.data)
                }
                setisLoading(false)
            })

            let interviewed_applicants;
            const { data } = await axios.get(`${serverLink}/enrollment/interviewed_applicants`, { signal })
            setInterviewed(data[0]?.InterviewedCount)
            interviewed_applicants = data[0]?.InterviewedCount;

            // props.setOnDashBoardData([
            //     {
            //         applications: applications,
            //         by_gender: by_gender,
            //         by_gender_chart: by_gender_chart,
            //         openings_list: openings_list,
            //         interviewed_applicants: interviewed_applicants,
            //         staff_distribution: staff_distribution
            //     }
            // ])

        } catch (e) {
            console.log(e)
            toast.error("network error!")
        }
    }

    useEffect(() => {
        if (slug !== undefined) {
            window.sessionStorage.setItem("FromStaffPortal", staff[0]?.StaffID)
            window.sessionStorage.setItem("staff", "1")
        }
        getData();

        return () => controller.abort();
    }, [props.activeCohort])

    return (
        <div>
            <div>

                <div className="card-group">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-flex no-block align-items-center">
                                        <div>
                                            <i className="ti ti-user font-24 " />
                                            <p className="font-16 m-b-5 font-bold mt-3 ">Total Staff</p>
                                        </div>
                                        <div className="ml-auto">
                                            <h1 className="font-bold text-right text-primary">
                                                {staffList.length}
                                            </h1>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-flex no-block align-items-center">
                                        <div>
                                            <i className="ti ti-user font-24" />
                                            <p className="font-16 m-b-5 font-bold mt-3">Academic Staff</p>
                                        </div>
                                        <div className="ml-auto">
                                            <h1 className="font-bold text-right">
                                                {
                                                    staffList.length > 0 ?
                                                        staffList.filter(x => x.IsAcademicStaff === 1).length
                                                        : 0
                                                }
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-flex no-block align-items-center">
                                        <div>
                                            <i className="ti ti-user font-20 text-bold" />
                                            <p className="font-16 m-b-5 font-bold mt-3">Administrative Staff</p>
                                        </div>
                                        <div className="ml-auto">
                                            <h1 className="font-bold text-right">
                                                {
                                                    staffList.length > 0 ?
                                                        staffList.filter(x => x.IsAcademicStaff === 0).length
                                                        : 0
                                                }
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-flex no-block align-items-center">
                                        <div>
                                            <i className="ti ti-user font-20 text-bold" />
                                            <p className="font-16 m-b-5 mt-3 font-bold">Male Staff</p>
                                        </div>
                                        <div className="ml-auto">
                                            <h1 className="font-bold text-right">
                                                {
                                                    staffList.length > 0 ?
                                                        staffList.filter(x => x.Gender === "Male").length
                                                        : 0
                                                }
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-flex no-block align-items-center">
                                        <div>
                                            <i className="ti ti-user font-20 text-bold" />
                                            <p className="font-16 m-b-5 mt-3 font-bold">Female Staff</p>
                                        </div>
                                        <div className="ml-auto">
                                            <h1 className="font-bold text-right">
                                                {
                                                    staffList.length > 0 ?
                                                        staffList.filter(x => x.Gender === "Female").length
                                                        : 0
                                                }
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Job Applicants</h4>
                                {
                                    genderChart.length > 0 ?
                                        <Charts title="Assessed Appraisal by HOD(s)"
                                            pieHole={"0.5"}
                                            type="PieChart"
                                            data={genderChart}
                                            height="100px"
                                        />
                                        :
                                        <div className='text-center '>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <h4>No Applicants</h4>
                                            <br />
                                            <br />
                                            <br />
                                        </div>
                                }

                                {/* row */}
                                <div className="row text-center text-lg-left">
                                    <div className="col-4">
                                        <h4 className="m-b-0 font-medium">
                                            {byGender.male + byGender.female}
                                        </h4>
                                        <span className="font-bold">All</span>
                                    </div>
                                    <div className="col-4">
                                        <h4 className="m-b-0 font-medium">{byGender.male}</h4>
                                        <span className="font-bold">
                                            Male
                                        </span>
                                    </div>
                                    <div className="col-4">
                                        <h4 className="m-b-0 font-medium">
                                            {byGender.female}
                                        </h4>
                                        <span className="font-bold">Female</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title m-b-5">Applications</h5>

                                <div className='d-flex justify-content-between mt-3'>
                                    <div>
                                        <h1 className="font-bold">
                                            {
                                                JobApplications.length > 0 ?
                                                    JobApplications.filter(x => x.ApplicationStatus === "0").length
                                                    :
                                                    0
                                            }
                                        </h1>
                                        <span className='h4'>Pending</span>
                                    </div>
                                    <div>
                                        <h1 className="font-bold">
                                            {
                                                JobApplications.length > 0 ?
                                                    JobApplications.filter(x => x.ApplicationStatus !== "0").length
                                                    :
                                                    0
                                            }
                                        </h1>
                                        <span className='h4'>Processed</span>
                                    </div>
                                </div>
                                <hr />
                                <h5 className="card-title m-b-5">Portal Users</h5>

                                <div className='d-flex justify-content-between mt-3'>
                                    <div>
                                        <h1 className="font-bold">
                                            {
                                                usersList.length
                                            }
                                        </h1>
                                        <span className='h4'>Admins</span>
                                    </div>
                                    <div>
                                        <h1 className="font-bold">
                                            {
                                                panelists.length
                                            }
                                        </h1>
                                        <span className='h4'>Panelists</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-12 order-lg-2 order-md-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <h4 className="card-title">Staff Distribution</h4>
                                    </div>
                                </div>

                                {
                                    staffDistr.length > 0 ?

                                        <Charts title="Assessed Appraisal by HOD(s)"
                                            pieHole={"0.5"}
                                            height={"440px"}
                                            type="Bar"
                                            data={staffDistr} />
                                        :
                                        <span className='text-center mt-5'>
                                            <i className='fa fa-spin fa-spinner fa-5x' />
                                        </span>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='mb-4'>
                            <h3><strong>Job Applications</strong></h3>
                        </div>
                        <div className="card-group mt-1">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="d-flex no-block align-items-center">
                                                <div>
                                                    <i className="ti ti-clip font-24 " />
                                                    <p className="font-16 m-b-5 font-bold mt-3">Total</p>
                                                </div>
                                                <div className="ml-auto">
                                                    <h1 className="font-bold text-right text-primary">
                                                        {JobApplications.length}
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="d-flex no-block align-items-center">
                                                <div>
                                                    <i className="ti ti-alert font-24" />
                                                    <p className="font-16 m-b-5 font-bold mt-3">Pending</p>
                                                </div>
                                                <div className="ml-auto">
                                                    <h1 className="font-bold text-right text-warning">
                                                        {
                                                            JobApplications.length > 0 ?
                                                                JobApplications.filter(x => x.ApplicationStatus === "0").length
                                                                : 0
                                                        }
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="d-flex no-block align-items-center">
                                                <div>
                                                    <i className="ti ti-reload font-20 text-bold" />
                                                    <p className="font-16 m-b-5 font-bold mt-3">Invited</p>
                                                </div>
                                                <div className="ml-auto">
                                                    <h1 className="font-bold text-right">
                                                        {
                                                            JobApplications.length > 0 ?
                                                                JobApplications.filter(x => x.ApplicationStatus === "1").length
                                                                : 0
                                                        }
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="d-flex no-block align-items-center">
                                                <div>
                                                    <i className="ti ti-reload font-20 text-bold" />
                                                    <p className="font-16 m-b-5 font-bold mt-3">Interviewed</p>
                                                </div>
                                                <div className="ml-auto">
                                                    <h1 className="font-bold text-right">
                                                        {
                                                            interViewed
                                                        }
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="d-flex no-block align-items-center">
                                                <div>
                                                    <i className="ti ti-share font-20 text-bold" />
                                                    <p className="font-16 m-b-5 mt-3 font-bold">Accepted</p>
                                                </div>
                                                <div className="ml-auto">
                                                    <h1 className="font-bold text-right text-success">
                                                        {
                                                            JobApplications.length > 0 ?
                                                                JobApplications.filter(x => x.ApplicationStatus === "2").length
                                                                : 0
                                                        }
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="d-flex no-block align-items-center">
                                                <div>
                                                    <i className="ti ti-close font-20 text-bold" />
                                                    <p className="font-16 m-b-5 mt-3 font-bold">Rejected</p>
                                                </div>
                                                <div className="ml-auto">
                                                    <h1 className="font-bold text-right text-danger">
                                                        {
                                                            JobApplications.length > 0 ?
                                                                JobApplications.filter(x => x.ApplicationStatus === "3").length
                                                                : 0
                                                        }
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="d-flex no-block align-items-center">
                                                <div>
                                                    <i className="ti ti-close font-20 text-bold" />
                                                    <p className="font-16 m-b-5 mt-3 font-bold">Cancelled</p>
                                                </div>
                                                <div className="ml-auto">
                                                    <h1 className="font-bold text-right text-danger">
                                                        {
                                                            JobApplications.length > 0 ?
                                                                JobApplications.filter(x => x.ApplicationStatus === "4").length
                                                                : 0
                                                        }
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <div className="row">
                    {/* column */}
                    <div className="col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <h4 className="card-title mb-0">New Applications ({JobApplications.length})</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th className="border-top-0">SN</th>
                                            <th className="border-top-0">FULL NAME</th>
                                            <th className="border-top-0">EMAIL ADDRESS</th>
                                            <th className="border-top-0">PASSPORT</th>
                                            <th className="border-top-0">POSITION APPLIED</th>
                                            <th className='border-top-0'>DEPARTMENT</th>
                                            <th className="border-top-0">DATE APPLIED</th>
                                            <th className="border-top-0">STATUS</th>
                                            <th className="border-top-0">ACTION</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            JobApplications.length > 0 ?
                                                JobApplications.filter(x => x.ApplicationStatus === "0").splice(0, 50).map((x, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td className="txt-oflo">{i + 1}</td>
                                                            <td>
                                                                {x.FirstName + " " + x.MiddleName + " " + x.Surname}
                                                            </td>
                                                            <td>
                                                                {x.Email}
                                                            </td>
                                                            <td>
                                                                <div className="p-2">
                                                                    <img src={`${serverLink}/public/uploads/${shortCode}/staff_enrollment/passports/${x.Passport}`} alt="user" width={50} style={{ maxHeight: "50px" }} className="img-thumbnail img-shadow" />
                                                                </div>
                                                            </td>
                                                            <td className="txt-oflo">{x.Position}</td>
                                                            <td className='font-medium'>{x.Department}</td>
                                                            <td><span className="font-medium">{formatDateAndTime(x.InsertDate, "date")}</span></td>
                                                            <td>
                                                                <span className="badge badge-primary">{"Pending"}</span>
                                                            </td>
                                                            <td>
                                                                <Link onClick={() => { props.setOnSingleJobApplication([x]) }} to={`/jobs/applicant/${x.ApplicantID}/${x.JobID}/${x.ApplicationID}`} className='btn btn-sm btn-success'>
                                                                    View Details
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={6}>No Record</td>
                                                </tr>
                                        }

                                        <tr>
                                            <td colSpan={6} className='text-center'>
                                                <Link to={`/jobs/all-pending-applications`} className='btn btn-md btn-success text-center w-50' > View All ({JobApplications.length})</Link>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>




            </div>
        </div>
    )

}

const mapStateToProps = (state) => {
    return {
        AdminLoginData: state.AdminLoginData,
        departmentList: state.departmentList,
        facultyList: state.facultyList,
        staffList: state.staffList,
        usersList: state.usersList,
        JobList: state.JobList,
        AllJobApplications: state.AllJobApplications,
        activeCohort: state.activeCohort
    };
};
const mapDisptachToProps = (dispatch) => {
    return {
        setOnAdminLoginData: (p) => {
            dispatch(setAdminLoginDetails(p))
        },
        setOnStaffList: (p) => {
            dispatch(setStaffList(p))
        },
        setOnHrDesignations: (p) => {
            dispatch(setHrdesignations(p))
        },
        setOnDepartmentList: (p) => {
            dispatch(setDepartmentsList(p))
        },
        setOnFacultyList: (p) => {
            dispatch(setFacultyList(p))
        },
        setOnUsersList: (p) => {
            dispatch(setUsersListAction(p))
        },
        setOnNotifications: (p) => {
            dispatch(setNotifcationsAction(p))
        },
        setOnDashBoardData: (p) => {
            dispatch(setDashboardData(p))
        },
        setOnSingleJobApplication: (p) => {
            dispatch(setSingleJobApplication(p))
        }
    }
}

export default connect(mapStateToProps, mapDisptachToProps)(AdminDashboard);


