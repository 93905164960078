import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { setAdminJobApplications, setJobListAction } from '../../../../actions/actions';
import { CheckPermission, formatDate, formatDateAndTime, onGeneralEdit, schoolName } from '../../../../util/constants';
import { serverLink } from '../../../../util/url';
import Modal from '../../../common/modal';
import PageTitle from '../../../common/pagetitle';
import ReportTable from '../../../common/report_table';
import { AddEditJobsValidationScehma } from '../../../formik/validationSchema';
import JoditEditor from "jodit-react";
import Select from "react-select";



function JobOpenings(props) {
    const allow = props.permissionsList.length > 0 ? props.permissionsList.filter(x => x.Designation === 'superadmin' || x.Designation === 'hr').length > 0 ? true : false : false;
    CheckPermission(allow);
    const activeCohort = props.activeCohort[0];

    const staff = props.AdminLoginData
    const controller = new AbortController();
    const [JobList, setJobList] = useState([]);
    const columns = ["SN", "Job ID", "Position", "Department", "Opening Date", "Closing Date", "Status", "Date Added", "Action"];
    const [tableData, setTableData] = useState([]);
    const [isLoading, setisLoading] = useState(true)
    const [departmentList, setdepartmentList] = useState(props.departmentList.length > 0 ? props.departmentList : [])
    const [departmentList2, setdepartmentList2] = useState([])
    const [facultyList, setfacultyList] = useState(props.facultyList.length > 0 ? props.facultyList : []);
    const [hrDesignations, sethrDesignations] = useState(props.hrDesignations.length > 0 ? props.hrDesignations : [])

    const [formData, setFormdata] = useState({
        EntryID: "",
        RequisitionID: "",
        Position: "",
        Position2: "",
        Faculty: "",
        Department: "",
        OpeningDate: "",
        ClosingDate: "",
        NoOfOpenings: 1,
        JobType: "",
        Urgent: "",
        Status: "",
        Description: "",
        Requirements: "",
        InterViewDate: activeCohort?.End,
        InterviewVenue: `Block A, Board Room, ${schoolName} `,
        InterviewTime: "10:00",
        InsertedBy: staff[0]?.StaffID,
        CohortCode: activeCohort?.CohortCode
    })
    const [interviewTypesList, setInterviewTypesList] = useState([]);

    const getData = async () => {
        const interview_types = await axios.get(`${serverLink}/enrollment/interview/assessment/interview-types`);
        setInterviewTypesList(interview_types.data);

        let rows = [];
        props.hrDesignations.length > 0 &&
            props.hrDesignations.map((x, i) => {
                rows.push({ value: x.DesignationName, label: x.DesignationName });
            })
        sethrDesignations(rows)


        const { signal } = controller.signal;
        try {
            await axios.get(`${serverLink}/enrollment/get_openings`, { signal }).then((res) => {
                if (res.data.length > 0) {
                    setJobList(res.data);
                    let rows = []
                    res.data.map((x, i) => {
                        rows.push([
                            i + 1,
                            x.RequisitionID,
                            x.Position,
                            x.Department,
                            formatDateAndTime(x.OpeningDate, "date"),
                            formatDateAndTime(x.ClosingDate, "date"),
                            <span className={x.Status === "1" ? "badge badge-success" : x.Status === "2" ? "badge badge-info" : "badge badge-danger"}>
                                {x.Status === "1" ? "Active" : x.Status === "2" ? "Inactive" : "Closed"}
                            </span>,
                            formatDateAndTime(x.InsertedDate, "date"),
                            <button className='btn btn-sm btn-main' data-toggle="modal" data-target="#job-modal"
                                onClick={() => {
                                    let detps = props.departmentList.length > 0 ? props.departmentList.filter(j => j.FacultyName === x.Faculty) : [];
                                    setdepartmentList2(detps);
                                    setFormdata({
                                        ...formData,
                                        EntryID: x.EntryID,
                                        RequisitionID: x.RequisitionID,
                                        Position2: { value: x.Position, label: x.Position },
                                        Position: x.Position,
                                        Faculty: x.Faculty,
                                        Department: detps.length > 0 ? detps.filter(j => j.DepartmentName === x.Department)[0].DepartmentName : '',
                                        OpeningDate: formatDate(x.OpeningDate),
                                        ClosingDate: formatDate(x.ClosingDate),
                                        JobType: x.JobType,
                                        NoOfOpenings: x.NoOfOpenings,
                                        Urgent: x.Urgent,
                                        Status: x.Status,
                                        Description: x.Description,
                                        Requirements: x.Requirements,
                                        InterViewDate: formatDate(x.InterViewDate),
                                        InterviewVenue: x.InterviewVenue,
                                        InterviewTime: x.InterviewTime,
                                    })
                                }} >
                                <i className='ti ti-pencil' /> &nbsp;
                                Edit

                            </button>
                        ])
                    })
                    setTableData(rows)
                }
                setisLoading(false)
            })
        } catch (e) {
            console.log(e)
            toast.error("netwrok error")
        }
    }

    useEffect(() => {
        getData();
        return () => { controller.abort() }
    }, [])

    const onSubmitJob = async (e) => {
        e.preventDefault();
        toast.info("please wait...");

        if (formData.Requirements === "") {
            toast.error("please enter job requirements");
            return false;
        }
        if (formData.Description === "") {
            toast.error("please enter job requirements");
            return false;
        }
        try {
            if (formData.EntryID === "") {
                await axios.post(`${serverLink}/job_applications/add_job_opening`, formData).then((res) => {
                    if (res.data.message === "success") {
                        ResetValues();
                        getData();
                        document.getElementById("modal-close").click();
                        toast.success("job opening added")
                    }
                })
            } else {
                await axios.put(`${serverLink}/job_applications/update_job_opening`, formData).then((res) => {
                    if (res.data.message === "success") {
                        ResetValues();
                        getData();
                        document.getElementById("modal-close").click();
                        toast.success("job opening updated")
                    }
                })
            }
        } catch (e) {
            toast.error("network error!")
        }
    }

    const onEdit = (e) => {
        setFormdata({
            ...formData,
            [e.target.id]: e.target.value
        })
        if (e.target.id === "Faculty") {
            setFormdata({
                ...formData,
                [e.target.id]: e.target.value
            })
            let detps = props.departmentList.length > 0 ? props.departmentList.filter(x => x.FacultyName === e.target.value) : [];
            setdepartmentList2(detps)
        }
    }

    const handlePositionChange = (e) => {
        setFormdata({
            ...formData,
            Position: e.value,
            Position2: e
        })
    }
    const ResetValues = () => {
        setFormdata({
            ...formData,
            EntryID: "",
            RequisitionID: "",
            Position: "",
            Faculty: "",
            Department: "",
            OpeningDate: "",
            ClosingDate: "",
            JobType: "",
            Urgent: "",
            Status: "",
            Description: "",
            Requirements: "",
            // InterViewDate: "",
            //InterviewVenue: "",
            //InterviewTime: "",
            InsertedBy: staff[0]?.StaffID
        });
    }

    return (
        <div>
            <PageTitle title={["Jobs", "Job Openings", "Manage Openings"]} />
            <div className="card mt-3">
                <div className="card-body">
                    <div className='d-flex justify-content-between mb-4'>
                        <h4 className="card-title">Manage Job Openings</h4>
                        <button className='btn btn-sm btn-main' data-toggle="modal" data-target="#job-modal" onClick={ResetValues} >
                            Add New Job Opening
                        </button>
                    </div>
                    <div className="table-responsive">
                        <ReportTable data={tableData} columns={columns} />

                    </div>

                    <Modal title={`Manage Job Opening (Cohort : ${formData.CohortCode})`} id="job-modal" close="modal-close">
                        <div className='row'>
                            <div className='col-md-12'>
                                <form className="m-t-30 needs-validation" onSubmit={onSubmitJob} >
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label htmlFor="Position">Position</label>
                                                <Select
                                                    id="PositionRecommend"
                                                    className="form-select form-select"
                                                    value={formData.Position2}
                                                    onChange={handlePositionChange}
                                                    options={hrDesignations}
                                                    placeholder="Position Recommend"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label htmlFor="Faculty">Faculty</label>
                                                <select id="Faculty" name="Faculty"
                                                    onChange={onEdit}
                                                    value={formData.Faculty}
                                                    className={'form-control'} required >
                                                    <option value={""}>--select--</option>
                                                    {
                                                        facultyList.length > 0 &&
                                                        facultyList.map((x, i) => {
                                                            return (
                                                                <option key={i} value={x.FacultyName}>{x.FacultyName}</option>
                                                            )
                                                        })
                                                    }
                                                </select>

                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label htmlFor="Department">Department</label>
                                                <select id="Department" name="Department"
                                                    onChange={onEdit}
                                                    value={formData.Department}
                                                    className={'form-control'} required >
                                                    <option value={""}>--select--</option>
                                                    {
                                                        departmentList2.length > 0 &&
                                                        departmentList2.map((x, i) => {
                                                            return (
                                                                <option key={i} value={x.DepartmentName}>{x.DepartmentName}</option>
                                                            )
                                                        })
                                                    }
                                                </select>

                                            </div>
                                        </div>

                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label htmlFor="JobType">Category</label>
                                                <select id="JobType" name="JobType"
                                                    onChange={onEdit}
                                                    value={formData.JobType}
                                                    className={'form-control'} required >
                                                    <option value={""}>--select--</option>
                                                    {
                                                        interviewTypesList.length > 0 &&
                                                        interviewTypesList.map((x, i) => {
                                                            return (
                                                                <option value={x.InterviewType} key={i}>{x.InterviewType}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label htmlFor="Urgent">Is Urgent ?</label>
                                                <select id="Urgent" name="Urgent"
                                                    onChange={onEdit}
                                                    value={formData.Urgent}
                                                    className={'form-control'} required >
                                                    <option value={""}>--select--</option>
                                                    <option value={"1"}>Yes</option>
                                                    <option value={"0"}>No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label htmlFor="Status">Status</label>
                                                <select id="Status" name="Status"
                                                    onChange={onEdit}
                                                    value={formData.Status}
                                                    className={'form-control'} required >
                                                    <option value={""}>--select--</option>
                                                    <option value={"1"}>Active</option>
                                                    <option value={"2"}>In-Active</option>
                                                    <option value={"0"}>Close</option>
                                                </select>

                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label htmlFor="NoOfOpenings">No. Of Openings</label>
                                                <input type="number" step={0} id="NoOfOpenings" name="NoOfOpenings" onChange={onEdit}
                                                    value={formData.NoOfOpenings}
                                                    className={'form-control'} required
                                                />

                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label htmlFor="OpeningDate">Opening Date</label>
                                                <input type="date" id="OpeningDate" name="OpeningDate" onChange={onEdit}
                                                    value={formData.OpeningDate}
                                                    className={'form-control'} required
                                                />

                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label htmlFor="ClosingDate">Closing Date</label>
                                                <input type="date" id="ClosingDate" name="ClosingDate" onChange={onEdit}
                                                    value={formData.ClosingDate}
                                                    className={'form-control'} required
                                                />

                                            </div>
                                        </div>

                                        <div className='col-md-12'>
                                            <div className="form-group">
                                                <label htmlFor="Description">Description</label>
                                                <JoditEditor
                                                    id="Description"
                                                    value={formData.Description}
                                                    tabIndex={1}
                                                    onChange={(e) => {
                                                        setFormdata({
                                                            ...formData,
                                                            Description: e
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className='col-md-12'>
                                            <div className="form-group">
                                                <label htmlFor="Requirements">Requirements</label>
                                                <JoditEditor
                                                    id="Requirements"
                                                    value={formData.Requirements}
                                                    tabIndex={1}
                                                    onChange={(e) => {
                                                        setFormdata({
                                                            ...formData,
                                                            Requirements: e
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className='col-md-12'>
                                            <div className="alert alert-info">
                                                <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="alert"
                                                    aria-label="Close"
                                                >
                                                    {" "}
                                                    <span aria-hidden="true">×</span>{" "}
                                                </button>
                                                <h3 className="text-info">
                                                    <i className="fa fa-exclamation-circle" /> Information -- Active Cohort : {formData.CohortCode}
                                                </h3>{" "}
                                                Start : {formatDateAndTime(formatDate(activeCohort?.Start), "date")} &emsp; End : {formatDateAndTime(formatDate(activeCohort?.End), "date")}
                                            </div>
                                        </div>

                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label htmlFor="InterViewDate">Interview Date</label>
                                                <input type="date" id="InterViewDate" name="InterViewDate" onChange={onEdit}
                                                    value={formatDate(formData.InterViewDate)}
                                                    className={'form-control'} required
                                                />

                                            </div>
                                        </div>

                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label htmlFor="InterviewTime">Interview Time</label>
                                                <input type="time" id="InterviewTime" name="InterviewTime" onChange={onEdit}
                                                    value={formData.InterviewTime}
                                                    className={'form-control'} required
                                                />

                                            </div>
                                        </div>


                                        <div className='col-md-12'>
                                            <div className="form-group">
                                                <label htmlFor="InterviewVenue">Interview Venue</label>
                                                <textarea type="text" id="InterviewVenue" name="InterviewVenue" onChange={onEdit}
                                                    value={formData.InterviewVenue}
                                                    className={'form-control'} required
                                                ></textarea>

                                            </div>
                                        </div>
                                    </div>

                                    {/* disabled={isValid === true ? false : true} */}
                                    <button type="submit" className="btn btn-get-started w-100">Submit</button>
                                </form>
                            </div>
                        </div>

                    </Modal>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        AdminLoginData: state.AdminLoginData,
        departmentList: state.departmentList,
        facultyList: state.facultyList,
        staffList: state.staffList,
        usersList: state.usersList,
        JobList: state.JobList,
        AllJobApplications: state.AllJobApplications,
        hrDesignations: state.hrDesignations,
        permissionsList: state.permissionsList,
        activeCohort: state.activeCohort
    };
};
const mapDisptachToProps = (dispatch) => {
    return {


    }
}

export default connect(mapStateToProps, mapDisptachToProps)(JobOpenings);

