import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { setAdminLoginDetails } from '../../../../actions/actions';
import Loader from '../../../../common/loader';
import { serverLink } from '../../../../util/url';
import Breadcrum from "../../../../common/breadcrum/breadcrum";
import ReportTable from "../../../common/report_table";
import Modal from "../../../common/modal";
import { showAlert } from "../../../../common/sweetalert/sweetalert";
import { CheckPermission } from '../../../../util/constants';

function InterviewScoreSettings(props) {
    const allow = props.permissionsList.length > 0 ? props.permissionsList.filter(x => x.Designation === 'superadmin' || x.Designation === 'hr').length > 0 ? true : false : false;
    CheckPermission(allow);

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [generalDatatable, setGeneralDatatable] = useState([]);
    const columns = ["S/N", "Interview Type", "Section", "Item Name", "Item Score", "Action"];
   
    const [formData, setFormData] = useState({
        EntryID: "",
        InterviewType: "",
        ItemName: "",
        ItemScore: "",
        Section: "",
        InsertedBy: `${props.AdminLoginData[0]?.StaffID}`,
        SectionName: "",

    })

    const [formType, setFormType] = useState('')

    const resetFormData = () => {
        return setFormData({
            ...formData,
            EntryID: "",
            InterviewType: "",
            ItemName: "",
            Section: "",
            ItemScore: "",
            SectionName: "",
        });
    }
    const [sectionsList, setSectionsList] = useState([]);
    const [interviewTypesList, setInterviewTypesList] = useState([]);

    const getData = async () => {
        const sections = await axios.get(`${serverLink}/enrollment/interview/assessment/section-settings`);
        setSectionsList(sections.data);
        const sec_tions = [{ EntryID: 0, InsertedBy: "", InsertedDate: "", SectionName: "Personal Information" }, ...sections.data]

        const interview_types = await axios.get(`${serverLink}/enrollment/interview/assessment/interview-types`);
        setInterviewTypesList(interview_types.data);

        await axios.get(`${serverLink}/enrollment/interview/assessment/settings`,).then((res) => {
            const data = res.data;
            let rows = [];
            if (data.length > 0) {
                setData(data);
                data.map((item, index) => {
                    rows.push([
                        index + 1,
                        item.InterviewType,
                        sec_tions?.filter(e => e.EntryID.toString() === item.Section.toString())[0]?.SectionName,
                        item.ItemName,
                        item.ItemScore,
                        (
                            <>
                                <button
                                    type="button" data-toggle="modal" data-target=".bs-example-modal-lg"
                                    className="btn btn-sm btn-main"
                                    onClick={() => {
                                        setFormType('score-settings')
                                        setFormData({
                                            ...formData,
                                            EntryID: item.EntryID,
                                            InterviewType: item.InterviewType,
                                            ItemName: item.ItemName,
                                            ItemScore: item.ItemScore,
                                            Section: item.Section,
                                        })
                                    }}
                                >
                                    <i className="fa fa-edit" />
                                </button>

                            </>
                        ),
                    ]);
                });
            }
            setGeneralDatatable(rows);
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }

    const getSections = async () => {
        const sections = await axios.get(`${serverLink}/enrollment/interview/assessment/section-settings`);
        setSectionsList(sections.data);

        const interview_types = await axios.get(`${serverLink}/enrollment/interview/assessment/interview-types`);
        setInterviewTypesList(interview_types.data);
    }

    useEffect(() => {
        getData();
    }, []);


    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const descriptionHandler = (event) => {
        setFormData({
            ...formData,
            ENTRY_REQUIREMENT: event,
        });
    }

    const onSubmit = async () => {

        if (formData.ItemName.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter item name", "error");
            return false;
        }

        if (formData.ItemScore.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter item score", "error");
            return false;
        }

        if (formData.InterviewType.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select interview type", "error");
            return false;
        }

        if (formData.Section.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select section", "error");
            return false;
        }



        if (formData.EntryID === "") {

            let sendData = {
                ...formData,
            }

            setIsFormLoading(true);
            await axios
                .post(`${serverLink}/enrollment/interview/assessment/settings/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Interview Score Settings Added Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("modal-close").click();
                        resetFormData()

                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        } else {
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios.patch(`${serverLink}/enrollment/interview/assessment/settings/update`, sendData).then((result) => {
                if (result.data.message === "success") {
                    toast.success("Interview Score Settings Updated Successfully");
                    setIsFormLoading(false);
                    getData();
                    document.getElementById("modal-close").click();
                    resetFormData();
                } else {
                    setIsFormLoading(false);
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                }
            })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }

    const onAddSection = async () => {
        await axios.post(`${serverLink}/enrollment/interview/assessment/section-settings/add/`, formData).then((result) => {
            if (result.data.message === "success") {
                toast.success("Section Settings Updated Successfully");
                setIsFormLoading(false);
                getSections();
                document.getElementById("modal-close").click();
                resetFormData();
            } else {
                setIsFormLoading(false);
                showAlert(
                    "ERROR",
                    "Already exists!",
                    "error"
                );
            }
        }).catch((error) => {
            setIsFormLoading(false);
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }

    const onAddInterviewType = async () => {
        await axios.post(`${serverLink}/enrollment/interview/assessment/interview-types/add/`, formData).then((result) => {
            if (result.data.message === "success") {
                toast.success("Interview type Updated Successfully");
                setIsFormLoading(false);
                getSections();
                document.getElementById("modal-close").click();
                resetFormData();
            } else {
                setIsFormLoading(false);
                showAlert(
                    "ERROR",
                    "Already exists!",
                    "error"
                );
            }
        }).catch((error) => {
            setIsFormLoading(false);
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }

    return (
        isLoading ? <Loader />
            :
            <div>
                <div className="container-fluid" style={{ marginLeft: '-10px' }}>
                    <Breadcrum title="Interview Score Settings" />
                    <div className="row mt-3">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex justify-content-end"  >
                                        <button type="button" data-toggle="modal" data-target=".bs-example-modal-lg" className="btn btn-main waves-effect text-left ml-3"
                                            onClick={() => { resetFormData(); setFormType('interview-type') }}>
                                            Add Interview Type
                                        </button>
                                        <button type="button" data-toggle="modal" data-target=".bs-example-modal-lg" className="btn btn-main waves-effect text-left ml-3"
                                            onClick={() => { resetFormData(); setFormType('score-section') }}>
                                            Add Score Section
                                        </button>
                                        <button type="button" data-toggle="modal" data-target=".bs-example-modal-lg" className="btn btn-main waves-effect text-left ml-3"
                                            onClick={() => { resetFormData(); setFormType('score-settings') }}>
                                            Add Interview Score
                                        </button>
                                    </div>
                                    <hr />
                                    <ReportTable title={generalDatatable.length + " Records"} columns={columns} data={generalDatatable} height="700px" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal id="open" title="Interview Score Form">
                    <form className="m-t-5">
                        {
                            formType === 'score-settings' ?
                                <div className="row">
                                    <div className="form-group col-md-6 mb-3">
                                        <label htmlFor="ItemName">Item name </label>
                                        <input type="text" className="form-control" id="ItemName" name="ItemName" value={formData.ItemName} onChange={onEdit} placeholder="Item Name" />
                                    </div>
                                    <div className="form-group col-md-6 mb-3">
                                        <label htmlFor="ItemScore">Item Score</label>
                                        <input type="number" className="form-control" id="ItemScore" min={0} name="ItemScore" value={formData.ItemScore} onChange={onEdit} placeholder="Item Score" />
                                    </div>
                                    <div className="form-group col-md-12 mb-3">
                                        <label htmlFor="InterviewType">Interview Type</label>
                                        <select className="form-control" name="InterviewType" id="InterviewType" onChange={onEdit} value={formData.InterviewType}>
                                            <option value="">Select Interview Type</option>
                                            {
                                                interviewTypesList.length > 0 &&
                                                interviewTypesList.map((x, i) => {
                                                    return (
                                                        <option value={x.InterviewType} key={i}>{x.InterviewType}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group col-md-12 mb-3">
                                        <label htmlFor="Section">Section</label>
                                        <select className="form-control" name="Section" id="Section" onChange={onEdit} value={formData.Section}>
                                            <option value="">Select Section</option>
                                            <option value={"0"} >{"Personal Information"}</option>
                                            {
                                                sectionsList.length > 0 &&
                                                sectionsList.map((x, i) => {
                                                    return (
                                                        <option value={x.EntryID} key={i}>{x.SectionName}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group mb-1 mt-3 col-md-12">
                                        {
                                            IsFormLoading ?
                                                <button className="btn btn-main float-right" type="button" disabled>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                                    Loading...
                                                </button>
                                                :
                                                <button type="button" className="btn btn-main float-right" onClick={onSubmit}>Submit</button>
                                        }

                                    </div>
                                </div>
                                :
                                formType === "score-section" ?
                                    <div className='row'>
                                        <div className="form-group col-md-8">
                                            <label htmlFor="SectionName">Section Name </label>
                                            <input type="text" className="form-control" id="SectionName" name="SectionName" value={formData.SectionName} onChange={onEdit} placeholder="Section Name" />
                                        </div>
                                        <div className="form-group col-md-12" >
                                            {
                                                IsFormLoading ?
                                                    <button className="btn btn-main float-left" type="button" disabled>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                                        Loading...
                                                    </button>
                                                    :
                                                    <button type="button" className="btn btn-main float-left" onClick={onAddSection}>Submit</button>
                                            }

                                        </div>
                                        <br /><br />
                                        <hr />
                                        <div className="table-responsive">
                                            <table className='table table-striped'>
                                                <thead>
                                                    <tr>
                                                        <td>SN</td>
                                                        <td>Section Name</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        sectionsList.length > 0 &&
                                                        sectionsList.map((x, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{i + 1}</td>
                                                                    <td>{x.SectionName}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    :
                                    <div className='row'>
                                        <div className="form-group col-md-8">
                                            <label htmlFor="InterviewType">Interview Type </label>
                                            <input type="text" className="form-control" id="InterviewType" name="InterviewType" value={formData.InterviewType} onChange={onEdit} placeholder="Interview Type" />
                                        </div>
                                        <div className="form-group col-md-12" >
                                            {
                                                IsFormLoading ?
                                                    <button className="btn btn-main float-left" type="button" disabled>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                                        Loading...
                                                    </button>
                                                    :
                                                    <button type="button" className="btn btn-main float-left" onClick={onAddInterviewType}>Submit</button>
                                            }

                                        </div>
                                        <br /><br />
                                        <hr />
                                        <div className="table-responsive">
                                            <table className='table table-striped'>
                                                <thead>
                                                    <tr>
                                                        <td>SN</td>
                                                        <td>Type Name</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        interviewTypesList.length > 0 &&
                                                        interviewTypesList.map((x, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{i + 1}</td>
                                                                    <td>{x.InterviewType}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                        }
                    </form>
                </Modal>
            </div>
    );
}

const mapStateToProps = (state) => {
    return {
        AdminLoginData: state.AdminLoginData,
        permissionsList: state.permissionsList

    };
};
const mapDisptachToProps = (dispatch) => {
    return {
        setOnAdminLoginData: (p) => {
            dispatch(setAdminLoginDetails(p))
        },

    }
}

export default connect(mapStateToProps, mapDisptachToProps)(InterviewScoreSettings);


