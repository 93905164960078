
import axios from 'axios';
import React, { useState } from 'react'
import { connect } from 'react-redux';
import { setApplicantLoginDetails } from '../../../actions/actions';
import { decryptData, encryptData, projectLogo } from '../../../util/constants';
import { serverLink } from '../../../util/url';



const ApplicantRegister = (props) => {

  const [data, setData] = useState({
    FirstName:"",
    MiddleName:"",
    SurName:"",
    Phone:"",
    Email: "",
    Password: "",
    CPassword:""
  })

  const onRegister = async () => {
    const dt = {
      Email: data.Email,
      Password: encryptData(data.Password)
    }
    await axios.post(`${serverLink}/job_applications/job_applicant_login`, dt).then((res) => {
      if (res.data.length > 0) {
        props.setOnJobApplicantData(res.data);
      }
    })


  }



  return (
    <div className="">
      <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 d-flex flex-column align-items-center justify-content-center">
              <div className="d-flex justify-content-center py-4">
                <a href="index.html" className="d-flex align-items-center w-auto">
                  <img src={projectLogo} style={{ width: '90px', height: '90px' }} alt="" />
                </a>
              </div>{/* End Logo */}
              <div className="card mb-3">
                <div className="card-body">
                  <div className="pt-4 pb-2">
                    <h5 className="card-title text-center pb-0 fs-4">Create Your Account</h5>
                  </div>
                  <form className="row g-3 needs-validation" noValidate onSubmit={onRegister}>
                    <div className="col-12">
                      <label htmlFor="yourUsername" className="form-label">Email Address</label>
                      <div className="input-group has-validation">
                        <span className="input-group-text" id="inputGroupPrepend">@</span>
                        <input type="email" name="Email" className="form-control" id="Email" required />
                        <div className="invalid-feedback">Please enter your email.</div>
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="yourPassword" className="form-label">Password</label>
                      <input type="password" name="Password" className="form-control" id="Password" required />
                      <div className="invalid-feedback">Please enter your password!</div>
                    </div>
                    <div className="col-12">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="remember" defaultValue="true" id="rememberMe" />
                        <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-primary w-100" type="submit">Login</button>
                    </div>
                    <div className="col-12">
                      <p className="small mb-0">Don't have account? <a href="pages-register.html">Create an account</a></p>
                    </div>
                  </form>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    JobApplicants: state.JobApplicantData,

  };
};
const mapDisptachToProps = (dispatch) => {
  return {
    setOnJobApplicantData: (p) => {
      dispatch(setApplicantLoginDetails(p))
    }
  }
}

export default connect(mapStateToProps, mapDisptachToProps)(ApplicantRegister);


