import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link, useLocation, useParams } from "react-router-dom";
import Breadcrum from '../../../../common/breadcrum/breadcrum';
import ReportTable from '../../../common/report_table';
import { serverLink } from '../../../../util/url';
import Loader from '../../../../common/loader';
import { CheckPermission, decryptData, formatDateAndTime } from '../../../../util/constants';
import Swal from 'sweetalert2';


function Permissions(props) {


    const location = useLocation();
    const { slug } = useParams();
    const decrypted = slug === undefined ? "" : decryptData(slug)

    if (decrypted === "") {
        const allow = props.permissionsList.length > 0 ? props.permissionsList.filter(x => x.Designation === 'superadmin' || x.Designation === 'hr').length > 0 ? true : false : false;
        CheckPermission(allow);
    }
    const [isLoading, setIsLoading] = useState(true);
    const [generalDatatable, setGeneralDatatable] = useState([]);
    const columns = ["SN", "StaffID", "Name", "Designation", "Department", "Status", "InsertedBy", "Inserted date", "Action"];

    const getData = async () => {
        setIsLoading(true)
        await axios.get(`${serverLink}/enrollment/get-permissions`).then((res) => {
            if (res.data.length > 0) {
                let rows = [];
                const dt = slug === undefined ? res.data : res.data.filter(x => x.StaffID === decrypted)

                if (dt.length > 0) {
                    dt.map((item, index) => {
                        rows.push([
                            index + 1,
                            item.StaffID,
                            item.staff_name,
                            item.Designation.toString().toUpperCase(),
                            item.Department,
                            <span className={item.Status === "1" ? "badge badge-success" : "badge badge-danger"}>
                                {item.Status === "1" ? "Active" : "In-Active"}
                            </span>,
                            item.InsertedBy,
                            formatDateAndTime(item.InsertedDate, "date"),
                            <>
                                {
                                    decrypted === "" &&
                                    <button className='btn btn-sm btn-danger' onClick={() => { onDropPermission(item.StaffID, item.Designation) }} >
                                        <i className='fa fa-trash' />
                                    </button>
                                }
                            </>
                        ]);
                    });
                }
                setGeneralDatatable(rows);
                setIsLoading(false);
            }
        })
            .catch((err) => {
                setIsLoading(false);
                toast.error("NETWORK ERROR. Please try again!");
            });


    }

    useEffect(() => {
        getData();
    }, [location.pathname]);

    const onDropPermission = async (staff_id, designation) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `proceed to drop permission ${designation} for ${staff_id} `,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Submit'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await axios.delete(`${serverLink}/enrollment/delete-permissions/${staff_id}/${designation}`).then((res) => {
                    if (res.data.message === "success") {
                        toast.success("permission removed");
                        getData();
                    } else {
                        toast.error("please try again!")
                    }
                }).catch((e) => {
                    toast.error("network error!")
                })

            }
        })
    }

    return (
        isLoading ? <Loader />
            :
            <div>
                <div className="container-fluid" style={{ marginLeft: '-10px' }}>
                    <Breadcrum title={"System Permissions"} />
                    <div className="row mt-3">
                        <div className="col-12">
                            <ReportTable columns={columns} data={generalDatatable} height="900px" />
                        </div>
                    </div>
                </div>
            </div>
    );
}

const mapStateToProps = (state) => {
    return {
        AdminLoginData: state.AdminLoginData,
        AllJobApplications: state.AllJobApplications,
        staffList: state.staffList,
        permissionsList: state.permissionsList
    };
};



export default connect(mapStateToProps, null)(Permissions);


