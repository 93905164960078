import React, { useEffect, useState } from "react";
import Loader from "../../../../../common/loader";
import "./print-assessment-sheet.css";
import { calculateAge, formatDateAndTime, projectAddress, projectLogo, projectName } from "../../../../../util/constants";
import { connect } from "react-redux";
import axios from "axios";
import { serverLink } from "../../../../../util/url";
import { toast } from "react-toastify";
import { showAlert } from "../../../../../common/sweetalert/sweetalert";

function PrintAssessmentSheet(props) {
    const [isLoading, setIsLoading] = useState(true);

    const [Panel, setPanel] = useState([]);
    const [totalScore, setTotalScore] = useState(0);
    const [staffTotalScore, setStaffTotalScore] = useState(0);
    const [applicantRecommendation, setApplicantRecommendation] = useState([]);
    const [applicantScore, setApplicantScore] = useState([]);
  
    const [formData, setFormData] = useState({
        EntryID: "",
        ApplicantID: props.Interviewee?.EntryID,
        JobID: props.JobData?.RequisitionID,
        PanelRecommendation: "",
        HRRecommendation: "",
        InsertedBy: `${props.AdminLoginData[0]?.StaffID}`,
    })

    const printNow = () => {
        document.getElementById("toggle-side").click();
        window.print();
        document.getElementById("toggle-side").click();
    }

    const [sectionsList, setSectionsList] = useState([]);

    const getData = async () => {
        const sections = await axios.get(`${serverLink}/enrollment/interview/assessment/section-settings`);

        setSectionsList([{ EntryID: 0, section: "Personal Information" }, ...sections.data]);


        await axios.get(`${serverLink}/enrollment/interview/hr/assessment/print/data/${props.Interviewee?.EntryID}/${props.JobData?.RequisitionID}`,).then((res) => {
            const data = res.data;
            if (data.Recommendation.length > 0) {
                setApplicantScore(data.Score);
                setStaffTotalScore(data.Score.length > 0 && data.Score.filter(e => parseFloat(e.Score, 10) && e.Section.toString() !== "1").map(j => parseFloat(j.Score)).reduce((a, b) => a + b, 0))
                setPanel(data.Panel);
                setApplicantRecommendation(data.Recommendation);
                setFormData({
                    ...formData,
                    PanelRecommendation: data.Recommendation[0]?.PanelRecommendation,
                    HRRecommendation: data.Recommendation[0]?.HRRecommendation,
                });
            }
            setIsLoading(false);
        })
            .catch((err) => {
                toast.error("NETWORK ERROR. Please try again!");
            });
    }

    useEffect(() => {
        getData();
        let sum = props.InterviewSettings.length > 0 && props.InterviewSettings.map(j => parseFloat(j.ItemScore)).reduce((a, b) => a + b, 0);
        setTotalScore(sum)
    }, [])

    const getIntervieweeData = (data, interviewee) => {
        if (data.toLowerCase().includes('name')) {
            return interviewee.FirstName + " " + interviewee.MiddleName + " " + interviewee.Surname;
        } else if (data.toLowerCase().includes('position')) {
            return props.JobData?.Position;
        } else if (data.toLowerCase().includes('marital') || data.toLowerCase().includes('children')) {
            return interviewee.MaritalStatus;
        } else if (data.toLowerCase().includes('age') || data.toLowerCase().includes('birth')) {
            return calculateAge(interviewee.DateOfBirth);
        } else {
            return "";
        }
    }


    return isLoading ? (
        <Loader />
    ) : (<>
        <div className="">
            {
                Panel.length > 0 ?
                    Panel.map((element, keys) => {
                        return (
                            <div key={keys} className={`${keys.toString() === "0" ? 'top-margin' : ''} bg-white take-left`}>
                                <div className="col-md-12 pt-0 bg-white break-page" id="section-to-print">
                                    <div className="col-lg-12 bgImg hero-image pt-0">
                                        <div className="head-main ">
                                            <div className="item-data">
                                                <img src={projectLogo} alt="Logo" width={100} height={100} style={{ marginRight: '15px' }} />
                                            </div>
                                            <div className="head-item">
                                                <span className="">
                                                    <span className="item-data" style={{ fontSize: '16px', wordSpacing: '1px' }}>{projectAddress}</span>
                                                    <h5 className="m-0 item-data text-uppercase"><b>INTERVIEW SCORE SHEET ({props.JobData?.JobType})</b></h5>
                                                </span>
                                            </div>
                                        </div>
                                        <br />
                                        {

                                            <div className="table-responsive">
                                                <div className="p-2" style={{ backgroundColor: '#eeeeee', fontWeight: 'bold' }}><h5><b>Applicant Bio-data</b></h5></div>
                                                <hr style={{ marginTop: '-3px', border: '1px solid #eeeeee' }} />
                                                <table className="border-0 mb-3">
                                                    <tbody>
                                                        {
                                                            props.InterviewSettings.length > 0 ?
                                                                props.InterviewSettings.filter(e => e.Section.toString() === "0").map((x, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <th width={300}>{x.ItemName}</th>
                                                                            <td>{applicantScore.length > 0 ? applicantScore.filter(e => e.AssessmentID.toString() === x.EntryID.toString() && e.InsertedBy === element.PanelInsertedBy)[0]?.Score : getIntervieweeData(x.ItemName, props.Interviewee)}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                :
                                                                <></>
                                                        }
                                                    </tbody>
                                                </table>
                                                <hr style={{ marginTop: '-3px', border: '1px solid #eeeeee' }} />
                                                <table className="table-bordered w-100 mb-5">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ fontWeight: 'bold', paddingTop: '6px' }}>Area of Scoring</th>
                                                            <th className="text-center">Maximum Scores/Point</th>
                                                            <th className="text-center">Scores/Points</th>
                                                        </tr>
                                                    </thead>
                                                    {
                                                        sectionsList.filter(e => e.EntryID.toString() !== "0" &&
                                                            props.InterviewSettings.map(j => j.Section.toString()).includes(e.EntryID.toString())).map((item, int) => {
                                                                return (
                                                                    <tbody key={int}>
                                                                        <tr>
                                                                            <td colSpan={3} className="p-2" style={{ backgroundColor: '#eeeeee', fontWeight: 'bold' }}>
                                                                                <h5><b>{item.SectionName}</b>
                                                                                </h5>
                                                                            </td>
                                                                        </tr>

                                                                        {
                                                                            props.InterviewSettings.length > 0 ?
                                                                                props.InterviewSettings.filter(e => e.Section.toString() !== "0" && e.Section.toString() === item.EntryID.toString()).map((x, i) => {
                                                                                    return (<tr key={i}>
                                                                                        <td>{x.ItemName}</td>
                                                                                        <td className="text-center">{x.ItemScore}</td>
                                                                                        <td className="text-center">{applicantScore.length > 0 ? applicantScore.filter(e => e.AssessmentID.toString() === x.EntryID.toString() && e.InsertedBy === element.PanelInsertedBy)[0]?.Score : ""}</td>
                                                                                    </tr>
                                                                                    )
                                                                                })
                                                                                :
                                                                                <tr>
                                                                                    <td colSpan={5}>No Record Found</td>
                                                                                </tr>
                                                                        }

                                                                    </tbody>
                                                                )
                                                            })
                                                    }
                                                    <tbody>
                                                        <tr style={{ fontSize: '22px', fontWeight: 'bold' }}>
                                                            <td className="text-end">Total:</td>
                                                            <td className="text-center">{totalScore}</td>
                                                            <td className="text-center">{applicantScore.filter(e => parseFloat(e.Score, 10) && e.Section.toString() !== "1" && e.InsertedBy === element.PanelInsertedBy).map(j => parseFloat(j.Score)).reduce((a, b) => a + b, 0)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        }
                                        <div className="row">
                                            <div className="col-md-4 mb-4"><b>Recommendation:</b> {applicantRecommendation.filter(e => e.PanelInsertedBy === element.PanelInsertedBy)[0]?.PanelRecommendation}</div>
                                            <div className="col-md-8 mb-4 text-right"><b>Position Recommended:</b> {applicantRecommendation.filter(e => e.PanelInsertedBy === element.PanelInsertedBy)[0]?.PanelPositionRecommend}</div>
                                            <div className="col-md-8 mb-4"><b>Recommended By:</b> <span className="text-capitalize">{element.StaffName}</span> <span className="text-capitalize">({element.Designation})</span></div>
                                            <div className="col-md-4 mb-4 text-right"><b>Date:</b> {formatDateAndTime(element.PanelInsertedDate, 'date')}</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )
                    })
                    :
                    <></>
            }
            <button id="printPageButton" onClick={printNow} className="btn btn-get-started mt-3 mb-4 ">Print <i className="bi-printer" /></button>
        </div>
    </>

    )
}

const mapStateToProps = (state) => {
    return {
        AdminLoginData: state.AdminLoginData,
        Interviewee: state.interviewee,
        JobData: state.panelJobList,
        PanelDetails: state.panelDetails,
        InterviewSettings: state.assessmentSettings,
    };
};

export default connect(mapStateToProps, null)(PrintAssessmentSheet);

