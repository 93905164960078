import { Try } from '@mui/icons-material'
import axios from 'axios'
import { passwordStrength } from 'check-password-strength'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import {  encryptData, LoginBgImage, projectLogo } from '../../../util/constants'
import { serverLink } from '../../../util/url'

export default function RecoverPassword() {
    const location = useLocation();
    const token = location.search.split("=")[1]
    const navigate = useNavigate();

    const [data, setData] = useState({
        Token: "",
        NewPassword: "",
        ConfirmNewPassword: "",
        btnDisabled: true,
        Pstrength: ""
    })

    const validateToken = async () => {
        await axios.get(`${serverLink}/job_applications/validate_token/${token}`).then((res) => {
            if (res.data.length > 0) {
                setData({
                    ...data,
                    Token: res.data[0]?.Token,
                })

            } else {
                toast.error("This link has expired");
                setTimeout(() => {
                    navigate('/')
                }, 1000)
            }
        })
    }

    useEffect(() => {
        validateToken();
    }, token)

    const onChangePassword = async (e) => {
        e.preventDefault();

        if (data.NewPassword !== data.ConfirmNewPassword) {
            toast.error("password do not match");
            return false;
        }

        const dt = {
            ...data,
            Password: encryptData(data.NewPassword)
        }

        await axios.post(`${serverLink}/job_applications/reset_password`, dt).then((res) => {
            if (res.data.message === "success") {
                toast.success("password changed succesfully!");
                setTimeout(() => {
                    navigate('/applicant/login')
                }, 100)
            } else {
                toast.error("error occured")
            }
        }).catch(() => {
            toast.error("network error!")
        })

    }
    const onEdit = (e) => {
        if (e.target.id === "NewPassword") {
            const str = passwordStrength(e.target.value);
            setData({
                ...data,
                [e.target.id]: e.target.value,
                Pstrength: StrnghtCaption(str),
                btnDisabled: Allow(str)
            })
        } else {
            setData({
                ...data,
                [e.target.id]: e.target.value,
            })
        }

    }

    const StrnghtCaption = (e) => {

        if (e.value === "Too weak") {
            return (<div className='text-danger'> Password strength is too weak</div>)
        } else if (e.value === "Weak") {
            return (<div className='text-warning'> Password strength is weak</div>)
        } else if (e.value === "Medium" && e.length >= 8) {
            setData({
                ...data,
                btnDisabled: false
            })
            return (<div className='text-success'> Strong Password!</div>)
        } else if (e.value === "Strong" && e.length >= 8) {
            setData({
                ...data,
                btnDisabled: false
            })
            return (<div className='text-success'> Strong Password!</div>)
        } else {
            setData({
                ...data,
                btnDisabled: false
            })
            return (<div className='text-success'> Strong Password!</div>)
        }


    }

    const Allow = (e) => {
        if (e.value === "Medium" && e.length >= 8) {
            return false

        } else if (e.value === "Strong" && e.length >= 8) {
            return false

        } else {
            return true
        }

    }

    return (
        <div className="main-wrapper">

            <div className="preloader" style={{ display: 'none' }}>
                <div className="lds-ripple">
                    <div className="lds-pos" />
                    <div className="lds-pos" />
                </div>
            </div>

            <div className="auth-wrapper d-flex no-block justify-content-center align-items-center"
                style={{ background: `url(${LoginBgImage}) no-repeat center center` }}>
                <div className="auth-box" style={{ maxWidth: 400 }}>
                    <div >
                        <div className="logo">
                            <span className="db"><img src={projectLogo} width="70px" alt="logo" /></span>
                            <h5 className="font-medium m-b-20">Recover Password</h5>
                            <span>Change your password!</span>
                        </div>
                        <div className="row m-t-20">
                            {/* Form */}
                            <form className="col-12" onSubmit={onChangePassword}>
                                <div className="form-group row">
                                    <div className="col-12">
                                        <input className="form-control form-control-lg"
                                            type="text" style={{ fontSize: '14px' }} onChange={onEdit} id="NewPassword" required placeholder='new password' />
                                    </div>
                                </div>
                                {/* pwd */}
                                <div className="form-group row">
                                    <div className="col-12">
                                        <input className="form-control form-control-lg"
                                            type="text" style={{ fontSize: '14px' }} onChange={onEdit} id="ConfirmNewPassword" required placeholder='confirm password' />
                                    </div>
                                </div>

                                <div className="row m-t-20">
                                    <div className="col-12">
                                        <button disabled={data.btnDisabled} className="btn btn-block btn-lg btn-success" type="submit" name="action">Reset</button>
                                    </div>
                                </div>

                                <div className='col-md-12'>
                                    <div className='text-center'>
                                        <div className='input-group mb-3'>
                                            {
                                                data.Pstrength
                                            }
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
