import React from 'react'

export default function PageTitle(props) {
    return (
        <div className="page-breadcrumb">
            <div className="row">
                <div className="col-5 align-self-center">
                    <h4 className="page-title">{props.title[0]}</h4>
                </div>
                <div className="col-7 align-self-center">
                    <div className="d-flex align-items-center justify-content-end">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="#" >{props.title[1]}</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">{props.title[2]}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
}
