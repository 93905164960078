import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link, useLocation } from "react-router-dom";
import Breadcrum from '../../../../common/breadcrum/breadcrum';
import ReportTable from '../../../common/report_table';
import { serverLink } from '../../../../util/url';
import Loader from '../../../../common/loader';
import {CheckPermission, formatDate, formatDateAndTime} from '../../../../util/constants';
import Select from "react-select";

function JobApplicationReport(props) {
    const allow = props.permissionsList.length > 0 ? props.permissionsList.filter(x => x.Designation === 'superadmin' || x.Designation === 'hr').length > 0 ? true : false : false;
    CheckPermission(allow);

    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [generalDatatable, setGeneralDatatable] = useState([]);
    const columns = ["S/N", "Programmes", "Total Opening", "Total Application"];
    const [cohortList, setCohortList] = useState([{label : 'select Cohort', value: ''}]);
    const [selectedCohort, setSelectedCohort] = useState({});

    const getCohorts = async () => {
        await axios.get(`${serverLink}/job_applications/cohort/data`).then((res) => {
            const data = res.data;
            let row = [{label : 'select Cohort', value: ''}];
            if (data.message === "success") {
                let Cohort = data.Cohort;
                Cohort.map((x, i) => {
                    row.push({label : `${x.CohortCode} - ${formatDateAndTime(new Date(x.Start), 'date')} - ${formatDateAndTime(x.End, 'date')}`, value: x.CohortCode})
                })
                setCohortList(row)
            }
            setIsLoading(false);
        })
            .catch((err) => {
                setIsLoading(false);
                toast.error("NETWORK ERROR. Please try again!");
            });
    }

    const getData = async (cohort) => {
        setIsLoading(true);
        await axios.get(`${serverLink}/job_applications/report/application/count/program/${cohort}`).then((res) => {
            const data = res.data;
            let row = []; let rows = [];
            if (data.message === "success") {
                let Position = data.Position;
                let Department = data.Department;
                let Openings = data.Openings;
                let Application = data.Application;
                let Cohort = data.Cohort;
                let grand_total_openings = 0; let grand_total_application = 0;
                Department.map((item, index)=> {
                    let total_openings = 0; let total_application = 0;
                    total_openings =  Openings.filter(element=>element.Department === item.Department ).length
                    Openings.filter(element=>element.Department === item.Department).map((e, k)=>{
                        total_application +=  Application.filter(element=>element.JobID === e.RequisitionID ).length
                    })
                    grand_total_openings += total_openings; grand_total_application += total_application

                    rows.push([
                        index + 1,
                        item.Department,
                        total_openings,
                        total_application,
                    ]);
                })
                rows.push([
                    "",
                    <b>TOTAL</b>,
                    <b>{grand_total_openings}</b>,
                    <b>{grand_total_application}</b>,
                ]);
            }
            setGeneralDatatable(rows);
            setIsLoading(false);
        })
            .catch((err) => {
                setIsLoading(false);
                toast.error("NETWORK ERROR. Please try again!");
            });
    }

    const onCohortChange = (e) => {
        if (e.value === ""){
            toast.error("Please select cohort");
            return false
        }
        setSelectedCohort(e)
        getData(e.value);
    }

    useEffect(() => {
        getCohorts();
    }, []);


    return (
        isLoading ? <Loader />
            :
            <div>
                <div className="container-fluid" style={{ marginLeft: '-10px' }}>
                    <Breadcrum title={"Job Application Report"} />
                    <div className="row mt-3">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="CohortCode">Select Cohort</label>
                                <Select
                                    name="CohortCode"
                                    value={selectedCohort}
                                    onChange={onCohortChange}
                                    options={cohortList}
                                    placeholder="select Cohort"
                                />

                            </div>
                            <ReportTable title={"Job Application Report"} columns={columns} data={generalDatatable} height="700px" />
                        </div>
                    </div>
                </div>
            </div>
    );
}

const mapStateToProps = (state) => {
    return {
        AdminLoginData: state.AdminLoginData,
        AllJobApplications: state.AllJobApplications,
        permissionsList: state.permissionsList
    };
};



export default connect(mapStateToProps, null)(JobApplicationReport);


