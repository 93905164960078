import { projectCode } from "../util/constants";

export const setApplicantLoginDetails = (p) => {
    return {
        type: `SET_${projectCode}_APPLICANT_LOGIN_DATA`,
        payload: p,
    };
};


export const setJobListAction = (p) => {
    return {
        type: `SET_${projectCode}_JOB_LIST`,
        payload: p,
    };
};


export const setAppliedJobs = (p) => {
    return {
        type: `SET_${projectCode}_APPLIED_JOBS`,
        payload: p,
    };
};


export const setAdminLoginDetails = (p) => {
    return {
        type: `SET_${projectCode}_ADMIN_LOGIN_DATA`,
        payload: p,
    };
};

export const setAdminJobApplications = (p) => {
    return {
        type: `SET_${projectCode}_ADMIN_JOB_APPLICATIONS`,
        payload: p,
    };
};

export const setSingleJobApplication = (p) => {
    return {
        type: `SET_${projectCode}_SINGLE_JOB_APPLICATION`,
        payload: p,
    };
};

export const setStaffList = (p) => {
    return {
        type: `SET_${projectCode}_STAFF_LIST`,
        payload: p,
    };
};

export const setHrdesignations = (p) => {
    return {
        type: `SET_${projectCode}_HR_DESIGNATIONS`,
        payload: p,
    };
};

export const setDepartmentsList = (p) => {
    return {
        type: `SET_${projectCode}_DEPARTMENT_LIST`,
        payload: p,
    };
};

export const setFacultyList = (p) => {
    return {
        type: `SET_${projectCode}_FACULTY_LIST`,
        payload: p,
    };
};

export const setUsersListAction = (p) => {
    return {
        type: `SET_${projectCode}_USERS_LIST`,
        payload: p,
    };
};


export const setPanelJobListAction = (p) => {
    return {
        type: `SET_${projectCode}_PANEL_JOB_LIST`,
        payload: p,
    };
};



export const setAssessmentSettingsAction = (p) => {
    return {
        type: `SET_${projectCode}_ASSESSMENT_SETTINGS`,
        payload: p,
    };
};

export const setIntervieweeAction = (p) => {
    return {
        type: `SET_${projectCode}_INTERVIEWEE`,
        payload: p,
    };
};

export const setPanelDetailsAction = (p) => {
    return {
        type: `SET_${projectCode}_PANEL_DETAIL`,
        payload: p,
    };
};

export const setAdmin = (p) => {
    return {
        type: `SET_${projectCode}_ADMIN`,
        payload: p,
    };
};

export const setNotifcationsAction = (p) => {
    return {
        type: `SET_${projectCode}_NOTIFICATION`,
        payload: p,
    };
};

export const setPermissions = (p) => {
    return {
        type: `SET_${projectCode}_PERMISSIONS`,
        payload: p,
    };
};

export const setDashboardData = (p) => {
    return {
        type: `SET_${projectCode}_DASHBAORD_DATA`,
        payload: p,
    };
};

export const setActiveCohort = (p) => {
    return {
        type: `SET_${projectCode}_ACTIVE_COHORT`,
        payload: p,
    };
};