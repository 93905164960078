import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setAdminLoginDetails, setApplicantLoginDetails } from "../../actions/actions";


const SideBar = (props) => {

    const onLogout = () => {
        props.setOnJobApplicantData([]);
        props.setOnAdminLoginData([]);
        setTimeout(() => {
            window.location.href = "/admin/login"
        }, 200);
    }

    return (
        <aside className="left-sidebar hideSectionSidebar">
            <div className="scroll-sidebar">
                <nav className="sidebar-nav">
                    <ul id="sidebarnav">
                        <li className="nav-small-cap">
                            <i className="mdi mdi-dots-horizontal" />
                            <span className="hide-menu">Dashboard</span>
                        </li>
                        <li className="sidebar-item">
                            <Link to="/" className="sidebar-link waves-effect waves-dark" aria-expanded="false">
                                <i className="mdi mdi-av-timer" />
                                <span className="hide-menu">Dashboard </span>
                            </Link>

                        </li>

                        <li className="nav-small-cap">
                            <i className="mdi mdi-dots-horizontal" />
                            <span className="hide-menu">Apps</span>
                        </li>

                        <li className="sidebar-item">
                            <a className="sidebar-link has-arrow waves-effect waves-dark" href="#" aria-expanded="false">
                                <i className="mdi mdi-briefcase" />
                                <span className="hide-menu">Job Openings</span>
                            </a>
                            <ul aria-expanded="false" className="collapse first-level">
                                <li className="sidebar-item">
                                    <Link to='/cohorts' className="sidebar-link">
                                        <i className="mdi mdi-clock-fast" />
                                        <span className="hide-menu"> Manage Cohorts </span>
                                    </Link>
                                    <Link to='/manage-positions' className="sidebar-link">
                                        <i className="mdi mdi-star" />
                                        <span className="hide-menu"> Manage Positions </span>
                                    </Link>
                                    <Link to='/job-openings' className="sidebar-link">
                                        <i className="mdi mdi-clock-fast" />
                                        <span className="hide-menu"> Manage Openings </span>
                                    </Link>
                                </li>

                            </ul>
                        </li>
                        {
                            props.permissionsList?.filter(x => x.Designation === 'superadmin' || x.Designation === 'hr').length > 0 &&
                            <li className="sidebar-item">
                                <a className="sidebar-link has-arrow waves-effect waves-dark" href="#" aria-expanded="false">
                                    <i className="mdi mdi-apple-safari" />
                                    <span className="hide-menu">Job Applications</span>
                                </a>
                                <ul aria-expanded="false" className="collapse first-level">

                                    <li className="sidebar-item">
                                        <Link to='/jobs/all-pending-applications' className="sidebar-link">
                                            <i className="mdi mdi-clock-end" />
                                            <span className="hide-menu"> Pending Applications (All)</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-item">
                                        <Link to='/jobs/pending-applications' className="sidebar-link">
                                            <i className="mdi mdi-clock-end" />
                                            <span className="hide-menu"> Pending Applications</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-item">
                                        <Link to='/jobs/invited-applications' className="sidebar-link">
                                            <i className="mdi mdi-clock-end" />
                                            <span className="hide-menu"> Invited Applicants</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-item">
                                        <Link to='/jobs/accepted-applications' className="sidebar-link">
                                            <i className="mdi mdi-clock-end" />
                                            <span className="hide-menu"> Accepted Applications</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-item">
                                        <Link to='/jobs/rejected-applications' className="sidebar-link">
                                            <i className="mdi mdi-clock-end" />
                                            <span className="hide-menu"> Rejected Applications</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-item">
                                        <Link to='/jobs/cancelled-applications' className="sidebar-link">
                                            <i className="mdi mdi-clock-end" />
                                            <span className="hide-menu"> Cancelled Applications</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        }




                        <li className="sidebar-item">
                            <a className="sidebar-link has-arrow waves-effect waves-dark" href="#" aria-expanded="false">
                                <i className="mdi mdi-pencil-box" />
                                <span className="hide-menu">Interview </span>
                            </a>
                            <ul aria-expanded="false" className="collapse first-level">
                                {
                                    props.permissionsList?.filter(x => x.Designation === 'superadmin' || x.Designation === 'hr').length > 0 &&
                                    <li className="sidebar-item">
                                        <Link to='/interview/settings' className="sidebar-link">
                                            <i className="mdi mdi-settings" />
                                            <span className="hide-menu"> Interview Score Settings</span>
                                        </Link>
                                    </li>
                                }
                                {
                                    props.permissionsList?.filter(x => x.Designation === 'superadmin' || x.Designation === 'hr').length > 0 &&
                                    <li className="sidebar-item">
                                        <Link to='/interview/panel/request' className="sidebar-link">
                                            <i className="mdi mdi-reply" />
                                            <span className="hide-menu"> Interview Panel Request</span>
                                        </Link>
                                    </li>
                                }
                                {
                                    props.permissionsList?.filter(x => x.Designation === 'superadmin' || x.Designation === 'hr').length > 0 &&
                                    <li className="sidebar-item">
                                        <Link to='/interview/panel-members' className="sidebar-link">
                                            <i className="mdi mdi-reply" />
                                            <span className="hide-menu"> Interview Panel Members</span>
                                        </Link>
                                    </li>
                                }
                                {
                                    props.permissionsList?.filter(x => x.Designation === 'panelist').length > 0 &&
                                    <li className="sidebar-item">
                                        <Link to='/interview/panel' className="sidebar-link">
                                            <i className="fa fa-users" />
                                            <span className="hide-menu">Panel Assessment</span>
                                        </Link>
                                    </li>
                                }
                                {
                                    props.permissionsList?.filter(x => x.Designation === 'superadmin' || x.Designation === 'hr').length > 0 &&
                                    <li className="sidebar-item">
                                        <Link to='/interview/hr' className="sidebar-link">
                                            <i className="mdi mdi-account" />
                                            <span className="hide-menu">HR Assessment</span>
                                        </Link>
                                    </li>
                                }
                            </ul>
                        </li>
                        {
                            props.permissionsList?.filter(x => x.Designation === 'superadmin' || x.Designation === 'hr').length > 0 &&
                            <li className="sidebar-item">
                                <a className="sidebar-link has-arrow waves-effect waves-dark" href="#" aria-expanded="false">
                                    <i className="mdi mdi-airplane-takeoff" />
                                    <span className="hide-menu">Manage Enrolment</span>
                                </a>
                                <ul aria-expanded="false" className="collapse first-level">
                                    <li className="sidebar-item">
                                        <Link to='/enrolment-list' className="sidebar-link">
                                            <i className="mdi mdi-bus" />
                                            <span className="hide-menu"> Enrol Accepted Applicant</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-item">
                                        <Link to='/enrolled' className="sidebar-link">
                                            <i className="mdi mdi-airplane" />
                                            <span className="hide-menu"> Enrolled Applicants </span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        }

                        <li className="sidebar-item">
                            <a className="sidebar-link has-arrow waves-effect waves-dark" href="#" aria-expanded="false">
                                <i className="mdi mdi-email-open-outline" />
                                <span className="hide-menu">Manage Staff</span>
                            </a>
                            <ul aria-expanded="false" className="collapse first-level">
                                <li className="sidebar-item">
                                    <Link to='/staff/all-staff' className="sidebar-link">
                                        <i className="mdi mdi-message-alert" />
                                        <span className="hide-menu"> Staff List </span>
                                    </Link>
                                </li>
                                <li className="sidebar-item">
                                    <Link to='/staff/academic-staff' className="sidebar-link">
                                        <i className="mdi mdi-message-bulleted" />
                                        <span className="hide-menu"> Academic Staff</span>
                                    </Link>
                                </li>
                                <li className="sidebar-item">
                                    <Link to='/staff/non-academic-staff' className="sidebar-link">
                                        <i className="mdi mdi-message-draw" />
                                        <span className="hide-menu"> Non Academic Staff</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        {
                            props.permissionsList?.filter(x => x.Designation === 'superadmin' || x.Designation === 'hr').length > 0 &&
                            <li className="sidebar-item">
                                <a className="sidebar-link has-arrow waves-effect waves-dark" href="#" aria-expanded="false">
                                    <i className="mdi mdi-record" />
                                    <span className="hide-menu">Reports</span>
                                </a>
                                <ul aria-expanded="false" className="collapse first-level">
                                    <li className="sidebar-item">
                                        <Link to='/jobs/application-report' className="sidebar-link">
                                            <i className="mdi mdi-file" />
                                            <span className="hide-menu"> Job Application</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-item">
                                        <Link to='/jobs/application-report-by-role' className="sidebar-link">
                                            <i className="mdi mdi-file" />
                                            <span className="hide-menu"> Job Application (By Role)</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        }



                        <li className="nav-small-cap">
                            <i className="mdi mdi-dots-horizontal" />
                            <span className="hide-menu">Extra</span>
                        </li>
                        <li className="sidebar-item">
                            <Link to="/settings/staff-list" className="sidebar-link waves-effect waves-dark sidebar-link" aria-expanded="false">
                                <i className="mdi mdi-content-paste" />
                                <span className="hide-menu">Permission Settings</span>
                            </Link>
                            <Link to="/settings/permissions" className="sidebar-link waves-effect waves-dark sidebar-link" aria-expanded="false">
                                <i className="mdi mdi-key-variant" />
                                <span className="hide-menu">Permissions</span>
                            </Link>
                        </li>
                        <li className="sidebar-item">
                            <a href="#" className="sidebar-link waves-effect waves-dark sidebar-link" onClick={onLogout} aria-expanded="false">
                                <i className="mdi mdi-directions" />
                                <span className="hide-menu">Log Out</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside>
    )
}

const mapStateToProps = (state) => {
    return {
        AdminLoginData: state.AdminLoginData,
        permissionsList: state.permissionsList
    };
};
const mapDisptachToProps = (dispatch) => {
    return {
        setOnJobApplicantData: (p) => {
            dispatch(setApplicantLoginDetails(p))
        },
        setOnAdminLoginData: (p) => {
            dispatch(setAdminLoginDetails(p))
        }
    }
}

export default connect(mapStateToProps, mapDisptachToProps)(SideBar);
