import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { setActiveCohort, setAdminJobApplications, setHrdesignations, setJobListAction } from '../../../../actions/actions';
import { CheckPermission, formatDate, formatDateAndTime, onGeneralEdit } from '../../../../util/constants';
import { serverLink } from '../../../../util/url';
import Modal from '../../../common/modal';
import PageTitle from '../../../common/pagetitle';
import ReportTable from '../../../common/report_table';
import { AddEditJobsValidationScehma } from '../../../formik/validationSchema';
import JoditEditor from "jodit-react";


function Manage(props) {
    const allow = props.permissionsList.length > 0 ? props.permissionsList.filter(x => x.Designation === 'superadmin' || x.Designation === 'hr').length > 0 ? true : false : false;
    CheckPermission(allow);
    const staff = props.AdminLoginData
    const controller = new AbortController();
    const columns = ["SN", "Cohort", "Start Date", "End Date", "Status", "Action", "Status"];
    const [tableData, setTableData] = useState([]);
    const [isLoading, setisLoading] = useState(true)
    const [cohortsList, setcohortsList] = useState([])

    const [formData, setFormdata] = useState({
        EntryID: "",
        CohortCode: "",
        Start: "",
        End: "",
        Status: "",
        InsertedBy: staff[0]?.StaffID
    })
    const getData = async () => {
        try {
            await axios.get(`${serverLink}/job_applications/cohorts-list`).then((res) => {
                if (res.data.length > 0) {
                    setcohortsList(res.data)
                    props.setCohortList(res.data)
                    let rows = []
                    res.data.map((x, i) => {
                        rows.push([
                            i + 1,
                            x.CohortCode,
                            formatDateAndTime(formatDate(x.Start), "date"),
                            formatDateAndTime(formatDate(x.End), "date"),
                            x.Status === 1 ? "Active" : "In-Active",
                            <button className='btn btn-sm btn-main' data-toggle="modal" data-target="#job-modal"
                                onClick={() => {
                                    setFormdata({
                                        ...formData,
                                        EntryID: x.EntryID,
                                        CohortCode: x.CohortCode,
                                        Start: x.Start,
                                        End: x.End,
                                        Status: x.Status
                                    })
                                }} >
                                <i className='ti ti-pencil' /> &nbsp;
                                Edit

                            </button>,
                            <input type='checkbox' className='checkbox-control' checked={x.Status === 1} disabled={x.Status === 1}
                                onChange={(e) => {
                                    if (e.target.checked === true) {
                                        changeStatus(x, 1)
                                    } else {
                                        changeStatus(x, 0)
                                    }
                                }} />
                        ])
                    })
                    setTableData(rows)

                    props.setOnActiveCohort(res.data.filter(x => x.Status === 1))
                }
            })


            setisLoading(false)

        } catch (e) {
            console.log(e)
            toast.error("netwrok error")
        }
    }

    useEffect(() => {
        getData();
        return () => { controller.abort() }
    }, [])

    const onSubmitJob = async (e) => {
        e.preventDefault();

        try {
            if (formData.EntryID === "") {
                await axios.post(`${serverLink}/job_applications/add-cohort`, formData).then((res) => {
                    if (res.data.message === "success") {
                        ResetValues();
                        getData();
                        document.getElementById("modal-close").click();
                        toast.success("cohort added")
                    } else if (res.data.message === "exists") {
                        toast.error("cohort already exists or check that the date does not conflicts")
                    } else {
                        toast.error("network error!")
                    }
                })
            } else {
                await axios.patch(`${serverLink}/job_applications/update-cohort/${formData.EntryID}`, formData).then((res) => {
                    if (res.data.message === "success") {
                        ResetValues();
                        getData();
                        document.getElementById("modal-close").click();
                        toast.success("cohort updated")
                    }
                    else if (res.data.message === "exists") {
                        toast.error("cohort already exists or check that the date does not conflicts")
                    } else {
                        toast.error("network error!")
                    }
                })
            }

        } catch (e) {
            toast.error("network error!")
        }
    }


    const changeStatus = async (data, status) => {
        try {
            await axios.patch(`${serverLink}/job_applications/update-cohort-status/${data.EntryID}`, { ...data, status: status }).then((res) => {
                if (res.data.message === "success") {
                    getData();
                    toast.success("cohort updated")
                }
            })
        } catch (e) {
            toast.error("network error!")

        }
    }

    const onEdit = (e) => {
        setFormdata({
            ...formData,
            [e.target.id]: e.target.value
        })
    }

    const ResetValues = () => {
        setFormdata({
            ...formData,
            EntryID: "",
            CohortCode: "",
            Start: "",
            End: "",
            Status: "",
            InsertedBy: staff[0]?.StaffID
        });
    }

    return (
        <div>
            <PageTitle title={["Jobs", "Jobs", "Manage Cohorts"]} />
            <div className="card mt-3">
                <div className="card-body">
                    <div className='d-flex justify-content-between mb-4'>
                        <h4 className="card-title">Manage Cohorts</h4>
                        <button className='btn btn-sm btn-main' data-toggle="modal" data-target="#job-modal" onClick={ResetValues} >
                            Add New Cohort
                        </button>
                    </div>
                    <div className="table-responsive">
                        <ReportTable data={tableData} columns={columns} height={"700px"} />

                    </div>

                    <Modal title="Manage Cohorts" id="job-modal" close="modal-close">
                        <div className='row'>
                            <div className='col-md-12'>
                                <form className="m-t-30 needs-validation" onSubmit={onSubmitJob} >
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label htmlFor="Start">Start Date</label>
                                                <input
                                                    type='date'
                                                    id="Start"
                                                    name="Start"
                                                    onChange={onEdit}
                                                    value={formData.Start}
                                                    className={'form-control'} required
                                                />

                                            </div>
                                        </div>

                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label htmlFor="End">End Date</label>
                                                <input
                                                    type='date'
                                                    id="End"
                                                    name="End"
                                                    disabled={formData.Start === ""}
                                                    min={formData.Start}
                                                    onChange={onEdit}
                                                    value={formData.End}
                                                    className={'form-control'} required
                                                />

                                            </div>
                                        </div>

                                        <div className='col-md-12'>
                                            <div className="form-group">
                                                <label htmlFor="Status">Start Date</label>
                                                <select
                                                    id="Status"
                                                    name="Status"
                                                    onChange={onEdit}
                                                    value={formData.Status}
                                                    className={'form-control'} required
                                                >
                                                    <option value={""}>--select status--</option>
                                                    <option value={"1"}>Active</option>
                                                    <option value={"0"}>In-Active</option>
                                                </select>

                                            </div>
                                        </div>

                                    </div>

                                    {/* disabled={isValid === true ? false : true} */}
                                    <button type="submit" className="btn btn-get-started w-100">Submit</button>
                                </form>
                            </div>
                        </div>

                    </Modal>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        AdminLoginData: state.AdminLoginData,
        hrDesignations: state.hrDesignations,
        permissionsList: state.permissionsList
    };
};
const mapDisptachToProps = (dispatch) => {
    return {
        setOnJobList: (p) => {
            dispatch(setJobListAction(p))
        },
        setOnHrDesignations: (p) => {
            dispatch(setHrdesignations(p))
        },
        setOnActiveCohort: (p) => {
            dispatch(setActiveCohort(p))
        }

    }
}

export default connect(mapStateToProps, mapDisptachToProps)(Manage);

