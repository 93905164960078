import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link, useLocation, useParams } from "react-router-dom";
import Breadcrum from '../../../../common/breadcrum/breadcrum';
import ReportTable from '../../../common/report_table';
import { serverLink } from '../../../../util/url';
import Loader from '../../../../common/loader';
import { CheckPermission, formatDateAndTime } from '../../../../util/constants';
import { setSingleJobApplication } from '../../../../actions/actions';


function EnrolmentList(props) {
    const location = useLocation();

    const allow = props.permissionsList.length > 0 ? props.permissionsList.filter(x => x.Designation === 'superadmin' || x.Designation === 'hr').length > 0 ? true : false : false;
    CheckPermission(allow);

    const [isLoading, setIsLoading] = useState(true);
    const [generalDatatable, setGeneralDatatable] = useState([]);
    const columns = ["S/N", "Name", "Job ID", "Position Applied", "Position Offered", "Job Type", "Department", "Enrolment", "Action"];
    const active_cohort = props.activeCohort[0];


    const getData = async () => {
        try {
            const { data } = await axios.get(`${serverLink}/enrollment/get_job_applications/not-enrolled?cohort=${active_cohort?.CohortCode}`)
            let rows = []
            if (data.length > 0) {
                data.map((item, index) => {
                    rows.push([
                        index + 1,
                        item.FirstName + " " + item.MiddleName + " " + item.Surname,
                        item.RequisitionID,
                        item.Position,
                        item.PositionOffered,
                        item.JobType,
                        item.Department,
                        <span className={item.EnrolmentStatus === "2" ? "badge badge-success" : item.EnrolmentStatus === "1" ? "badge badge-info" : "badge badge-danger"}>
                            {item.EnrolmentStatus === "2" ? "Enrolled" : item.EnrolmentStatus === "1" ? "Enrolment Details Submitted" : "Enrolment not Submitted"}
                        </span>,
                        (
                            <>
                                <Link onClick={() => { props.setOnSingleJobApplication([item]) }} to={`/enrolment/${item.ApplicantID}/${item.RequisitionID}/${item.ApplicationID}`}
                                    className="btn btn-sm btn-main"
                                >
                                    <i className="fa fa-eye" />
                                </Link>

                            </>
                        ),
                    ]);
                });
            }

            setGeneralDatatable(rows);

            setIsLoading(false);
        } catch (error) {

        }
    }

    useEffect(() => {

        getData();
    }, [location.pathname]);

    return (
        isLoading ? <Loader />
            :
            <div>
                <div className="container-fluid" style={{ marginLeft: '-10px' }}>
                    <Breadcrum title={"Enrolment List"} />
                    <div className="row mt-3">
                        <div className="col-12">
                            <ReportTable title={generalDatatable.length + " Record(s)"} columns={columns} data={generalDatatable} height="900px" />
                        </div>
                    </div>
                </div>
            </div>
    );
}

const mapStateToProps = (state) => {
    return {
        AdminLoginData: state.AdminLoginData,
        AllJobApplications: state.AllJobApplications,
        staffList: state.staffList,
        permissionsList: state.permissionsList,
        activeCohort: state.activeCohort

    };
};
const mapDisptachToProps = (dispatch) => {
    return {
        setOnSingleJobApplication: (p) => {
            dispatch(setSingleJobApplication(p))
        }
    }
}



export default connect(mapStateToProps, mapDisptachToProps)(EnrolmentList);


