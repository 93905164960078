import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { serverLink } from '../../../../../util/url';
import * as DOMPurify from 'dompurify';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { schoolName, sendEmail } from '../../../../../util/constants';
import Loader from '../../../../../common/loader';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';


function FinalSubmission(props) {
    const controller = new AbortController();
    const applicant = props.JobApplicant

    const [isLoading, setIsLoading] = useState(true)
    const [Qualifications, setQualifications] = useState([]);
    const [EmpHistory, setEmpHistory] = useState([]);
    const [SocialMedia, setSocialMedia] = useState([]);
    const [BankInfo, setBankInfo] = useState([]);
    const [NextOfKin, setNextOfKin] = useState([]);
    const [refresh, setRefresh] = useState(0)



    const getData = async () => {
        try {
            await axios.get(`${serverLink}/enrollment/get_qualification/${props.JobApplicant[0]?.EntryID}`).then((res) => {
                if (res.data.length > 0) {
                    setQualifications(res.data)
                }
            })

            await axios.get(`${serverLink}/enrollment/get_employment_History/${props.JobApplicant[0]?.EntryID}`).then((res) => {
                if (res.data.length > 0) {
                    setEmpHistory(res.data)
                }
            })

            await axios.get(`${serverLink}/enrollment/get_social_media/${props.JobApplicant[0]?.EntryID}`).then((res) => {
                if (res.data.length > 0) {
                    const values = res.data[0]
                    const mediaList = [
                        { name: 'Facebook', media: values?.Facebook },
                        // { name: 'Twitter', media: values?.Twitter },
                        { name: 'LinkedIn', media: values?.LinkedIn },
                        { name: 'Google Scholar', media: values?.Scholar },
                        { name: 'Reasearch Gate', media: values?.ResearchGate },
                        { name: 'Academia', media: values?.Academia },
                        { name: 'ORCID', media: values?.Orcid }
                    ]
                    setSocialMedia(mediaList)
                }
            })

            await axios.get(`${serverLink}/enrollment/get_bank_information/${props.JobApplicant[0]?.EntryID}`).then((res) => {
                if (res.data.length > 0) {
                    const vals = res.data[0]
                    setBankInfo(res.data)
                }
            })

            await axios.get(`${serverLink}/enrollment/get_next_of_kin/${props.JobApplicant[0]?.EntryID}`).then((res) => {
                if (res.data.length > 0) {
                    setNextOfKin(res.data)
                }

                setIsLoading(false)
                
            })

        } catch (e) {

        }
    }

    useEffect(() => {
        getData();

    }, [props.final])

    const navigate = useNavigate();

    const onFinalSubmit = () => {
        if (Qualifications.length === 0) {
            toast.error("please complete qualifications form");
            return false;
        }
        // if (EmpHistory.length === 0) {
        //     toast.error("please complete employment history form");
        //     return false;
        // }
        if (SocialMedia.length === 0) {
            toast.error("please complete profile form at the home page");
            return false;
        }
        // if (BankInfo.length === 0) {
        //     toast.error("please complete bank information form");
        //     return false;
        // }
        if (NextOfKin.length === 0) {
            toast.error("please complete next of kin form");
            return false;
        }

        Swal.fire({
            title: 'Are you sure?',
            text: "Are you sure you want to submit",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Submit'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.post(`${serverLink}/enrollment/final_submission`, props.AppliedJobs[0]).then((res) => {
                        if (res.data.message === "success") {
                            toast.success("enrolment form submitted successfully");
                            props.getAppliedJobs()
                            sendEmail(
                                applicant[0]?.Email,
                                `CONFIRMATION OF ENROLMENT`,
                                `Confirmation of Enrolment`,
                                applicant[0]?.FirstName,
                                `<div>
                                We have received your submission of enrolment details, kindly proceed to the University Human Resources Office for physical verification.
                                <br/>
                                Thank you,
                                <br/>
                                ${schoolName} HR Team
                                </div>`,
                                ''
                            )
                            navigate('/applicant/dashboard/')
                        }
                    })

                } catch (e) {
                    toast.error("network error!")
                }

            }
        })
    }

    return isLoading ? (<Loader />) : (
        <div>
            <div className='row justify-content-between'>
                <div>
                    <h2>Final Submission</h2>
                    <br />
                    <div>
                        <strong>Ensure that all sections have been completed before proceeding</strong>
                    </div>
                </div>
                <div className='text-center'>
                    <br />
                    <button disabled={props.AppliedJobs[0]?.EnrolmentStatus !== "0" ? true : false} className='btn btn-lg btn-get-started' onClick={onFinalSubmit}>
                        Submit

                    </button>
                </div>
            </div>

            <br />
            <br />
            <div className='row'>
                <h3>Qualifications</h3>
                <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>SN</th>
                                <th>Title</th>
                                <th>Discipline </th>
                                <th>Institution</th>
                                <th>Year Obtained</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Qualifications.length > 0 ?
                                    Qualifications.map((x, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{x.QualificationTitle}</td>
                                                <td>{x.Discipline}</td>
                                                <td>{x.InstitutionName}</td>
                                                <td>{x.Year}</td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr >
                                        <td colSpan={5} >
                                            No Data
                                        </td>
                                    </tr>

                            }
                        </tbody>

                    </table>
                </div>

                <br />
                <br />

                <h3>Employment History</h3>
                <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>SN</th>
                                <th>Organization </th>
                                <th>Position</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th className='w-25'>Duties</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                EmpHistory.length > 0 ?
                                    EmpHistory.map((x, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{x.Organization}</td>
                                                <td>{x.Position}</td>
                                                <td>{x.StartDate}</td>
                                                <td>{x.EndDate}</td>
                                                <td><span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(x.Duties) }} /></td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr >
                                        <td colSpan={5} >
                                            No Data
                                        </td>
                                    </tr>

                            }
                        </tbody>

                    </table>
                </div>

                <br /><br />
                <h3>Media Visisbility</h3>
                <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>SN</th>
                                <th>Media Title</th>
                                <th>Media Link </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                SocialMedia.length > 0 ?
                                    SocialMedia.map((x, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{x.name}</td>
                                                <td><a target={"_blank"} href={x?.media?.includes("https") === true ? x.media : `https://${x.media}`}  >{x.media} </a></td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr >
                                        <td colSpan={5} >
                                            No Data
                                        </td>
                                    </tr>

                            }
                        </tbody>

                    </table>
                </div>

                {/* <br /><br />
                <h3>Bank Information</h3>
                <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>SN</th>
                                <th>Bank Name</th>
                                <th>Account Type </th>
                                <th>Account Number </th>
                                <th>Sort Code </th>
                                <th>Country </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                BankInfo.length > 0 ?
                                    BankInfo.map((x, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{x.BankName}</td>
                                                <td>{x.AccountType}</td>
                                                <td>{x.AccountNumber}</td>
                                                <td>{x.SortCode}</td>
                                                <td>{x.Country}</td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr >
                                        <td colSpan={5} >
                                            No Data
                                        </td>
                                    </tr>

                            }
                        </tbody>

                    </table>
                </div> */}

                <br /><br />
                <h3>Next of Kin</h3>
                <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>SN</th>
                                <th>FirstName</th>
                                <th>MiddleName </th>
                                <th>Surname </th>
                                <th>Relationship </th>
                                <th>Email </th>
                                <th>Phone</th>
                                <th>Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                NextOfKin.length > 0 ?
                                    NextOfKin.map((x, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{x.FirstName}</td>
                                                <td>{x.MiddleName}</td>
                                                <td>{x.Surname}</td>
                                                <td>{x.Relationship}</td>
                                                <td>{x.Email}</td>
                                                <td>{x.Phone}</td>
                                                <td> {x.Address} </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr >
                                        <td colSpan={5} >
                                            No Data
                                        </td>
                                    </tr>

                            }
                        </tbody>

                    </table>
                </div>

            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        JobApplicant: state.JobApplicantData,
        JobList: state.JobList,
        AppliedJobs: state.AppliedJobs,
        Admin: state.Admin

    };
};


export default connect(mapStateToProps, null)(FinalSubmission);