import React, { useEffect } from 'react'
import { decryptData, formatDate, formatDateAndTime, projectEmail, projectLogo, projectPhone, schoolName } from '../../../util/constants'
import { Link, useParams } from 'react-router-dom'
import { useState } from 'react'
import axios from 'axios';
import { serverLink } from '../../../util/url';
import Select from "react-select";
import Loader from '../../../common/loader';
import { showAlert } from '../../../common/sweetalert/sweetalert';
import { toast } from 'react-toastify';


export default function Visibility() {

    const { slug } = useParams();
    const param = decryptData(slug.split("-"))[1];

    if (param === undefined) {
        window.location.href = "/"
    }


    const [isSubmitting, setisSubmitting] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState('');
    const [formData, setFormData] = useState({
        ApplicantID: "",
        ApplicationID: "",
        HighestQualification: "",
        Publications: "",
        Citations: "",
        HIndex: "",
        I10Index: "",
        InsertedBy: ""
    })

    useEffect(() => {
        getData();
    }, [slug])


    const getData = async () => {

        const check = await axios.get(`${serverLink}/enrollment/visibility/check/${param}`);
        if (check.data.length > 0) {
            window.location.href = "/"
            return
        } else {
            const { data } = await axios.get(`${serverLink}/enrollment/visibility/applicant/${param}`);
            if (data.length > 0) {
                setData(data[0])
                setFormData({
                    ...formData,
                    ApplicantID: data[0]?.ApplicantID,
                    ApplicationID: data[0]?.ApplicationID,
                    InsertedBy: data[0].ApplicantID,
                })
            } else {
                window.location.href = "/"
            }
        }
        setIsLoading(false)
    }


    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setisSubmitting(true)
        await axios.post(`${serverLink}/enrollment/visibility/add`, formData).then((res) => {
            if (res.data.message === "success") {
                showAlert("Success", "Your response has been submitted successfully!", "success").then(() => {
                    window.location.href = "/"
                })
            } else {
                showAlert("Error", "Form already submitted!", "Error").then(() => {
                    window.location.href = "/"
                })
            }
        }).catch(() => {
            setisSubmitting(false);
            toast.error("please try again")
        }).finally(() => {
            setisSubmitting(false)
        })
    }

    return isLoading ? (<Loader />) : (
        <div className="container-fluid">
            <div className="landing-box">
                <div className="landing-body">
                    <div className="">

                        <div className='text-center'>
                            <img src={projectLogo} width="90px" />
                            <h1 className='mt-3'>Welcome!</h1>
                        </div>
                        <div className="card col-lg-10 offset-lg-1 text-center">
                            <p>
                                <b>
                                    You are on this page because you have been invited to an interview at {schoolName}, please provide accurate details in the form below before coming for the interview.

                                    <br />
                                    <span className='text-danger'>Also note that you can only submit this form once.</span>
                                </b>
                            </p>
                        </div>


                        <div className="card col-lg-10 offset-lg-1">
                            <div className="card-body">
                                <h3 className="card-title">Publications and Academic Visibility</h3>
                            </div>
                            <div className="card-body bg-light">
                                <div className="card">
                                    <div className="card-body">
                                        <form onSubmit={onSubmit}>
                                            <div className='row'>
                                                <div className='col-md-6 mb-3'>
                                                    <label>Email Address</label>
                                                    <input disabled className='form-control' value={data.Email} />
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <label>Phone Number</label>
                                                    <input disabled className='form-control' value={data.Phone} />
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <label>Position Applied</label>
                                                    <input disabled className='form-control' value={data.Position} />
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <label>Interview Details</label>
                                                    <p>Interview Date: {formatDateAndTime(formatDate(data.InterViewDate), "date")}</p>
                                                    <p>Interview Time: {data.InterviewTime}</p>
                                                    <p>Interview Venue: {data.InterviewVenue}</p>
                                                </div>

                                                <div className='col-md-12 mt-5'>
                                                    <label>Highest Qualification</label>
                                                    <select className="custom-select" id="HighestQualification" value={data.HighestQualification} onChange={onEdit} required>
                                                        <option value={""}>Choose...</option>
                                                        <option value={"B.Eng."}>B.Eng.</option>
                                                        <option value={"B.Tech."}>B.Tech.</option>
                                                        <option value={"B.A."}>B.A.</option>
                                                        <option value={"B.Sc"}>B.Sc</option>
                                                        <option value={"Msc"}>Msc</option>
                                                        <option value={"M.Tech"} >M.Tech</option>
                                                        <option value={"MA"}>MA</option>
                                                        <option value={"MEd"}>MEd</option>
                                                        <option value={"OND"}>OND</option>
                                                        <option value={"HND"}>HND</option>
                                                        <option value={"ND"}>ND</option>
                                                        <option value={"MBA"}>MBA</option>
                                                        <option value={"MPA"}>MPA</option>
                                                        <option value={"MPH"}>MPH</option>
                                                        <option value={"MTech"}>MTech</option>
                                                        <option value={"MPhil"}>MPhil</option>
                                                        <option value={"PhD"}>PhD</option>
                                                        <option value={"SSCE"}>SSCE</option>
                                                        <option value={"Certificate"}>Certificate</option>
                                                        <option value={"LLB"}>LLB</option>
                                                        <option value={"LLM"}>LLM</option>
                                                        <option value={"LLD"}>LLD</option>
                                                        <option value={"M.Eng"}>M. Engr</option>
                                                        <option value={"N C E"}>N C E</option>
                                                        <option value={"PGD"}>PGD</option>
                                                        <option value={"Diploma"}>Diploma</option>
                                                        <option value={"MBBS"}>MBBS</option>
                                                        <option value={"PGDE"}>PGDE</option>
                                                        <option value={"Others"}>Others</option>
                                                    </select>
                                                </div>

                                                <div className='col-md-6 mt-3'>
                                                    <label>Total Publications </label>
                                                    <input type='number' required step={0} id="Publications" className='form-control' value={data.Publications} onChange={onEdit} />
                                                </div>
                                                <div className='col-md-6 mt-3'>
                                                    <label>Total Citations</label>
                                                    <input type='number' required step={0} id="Citations" className='form-control' value={data.Citations} onChange={onEdit} />
                                                </div>
                                                <div className='col-md-6 mt-3'>
                                                    <label>Total H-Index</label>
                                                    <input type='number' required step={0} id="HIndex" className='form-control' value={data.HIndex} onChange={onEdit} />
                                                </div>
                                                <div className='col-md-6 mt-3'>
                                                    <label>Total I-10 Index</label>
                                                    <input type='number' required step={0} id="I10Index" className='form-control' value={data.I10Index} onChange={onEdit} />
                                                </div>

                                                <div className='col-md-12 mt-3'>
                                                    <button type='submit' disabled={isSubmitting} className='btn btn-md btn-success w-100 mt-5'>
                                                        Submit
                                                    </button>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <br />
                        <hr />

                        <footer className='footer'>
                            <div className='col-md-12'>
                                <div className="card col-lg-10 offset-lg-1">
                                    <div className="card-body bg-dark text-light ">
                                        <h4>For Enquiries, please contact</h4>
                                        <address>
                                            <div>
                                                <a className='text-white text-monospace mb-2' href={`mailto:${projectEmail}`} >{projectEmail}</a>
                                            </div>
                                            <div>
                                                <a className='text-white text-monospace mb-2' href={`phone:${projectPhone}`} >{projectPhone}</a>
                                            </div>
                                        </address>
                                    </div>

                                </div>
                            </div>

                        </footer>

                    </div>
                </div>

            </div>
        </div>
    )
}
