import React from 'react'

export default function Loader() {
    return (
        <div className="">
            <div className="lds-ripple">
                <div className="lds-pos" />
                <div className="lds-pos" />
            </div>
        </div>
    )
}
