
import React from 'react'
import { Route, Routes } from 'react-router';
import ApplicantLogin from '../job-application/auth/login';
import ApplicantRegister from '../job-application/auth/register';
import JobDetails from '../job-application/pages/job-board/job-details';
import AdminLogin from '../admin/auth/login';
import LandingPage from '../job-application/pages/job-board/landing/landing';
import RecoverPassword from '../job-application/auth/recover-password';
import Visibility from '../job-application/pages/visibility-form';


const PublicRoutes = () => {
    return (
        <div>

            <Routes>
                <Route path='*' element={<LandingPage />} />
                <Route path='/' element={<LandingPage />} />
                <Route path='/applicant/login' element={<ApplicantLogin />} />
                <Route path='/applicant/register' element={<ApplicantRegister />} />
                <Route path='/jobs' element={<LandingPage/>} />

                <Route path='/recover-password' element={<RecoverPassword />} />

                {/* <Route path='/jobs' element={<JobListing />} /> */}
                <Route path='/job/:slug' element={<JobDetails />} />


                <Route path='/admin/login' element={<AdminLogin/>}  />
                <Route path='/admin/login/:slug' element={<AdminLogin/>}  />
                <Route path='/interview-details/:slug' element={<Visibility/>}  />
            </Routes>

        </div>
    )
}

export default PublicRoutes;