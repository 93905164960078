import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link, useLocation, useParams } from "react-router-dom";
import Breadcrum from '../../../../common/breadcrum/breadcrum';
import ReportTable from '../../../common/report_table';
import { serverLink } from '../../../../util/url';
import Loader from '../../../../common/loader';
import JoditEditor from 'jodit-react';
import Modal from '../../../common/modal';
import Swal from 'sweetalert2';
import { sendEmail } from '../../../../util/constants';



function AllStaffList(props) {
    const location = useLocation();
   
    const [data, setData] = useState(props.staffList.length > 0 ? props.staffList : []);
    const [isLoading, setIsLoading] = useState(true);
    const [generalDatatable, setGeneralDatatable] = useState([]);
    const columns = ["SN", "Staff ID", "Name", "Email", "Designation", "Department", "Gender", "Type", "Action"];

    const getData = async () => {
        setIsLoading(true)
        let stf = []
        await axios.get(`${serverLink}/enrollment/get_all_staff`).then((res) => {
            if (res.data.length > 0) {
                stf = res.data
            }
        })
            .catch((err) => {
                setIsLoading(false);
                toast.error("NETWORK ERROR. Please try again!");
            });

        let rows = [];
        const dt = data.length > 0 ? data : stf
        if (dt.length > 0) {
            dt.map((item, index) => {
                rows.push([
                    index + 1,
                    item.StaffID,
                    item.StaffName,
                    item.OfficialEmailAddress,
                    item.Designation,
                    item.Department,
                    <span className={item.Gender === "Male" ? "badge badge-primary" : "badge badge-secondary"}>
                        {item.Gender === 1 ? "Male" : "Female"}
                    </span>,
                    <span className={item.IsAcademicStaff === 1 ? "badge badge-success" : "badge badge-secondary"}>
                        {item.IsAcademicStaff === 1 ? "Academic" : "Non Academic"}
                    </span>,
                    (
                        <>
                            <button data-toggle="modal" data-target="#email-modal"
                                className="btn btn-sm btn-main"
                                onClick={() => {
                                    setFormData({
                                        ...formData,
                                        msgTo: item.OfficialEmailAddress,
                                        staff_name: `${item.Gender === "Male" ? "Mr. " : "Mrs. "}` + item.StaffName
                                    })
                                }}
                            >
                                <i className="fa fa-eye" />
                            </button>

                        </>
                    ),
                ]);
            });
        }
        setGeneralDatatable(rows);
        setIsLoading(false);
    }

    useEffect(() => {
        getData();
    }, [location.pathname]);

    const [formData, setFormData] = useState({
        msgTitle: "",
        msgSubject: "",
        msgTo: "",
        msgBody: "",
        staff_name: ""
    })
    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
    }
    const onContact = (e) => {
        e.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: "proceed to send email?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Submit'
        }).then((result) => {
            if (result.isConfirmed) {
                sendEmail(
                    formData.msgTo,
                    formData.msgSubject,
                    formData.msgTitle,
                    formData.staff_name,
                    formData.msgBody,
                    ''
                )
            }
        })

    }

    return (
        isLoading ? <Loader />
            :
            <div>
                <div className="container-fluid" style={{ marginLeft: '-10px' }}>
                    <Breadcrum title={"Staff List"} />
                    <div className="row mt-3">
                        <div className="col-12">
                            <ReportTable title={data.length + " Records"} columns={columns} data={generalDatatable} height="700px" />
                        </div>
                    </div>

                    <Modal id="email-modal" title={`Contact ${formData.staff_name}`}  >
                        <div className='row'>
                            <div className='col-md-12'>
                                <form onSubmit={onContact} >
                                    <label className='form-label' >Title</label>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control form-control-sm" placeholder='enter email title' onChange={onEdit} id="msgTitle" value={formData.msgTitle} required />
                                    </div>
                                    <br />
                                    <label className='form-label' >Subject</label>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control form-control-sm" placeholder='enter email subject' onChange={onEdit} id="msgSubject" value={formData.msgSubject} required />
                                    </div>
                                    <br />

                                    <label className='form-label' >To</label>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control form-control-sm" onChange={onEdit} id="msgTo" value={formData.msgTo} required disabled />
                                    </div>
                                    <br />

                                    <label className='form-label' >Email Body</label>
                                    <JoditEditor
                                        value={formData.msgBody}
                                        tabIndex={1}
                                        onChange={(e) => {
                                            setFormData({
                                                ...formData, msgBody: e
                                            })
                                        }}
                                    />
                                    <hr />
                                    <div className="input-group mb-3">
                                        <button type='submit' className='btn btn-md btn-main w-100'>
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
    );
}

const mapStateToProps = (state) => {
    return {
        AdminLoginData: state.AdminLoginData,
        AllJobApplications: state.AllJobApplications,
        staffList: state.staffList
    };
};



export default connect(mapStateToProps, null)(AllStaffList);


