import * as Yup from 'yup'


const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

// export const QualificationsValidationScehma = Yup.object().shape({
//     FirstName: Yup.string().required("first name is required"),
//     LastName: Yup.string().required("last name is required"),
//     Email: Yup.string().email("please enter a valid email").required("email is required"),
//     Phone: Yup.string().required(),
//     Password: Yup.string().min(5, "password length must be upto 5")
//         .matches(passwordRules, { "message": "password must contain a small letters, capital letters, special character and a number" })
//         .required("password is required"),

//     cPassword: Yup.string().oneOf([Yup.ref("Password"), null, "passwords must match"]).required("confirm password is required")

// })

export const QualificationsValidationScehma = Yup.object().shape({
    QualificationID: Yup.string().required("qualification is required"),
    Discipline: Yup.string().required("discipline is required"),
    InstitutionName: Yup.string().required("institution name is required"),
    Year: Yup.string().required("year obtained is required"),
})

export const EmplymentHistoryValidationScehma = Yup.object().shape({
    Organization: Yup.string().required("Organization is required"),
    Position: Yup.string().required("Position is required"),
    StartDate: Yup.string().required("StartDate is required"),
    EndDate: Yup.string().required("EndDate obtained is required"),
})

export const SocialMediaValidationScehma = Yup.object().shape({
    MediaName: Yup.string().required("please select media type"),
    MediaValue: Yup.string().required("link is required"),
})

export const BankInfoValidationScehma = Yup.object().shape({
    BankName: Yup.string().required("Bank Name is required"),
    AccountName: Yup.string().required("Account Name is required"),
    AccountNumber: Yup.string().required("Account Number is required"),
    AccountType: Yup.string().required("Account Type is required"),
    SortCode: Yup.string().required("Sort Code is required"),
    Country: Yup.string().required("Country is required"),
})

export const NextOfKinValidationScehma = Yup.object().shape({
    FirstName: Yup.string().required("first name is required"),
    //MiddleName: Yup.string().required("middle name is required"),
    Surname: Yup.string().required("surname is required"),
    Relationship: Yup.string().required("relationship is required"),
    Phone: Yup.string().required("phone is required"),
    Address: Yup.string().required("address is required"),
    // Email: Yup.string().email("please enter a valid email").required("email is required"),
})

export const AddEditJobsValidationScehma = Yup.object().shape({
    Position: Yup.string().required("Position is required"),
    Faculty: Yup.string().required("Faculty is required"),
    Department: Yup.string().required("Department is required"),
    OpeningDate: Yup.string().required("OpeningDate is required"),
    ClosingDate: Yup.string().required("ClosingDate is required"),
    JobType: Yup.string().required("JobType is required"),
    Urgent: Yup.string().required("Urgent is required"),
    Status: Yup.string().required("Status is required"),
    InterViewDate: Yup.string().required("InterViewDate is required"),
    InterviewVenue: Yup.string().required("InterviewVenue is required"),
    InterviewTime: Yup.string().required("InterviewTime is required"),
})