import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link, useLocation, useParams } from "react-router-dom";
import Breadcrum from '../../../../common/breadcrum/breadcrum';
import ReportTable from '../../../common/report_table';
import Loader from '../../../../common/loader';
import { CheckPermission, formatDateAndTime } from '../../../../util/constants';
import { serverLink } from '../../../../util/url';
import { setSingleJobApplication } from '../../../../actions/actions';

function JobApplicants(props) {
    const allow = props.permissionsList.length > 0 ? props.permissionsList.filter(x => x.Designation === 'superadmin' || x.Designation === 'hr').length > 0 ? true : false : false;
    CheckPermission(allow);
    const params = useParams();
    const location = useLocation()
    const active_cohort = props.activeCohort[0];

    const [isLoading, setIsLoading] = useState(true);
    const [generalDatatable, setGeneralDatatable] = useState([])

    const columns = ["S/N", "Name", "Job ID", "Position", "Job Type", "Department", "Application Date", "Status", "Action"];

    const getData = async () => {
        try {
            await axios.get(`${serverLink}/enrollment/get_job_applications/by-status/${params.Status.toString()}?cohort=${active_cohort?.CohortCode}&job=${params?.JobID}`).then((res) => {
                if (res.data.length > 0) {
                    let rows = []
                    if (res.data.length > 0) {
                        res.data.map((item, index) => {
                            rows.push([
                                index + 1,
                                item.FirstName + " " + item.MiddleName + " " + item.Surname,
                                item.RequisitionID,
                                item.Position,
                                item.JobType,
                                item.Department,
                                formatDateAndTime(item.InsertedDate, "date"),
                                <span className={item.ApplicationStatus === "0" ? 'badge badge-secondary' :
                                    item.ApplicationStatus === "1" ? "badge badge-info" :
                                        item.ApplicationStatus === "2" ? "badge badge-success" : item.ApplicationStatus === "3" ? "badge badge-danger" : "badge badge-danger"}>

                                    {item.ApplicationStatus === "0" ? 'pending' :
                                        item.ApplicationStatus === "1" ? "invited" :
                                            item.ApplicationStatus === "2" ? "accepted" : item.ApplicationStatus === "3" ? "rejected" : "cancelled"}
                                </span>,
                                (
                                    <>
                                        <Link onClick={() => { props.setOnSingleJobApplication([item]) }}
                                            to={`/jobs/applicant/${item.ApplicantID}/${item.RequisitionID}/${item.ApplicationID}`}
                                            className="btn btn-sm btn-main"
                                        >
                                            <i className="fa fa-eye" />
                                        </Link>

                                    </>
                                ),
                            ]);
                        });
                    }
                    setGeneralDatatable(rows);
                    setIsLoading(false);
                }
            })



        } catch (error) {

        }
    }

    useEffect(() => {
        getData();
    }, [location.pathname]);

    return (
        isLoading ? <Loader />
            :
            <div>
                <div className="container-fluid" style={{ marginLeft: '-10px' }}>
                    <Breadcrum title={params.Status === "0" ? "Pending Applications" : params.Status === "1" ? "Invited Applications" :
                        params.Status === "2" ? "Approved Applications" : "Rejected Applications"} />
                    <div className="row mt-3">
                        <div className="col-12">
                            <ReportTable title={generalDatatable.length + " Records"} columns={columns} data={generalDatatable} height="700px" />
                        </div>
                    </div>
                </div>
            </div>
    );
}

const mapStateToProps = (state) => {
    return {
        AdminLoginData: state.AdminLoginData,
        permissionsList: state.permissionsList,
        activeCohort: state.activeCohort

    };
};

const mapDisptachToProps = (dispatch) => {
    return {
        setOnSingleJobApplication: (p) => {
            dispatch(setSingleJobApplication(p))
        }
    }
}



export default connect(mapStateToProps, mapDisptachToProps)(JobApplicants);


