import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Breadcrum from '../../../../common/breadcrum/breadcrum';
import ReportTable from '../../../common/report_table';
import { serverLink } from '../../../../util/url';
import Loader from '../../../../common/loader';
import { projectLogo, CheckPermission, encryptData, formatDateAndTime, FullcurrentDate, MailTemplates, sendEmail, shortCode, schoolName, projectEmail, formatDate } from '../../../../util/constants';
import { Document, Page, pdfjs } from 'react-pdf';
import Swal from 'sweetalert2';
import Modal from '../../../common/modal';
import JoditEditor from 'jodit-react';
import { setAdmin, setAdminJobApplications, setAdminLoginDetails, setApplicantLoginDetails } from '../../../../actions/actions';
import * as Dompurify from 'dompurify'
import './applicant-details.css'
import Select from "react-select";
import { useReactToPrint } from "react-to-print";
import CUOfferLetter from './offer-letters/cu-offer-letter';
import Charts from '../../../common/chart';




pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function JobApplicantDetails(props) {
    const allow = props.permissionsList.length > 0 ? props.permissionsList.filter(x => x.Designation === 'superadmin' || x.Designation === 'hr').length > 0 ? true : false : false;
    CheckPermission(allow);

    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [applicants, setapplicants] = useState(
        props.SingleApplication.length > 0 ?
            props.SingleApplication.filter(x => x.ApplicationID.toString() === params.ApplicationID.toString()) : []
    );

    const [generalDatatable, setGeneralDatatable] = useState([])

    const columns = ["S/N", "Name", "Email", "Job ID", "Position", "Dept.", "App. Date", "Status"];

    const [enrolmentData, setEnrolmentData] = useState([])
    const [HrQualifications, setHrQualifications] = useState([])
    const [SocialMedia, setSocialMedia] = useState([]);
    const [statesList, setStatesList] = useState([])
    const [titleList, setTitleList] = useState([]);
    const [staffTypeList, setStaffTypeList] = useState([])
    const componentRef = useRef();
    const [insights, setInsights] = useState([])
    const [interviewTypesList, setInterviewTypesList] = useState([]);
    const [visibility, setVisibility] = useState([]);
    const [insightsCheck, setInsightCheck] = useState({ accepted: 0, invited: 0 })

    const getData = async () => {
        try {

            const check = await axios.get(`${serverLink}/enrollment/visibility/check/${applicants[0].ApplicantID}`);
            setVisibility(check.data)

            const { data } = await axios.get(`${serverLink}/enrollment/interview/assessment/interview-types`);
            setInterviewTypesList(data)

            await axios.get(`${serverLink}/enrollment/enrolment-deatils/${applicants[0]?.ApplicantID}`).then((res) => {
                if (res.data.length > 0) {
                    setEnrolmentData(res.data);
                    const values = res.data[0].social_media[0]
                    const mediaList = [
                        { name: 'Facebook', media: values?.Facebook },
                        { name: 'Twitter', media: values?.Twitter },
                        { name: 'LinkedIn', media: values?.LinkedIn },
                        { name: 'Google Scholar', media: values?.Scholar },
                        { name: 'Reasearch Gate', media: values?.ResearchGate },
                        { name: 'Academia', media: values?.Academia },
                        { name: 'ORCID', media: values?.Orcid }
                    ]
                    setSocialMedia(mediaList)
                }
            })
            if (HrQualifications.length === 0) {
                await axios.get(`${serverLink}/job_applications/hr_qualification`).then((res) => {
                    if (res.data.length > 0) {
                        setHrQualifications(res.data)
                    }
                })
            }

            if (statesList.length === 0) {
                await axios.get(`${serverLink}/job_applications/state/list`).then((res) => {
                    if (res.data.length > 0) {
                        setStatesList(res.data)
                    }
                })
            }

            await axios.get(`${serverLink}/job_applications/hr_title`).then((res) => {
                if (res.data.length > 0) {
                    setTitleList(res.data)
                }
            })

            await axios.get(`${serverLink}/job_applications/hr_staff_type`).then((res) => {
                if (res.data.length > 0) {
                    setStaffTypeList(res.data)
                }
            })

            await axios.get(`${serverLink}/enrollment/get_social_media/email/${applicants[0]?.Email}`).then((res) => {
                if (res.data.length > 0) {
                    const values = res.data[0];
                    const mediaList = [
                        { name: 'Facebook', media: values?.Facebook },
                        { name: 'LinkedIn', media: values?.LinkedIn },
                        { name: 'Google Scholar', media: values?.Scholar },
                        { name: 'Reasearch Gate', media: values?.ResearchGate },
                        { name: 'Academia', media: values?.Academia },
                        { name: 'ORCID', media: values?.Orcid }
                    ]
                    setSocialMedia(mediaList)
                }
                setIsLoading(false)
            })

        } catch (error) {

        }
    }

    const [viewCv, setViewCv] = useState(false)

    const GetOtherApplications = (data = []) => {
        let rows = [];
        const dt = data.length > 0 ? data : []
        if (dt.length > 0) {
            dt.map((x, i) => {
                rows.push([
                    i + 1,
                    x.FirstName + " " + x.MiddleName + " " + x.Surname,
                    x.Email,
                    x.RequisitionID,
                    x.Position,
                    x.Department,
                    formatDateAndTime(x.InsertedDate, "date"),
                    <span className={x.ApplicationStatus === "0" ? 'badge badge-secondary' :
                        x.ApplicationStatus === "1" ? "badge badge-info" :
                            x.ApplicationStatus === "2" ? "badge badge-success" : x.ApplicationStatus === "3" ? "badge badge-danger" : "badge badge-danger"}>

                        {x.ApplicationStatus === "0" ? 'pending' :
                            x.ApplicationStatus === "1" ? "invited" :
                                x.ApplicationStatus === "2" ? "accepted" : x.ApplicationStatus === "3" ? "rejected" : "cancelled"}
                    </span>,
                ])
            })
            setGeneralDatatable(rows)
        }
    }

    const getApplicationData = async () => {
        try {
            await axios.get(`${serverLink}/enrollment/get_job_application/${params.ApplicationID}`).then((res) => {
                if (res.data.length > 0) {
                    setapplicants(res.data)
                }
            })
        } catch (e) {

        }
    }

    const [staff_id, set_staff_id] = useState([])
    const getStaffDetails = async (email) => {
        try {
            await axios.get(`${serverLink}/enrollment/get_staff/by_email/${email}`).then((res) => {
                if (res.data.length > 0) {
                    set_staff_id(res.data[0]?.StaffID)
                }
            })
        } catch (e) {

        }
    }
    const [staffList, setstaffList] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0)
        if (props.staffList.length > 0) {
            let rows = [];
            props.staffList.length > 0 &&
                props.staffList.map((x, i) => {
                    rows.push({ value: x.StaffID, label: x.StaffID + "--" + x.StaffName });
                })
            setstaffList(rows)
        }

        const data = props.AllJobApplications.length > 0 ?
            props.AllJobApplications.filter(x => x.ApplicantID === params.ApplicantID) : [];

        applicants.length > 0 && getInsights(applicants[0]);

        getData();
        getApplicationData()
        GetOtherApplications(data);
        applicants.length > 0 && getStaffDetails(applicants[0]?.Email);


    }, [location.pathname]);

    const getInsights = async (app) => {
        const { data } = await axios.get(`${serverLink}/enrollment/get_job_application/by-job/${app?.JobID}`);
        const all = data.length
        const invited = data?.filter(x => x.ApplicationStatus === "1").length
        const accepted = data?.filter(x => x.ApplicationStatus === "2").length
        const rejected = data?.filter(x => x.ApplicationStatus === "3").length
        setInsightCheck({ ...insightsCheck, accepted: accepted, invited: invited });
        const enrolled = data?.filter(x => x.ApplicationStatus === "2" && x.EnrolmentStatus === "1").length;
        let tt = [["Type", "Count"], ["No of Openings", parseInt(app?.NoOfOpenings)], ["All Applications", all], ["Accepted Applications", accepted], ["Rejected Applications", rejected], ["Enrolled Applications", enrolled]];
        setInsights(tt)
    }

    const handleManagerChange = (e) => {
        setFormData({
            ...formData,
            LineManager: e.value,
            LineManager2: e
        })
    }

    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const goToPrevPage = () =>
        setPageNumber((pageNumber) => pageNumber - 1)
    const goToNextPage = () =>
        setPageNumber((pageNumber) => pageNumber + 1)

    const toggleCvView = () => {
        if (viewCv === true) {
            setViewCv(false)
        } else {
            setViewCv(true)
        }
    }

    const [formData, setFormData] = useState({
        ApplicationID: applicants[0]?.ApplicationID,
        DescisionType: "",
        ApplicationStatus: "",
        EmailContent: "",
        EmailContentBody: "",
        StatusBy: props.AdminLoginData[0]?.StaffID,
        EnrolmentEmailContentBody: "",
        EnrolmentEmail: "",
        ...applicants[0],
        IsAcademicStaff: "",
        LineManager: "",
        IsFullTime: "",
        IsSenior: "",
        image: applicants[0]?.Passport,
        Title: "",
        NetworkEmailSubject: "",
        NetworkEmailContent: "",
        NetworkEmailContentBody: "",
        DateOfFirstEmployment: "",
        DateOfCurrentEmployment: "",
        ContractStartDate: applicants.length > 0 ? formatDate(applicants[0]?.AppointmentDate) : '',
        ContractEndDate: "",
        GrossPay: applicants.length > 0 ? applicants[0]?.GrossPay : 0,
        StaffType: "",
        InterviewType: applicants[0]?.InterviewType,

    })

    const onDecide = (e) => {
        e.preventDefault();
       
        Swal.fire({
            title: 'Are you sure?',
            text: formData.ApplicationStatus === "1" ?
                "This will place applicant on Invite List and send invitation email" :
                "This will place applicant on rejection list and send rejection email",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Submit'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.put(`${serverLink}/job_applications/set_application_status/${formData.ApplicationID}`, formData).then((res) => {
                        if (res.data.message === "success") {
                            toast.success("application status updated successfully!")
                            document.getElementById("modal-close").click();
                            sendEmail(applicants[0]?.Email,
                                formData.EmailContent.subject,
                                formData.EmailContent.title, applicants[0]?.FirstName, formData.EmailContentBody,
                                ''
                            );
                            getApplicationData();
                        } else {
                            toast.error("network error!!!")
                        }
                    })
                } catch (e) {
                    console.log(e)
                    toast.error("network error!")
                }
            }

        })
    }



    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })

        if (e.target.id === "ApplicationStatus") {
            if (e.target.value !== "") {
                const email = MailTemplates(e.target.value, applicants[0]);
                setFormData({
                    ...formData,
                    EmailContent: email,
                    EmailContentBody: email.body,
                    ApplicationStatus: e.target.value,
                    InterviewType: applicants[0]?.InterviewType,
                })
            } else {
                setFormData({
                    ...formData,
                    EmailContent: "",
                    EmailContentBody: "",
                    ApplicationStatus: e.target.value,
                    InterviewType: "",
                })
            }
        }
    }


    const OnLoginFromAdmin = async (e) => {
        e.preventDefault()
        await axios.get(`${serverLink}/job_applications/job_applicant_login/${applicants[0].Email}`).then((res) => {
            if (res.data.length > 0) {
                props.setOnAdmin(props.AdminLoginData)
                props.setOnAdminLoginData([])
                props.setOnJobApplicantData(res.data);
                navigate(`/applicant/enrolment/${encryptData(applicants[0]?.JobID)}`)
            }
        })

    }
    const onAddNewStaff = async (e) => {
        e.preventDefault();

        const dt = {
            enrolmentData,
            ...formData,
            PositionOfferedID: props.hrDesignations.length > 0 ?
                props.hrDesignations.filter(x => x.DesignationName === formData.PositionOffered)[0]?.EntryID : 0,
            PositionID: props.hrDesignations.length > 0 ?
                props.hrDesignations.filter(x => x.DesignationName === formData.Position)[0]?.EntryID : 0,
            DepartmentCode: props.departmentList.length > 0 ?
                props.departmentList.filter(x => x.DepartmentName === applicants[0]?.Department)[0]?.DepartmentCode : 0,
            StateID: statesList.length > 0 ?
                statesList.filter(x => x.StateName === formData.StateOfOrigin)[0]?.EntryID : 0,
            NationalityID: statesList.length > 0 ?
                statesList.filter(x => x.StateName === formData.StateOfOrigin)[0]?.NationalityID : 0,
            InsertedBy: props.AdminLoginData[0]?.StaffID,
            TitleID: formData.Title,
        }

        Swal.fire({
            title: 'Are you sure?',
            text: "Are you sure you want to proceed",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Submit'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.post(`${serverLink}/enrollment/add_new_employee`, dt).then((res) => {
                        if (res.data.message === "success") {
                            toast.success("staff added successfully");
                            document.getElementById("close-enrolment-modal").click();
                            sendEmail(
                                applicants[0]?.Email, formData.EnrolmentEmail.subject, formData.EnrolmentEmail.title, applicants[0]?.FirstName, formData.EnrolmentEmailContentBody, ''
                            )
                            getApplicationData();
                        }
                        else if (res.data.message === "exists") {
                            toast.error("staff already enrolled")
                        }
                        else {
                            toast.error("enrolment failed, please try again")
                        }
                    })

                } catch (e) {
                    console.log(e)
                    toast.error("network error")

                }
            }
        })

    }

    const [mailLoading, setMailLoading] = useState(true)
    const getLastStaffID = async () => {
        try {
            await axios.get(`${serverLink}/enrollment/last_staff_id`).then((res) => {
                if (res.data.length > 0) {
                    const max_staff_id = parseInt(res.data[0].LastStaffID.split('E')[1]);
                    const lastIndex = Number(max_staff_id) + 1;

                    const padStaffID = (lastIndex, places) => String(lastIndex).padStart(places, "0");
                    const new_staff_id = `E${padStaffID(lastIndex, 4)}`;

                    const mail = MailTemplates('4', { ...applicants[0], NewStaffID: new_staff_id })
                    setFormData({
                        ...formData,
                        EnrolmentEmail: mail,
                        EnrolmentEmailContentBody: mail.body,
                        NewStaffID: new_staff_id,
                        GrossPay: applicants[0].GrossPay,
                        ContractStartDate: applicants[0].AppointmentDate,
                        DateOfCurrentEmployment: applicants[0].AppointmentDate,
                        DateOfFirstEmployment: applicants[0].AppointmentDate
                    })
                    setTimeout(() => {
                        setMailLoading(false)
                    }, 1000);
                }
            })
        } catch (e) {
            console.log(e)

        }

    }

    const onPrintPage = () => {
        document.getElementById("toggle-side").click();
        window.print();
    }



    const onServiceDeskRequest = (e) => {
        e.preventDefault();

        Swal.fire({
            title: 'Are you sure?',
            text: "proceed to send email?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Submit'
        }).then((result) => {
            if (result.isConfirmed) {
                sendEmail(
                    formData.NetworkEmailTo,
                    formData.NetworkEmailSubject,
                    formData.NetworkEmailContent.title,
                    formData.NetworkEmailTo,
                    formData.NetworkEmailContentBody,
                    ''
                )
                toast.success("Network request sent successfully!");
                document.getElementById("email-close").click();
            }
        })
    }

    const [showOfferLetter, setShowOfferLetter] = useState(false)

    const handleAdmissionPrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const printOfferLetter = () => {
        setShowOfferLetter(true);

        setTimeout(() => {
            handleAdmissionPrint();
            setShowOfferLetter(false);
        }, 100);

    }

    const ActionButtons = () => {
        return (
            <>
                {
                    location.pathname.includes("enrolment") &&
                    <div className='hideSection'>
                        <div className='row mb-5 table-responsive'>
                            <button type='submit' className='btn btn-md btn-main m-l-20 mb-2' onClick={OnLoginFromAdmin}>
                                Edit Applicants Enrolment Data
                            </button>
                            <button
                                disabled={applicants[0]?.EnrolmentStatus === "2" || applicants[0]?.EnrolmentStatus === "0"}
                                className='btn btn-md btn-success m-l-20 mb-2' data-toggle="modal" data-target="#enrol-staff-modal"
                                onClick={() => {
                                    getLastStaffID();
                                }} >
                                Confirm Enrolment/ Generate Staff ID
                            </button>
                            {
                                applicants[0]?.EnrolmentStatus === "2" &&
                                <button className='btn btn-md btn-main m-l-20 mb-2' data-toggle="modal" data-target="#email-modal"
                                    onClick={() => {
                                        const mail = MailTemplates('5', { ...applicants[0], staff_id: staff_id, social_media: enrolmentData[0].social_media, next_of_kin: enrolmentData[0].next_of_kin })
                                        setFormData({
                                            ...formData,
                                            NetworkEmailContent: mail,
                                            NetworkEmailTo: projectEmail,
                                            NetworkEmailSubject: mail.subject,
                                            NetworkEmailContentBody: mail.body
                                        })
                                    }} >
                                    Request ID Card/ Network Connectivity Details/ Official Email
                                </button>
                            }

                            <button className='btn btn-md btn-main m-l-20 mb-2'
                                onClick={() => {
                                    onPrintPage()

                                }} >
                                Print Applicant Profile
                            </button>

                            {
                                applicants[0]?.OfferAcceptance === "1" &&
                                <button className='btn btn-md btn-success m-l-20 mb-2'
                                    onClick={printOfferLetter} >
                                    Print Offer Letter
                                </button>
                            }

                        </div>
                    </div>
                }
            </>
        )
    }

    return (
        isLoading ? <Loader />
            :
            <div>
                <div className="hideSection">
                    <Breadcrum title="Applicant's Details" />
                </div>
                <div className="row m-t-15" id="pageToPrint">
                    <div className="col-lg-12 col-xlg-12 col-md-12 ">
                        <strong className='d-flex justify-content-end'>
                            &nbsp; <span className='text-monospace'>{formatDateAndTime(FullcurrentDate, "date_and_time")}</span>
                        </strong>
                        <div className='text-center justify-content-center mb-5'>
                            <img src={projectLogo} width="100px" />
                            <h2>
                                <strong className='text-dark'>{schoolName}</strong>
                            </h2>
                            <h5><strong className='text-monospace' style={{ textDecoration: 'underline' }} >Human Resources Office</strong></h5>

                        </div>
                        {
                            applicants[0]?.EnrolmentStatus === "0" &&
                            <div className="alert alert-danger">
                                <i className="ti-user"></i>
                                This Applicant has not submitted enrollment details. (Only Applicants who submit enrollment details can be confirmed!)
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">×</span> </button>
                            </div>
                        }

                    </div>

                    <div className="col-lg-12 col-xlg-12 col-md-12">
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='card'>
                                    <div className="card-body">
                                        <center className="m-t-5">
                                            <img src={`${serverLink}/public/uploads/${shortCode}/staff_enrollment/passports/${applicants[0]?.Passport}`} className="img-thumbnail" width={150} />
                                            <h4 className="card-title m-t-10">{applicants[0]?.FirstName} {applicants[0]?.MiddleName} {applicants[0]?.Surname}</h4>
                                            <h6 className=""><strong>Position Applied</strong>: {applicants[0]?.Position}</h6>
                                            <h6 className="text-success"><strong>Position Offered</strong>: {applicants[0]?.PositionOffered !== null ? applicants[0]?.PositionOffered : "N/A"}</h6>
                                            <h6 className=""><strong>Department</strong>: {applicants[0]?.Department}</h6>
                                            <h6 className=""><strong>Date Applied</strong>: {formatDateAndTime(applicants[0]?.InsertedDate, "date")}</h6>
                                        </center>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-8'>

                                <div className='card'>
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <small className="h5">Email address </small>
                                                <div className='font-dark text-dark font-weight-bold ql-font-monospace text-monospace'>{applicants[0]?.Email}</div>
                                                <br />
                                                <small className="p-t-30 h5">Phone</small>
                                                <div className='font-dark text-dark font-weight-bold ql-font-monospace text-monospace'>{applicants[0]?.Phone}</div>
                                                <br />
                                                <small className="p-t-30 h5">Address</small>
                                                <div className='font-dark text-dark font-weight-bold ql-font-monospace text-monospace'>{applicants[0]?.Address}</div>
                                                <br />
                                                <small className="p-t-30 h5">Gender</small>
                                                <div className='font-dark text-dark font-weight-bold ql-font-monospace text-monospace'>{applicants[0]?.Gender}</div>
                                                <br />
                                                <small className="p-t-30 h5">Marital Status</small>
                                                <div className='font-dark text-dark font-weight-bold ql-font-monospace text-monospace'>{applicants[0]?.MaritalStatus}</div>
                                            </div>

                                            <div className='col-md-4'>
                                                <small className="p-t-30 h5">Date of Birth</small>
                                                <div className='font-dark text-dark font-weight-bold ql-font-monospace text-monospace'>{applicants[0]?.DateOfBirth}</div>
                                                <br />
                                                <small className="p-t-30 h5">Highest Qualification</small>
                                                <div className='font-dark text-dark font-weight-bold ql-font-monospace text-monospace'>{applicants[0]?.HighestQualification}</div>
                                                <br />
                                                <small className="p-t-30 h5">Nationality</small>
                                                <div className='font-dark text-dark font-weight-bold ql-font-monospace text-monospace'>{applicants[0]?.Nationality}</div>
                                                <br />
                                                <small className="p-t-30 h5">State Of Origin</small>
                                                <div className='font-dark text-dark font-weight-bold ql-font-monospace text-monospace'>{applicants[0]?.StateOfOrigin}</div>
                                                <br />
                                                <small className="p-t-30 h5">LGA</small>
                                                <div className='font-dark text-dark font-weight-bold ql-font-monospace text-monospace'>{applicants[0]?.LGA}</div>
                                            </div>
                                            <div className='col-md-4'>
                                                <small className="p-t-30 h5">Alternative Email</small>
                                                <div className='font-dark text-dark font-weight-bold ql-font-monospace text-monospace'>{applicants[0]?.AlternativeEmail}</div>
                                                <br />
                                                <small className="p-t-30 h5">AlternativePhone</small>
                                                <div className='font-dark text-dark font-weight-bold ql-font-monospace text-monospace'>
                                                    {applicants[0]?.AlternativePhone}
                                                </div>
                                                <br />
                                                <small className="p-t-30 h5">Disability</small>
                                                <div className='font-dark text-dark font-weight-bold ql-font-monospace text-monospace'>{applicants[0]?.Disability}</div>
                                                <br />
                                                <small className="p-t-30 h5">Additional Description</small>
                                                <div className='font-dark text-dark font-weight-bold ql-font-monospace text-monospace'>{applicants[0]?.DisabilityType}</div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                <ApplicantVisibility visibility={visibility} applicants={applicants} />

                            }
                        </div>
                        <br />
                        <ActionButtons />

                    </div>

                    <div className="col-lg-12 col-xlg-12 col-md-12">
                        <div className="card">

                            <div className="card-body">
                                <h4 className='m-l-4'>Insights for <strong>{applicants[0]?.Position + ` (${params.JobID})`} --- {applicants[0]?.Department} </strong></h4>
                                {
                                    insights.length > 0 ?
                                        <Charts title={applicants[0]?.Position + `(${props.JobID})`}
                                            height={"300px"}
                                            type="Bar"
                                            data={insights} />
                                        :
                                        <span className='text-center mt-5'>
                                            <i className='fa fa-spin fa-spinner fa-5x' />
                                        </span>
                                }

                            </div>
                        </div>
                    </div>


                    <div className="col-lg-12 col-xlg-12 col-md-12">
                        <div className="card">
                            <div className='d-flex justify-content-between p-10'>
                                <h3>Application Details</h3>
                                <div className='hideSection'>
                                    <div className='d-flex'>
                                        <button
                                            disabled={applicants[0]?.ApplicationStatus !== "0" ? true : false}
                                            className='btn btn-sm btn-main m-r-15' data-toggle="modal" data-target="#bs-modal"
                                            onClick={() => {
                                                setFormData({
                                                    ...formData,
                                                    DescisionType: "Invite to Interview"
                                                })
                                            }}>
                                            Make Decision
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <hr />
                            </div>

                            <div className='p-10'>
                                <div className='row'>
                                    <div className='col-lg-4 col-md-6 col-sm-12'>
                                        <div className='table-resposive'>
                                            <table className='table '>
                                                <thead>
                                                    <tr>
                                                        <th>Item</th>
                                                        <th>Value</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Application ID</td>
                                                        <td>{applicants[0]?.ApplicationID}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Position Applied</td>
                                                        <td>{applicants[0]?.Position}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Date Applied</td>
                                                        <td>{formatDateAndTime(applicants[0]?.InsertedDate, "date")}</td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                    <div className='col-lg-8 col-md-6 col-sm-12'>
                                        <div className={
                                            applicants[0]?.ApplicationStatus === "0" ? "alert alert-warning" : applicants[0]?.ApplicationStatus === "1" ? "alert alert-info" :
                                                applicants[0]?.ApplicationStatus === "2" ? "alert alert-success" : "alert alert-danger"
                                        }>
                                            <h3 className={
                                                applicants[0]?.ApplicationStatus === "0" ? "text-warning" : applicants[0]?.ApplicationStatus === "1" ? "text-info" :
                                                    applicants[0]?.ApplicationStatus === "2" ? "text-success" : "text-danger"
                                            }>
                                                <i className="fa fa-check-circle" />
                                                {
                                                    applicants[0]?.ApplicationStatus === "0" ? "Pending" : applicants[0]?.ApplicationStatus === "1" ? "Invited" :
                                                        applicants[0]?.ApplicationStatus === "2" ? "Approved" : "Rejected"
                                                }
                                            </h3>
                                            {
                                                applicants[0]?.ApplicationStatus === "0" ? "Application is awaiting decision" : applicants[0]?.ApplicationStatus === "1" ? "Applicant have been invited to participate in interview" :
                                                    applicants[0]?.ApplicationStatus === "2" ? "Application have been approved" : "Application have been Rejected"
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='col-lg-12 col-xlg-12 col-md-12 break-page hideSection'>
                        <div className='card'>
                            <div className="card-body">
                                <div className="m-t-0">
                                    <h3>Social and Academic Competitiveness</h3>
                                    <div className='table-responsive'>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th>SN</th>
                                                    <th>Media Title</th>
                                                    <th>Media Link </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    SocialMedia.length > 0 ?
                                                        SocialMedia.map((x, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{i + 1}</td>
                                                                    <td>{x.name}</td>
                                                                    <td>
                                                                        <a className='link text-info' target={"_blank"} href={x?.media?.includes("https") === true ? x.media : `https://${x.media}`}  >{x.media} </a>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                        :
                                                        <tr >
                                                            <td colSpan={5} >
                                                                No Data
                                                            </td>
                                                        </tr>

                                                }
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-xlg-12 col-md-12 break-page hideSection">
                        <div className='card'>
                            <div className="card-body">
                                <div className="m-t-0">
                                    <h3>Cover Letter</h3>
                                    <div className='bg-dark text-white text-monospace h5 p-20'>
                                        <span dangerouslySetInnerHTML={{ __html: Dompurify.sanitize(applicants[0]?.CoverLetter?.replace(/\\n/g, '')) }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 col-xlg-12 col-md-12 hideSection">
                        <div className='card'>
                            <div className='card-body'>
                                <div className="m-t-0">
                                    <hr />
                                    <div className=''>
                                        <h3>Curriculum Vitae</h3>
                                        <div className='row m-t-20'>
                                            <span style={{ cursor: 'pointer' }} className='btn btn-sm btn-info m-r-20 mb-2'
                                                href={'#'} onClick={toggleCvView}  >
                                                Click to View Curriculum Vitae in Page
                                            </span>
                                            <a className='btn btn-sm btn-main text-white' target={"_blank"}
                                                href={`${applicants[0]?.CurrcilumVitae}`}   >
                                                Click to View Curriculum Vitae in new tab
                                            </a>
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    {
                                        viewCv === true &&
                                        <>
                                            <div style={{ width: '100%', borderStyle: 'groove', borderWidth: '3px' }}>
                                                <Document
                                                    file={`${applicants[0]?.CurrcilumVitae}`}
                                                    onLoadSuccess={onDocumentLoadSuccess}
                                                >
                                                    <Page pageNumber={pageNumber} width={750} />
                                                </Document>
                                            </div>
                                            <span>
                                                <strong style={{ color: 'green' }}>Page {pageNumber} of {numPages}</strong>
                                                <nav>
                                                    <div style={{ float: 'right' }}>
                                                        <button className='btn btn-mini btn-main m-r-10' onClick={goToPrevPage}><i className='fa fa-arrow-left'></i>Prev</button>
                                                        <button className='btn btn-mini btn-main' onClick={goToNextPage}><i className='fa fa-arrow-right'></i> Next</button>
                                                    </div>
                                                </nav>

                                            </span>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <ActionButtons />
                </div>
                {
                    location.pathname.includes("enrolment") !== true &&
                    <div className="container-fluid" style={{ marginLeft: '-10px' }}>
                        <div className="row mt-3">
                            <div className="col-12">
                                <ReportTable title={"Application History"} columns={columns} data={generalDatatable} />
                            </div>
                        </div>
                    </div>
                }

                {location.pathname.includes("enrolment") && applicants[0]?.OfferAcceptance === "1" && <CUOfferLetter componentRef={componentRef} printData={applicants[0]} />}


                <Modal id="email-modal" title={`Request Email`} close={"email-close"}  >
                    <div className='row'>
                        <div className='col-md-12'>
                            <form onSubmit={onServiceDeskRequest} >

                                <label className='form-label' >Email Subject</label>
                                <div className="input-group mb-3">
                                    <input type={"text"} className="form-control" id="NetworkEmailSubject" value={formData.NetworkEmailSubject} onChange={onEdit} />
                                </div>
                                <br />

                                <label className='form-label' >Email Receiver</label>
                                <div className="input-group mb-3">
                                    <input type={"text"} className="form-control" id="NetworkEmailTo" value={formData.NetworkEmailTo} onChange={onEdit} />
                                </div>

                                <label className='form-label' >Email Body</label>
                                <JoditEditor
                                    value={formData.NetworkEmailContentBody}
                                    tabIndex={1}
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData, NetworkEmailContentBody: e
                                        })
                                    }}
                                />
                                <hr />
                                <div className="input-group mb-3">
                                    <button type='submit' className='btn btn-md btn-main w-100'>
                                        Send Email
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal>

                <Modal id="bs-modal" title={`Make Decision on Application`}  >
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div className='table-resposive'>
                                <table className='table table-striped'>
                                    <tbody>
                                        <tr>
                                            <td>Application ID</td>
                                            <td>{applicants[0]?.ApplicationID} -- {applicants[0]?.FirstName} {applicants[0]?.MiddleName} {applicants[0]?.Surname}</td>
                                        </tr>
                                        <tr>
                                            <td>Position Applied</td>
                                            <td>{applicants[0]?.Position} -- {applicants[0]?.Department}</td>
                                        </tr>
                                        <tr>
                                            <td>Date Applied</td>
                                            <td>{formatDateAndTime(applicants[0]?.InsertedDate, "date")}</td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>

                            {
                                <ApplicantVisibility visibility={visibility} applicants={applicants} />
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <form onSubmit={onDecide} >
                                <label className='form-label' >Action</label>
                                <div className="input-group mb-3">
                                    <select type="text" className="form-control form-control-lg" onChange={onEdit} id="ApplicationStatus"
                                        value={data.ApplicationStatus} required >
                                        <option value={""} >--select--</option>
                                        <option value={"1"} >Invite to Interview</option>
                                        <option value={"3"} >Reject Application</option>
                                    </select>
                                </div>
                                {
                                    formData.ApplicationStatus === "1" &&
                                    <>
                                        <label className='form-label' >Interview Type</label>
                                        <div className="input-group mb-3">
                                            <select type="text" className="form-control form-control-lg" onChange={onEdit} id="InterviewType"
                                                value={formData.InterviewType} required >
                                                <option value={""} >--select--</option>
                                                {
                                                    interviewTypesList.length > 0 &&
                                                    interviewTypesList.map((x, i) => {
                                                        return (
                                                            <option key={i} value={x.InterviewType} >{x.InterviewType}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </>
                                }


                                <br />
                                <label className='form-label' >Email Template</label>
                                <JoditEditor
                                    value={formData.EmailContentBody}
                                    tabIndex={1}
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData, EmailContentBody: e
                                        })
                                    }}
                                />
                                <hr />
                                <div className="input-group mb-3">
                                    <button type='submit' className='btn btn-md btn-main w-100'>
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal>

                <Modal id="enrol-staff-modal" title={`Enrol New Staff`} close="close-enrolment-modal" >
                    <div className='row'>
                        <div className='col-md-12'>
                            <form onSubmit={onAddNewStaff} >
                                <strong className='text-danger'>
                                    You are about to add a new staff to {schoolName}.
                                    This will generate a staff id and accord him all benefits and access to {schoolName} Resources Online and Offline, Please ensure that he/she have satisfied the necessary requirements and have completed the enrolment form with appropriate details before proceeding.
                                </strong>
                                <hr />

                                <br />
                                {
                                    mailLoading === false ?
                                        <>

                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <label className='form-label' >Title</label>
                                                    <div className="input-group mb-3">
                                                        <select type="text" className="form-control form-control-lg" onChange={onEdit} id="Title"
                                                            value={formData.Title} required >
                                                            <option value={""} >--select--</option>
                                                            {
                                                                titleList.length > 0 &&
                                                                titleList.map((x, i) => {
                                                                    return (
                                                                        <option key={i} value={x.EntryID} >{x.TitleName}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label className='form-label' >Staff Type</label>
                                                    <div className="input-group mb-3">
                                                        <select type="text" className="form-control form-control-lg" onChange={onEdit} id="IsAcademicStaff"
                                                            value={formData.IsAcademicStaff} required >
                                                            <option value={""} >--select--</option>
                                                            <option value={1} >Academic</option>
                                                            <option value={0} >Non Academic</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label className='form-label' >Line Manager</label>
                                                    <div className="form-group mb-3">


                                                        <Select
                                                            id="LineManager"
                                                            className="form-select form-select"
                                                            value={formData.LineManager2}
                                                            onChange={handleManagerChange}
                                                            options={staffList}
                                                            width={'100%'}
                                                            placeholder="Select Line Manager"
                                                        />

                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label className='form-label' >Is FullTime?</label>
                                                    <div className="input-group mb-3">
                                                        <select type="text" className="form-control form-control-lg" onChange={onEdit} id="IsFullTime"
                                                            value={formData.IsFullTime} required >
                                                            <option value={""} >--select--</option>
                                                            <option value={1} >YES</option>
                                                            <option value={0} >NO</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label className='form-label' >Is Senior?</label>
                                                    <div className="input-group mb-3">
                                                        <select type="text" className="form-control form-control-lg" onChange={onEdit} id="IsSenior"
                                                            value={formData.IsSenior} required >
                                                            <option value={""} >--select--</option>
                                                            <option value={1} >YES</option>
                                                            <option value={0} >NO</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label className='form-label' >Date Of First Employment</label>
                                                    <div className="input-group mb-3">
                                                        <input type="date" className="form-control form-control-lg" onChange={onEdit} id="DateOfFirstEmployment"
                                                            value={formatDate(formData.DateOfFirstEmployment)} required />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label className='form-label' >Date Of Current Employment</label>
                                                    <div className="input-group mb-3">
                                                        <input type="date" className="form-control form-control-lg" onChange={onEdit} id="DateOfCurrentEmployment"
                                                            value={formatDate(formData.DateOfCurrentEmployment)} required />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label className='form-label' >Contract Start Date</label>
                                                    <div className="input-group mb-3">
                                                        <input type="date" className="form-control form-control-lg" onChange={onEdit} id="ContractStartDate"
                                                            value={formatDate(formData.ContractStartDate)} required />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label className='form-label' >Contract End Date</label>
                                                    <div className="input-group mb-3">
                                                        <input type="date" className="form-control form-control-lg" onChange={onEdit} id="ContractEndDate"
                                                            value={formData.ContractEndDate} required />
                                                    </div>
                                                </div>

                                                <div className='col-md-6'>
                                                    <label className='form-label' >Gross Pay</label>
                                                    <div className="input-group mb-3">
                                                        <input type="number" step={0.00} className="form-control form-control-lg" onChange={onEdit} id="GrossPay"
                                                            value={formData.GrossPay} required />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label className='form-label' >Staff Type</label>
                                                    <div className="input-group mb-3">
                                                        <select type="text" className="form-control form-control-lg" onChange={onEdit} id="StaffType"
                                                            value={formData.StaffType} required >
                                                            <option value={""} >--select--</option>
                                                            {
                                                                staffTypeList.length > 0 &&
                                                                staffTypeList.map((x, i) => {
                                                                    return (
                                                                        <option value={x.TypeName} key={i} >{x.TypeName}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />


                                            <label className='form-label' >Email Template</label>
                                            <JoditEditor
                                                value={formData.EnrolmentEmailContentBody}
                                                tabIndex={1}
                                                onChange={(e) => {
                                                    setFormData({
                                                        ...formData, EnrolmentEmailContentBody: e
                                                    })
                                                }}
                                            />
                                            <hr />
                                            <div className="input-group mb-3">
                                                <button type='submit' className='btn btn-md btn-main w-100'>
                                                    Submit
                                                </button>
                                            </div>
                                        </>
                                        :
                                        <div className='text-center'>
                                            <i className='fa fa-spinner fa-spin fa-10x' />

                                        </div>
                                }
                            </form>
                        </div>
                    </div>
                </Modal>

            </div>
    );
}


const ApplicantVisibility = ({ visibility, applicants }) => {
    return (
        <div className='col-md-12'>
            <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12'>
                    <div className="card-group mt-1">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="d-flex no-block align-items-center">
                                            <div>
                                                <p className="font-16 m-b-5 font-bold mt-3">Highest Qualification</p>
                                                <h1 className="font-bold text-primary">
                                                    {visibility.length > 0 ? visibility[0]?.HighestQualification : applicants[0]?.HighestQualification}
                                                </h1>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="d-flex no-block align-items-center">
                                            <div>
                                                <p className="font-16 m-b-5 font-bold mt-3">Total Publications</p>
                                                <h1 className="font-bold text-warning">
                                                    {visibility.length > 0 ? visibility[0]?.Publications : 0}
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="d-flex no-block align-items-center">
                                            <div>
                                                <p className="font-16 m-b-5 font-bold mt-3">Total Citations</p>
                                                <h1 className="font-bold">
                                                    {visibility.length > 0 ? visibility[0]?.Citations : 0}
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="d-flex no-block align-items-center">
                                            <div>
                                                <p className="font-16 m-b-5 font-bold mt-3">H-Index</p>
                                                <h1 className="font-bold">
                                                    {visibility.length > 0 ? visibility[0]?.HIndex : 0}
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="d-flex no-block align-items-center">
                                            <div>
                                                <p className="font-16 m-b-5 mt-3 font-bold">Total I10-Index</p>
                                                <h1 className="font-bold text-right text-success">
                                                    {visibility.length > 0 ? visibility[0]?.I10Index : 0}
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        AdminLoginData: state.AdminLoginData,
        AllJobApplications: state.AllJobApplications,
        SingleApplication: state.SingleJobApplication,
        departmentList: state.departmentList,
        staffList: state.staffList,
        Admin: state.Admin,
        hrDesignations: state.hrDesignations,
        permissionsList: state.permissionsList,
        activeCohort: state.activeCohort


    };
};

const mapDisptachToProps = (dispatch) => {
    return {
        setOnJobApplicantData: (p) => {
            dispatch(setApplicantLoginDetails(p))
        },
        setOnAdminLoginData: (p) => {
            dispatch(setAdminLoginDetails(p))
        },
        setOnAdmin: (p) => {
            dispatch(setAdmin(p))
        }
    }
}



export default connect(mapStateToProps, mapDisptachToProps)(JobApplicantDetails);


