import React from "react";
import {Link} from "react-router-dom";
import './style.css';
export default function Breadcrum(props) {
    return (
        <div className="page-breadcrumb" style={{marginTop: '-20px', marginLeft: '-10px'}}>
            <div className="row">
                <div className="col-5 align-self-center">
                    <h4 className="page-title">{props.title ?? ""}</h4>
                </div>
                <div className="col-7 align-self-center ">
                    <div className="d-flex align-items-center justify-content-end">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">{props.title ?? ""}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
}