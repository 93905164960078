
import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setAdmin, setAdminLoginDetails, setApplicantLoginDetails, setPermissions } from '../../../actions/actions';
import { projectLogo, encryptData, onGeneralEdit, decryptData } from '../../../util/constants';
import { serverLink } from '../../../util/url';

const AdminLogin = (props) => {
    const { slug } = useParams();
    const [isloggingIn, setIsLoggingIn] = useState(false)
    const navigate = useNavigate();
    const [data, setData] = useState({
        StaffID: "",
        Password: "",
    })

    useEffect(() => {
        window.sessionStorage.removeItem("staff")
        props.setOnAdminLoginData([]);
        props.setOnJobApplicantData([]);
        if (slug !== undefined) {
            LoginFromStaff(decryptData(slug))
        }
    }, [])

    const onLogin = async (e) => {
        e.preventDefault();
        setIsLoggingIn(true)
        const dt = {
            StaffID: data.StaffID,
            Password: encryptData(data.Password)
        }

        try {
            await axios.post(`${serverLink}/enrollment/staff_login`, dt).then((res) => {
                if (res.data.message === "success") {
                    window.sessionStorage.setItem("staff", "1")
                    let x = 1;
                    props.setOnJobApplicantData([])
                    props.setOnAdmin([]);
                    props.setOnPermissions(res.data.permissions)
                    props.setOnAdminLoginData(res.data.data);
                    toast.success("login successful, please wait!");

                    setTimeout(() => {
                        window.location.reload();
                    }, 200);
                } else {
                    toast.error("wrong credentials")
                }
                setIsLoggingIn(false)
            })
        } catch (error) {
            console.log(error)
            toast.error("network error, please try again!")
        }
        finally {
            setIsLoggingIn(false)
        }

    }

    const onEdit = (e) => {
        onGeneralEdit(e, data, setData);
    }


    const LoginFromStaff = async (staff_id) => {
        props.setOnJobApplicantData([])
        props.setOnAdmin([]);
        props.setOnPermissions([])
        props.setOnAdminLoginData([]);
        setIsLoggingIn(true)
       
        const dt = {
            StaffID: staff_id,
        }

        try {
            await axios.post(`${serverLink}/enrollment/login_from_staff`, dt).then((res) => {
                if (res.data.message === "success") {
                    window.sessionStorage.setItem("staff", "1")
                    props.setOnJobApplicantData([])
                    props.setOnAdmin([]);
                    props.setOnPermissions(res.data.permissions)
                    props.setOnAdminLoginData(res.data.data);
                    toast.success("login successful, please wait!");
                   

                    setTimeout(() => {
                        window.location.reload();
                    }, 400);
                } else {
                    toast.error("wrong credentials");

                }
                setIsLoggingIn(false)
            })
        } catch (error) {
            console.log(error)
            toast.error("network error, please try again!")
        }
    }

    return  (
        <div className="main-wrapper">

            <div className="preloader" style={{ display: 'none' }}>
                <div className="lds-ripple">
                    <div className="lds-pos" />
                    <div className="lds-pos" />
                </div>
            </div>

            <div className="auth-wrapper d-flex no-block justify-content-center align-items-center"
                style={{ background: ` no-repeat center center` }}>

                <div className="auth-box" style={{ maxWidth: 400 }} >
                    <div id="loginform">
                        <div className="logo">
                            <span className="db"><img src={projectLogo} width="70px" alt="logo" /></span>
                            <h5 className="font-medium m-b-20">Sign In</h5>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <form className="form-horizontal m-t-20" onSubmit={onLogin}>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1"><i className="ti-user" /></span>
                                        </div>
                                        <input type="text" className="form-control form-control-lg" onChange={onEdit} id="StaffID" placeholder="StaffID" aria-label="StaffID" required />
                                    </div>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon2"><i className="ti-pencil" /></span>
                                        </div>
                                        <input type="password" className="form-control form-control-lg" id="Password" onChange={onEdit} placeholder="Password" aria-label="Password" required />
                                    </div>
                                    <br />
                                    <div className="form-group text-center">
                                        <div className="col-xs-12 p-b-20">
                                            <button disabled={isloggingIn} className="btn btn-block btn-lg btn-main" type="submit">
                                                {isloggingIn ? <span><i className='fa fa-spin fa-spinner' /> Please wait... </span> : "Log In"}
                                            </button>
                                        </div>
                                    </div>

                                    <div className="form-group m-b-0 m-t-10">
                                        <div className="col-sm-12 text-center">
                                            <Link to="/" className="text-main m-l-5">
                                                <b>Back to Home</b></Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        AdminLoginData: state.AdminLoginData
    };
};
const mapDisptachToProps = (dispatch) => {
    return {
        setOnJobApplicantData: (p) => {
            dispatch(setApplicantLoginDetails(p))
        },
        setOnAdminLoginData: (p) => {
            dispatch(setAdminLoginDetails(p))
        },
        setOnAdmin: (p) => {
            dispatch(setAdmin(p))
        },
        setOnPermissions: (p) => {
            dispatch(setPermissions(p))

        }
    }
}

export default connect(mapStateToProps, mapDisptachToProps)(AdminLogin);


