import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { serverLink } from '../../../../../util/url';
import { useFormik } from 'formik';
import { NextOfKinValidationScehma } from '../../../../formik/validationSchema';
import { toast } from 'react-toastify';
import Loader from '../../../../../common/loader';


export default function EnrolmentNextOfKin(props) {
    const [isLoading, setIsLoading] = useState(true)
    const applicant = props.applicant
    const [NextOfKin, setNextOfKin] = useState([]);
    const controller = new AbortController();
    const [showForm, setShowForm] = useState(false)

    const submitNOK = (values, helpers) => {

        if (values.EntryID === "") {
            try {
                axios.post(`${serverLink}/enrollment/add_next_of_kin`, values).then((res) => {
                    if (res.data.message === "success") {
                        //ResetValues();
                        toast.success('bank info successfully added');
                        getData();
                    }
                    else {
                        toast.error("failed to add media, please try again")
                    }
                })
            } catch (e) {
                toast.error("network error!")
            }
        } else {
            try {
                axios.put(`${serverLink}/enrollment/update_next_of_kin/${applicant[0]?.EntryID}`, values).then((res) => {
                    if (res.data.message === "success") {
                        // formik.resetForm();
                        // ResetValues();
                        toast.success('bank info successfully updated');
                        getData();
                    }
                })
            } catch (e) {
                toast.error("network error");
            }
        }
    }

    const getData = async () => {
        const { signal } = controller.signal

        await axios.get(`${serverLink}/enrollment/get_next_of_kin/${applicant[0]?.EntryID}`).then((res) => {
            if (res.data.length > 0) {
                props.setAllowSubmit({
                    ...props.allowSubmit,
                    NextOfKin: res.data
                })
                const vals = res.data[0]
                formik.setValues({
                    ...formik.values,
                    EntryID: vals?.EntryID,
                    ApplicantID: applicant[0]?.EntryID,
                    FirstName: vals?.FirstName,
                    MiddleName: vals?.MiddleName,
                    Surname: vals?.Surname,
                    Relationship: vals?.Relationship,
                    Phone: vals?.Phone,
                    Address: vals?.Address,
                    Email: vals?.Email
                })
                setNextOfKin(res.data)
            }
            setIsLoading(false)
        })
    }

    useEffect(() => {
        getData();

    }, [])


    const formik = useFormik({
        initialValues: {
            EntryID: "",
            ApplicantID: applicant[0]?.EntryID,
            FirstName: "",
            MiddleName: "",
            Surname: "",
            Relationship: "",
            Phone: "",
            Address: "",
            Email: ""
        },
        validationSchema: NextOfKinValidationScehma,
        onSubmit: (values, helpers) => { submitNOK(values, helpers) }
    })

    const ResetValues = () => {
        formik.setValues({
            ...formik.values,
            EntryID: "",
            FirstName: "",
            MiddleName: "",
            Surname: "",
            Relationship: "",
            Phone: "",
            Address: "",
            Email: ""
        });
    }

    const toggleForm = () => {
        if (showForm === true) {
            setShowForm(false)
        } else {
            setShowForm(true)
        }

    }


    return isLoading ? (<Loader />) : (
        <div>

            <div className='row justify-content-between'>
                <div>
                    <h2>Next of Kin</h2>
                    <small>toggle the button to show or hide the form</small>

                </div>
                <div>
                    <button className='btn btn-sm btn-get-started' onClick={toggleForm}>
                        {showForm === true ? NextOfKin.length > 0 ? "Hide Form" : "Show Form" : "Show Form"}
                    </button>
                </div>
            </div>
            <br />
            {
                showForm &&
                <div className='row'>
                    <div className='col-lg-8 offset-lg-2 col-sm-12'>
                        <form className="m-t-30 needs-validation" onSubmit={formik.handleSubmit} noValidate >

                            <div className="form-group">
                                <label htmlFor="FirstName">First Name</label>
                                <input type="text" id="FirstName" name="FirstName" placeholder="First Name" onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.FirstName}
                                    className={formik.errors.FirstName && formik.touched.FirstName ? 'form-control is-invalid' : 'form-control'} required
                                />
                                {formik.errors.FirstName && formik.touched.FirstName &&
                                    <div className='invalid-feedback'>{formik.errors.FirstName}
                                    </div>
                                }
                            </div>

                            <div className="form-group">
                                <label htmlFor="MiddleName">Middle Name</label>
                                <input type="text" id="MiddleName" name="MiddleName" placeholder="Middle Name" onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.MiddleName}
                                    className={formik.errors.MiddleName && formik.touched.MiddleName ? 'form-control is-invalid' : 'form-control'} required
                                />
                                {formik.errors.MiddleName && formik.touched.MiddleName &&
                                    <div className='invalid-feedback'>{formik.errors.MiddleName}
                                    </div>
                                }
                            </div>

                            <div className="form-group">
                                <label htmlFor="Surname">Surname</label>
                                <input type="text" id="Surname" name="Surname" placeholder="Surname" onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.Surname}
                                    className={formik.errors.Surname && formik.touched.Surname ? 'form-control is-invalid' : 'form-control'} required
                                />
                                {formik.errors.Surname && formik.touched.Surname &&
                                    <div className='invalid-feedback'>{formik.errors.Surname}
                                    </div>
                                }
                            </div>

                            <div className="form-group">
                                <label htmlFor="Email">Email (optional)</label>
                                <input type="text" id="Email" name="Email" placeholder="Email" onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.Email}
                                    className={formik.errors.Email && formik.touched.Email ? 'form-control is-invalid' : 'form-control'} 
                                    />
                                {formik.errors.Email && formik.touched.Email &&
                                    <div className='invalid-feedback'>{formik.errors.Email}
                                    </div>
                                }
                            </div>

                            <div className="form-group">
                                <label htmlFor="Phone">Phone</label>
                                <input type="text" id="Phone" name="Phone" placeholder="Phone" onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.Phone}
                                    className={formik.errors.Phone && formik.touched.Phone ? 'form-control is-invalid' : 'form-control'} required
                                />
                                {formik.errors.Phone && formik.touched.Phone &&
                                    <div className='invalid-feedback'>{formik.errors.Phone}
                                    </div>
                                }
                            </div>

                            <div className="form-group">
                                <label htmlFor="Relationship">Relationship</label>
                                <select id="Relationship" name="Relationship"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.Relationship}
                                    className={formik.errors.Relationship && formik.touched.Relationship ? 'form-control is-invalid' : 'form-control'} required >
                                    <option value={""}>--select--</option>
                                    <option value={"Father"}>Father</option>
                                    <option value={"Mother"}>Mother</option>
                                    <option value={"Brother"}>Brother</option>
                                    <option value={"Sister"}>Sister</option>
                                    <option value={"Uncle"}>Uncle</option>
                                    <option value={"Aunty"}>Aunty</option>
                                    <option value={"Son"}>Son</option>
                                    <option value={"Daughter"}>Daughter</option>
                                </select>
                                {formik.errors.Relationship && formik.touched.Relationship &&
                                    <div className='invalid-feedback'>{formik.errors.Relationship}
                                    </div>
                                }
                            </div>

                            <div className="form-group">
                                <label htmlFor="Address">Address</label>
                                <textarea rows={5} type="text" id="Address" name="Address" placeholder="Address" onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.Address}
                                    className={formik.errors.Address && formik.touched.Address ? 'form-control is-invalid' : 'form-control'} required
                                ></textarea>
                                {formik.errors.Address && formik.touched.Address &&
                                    <div className='invalid-feedback'>{formik.errors.Address}
                                    </div>
                                }
                            </div>

                            <button type="submit" disabled={formik.isValid === true ? false : true} className="btn btn-get-started w-100">Submit</button>
                        </form>
                    </div>
                </div>
            }

            <br />
            <div className='table-responsive'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>SN</th>
                            <th>FirstName</th>
                            <th>MiddleName </th>
                            <th>Surname </th>
                            <th>Relationship </th>
                            <th>Email </th>
                            <th>Phone</th>
                            <th>Address</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            NextOfKin.length > 0 ?
                                NextOfKin.map((x, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{x.FirstName}</td>
                                            <td>{x.MiddleName}</td>
                                            <td>{x.Surname}</td>
                                            <td>{x.Relationship}</td>
                                            <td>{x.Email}</td>
                                            <td>{x.Phone}</td>
                                            <td> {x.Address} </td>
                                            <td>
                                                <button className='btn btn-sm btn-info' onClick={() => {
                                                    setShowForm(true)
                                                }}>
                                                    <i className='ti-file' />
                                                    Update
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <tr >
                                    <td colSpan={5} >
                                        No Data
                                    </td>
                                </tr>

                        }
                    </tbody>

                </table>
            </div>
        </div>
    )
}
