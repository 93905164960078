import React from 'react'
import OfferBG from './cu_appointment_bg.jpg'
import './cu-offer-letter.css'
import { convertNumbertoWords, currencyConverter, currentDate, formatDateAndTime } from '../../../../../util/constants';


function CUOfferLetter(props) {
    const dt = props.printData;

    const amount = dt?.GrossPay !== undefined ? dt?.GrossPay : 0
    return (
        <>
            <div ref={props.componentRef} >
                <div className="row" style={{
                    backgroundImage: `url(${OfferBG})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundColor: 'transparent !important',
                    paddingBottom: '64px',
                }}
                >
                    <div className="contain">
                        <div className="row">
                            <div className="col-md-12">
                                <div className='d-flex justify-content-between'>
                                    <h4 className="font-bold">{formatDateAndTime(currentDate, "date")}</h4>
                                    <h4 className="font-bold">Ref: Admin003</h4>
                                </div>
                                <div className="pull-left">
                                    <address>
                                        <h4 className="font-bold">&nbsp;</h4>
                                        <p className="text-black font-bold m-l-5 medium_text">
                                            APP/{dt.ApplicationID}
                                            <br /> {dt.Address},
                                            <br /> Talaja Road,
                                            <br /> {dt.LGA} - {dt.StateOfOrigin}</p>
                                    </address>
                                    <h4 className="dear_name">Dear {dt.FirstName[0].toUpperCase() + dt.FirstName.slice(1).toLowerCase()},</h4>
                                </div>
                            </div>
                            <div className="col-md-12 mt-5">
                                <h4 className="font-bold offer_title">
                                    Offer of Position of {dt.PositionOffered}
                                </h4>
                            </div>
                            <div className="col-md-12">
                                <div className="table-responsive m-t-40" style={{
                                    clear: 'both',
                                    backgroundColor: "transparent !important",
                                    marginBottom: "20px"
                                }}>
                                    <table className="table table-borderless">
                                        <tbody>
                                            <tr>
                                                <td className="text-justify">
                                                    <>
                                                        <ol className='offer_content'>
                                                            <p>Further to your interaction with the Board of Directors, I am able to revert to you as  follows:</p>
                                                            <li>We are pleased to offer you the position of {dt.PositionOffered} at  Cosmopolitan University for an initial term of 1 year renewable, subject to mutual agreement. (Please note you will be on probation for the first 6  months).</li>
                                                            <li>
                                                                Your working hours will be from  9 AM to 6 PM Monday to Friday, you may be asked to work outside of office hours to meet operational demands.
                                                            </li>
                                                            <li>
                                                                Your remuneration will be {currencyConverter(amount)} ({convertNumbertoWords(amount)})
                                                                per  month subject to tax and other statutory deductions, and you will be entitled to the following benefits;
                                                                <ol type='a'>
                                                                    <li>&emsp;1-hour lunch each day to be taken between 12 noon and 1 PM</li>
                                                                    <li>&emsp;21 days Annual leave per year.</li>
                                                                </ol>
                                                            </li>
                                                            <li>
                                                                Other terms and conditions, as contained in Staff Handbook, are issued from time to time.
                                                            </li>
                                                            <li>
                                                                Your start date will be {formatDateAndTime(dt.AppointmentDate, "date")}, please indicate your acceptance of this offer by signing a copy of this letter and returning it to The Registrar, Cosmopolitan University.
                                                            </li>
                                                            <p><br />
                                                                We look forward to having you on board.
                                                            </p>
                                                        </ol>
                                                    </>
                                                    <p className='offer_registrar'>
                                                        Ibrahim Mani Ahmad PhD<br />
                                                        Registrar
                                                    </p>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row" style={{
                    backgroundImage: `url(${OfferBG})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundColor: 'transparent !important',
                    marginTop: "60px",
                    marginBottom: "60px",
                    paddingBottom: "120px",
                }}
                >
                    <div className="contain">
                        <div className="row">
                            <div className="col-md-12">
                                <h4 className="font-bold acceptance_text">ACCEPTANCE</h4>
                                <p style={{ fontSize: "30px" }}>
                                    I hereby accept the offer of the post of {dt.PositionOffered} as stipulated in this letter.
                                </p>
                                <br /><br /><br />
                            </div>
                            <div className="col-md-12">
                                <div className="m-t-40 " style={{
                                    clear: 'both',
                                    minHeight: '950px',
                                    fontSize: "30px",
                                    backgroundColor: "transparent !important",
                                }}>
                                    <p>
                                        Signed:

                                        <br /><br /><br />
                                        Name:

                                        <br /><br /><br />
                                        Date:
                                        <br /><br /><br />
                                    </p>
                                    <br /><br /><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CUOfferLetter;