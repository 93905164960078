import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import Loader from '../../../../../common/loader';
import { projectLogo, schoolName, shortCode } from '../../../../../util/constants'
import { serverLink } from '../../../../../util/url';
import JoditEditor from "jodit-react";

export default function EnrolmentHome(props) {

    const applicant = props.applicant;
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState({
        ApplicantID: applicant[0]?.EntryID,
        Title: "",
        Email: props.applicant[0]?.Email,
        Telephone: props.applicant[0]?.Phone,
        Image: `${serverLink}/public/uploads/${shortCode}/staff_enrollment/passports/${props.applicant[0]?.Passport}`,
        RoleTitle: props.AppliedJobs[0]?.Position,
        Biography: "",
        Research: "",
        BloodGroup: "",
        ImageFile: ""
    })
    const [disableUpload, setDisableUpload] = useState(true)
    const controller = new AbortController();
    const [Profile, setProfile] = useState([])

    const getData = async () => {
        const { signal } = controller.signal
        try {
            axios.get(`${serverLink}/enrollment/get_staff_profile/${props.applicant[0]?.EntryID}`).then((res) => {
                if (res.data.length > 0) {
                    const val = res.data[0];
                    props.setAllowSubmit({
                        ...props.allowSubmit,
                        SocialMedia: res.data
                    })
                    setData({
                        ...data,
                        Title: val?.Title,
                        Email: val?.Email,
                        Telephone: val?.Telephone,
                        Image: `${serverLink}/public/uploads/${shortCode}/hr/document/${val?.Image}`,
                        RoleTitle: val?.RoleTitle,
                        Biography: val?.Biography,
                        Research: val?.Research,
                        BloodGroup: val?.BloodGroup
                    })
                    setProfile(res.data)
                }
                setIsLoading(false)
            })
        } catch (e) {

        }
    }

    useEffect(() => {
        getData();


    }, [])
    const submitEmpProfile = (e) => {
        e.preventDefault();

        // if (data.Biography === "") {
        //     toast.error("please enter Biography");
        //     return false
        // }

        try {
            axios.post(`${serverLink}/enrollment/add_staff_profile`, data).then((res) => {
                if (res.data.message === "success") {
                    toast.success('profile data updated');
                    getData();
                } else {
                    toast.error("please try again")
                }
            })
        } catch (e) {
            toast.error("network error!")
        }
    }

    const onEdit = (e) => {
        setData({
            ...data,
            [e.target.id]: e.target.value
        })
    }

    const onImageChange = (e) => {
        if (e.target.files[0].size > 1000000) {
            setDisableUpload(true)
            toast.error("File size must not be greater than 1MB");
            return false;
        }
        else {
            if (e.target.files[0].type.includes("image")) {
                setDisableUpload(false)
                setData({
                    ...data,
                    [e.target.id]: e.target.files[0],
                    //Passport: URL.createObjectURL(e.target.files[0])
                })
            } else {
                setDisableUpload(true)
                toast.error("Only Image file is allowed");
            }
        }
    }

    const onImageUpload = async () => {
        toast.info("please wait... ")

        const dt = new FormData();
        dt.append("photo", data.ImageFile)
        try {

            axios.put(`${serverLink}/enrollment/update_passport/${props.applicant[0]?.Email}/${props.applicant[0]?.EntryID}`, dt).then((data) => {
                if (data.data.message === "success") {
                    toast.success('passport updated succesfully');
                    setDisableUpload(true)
                    getData();

                } else {
                    toast.error('error updating, please, try again', 'error')
                }
            })
        } catch (e) {
            toast.error("network error")
        }

    }


    return isLoading ? (<Loader />) : (
        <div >
            <div className='text-center'>
                <img src={projectLogo} width="90px" />
                <hr />
                <h1>Congratulations!</h1>
                <p>
                    We are happy that you've secured a position as <strong>{props.AppliedJobs[0]?.Position}</strong> at {schoolName}, you are on this page because you have decided to accept the offer.
                </p>
            </div>
            <div>
                <strong>Please read carefully!</strong>
                <ol>
                    <li>Carefully review your profile below to ensure everything is right, if not kindly navigate <Link to="/applicant/dashboard" >back to your applicant portal</Link> and update your profile.</li>
                    <li>
                        Begin by reviewing your profile below and completing other information.
                    </li>
                    <li>
                        Use the image upload field below to update your passport.
                    </li>
                    <li>
                        Use the left tabs to navigate the sections and fill the enrolment forms.
                    </li>
                    <li>
                        Fill in appropriate data, as this data would be use to create your staff profile, generate your Staff ID Card and biometrics.
                    </li>
                    <li className='text-danger'>
                        Ensure to submit the form finally after filling other tabs, to do that click on the Final submission tab.
                    </li>
                    <li className='text-danger'>
                        The Personal details form below, Qualifications and Next of Kin must be filled to make final submission
                    </li>
                </ol>
            </div>
            <br />
            <h4>Personal Details</h4>
            <hr />
            <div className='card'>
                <div className="row">

                    <div className='col-md-9'>
                        <div className='row'>
                            <div className="col-md-4 col-xs-6 b-r">
                                <strong>First Name</strong>
                                <p className="text-monospace">
                                    <div className='font-18'>
                                        {props.applicant[0]?.FirstName}
                                    </div>
                                </p>
                                <p>
                                    <strong> Middle Name:</strong>
                                    <div className='font-18'>
                                        {props.applicant[0]?.MiddleName}
                                    </div>
                                </p>
                                <p>
                                    <strong> Surname:</strong>
                                    <div className='font-18'>
                                        {props.applicant[0]?.Surname}
                                    </div>
                                </p>

                            </div>
                            <div className="col-md-4 col-xs-6 b-r"> <strong>Mobile</strong>
                                <br />
                                <p className="text-monospace font-18">{props.applicant[0]?.Phone}</p>
                                <br />
                                <strong>Email</strong>
                                <p className="text-monospace font-18">{props.applicant[0]?.Email}</p>
                            </div>
                            <div className="col-md-4 col-xs-6"> <strong>Location</strong>
                                <br />
                                <p className="text-monospace font-18">{props.applicant[0]?.Address}</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="d-flex justify-content-end m-t-30">
                            <img src={data.Image} className="rounded-circle" width={150} />
                            <br />
                        </div>
                    </div>
                </div>
            </div>

            <div className='card'>
                <h4>Offer Details</h4>
                <hr />
                <div className='row'>
                    <div className="col-md-3 col-xs-6 b-r"> <strong>Position</strong>
                        <br />
                        <p className="text-monospace font-18"> {props.AppliedJobs[0]?.Position} </p>
                    </div>
                    <div className="col-md-3 col-xs-6 b-r"> <strong>Department</strong>
                        <br />
                        <p className="text-monospace font-18">{props.AppliedJobs[0]?.Department}</p>
                    </div>
                    <div className="col-md-3 col-xs-6 b-r"> <strong>Faculty</strong>
                        <br />
                        <p className="text-monospace font-18">{props.AppliedJobs[0]?.Faculty}</p>
                    </div>
                </div>
            </div>

            <div className='card'>
                <h4>Update Profile</h4>
                <hr />
                <div className='row'>
                    <form className='col-lg-8 offset-lg-2 col-sm-12 col-md-12' onSubmit={submitEmpProfile}>
                        <div className='form-group text-center'>
                            <label htmlFor="">Update Passport (<small className='text-danger' >Max File Size: 1MB</small>)</label>
                            {/* <SimpleFileUpload
                                apiKey={simpleFileUploadAPIKey}
                                tag={simpleFileUploadFolderName + `-applicants-document`}
                                onSuccess={onImageChange}
                                maxFileSize={1}
                                preview={"false"}
                                width={"100%"}
                                height={"100"}
                            /> */}

                            <input type='file' className='form-control' id="ImageFile" onChange={onImageChange} />
                            <button className='btn btn-md btn-success mt-2 ' disabled={disableUpload} type='button' onClick={onImageUpload}>
                                Update Passport
                            </button>
                        </div>
                        {/* <div className="form-group">
                            <label htmlFor="Title">Title</label>
                            <input type="text" id="Title" name="Title" placeholder="Title" onChange={onEdit}
                                value={data.Title} className={'form-control'} required />
                        </div> */}

                        <div className="form-group">
                            <label htmlFor="Email">Email</label>
                            <input type="email" id="Email" disabled name="Email" placeholder="Email" onChange={onEdit}
                                value={data.Email} className={'form-control'} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="Phone">Phone</label>
                            <input type="text" id="Telephone" name="Telephone" placeholder="Phone" onChange={onEdit}
                                value={data.Telephone} className={'form-control'} required />
                        </div>

                        <div className="form-group">
                            <label htmlFor="BloodGroup">Blood Group</label>
                            {/*  */}
                            <select id="BloodGroup" name="BloodGroup" placeholder="BloodGroup" onChange={onEdit}
                                value={data.BloodGroup} className={'form-control'} required  >
                                <option value={""}>-select blood group-</option>
                                <option value={"A+"}>A+</option>
                                <option value={"O+"}>O+</option>
                                <option value={"B+"}>B+</option>
                                <option value={"AB+"}>AB+</option>
                                <option value={"AB+"}>AB+</option>
                                <option value={"A-"}>A-</option>
                                <option value={"O-"}>O-</option>
                                <option value={"B-"}>B-</option>
                                <option value={"AB-"}>AB-</option>
                            </select>
                        </div>


                        <div className='form-group'>
                            <label htmlFor="Biography" >Biography (optional)</label>
                            <JoditEditor
                                id="Biography"
                                value={data.Biography}
                                tabIndex={1}
                                onChange={(e) => {
                                    setData({
                                        ...data, Biography: e
                                    })
                                }}
                            />
                        </div>
                        <div className='form-group'>
                            <label htmlFor="Research" >Research (optional)</label>
                            <JoditEditor
                                id="Research"
                                value={data.Research}
                                tabIndex={1}
                                onChange={(e) => {
                                    setData({
                                        ...data, Research: e
                                    })
                                }}
                            />
                        </div>
                        <div className='form-group'>
                            <button className='btn btn-md btn-get-started w-100' type='submit' >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>


        </div>
    )
}
