import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { setAdminJobApplications, setHrdesignations, setJobListAction } from '../../../../actions/actions';
import { CheckPermission, formatDate, formatDateAndTime, onGeneralEdit } from '../../../../util/constants';
import { serverLink } from '../../../../util/url';
import Modal from '../../../common/modal';
import PageTitle from '../../../common/pagetitle';
import ReportTable from '../../../common/report_table';
import { AddEditJobsValidationScehma } from '../../../formik/validationSchema';
import JoditEditor from "jodit-react";


function ManagePositions(props) {
    const allow = props.permissionsList.length > 0 ? props.permissionsList.filter(x => x.Designation === 'superadmin' || x.Designation === 'hr').length > 0 ? true : false : false;
    CheckPermission(allow);
    const staff = props.AdminLoginData
    const controller = new AbortController();
    const columns = ["SN", "DesignationID", "DesignationName", "Action"];
    const [tableData, setTableData] = useState([]);
    const [isLoading, setisLoading] = useState(true)
    const [hrDesignations, sethrDesignations] = useState(props.hrDesignations.length > 0 ? props.hrDesignations : [])

    const [formData, setFormdata] = useState({
        EntryID: "",
        DesignationName: "",
        InsertedBy: staff[0]?.StaffID
    })
    const getData = async () => {
        try {
            await axios.get(`${serverLink}/enrollment/hrdesignations`).then((res) => {
                if (res.data.length > 0) {
                    sethrDesignations(res.data)
                    props.setOnHrDesignations(res.data)
                }
            })

            let rows = []
            hrDesignations.map((x, i) => {
                rows.push([
                    i + 1,
                    x.EntryID,
                    x.DesignationName,
                    <button className='btn btn-sm btn-main' data-toggle="modal" data-target="#job-modal"
                        onClick={() => {
                            setFormdata({
                                ...formData,
                                EntryID: x.EntryID,
                                DesignationName: x.DesignationName,
                            })
                        }} >
                        <i className='ti ti-pencil' /> &nbsp;
                        Edit

                    </button>
                ])
            })
            setTableData(rows)

            setisLoading(false)

        } catch (e) {
            console.log(e)
            toast.error("netwrok error")
        }
    }

    useEffect(() => {
        getData();
        return () => { controller.abort() }
    }, [])

    const onSubmitJob = async (e) => {
        e.preventDefault();

        try {
            if (formData.EntryID === "") {
                await axios.post(`${serverLink}/job_applications/add_position`, formData).then((res) => {
                    if (res.data.message === "success") {
                        ResetValues();
                        getData();
                        document.getElementById("modal-close").click();
                        toast.success("designation added")
                    } else if (res.data.message === "exists") {
                        toast.error("designation already exists")
                    } else {
                        toast.error("network error!")
                    }
                })
            } else {
                await axios.patch(`${serverLink}/job_applications/update_position`, formData).then((res) => {
                    if (res.data.message === "success") {
                        ResetValues();
                        getData();
                        document.getElementById("modal-close").click();
                        toast.success("designation updated")
                    }
                    else if (res.data.message === "exists") {
                        toast.error("designation already exists")
                    } else {
                        toast.error("network error!")
                    }
                })
            }

        } catch (e) {
            toast.error("network error!")
        }
    }

    const onEdit = (e) => {
        setFormdata({
            ...formData,
            [e.target.id]: e.target.value
        })
    }

    const ResetValues = () => {
        setFormdata({
            ...formData,
            EntryID: "",
            DesignationName: "",
            InsertedBy: staff[0]?.StaffID
        });
    }

    return (
        <div>
            <PageTitle title={["Jobs", "Jobs", "Manage Positions"]} />
            <div className="card mt-3">
                <div className="card-body">
                    <div className='d-flex justify-content-between mb-4'>
                        <h4 className="card-title">Manage Positions</h4>
                        <button className='btn btn-sm btn-main' data-toggle="modal" data-target="#job-modal" onClick={ResetValues} >
                            Add New Position
                        </button>
                    </div>
                    <div className="table-responsive">
                        <ReportTable data={tableData} columns={columns} height={"700px"} />

                    </div>

                    <Modal title="Manage Positions" id="job-modal" close="modal-close">
                        <div className='row'>
                            <div className='col-md-12'>
                                <form className="m-t-30 needs-validation" onSubmit={onSubmitJob} >
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className="form-group">
                                                <label htmlFor="DesignationName">Designation Name</label>
                                                <input
                                                    id="DesignationName"
                                                    name="DesignationName"
                                                    onChange={onEdit}
                                                    value={formData.DesignationName}
                                                    className={'form-control'} required
                                                />

                                            </div>
                                        </div>

                                    </div>

                                    {/* disabled={isValid === true ? false : true} */}
                                    <button type="submit" className="btn btn-get-started w-100">Submit</button>
                                </form>
                            </div>
                        </div>

                    </Modal>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        AdminLoginData: state.AdminLoginData,
        hrDesignations: state.hrDesignations,
        permissionsList: state.permissionsList
    };
};
const mapDisptachToProps = (dispatch) => {
    return {
        setOnHrDesignations: (p) => {
            dispatch(setHrdesignations(p))
        },

    }
}

export default connect(mapStateToProps, mapDisptachToProps)(ManagePositions);

