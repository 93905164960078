import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link, useLocation, useParams } from "react-router-dom";
import Breadcrum from '../../../../common/breadcrum/breadcrum';
import ReportTable from '../../../common/report_table';
import { serverLink } from '../../../../util/url';
import Loader from '../../../../common/loader';
import { setJobTypeApplicantsList } from '../../../../actions/actions';
import { setNestedObjectValues } from 'formik';
import { CheckPermission } from '../../../../util/constants';

function ApplicationCounts(props) {
    const allow = props.permissionsList.length > 0 ? props.permissionsList.filter(x => x.Designation === 'superadmin' || x.Designation === 'hr').length > 0 ? true : false : false;
    CheckPermission(allow);
    
    const location = useLocation();

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [generalDatatable, setGeneralDatatable] = useState([]);
    const columns = ["S/N", "Job ID", "Position", "Job Type", "Department", "Applicants", "Action"];
    const [AppStatus, setAppStatus] = useState(0);

    const getData = async () => {
        setIsLoading(true);

        const _status = location.pathname.includes("pending") ? "0" : location.pathname.includes("invited") ? "1" :
            location.pathname.includes("accepted") ? "2" : location.pathname.includes("rejected") ? "3" : "4";
        setAppStatus(_status)

        await axios.get(`${serverLink}/job_applications/applicants-counts`).then((res) => {
            const data = res.data;
            let rows = [];
            if (data.length > 0) {
                let dt = data.filter(x => x.ApplicationStatus === _status)
                setData(dt);
                dt.map((item, index) => {
                    rows.push([
                        index + 1,
                        item.RequisitionID,
                        item.Position,
                        item.JobType,
                        item.Department,
                        item.Applicants,
                        (
                            <>
                                <Link to={`/jobs/applicants/${item.RequisitionID}/${item.ApplicationStatus}`}
                                    className="btn btn-sm btn-main"
                                >
                                    <i className="fa fa-eye" />
                                </Link>

                            </>
                        ),
                    ]);
                });
            }
            setGeneralDatatable(rows);
            setIsLoading(false);
        })
            .catch((err) => {
                setIsLoading(false);
                toast.error("NETWORK ERROR. Please try again!");
            });
    }

    useEffect(() => {

        getData();
    }, [location.pathname]);

    return (
        isLoading ? <Loader />
            :
            <div>
                <div className="container-fluid" style={{ marginLeft: '-10px' }}>
                    <Breadcrum title={location.pathname.includes("pending") ? "Pending Applications" : location.pathname.includes("invited") ? "Invited Applications" :
                        location.pathname.includes("accepted") ? "Accepted Applications" : location.pathname.includes("accepted") ? "Rejected Applications" : "Cancelled Applications"} />
                    <div className="row mt-3">
                        <div className="col-12">
                            <ReportTable title={generalDatatable.length + " Records"} columns={columns} data={generalDatatable} height="700px" />
                        </div>
                    </div>
                </div>
            </div>
    );
}

const mapStateToProps = (state) => {
    return {
        AdminLoginData: state.AdminLoginData,
        permissionsList: state.permissionsList
    };
};



export default connect(mapStateToProps, null)(ApplicationCounts);


