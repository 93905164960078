import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { serverLink } from '../../../../../util/url';
import { useFormik } from 'formik';
import { QualificationsValidationScehma } from '../../../../formik/validationSchema';
import { toast } from 'react-toastify';
import Loader from '../../../../../common/loader';
import { schoolName } from '../../../../../util/constants';


export default function EnrolmentQualifications(props) {
    const [isLoading, setIsLoading] = useState(true)
    const applicant = props.applicant
    const [HrQualifications, setHrQualifications] = useState([])
    const [Qualifications, setQualifications] = useState([]);
    const controller = new AbortController();
    const [showForm, setShowForm] = useState(false)


    const getData = async () => {
        const { signal } = controller.signal
        await axios.get(`${serverLink}/job_applications/hr_qualification`, { signal }).then((res) => {
            if (res.data.length > 0) {
                setHrQualifications(res.data)
            }
        })

        await axios.get(`${serverLink}/enrollment/get_qualification/${applicant[0]?.EntryID}`, { signal }).then((res) => {
            if (res.data.length > 0) {
                setQualifications(res.data)
                props.setAllowSubmit({
                    ...props.allowSubmit,
                    Qualifications: res.data
                })
            }
            setIsLoading(false)
        }).catch((e) => {
            console.log(e)
        })
    }
    const Years = []
    for (let i = 1900; i <= new Date().getFullYear(); i++) {
        Years.push(i);
    }

    useEffect(() => {
        getData();

        return () => {
            controller.abort();
        }
    }, [])

    const RemoveQualification = async (e) => {
        try {
            await axios.delete(`${serverLink}/enrollment/remove_qualification/${e}`).then((res) => {
                if (res.data.message === "success") {
                    getData();
                    toast.success('Record removed')
                } else {
                    toast.error('Error removing record')
                }
            })
        } catch (e) {
            toast.error("network error!")
        }
    }

    const submitQualification = (values, helpers) => {
        if (values.EntryID === "") {
            try {
                axios.post(`${serverLink}/enrollment/add_qualification`, values).then((res) => {
                    if (res.data.message === "success") {
                        formik.resetForm();
                        ResetValues();
                        toast.success('qualification successfully Added');
                        getData();
                    } else {
                        toast.error("failed to add qualification, please try again")
                    }
                })
            } catch (e) {
                toast.error("network error!")
            }
        }
    }
    const formik = useFormik({
        initialValues: {
            EntryID: "",
            ApplicantID: applicant[0]?.EntryID,
            QualificationID: "",
            Discipline: "",
            InstitutionName: "",
            Year: "",
        },
        validationSchema: QualificationsValidationScehma,
        onSubmit: (values, helpers) => { submitQualification(values, helpers) }
    })

    const ResetValues = () => {
        formik.setValues({
            ...formik.values,
            EntryID: "",
            QualificationID: "",
            Discipline: "",
            InstitutionName: "",
            Year: "",
        });
    }

    const toggleForm = () => {
        if (showForm === true) {
            setShowForm(false)
        } else {
            setShowForm(true)
        }

    }


    return isLoading ? (<Loader />) : (
        <div>

            <div className='row justify-content-between'>
                <div>
                    <h2>Qualifications</h2>
                    <small>toggle the button to show or hide the form</small>
                </div>
                <div>
                    <button className='btn btn-sm btn-get-started' onClick={toggleForm}>
                        {showForm === true ? "Hide Form" : "Show Form"}
                    </button>
                </div>
            </div>
            <br />
            {
                showForm &&
                <div className='row'>
                    <div className='col-lg-8 offset-lg-2 col-sm-12'>
                        <form className="m-t-30 needs-validation" onSubmit={formik.handleSubmit} noValidate >
                            <div className="form-group">
                                <label htmlFor="QualificationID">Qualification</label>
                                <select id="QualificationID" name="QualificationID"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.QualificationID.toString()}
                                    className={formik.errors.QualificationID && formik.touched.QualificationID ? 'form-control is-invalid' : 'form-control'} required >
                                    <option value={""}>--select--</option>
                                    {
                                        HrQualifications.length > 0 &&
                                        HrQualifications.sort().map((x, i) => {
                                            return (
                                                <option key={i} value={x.QualificationID.toString()}>{x.QualificationTitle}</option>
                                            )
                                        })
                                    }


                                </select>
                                {formik.errors.QualificationID && formik.touched.QualificationID &&
                                    <div className='invalid-feedback'>{formik.errors.QualificationID}
                                    </div>
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="Discipline">Discipline</label>
                                <input type="text" id="Discipline" name="Discipline" placeholder="e.g Economics" onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.Discipline.toString()}
                                    className={formik.errors.Discipline && formik.touched.Discipline ? 'form-control is-invalid' : 'form-control'} required
                                />
                                {formik.errors.Discipline && formik.touched.Discipline &&
                                    <div className='invalid-feedback'>{formik.errors.Discipline}
                                    </div>
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="InstitutionName">Institution Name</label>
                                <input type="text" id="InstitutionName" name="InstitutionName" placeholder={`e.g ${schoolName}`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.InstitutionName.toString()}
                                    className={formik.errors.InstitutionName && formik.touched.InstitutionName ? 'form-control is-invalid' : 'form-control'} required
                                />
                                {formik.errors.InstitutionName && formik.touched.InstitutionName &&
                                    <div className='invalid-feedback'>{formik.errors.InstitutionName}
                                    </div>
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="Year">Year</label>

                                <select id="Year" name="Year"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.Year.toString()}
                                    className={formik.errors.Year && formik.touched.Year ? 'form-control is-invalid' : 'form-control'} required >
                                    <option value={""}>--select--</option>
                                    {
                                        Years.length > 0 &&
                                        Years.sort().map((x, i) => {
                                            return (
                                                <option key={i} value={x}>{x}</option>
                                            )
                                        })
                                    }


                                </select>

                                {formik.errors.Year && formik.touched.Year &&
                                    <div className='invalid-feedback'>{formik.errors.Year}
                                    </div>
                                }
                            </div>

                            <button type="submit" disabled={formik.isValid === true ? false : true} className="btn btn-get-started w-100">Submit</button>
                        </form>
                    </div>
                </div>
            }

            <br />
            <div className='table-responsive'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>SN</th>
                            <th>Title</th>
                            <th>Discipline </th>
                            <th>Institution</th>
                            <th>Year Obtained</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Qualifications.length > 0 ?
                                Qualifications.map((x, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{x.QualificationTitle}</td>
                                            <td>{x.Discipline}</td>
                                            <td>{x.InstitutionName}</td>
                                            <td>{x.Year}</td>
                                            <td>
                                                <button className='btn btn-sm btn-danger' onClick={() => RemoveQualification(x.EntryID)}>
                                                    <i className='ti-trash' />
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <tr >
                                    <td colSpan={5} >
                                        No Data
                                    </td>
                                </tr>

                        }
                    </tbody>

                </table>
            </div>
        </div>
    )
}
