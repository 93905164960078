
import React from 'react'
import { Route, Routes } from 'react-router';
import ApplicantDashboard from '../job-application/pages/applicant/dashboard';
import EnrolDasboard from '../job-application/pages/enrolment/dashboard';
import JobListing from '../job-application/pages/job-board/job-listing';
import JobDetails from '../job-application/pages/job-board/job-details';
import ApplicantLogin from '../job-application/auth/login';
import ApplyJob from '../job-application/pages/apply';
import AdminDashboard from '../admin/pages/dashboard';
import LandingPage from '../job-application/pages/job-board/landing/landing';


const PageRoutes = () => {
    return (
        <div>

            <Routes>
                <Route path='*' element={<LandingPage/>} />
                <Route path='/applicant/dashboard' element={<ApplicantDashboard />} />
                <Route path='/applicant/enrolment/:slug' element={<EnrolDasboard />} />

                <Route path='/jobs' element={<LandingPage />} />
                <Route path='/job/:slug' element={<JobDetails />} />
                <Route path='/apply/:slug' element={<ApplyJob />} />
                {/* <Route path='/admin/dashboard' element={<AdminDashboard/>} /> */}
            </Routes>

        </div>
    )
}

export default PageRoutes;