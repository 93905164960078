import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { setActiveCohort, setAdminJobApplications, setAdminLoginDetails, setApplicantLoginDetails, setDepartmentsList, setFacultyList, setHrdesignations, setJobListAction } from '../../actions/actions';
import { projectLogo, formatDateAndTime, encryptData, decryptData, Staff, formatDate } from '../../util/constants';
import { serverLink } from '../../util/url';
import { toast } from 'react-toastify';

function Header(props) {
    const allow = props.permissionsList.length > 0 ? props.permissionsList.filter(x => x.Designation === 'superadmin' || x.Designation === 'hr').length > 0 ? true : false : false;

    const navigate = useNavigate();
    const [Notifications, setNotification] = useState([])
    const [myPermissions, setMyPermissions] = useState([])
    const [cohortsList, setCohortsList] = useState(props.cohortList.length > 0 ? props.cohortList : [])
    const [isLoading, setIsLoading] = useState(false)
    const active_cohort = props.activeCohort[0];

    const staff = props.AdminLoginData;
    const [formData, setFormData] = useState({
        CohortCode: ""
    })

    const getData = async () => {
        await axios.get(`${serverLink}/enrollment/get_notifications`).then((res) => {
            if (res.data.length > 0) {
                setNotification(res.data)
                props.setOnNotifications(res.data)
            }
        })

        await axios.get(`${serverLink}/job_applications/cohorts-list`).then((res) => {
            if (res.data.length > 0) {
                setCohortsList(res.data);
                const active = res.data.filter(x => x.Status === 1)[0];
                setFormData({
                    ...formData,
                    CohortCode: active?.CohortCode
                })
                props.setOnActiveCohort(res.data.filter(x => x.Status === 1))
            }
        })
    }

    const onChangeCohort = async (e) => {
        const new_cohort = cohortsList.filter(x => x.CohortCode === e.target.value)
        setFormData({
            ...formData,
            CohortCode: e.target.value
        })
        if (new_cohort.length > 0) {
            props.setOnActiveCohort(new_cohort);

            await axios.get(`${serverLink}/job_applications/current_job_openings?cohort=${active_cohort?.CohortCode}`).then((res) => {
                props.setOnJobList(res.data)
                setIsLoading(false)
            })
            toast.info("please wait...")

            navigate('/')

        }
    }

    useEffect(() => {
        getData();
        const my_permissions = props.permissionsList?.filter(x => x.StaffID === staff[0]?.StaffID);
        const _my_perms = my_permissions.length > 0 ? [...new Set(my_permissions?.map((x) => { return x.Designation }))] : []
        setMyPermissions(_my_perms);
    }, [props.cohortList])

    const onLogout = () => {
        props.setOnAdminLoginData([])
        props.setOnJobApplicantData([])
        navigate("/admin/login")
    }
    return (
        <header className="topbar hideSection">
            <nav className="navbar top-navbar navbar-expand-md navbar-dark">
                <div className="navbar-header">
                    {/* This is for the sidebar toggle which is visible on mobile only */}
                    <a className="nav-toggler waves-effect waves-light d-block d-md-none" href="#">
                        <i className="ti-menu ti-close" />
                    </a>
                    <div className="navbar-brand ">
                        <a href="/" className="logo">
                            <b className="logo-icon">
                                <img src={projectLogo} width="50px" style={{ borderRadius: '15px' }} alt="homepage" className="dark-logo" />

                                <img src={projectLogo} alt="homepage" width="50px" style={{ borderRadius: '5px' }} className="light-logo"></img>
                            </b>

                        </a>
                        <span className="sidebartoggler d-none d-md-block" style={{ cursor: 'pointer' }} data-sidebartype="mini-sidebar" id="toggle-side">
                            <i className="mdi mdi-toggle-switch mdi-toggle-switch-off font-20" />
                        </span>
                    </div>
                    <a className="topbartoggler d-block d-md-none waves-effect waves-light" href="#" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="ti-more" />
                    </a>
                </div>
                <div className="navbar-collapse collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav float-left mr-auto">
                        <li className="nav-item search-box">
                            <a className="nav-link waves-effect waves-dark" href="#">
                                <div className="d-flex align-items-center">
                                    <i className="mdi mdi-magnify font-20 mr-1" />
                                    <div className="ml-1 d-none d-sm-block">
                                        <span>Search</span>
                                    </div>
                                </div>
                            </a>
                            <form className="app-search position-absolute">
                                <input type="text" className="form-control" placeholder="Search & enter" />
                                <a className="srh-btn">
                                    <i className="ti-close" />
                                </a>
                            </form>
                        </li>
                    </ul>

                    <ul className="navbar-nav float-left mr-auto">
                        <li className="nav-item search-box">
                            <select className="form-control" placeholder="Search & enter" value={formData.CohortCode} disabled={allow === false} onChange={onChangeCohort}  >
                                <option>--select cohort --</option>
                                {
                                    cohortsList.map((x, i) => {
                                        return (
                                            <option key={i} value={x.CohortCode}>{x.CohortCode + ' -- ' + formatDateAndTime(formatDate(x.Start), "date") + " to " + formatDateAndTime(formatDate(x.End), "date")}</option>
                                        )
                                    })
                                }

                            </select>
                        </li>
                    </ul>

                    <ul className="navbar-nav float-right">
                        {
                            window.sessionStorage.getItem("FromStaffPortal") &&
                            <li className="nav-item border-right">
                                <button className='btn btn-main' onClick={() => {
                                    window.sessionStorage.removeItem("FromStaffPortal")
                                    window.location.href = `${Staff}/human-resources/jobs/openings`
                                }} >
                                    Back To Staff Portal
                                </button>
                            </li>
                        }
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle waves-effect waves-dark pro-pic" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img src={projectLogo} className="rounded-circle" width={40} />
                                <span className="m-l-5 font-medium d-none d-sm-inline-block">{staff[0]?.staff_name} <i className="mdi mdi-chevron-down" /></span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right user-dd animated flipInY">
                                <span className="with-arrow">
                                    <span className="bg-secondary" />
                                </span>
                                <div className="d-flex no-block align-items-center p-15 bg-secondary text-white m-b-10">
                                    <div className="m-l-10">
                                        <h4 className="m-b-0">{staff[0]?.FirstName} {staff[0]?.Surname} </h4>
                                        <p className=" m-b-0">{staff[0]?.Email}</p>
                                        <p className=" m-b-0">{myPermissions?.join(" | ").toString().toUpperCase()}</p>
                                    </div>
                                </div>
                                <div className="profile-dis scrollable">
                                    <Link className="dropdown-item" to={`/settings/my-permissions/${encryptData(props.AdminLoginData[0]?.StaffID)}`} >
                                        <i className="ti-user m-r-5 m-l-5" /> My Permissions</Link>


                                    <div className="dropdown-divider" />
                                    {
                                        props.permissionsList?.filter(x => x.Designation === 'superadmin' || x.Designation === 'hr').length > 0 &&
                                        <Link to="/settings/staff-list" className="dropdown-item" >
                                            <i className="ti-settings m-r-5 m-l-5" /> Permission Settings
                                        </Link>
                                    }

                                    <div className="dropdown-divider" />
                                    <a className="dropdown-item" href="#" onClick={onLogout} >
                                        <i className="fa fa-power-off m-r-5 m-l-5" /> Logout</a>
                                    <div className="dropdown-divider" />
                                </div>

                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    )
}

const mapStateToProps = (state) => {
    return {
        AdminLoginData: state.AdminLoginData,
        Notifications: state.Notifications,
        permissionsList: state.permissionsList,
        activeCohort: state.activeCohort
    };
};

const mapDisptachToProps = (dispatch) => {
    return {
        setOnAdminLoginData: (p) => {
            dispatch(setAdminLoginDetails(p))
        },
        setOnJobApplicantData: (p) => {
            dispatch(setApplicantLoginDetails(p))
        },
        setOnHrDesignations: (p) => {
            dispatch(setHrdesignations(p))
        },
        setOnDepartmentList: (p) => {
            dispatch(setDepartmentsList(p))
        },
        setOnFacultyList: (p) => {
            dispatch(setFacultyList(p))
        },
        setOnActiveCohort: (p) => {
            dispatch(setActiveCohort(p))
        },
        setOnJobList: (p) => {
            dispatch(setJobListAction(p))
        },
    }
}

export default connect(mapStateToProps, mapDisptachToProps)(Header);
