import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link, useLocation } from "react-router-dom";
import Breadcrum from '../../../../common/breadcrum/breadcrum';
import ReportTable from '../../../common/report_table';
import { serverLink } from '../../../../util/url';
import Loader from '../../../../common/loader';
import {CheckPermission, formatDateAndTime} from '../../../../util/constants';
import Select from "react-select";

function JobApplicationRoleReport(props) {
    const allow = props.permissionsList.length > 0 ? props.permissionsList.filter(x => x.Designation === 'superadmin' || x.Designation === 'hr').length > 0 ? true : false : false;
    CheckPermission(allow);

    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [generalDatatable, setGeneralDatatable] = useState([]);
    const [columns, setColumn] = useState(["S/N", "Programmes", "Roles"]);
    const [cohortList, setCohortList] = useState([{label : 'select Cohort', value: ''}]);
    const [selectedCohort, setSelectedCohort] = useState({});

    const getCohorts = async () => {
        await axios.get(`${serverLink}/job_applications/cohort/data`).then((res) => {
            const data = res.data;
            let row = [{label : 'select Cohort', value: ''}];
            if (data.message === "success") {
                let Cohort = data.Cohort;
                Cohort.map((x, i) => {
                    row.push({label : `${x.CohortCode} - ${formatDateAndTime(new Date(x.Start), 'date')} - ${formatDateAndTime(x.End, 'date')}`, value: x.CohortCode})
                })
                setCohortList(row)
            }
            setIsLoading(false);
        })
            .catch((err) => {
                setIsLoading(false);
                toast.error("NETWORK ERROR. Please try again!");
            });
    }

    const getData = async (cohort) => {
        setIsLoading(true);
        await axios.get(`${serverLink}/job_applications/report/application/count/program/${cohort}`).then((res) => {
            const data = res.data;
            let row = []; let rows = []; let cols = ["S/N", "Programmes"];
            if (data.message === "success") {
                let Position = data.Position; let Department = data.Department; let Openings = data.Openings; let Application = data.Application; let Cohort = data.Cohort; let bottom_totals = [];
                Position.map((e, k)=> cols.push(e.Position))
                Department.map((item, index)=> {
                    let total_rows = []; let programme_tt_count = 0;

                    Position.map((x, int)=> {
                        let tt_count = 0;
                        Openings.filter(element=>element.Department === item.Department && element.Position === x.Position).map((e, k)=>{
                           let total =  Application.filter(element=>element.JobID === e.RequisitionID ).length
                            tt_count += total;
                        })
                        total_rows.push(tt_count)
                    })
                    programme_tt_count += total_rows.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                    bottom_totals.push([...total_rows, programme_tt_count])
                    rows.push([
                        index + 1,
                        item.Department,
                        ...[...total_rows, programme_tt_count]
                    ]);
                })
                const columnSums = bottom_totals.reduce((acc, column) => {
                    column.forEach((value, index) => {
                        acc[index] = (acc[index] || 0) + value;
                    });
                    return acc;
                }, []);

                rows.push([
                    "",
                    'TOTAL',
                    ...columnSums
                ]);
            }
            setColumn([...cols, "Total"])
            setGeneralDatatable(rows);
            setIsLoading(false);
        })
            .catch((err) => {
                setIsLoading(false);
                toast.error("NETWORK ERROR. Please try again!");
            });
    }

    const onCohortChange = (e) => {
        if (e.value === ""){
            toast.error("Please select cohort");
            return false
        }
        setSelectedCohort(e)
        getData(e.value);
    }

    useEffect(() => {
        getCohorts();
    }, []);


    return (
        isLoading ? <Loader />
            :
            <div>
                <div className="container-fluid" style={{ marginLeft: '-10px' }}>
                    <Breadcrum title={"Job Application Report (By Role)"} />
                    <div className="row mt-3">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="CohortCode">Select Cohort</label>
                                <Select
                                    name="CohortCode"
                                    value={selectedCohort}
                                    onChange={onCohortChange}
                                    options={cohortList}
                                    placeholder="select Cohort"
                                />

                            </div>
                            <ReportTable title={"Job Application Report"} columns={columns} data={generalDatatable} height="700px" />
                        </div>
                    </div>
                </div>
            </div>
    );
}

const mapStateToProps = (state) => {
    return {
        AdminLoginData: state.AdminLoginData,
        AllJobApplications: state.AllJobApplications,
        permissionsList: state.permissionsList
    };
};



export default connect(mapStateToProps, null)(JobApplicationRoleReport);


