import React, { useEffect } from 'react'
import './style.css'
import { AboutUs, ContactUs, CourseFinder, Fees, ProjectDomain, decryptData, encryptData, formatDateAndTime, googleMapAdress, projectAddress, projectEmail, projectFacebook, projectInstagram, projectLinkedIn, projectLogo, projectPhone, projectTwitter, projectYoutube, schoolName } from '../../../../../util/constants';
import { useState } from 'react';
import { serverLink } from '../../../../../util/url';
import axios from 'axios';
import { toast } from 'react-toastify';
import { setAdminLoginDetails, setApplicantLoginDetails, setAppliedJobs, setJobListAction } from '../../../../../actions/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

function LandingPage(props) {
    const [isLoading, setIsLoading] = useState(true)
    const [JobList, setJobList] = useState(props.JobList.length > 0 ? props.JobList : [])
    const [JobList2, setJobList2] = useState(props.JobList.length > 0 ? props.JobList : [])
    const [refresh, setRefresh] = useState(0)
    const getData = async () => {

        try {
            const {data} = await axios.get(`${serverLink}/job_applications/cohorts-list/active`)
            const active_cohort = data[0];

            await axios.get(`${serverLink}/job_applications/current_job_openings?cohort=${active_cohort?.CohortCode}`).then((res) => {
                setJobList(res.data);
                setJobList2(res.data);
                props.setOnJobList(res.data)
                setRefresh(1)
                setIsLoading(false)
            })
        } catch (e) {
            toast.error("network error, please try again")
        }

    }

    useEffect(() => {
        getData();

    }, [refresh])

    const SearchJob = (e) => {
        let filtered = JobList2.filter(x => x.Position.toLowerCase().includes(e.target.value.toLowerCase()) ||
            x.Description.toLowerCase().includes(e.target.value.toLowerCase()) ||
            x.Faculty.toLowerCase().includes(e.target.value.toLowerCase()) ||
            x.Department.toLowerCase().includes(e.target.value.toLowerCase()));
        setJobList(filtered)

    }

    const onLogout = () => {
        props.setOnAppliedJobs([]);
        props.setOnJobApplicantData([]);
        props.setOnAdminLoginData([])
    }

    const [mobile_nav, setMobileNav] = useState({
        nav: "",
        toggler: "bi mobile-nav-toggle"
    })

    return isLoading ? (<div id="preloader" />) : (
        <div>
            {/* <div id="preloader" /> */}

            <section id="topbar" className="d-flex align-items-center">
                <div className="container d-flex justify-content-center justify-content-md-between">
                    <div className="contact-info d-flex align-items-center">
                        <i className="bi bi-envelope d-flex align-items-center"><a href={`mailto: ${projectEmail}`}>{projectEmail}</a></i> &emsp;|
                        <i className="bi bi-phone d-flex align-items-center ms-4"><span>{projectPhone}</span></i>
                    </div>
                </div>
            </section>
            {/* ======= Header ======= */}

            <header id="header" className="d-flex align-items-center">
                <div className="container d-flex align-items-center justify-content-between">
                    <h1 className="logo">
                        <img src={projectLogo} style={{ width: '80px', height: '70px' }} />
                    </h1>
                    <nav id="navbar" className={`navbar ${mobile_nav.nav}`}>
                        <ul>
                            <li>
                                <a className="nav-link active" href={ProjectDomain}>
                                    Home
                                </a>
                            </li>
                            <li>
                                <a className="nav-link" href={AboutUs}>
                                    About Us
                                </a>
                            </li>
                            <li>
                                <a className="nav-link" href={CourseFinder}>
                                    Course Finder
                                </a>
                            </li>
                            <li>
                                <a className="nav-link" href={Fees}>
                                    Fees
                                </a>
                            </li>
                            <li>
                                <a className="nav-link" href={ProjectDomain}>
                                    Academic Calender
                                </a>
                            </li>
                            <li>
                                <a className="nav-link" href={ContactUs}>
                                    Contact Us
                                </a>
                            </li>
                            <li >
                                {
                                    props.JobApplicant.length > 0 ?
                                        <Link to='/' onClick={onLogout} className="btn-get-started text-white p-2" href="#hero">Logout</Link>
                                        :
                                        <Link to='/applicant/login' className="btn-get-started text-white p-2" href="#hero">Login</Link>
                                }
                            </li>

                        </ul>
                        <i className={`${mobile_nav.toggler}`} style={{ color: "black" }}
                        >
                            {
                                props.JobApplicant.length > 0 ?
                                    <Link to='/' onClick={onLogout} className="btn-get-started text-white p-3" href="#hero">Logout</Link>
                                    :
                                    <Link to='/applicant/login' className="btn-get-started text-white p-3" href="#hero">Login</Link>
                            }
                        </i>

                    </nav>
                    {/* .navbar */}
                </div>
            </header>


            {/* ======= Hero Section ======= */}
            {/* <section id="hero" className="d-flex justify-content-start align-items-end">
                <div className="container" data-aos="zoom-out" data-aos-delay={100}>
                    <div style={{ backgroundColor: "white", borderRadius: '10px', padding: '10px', opacity: '0.9', zIndex: '1' }}>
                        <h1> <span>Welcome to {schoolName}</span></h1>
                        <h2>
                            <span  >
                                Build your career with us...
                            </span>
                        </h2>
                        <div className="d-flex">
                            {
                                props.JobApplicant.length > 0 ?
                                    <Link to='/applicant/dashboard' className="btn-get-started scrollto" href="#hero">Get Started</Link>
                                    :
                                    <Link to='/applicant/login' className="btn-get-started scrollto" href="#hero">Get Started</Link>
                            }

                        </div>
                    </div>
                </div>
            </section> */}
            <section id="footer">
                <div className="footer-newsletter" style={{ padding: "-100px" }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                {/* <h4>Search Job</h4> */}
                                <span className='calli'>Find exciting jobs</span>
                                {/* <form >
                                    <input type="text" className='form-control' placeholder='search' name="email" onChange={SearchJob} />
                                </form> */}
                                <input type="text" className='form__input' placeholder='search' name="email" onChange={SearchJob} />

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <main id="main">
                <section id="featured-services" className="featured-services">
                    <div className="container" data-aos="fade-up">
                        <div className="row">
                            {
                                JobList.length > 0 ?
                                    JobList.map((x, i) => {
                                        return (
                                            <div className="col-md-4 col-lg-4 d-flex align-items-stretch mb-5">
                                                <Link to={`/job/${encryptData(x.RequisitionID)}`} >
                                                    <div className="icon-box" data-aos="fade-up" data-aos-delay={100}>
                                                        <h4 className="title description" style={{ fontSize: '16px' }}>{x.Position} ({x.RequisitionID})</h4>
                                                        <p className="description">
                                                            <div><strong>Department</strong>: {x.Department}</div>
                                                            <div><strong>Closing date</strong>: {formatDateAndTime(x.ClosingDate, "date")}</div>
                                                        </p>
                                                        <hr />
                                                        <div className='d-flex justify-content-between description'>
                                                            <strong>Status:&nbsp;&nbsp;
                                                                <span className={x.Status === "1" ? "badge badge-success" : "badge badge-danger"} >{x.Status === "1" ? "Open" : "Close"}
                                                                </span>
                                                            </strong>
                                                            &nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <strong>Type:&nbsp;&nbsp;
                                                                <span className={x.Urgent === "1" ? "badge badge-danger" : "badge badge-success"} >
                                                                    {x.Urgent === "1" ? "Urgent" : "Normal"}
                                                                </span>
                                                            </strong>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })
                                    :
                                    <div>
                                        <div className="col-md-12 text-center">
                                            <p className="text-center">No Openings, please check back later!</p>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </section >


                <section id="contact" className="contact">
                    <div className="container" data-aos="fade-up">
                        <div className="section-title">
                            <h3><span  >Contact Us</span></h3>
                        </div>
                        <div className="row" data-aos="fade-up" data-aos-delay={100}>
                            <div className="col-lg-6">
                                <div className="info-box mb-4">
                                    <i className="fa fa-location-arrow fa-10x" />
                                    <h3>Our Address</h3>
                                    <p className='p-3'>
                                        {projectAddress}
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="info-box  mb-4">
                                    <i className="fa fa-inbox fa-10x" />
                                    <h3>Email Us</h3>
                                    <p className='p-3'><a href={`mailto: ${projectEmail}`} target={"_blank"} >{projectEmail}</a></p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="info-box  mb-4">
                                    <i className="fa fa-phone fa-10x" />
                                    <h3>Call Us</h3>
                                    <p className='p-3'><a href='phone:+234 813 376 9658' target={"_blank"} >{projectPhone}</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="row" data-aos="fade-up" data-aos-delay={100}>
                            <div className="col-lg-12 ">
                                <iframe
                                    title={`${schoolName} Campus Map`}
                                    src={googleMapAdress}
                                    style={{ border: 0, width: '100%', height: '384px' }} allowFullScreen />


                            </div>
                        </div>
                    </div>
                </section>{/* End Contact Section */}
            </main > {/* End #main */}
            {/* ======= Footer ======= */}
            <footer id="footer">
                <hr />
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 footer-contact">
                                <h3>{schoolName}<span>.</span></h3>
                                <p>
                                    {projectAddress} <br />
                                    <strong>Phone:</strong> {projectPhone}<br />
                                    <strong>Email:</strong> {projectEmail}<br />
                                </p>
                            </div>
                            <div className="col-lg-4 col-md-6 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right" /> <a href={ProjectDomain} target={"_blank"} >Website</a></li>
                                    <li><i className="bx bx-chevron-right" /> <a href={AboutUs} target={"_blank"} >About us</a></li>
                                    <li><i className="bx bx-chevron-right" /> <a href={CourseFinder} target={"_blank"} >Admission</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-6 footer-links">
                                <h4>Our Social Networks</h4>
                                <div className="social-links mt-3">
                                    <a href={projectFacebook} target="_blank" className="facebook">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" /></svg>
                                    </a>
                                    <a href={projectTwitter} target="_blank" className="instagram">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c-.002 -.249 1.51 -2.772 1.818 -4.013z" /></svg>
                                    </a>
                                    <a href={projectLinkedIn} target="_blank" className="linkedin">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16"> <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" /> </svg>
                                    </a>
                                    <a href={projectYoutube} target="_blank" className="facebook">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><rect x={3} y={5} width={18} height={14} rx={4} /><path d="M10 9l5 3l-5 3z" /></svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short" /></a>
        </div >
    )
}


const mapStateToProps = (state) => {
    return {
        JobList: state.JobList,
        JobApplicant: state.JobApplicantData,
        activeCohort: state.activeCohort
    };
};
const mapDisptachToProps = (dispatch) => {
    return {
        setOnJobList: (p) => {
            dispatch(setJobListAction(p))
        },
        setOnJobApplicantData: (p) => {
            dispatch(setApplicantLoginDetails(p))
        },
        setOnAppliedJobs: (p) => {
            dispatch(setAppliedJobs(p))
        },
        setOnAdminLoginData: (p) => {
            dispatch(setAdminLoginDetails(p))
        }
    }
}

export default connect(mapStateToProps, mapDisptachToProps)(LandingPage);

