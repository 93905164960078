import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setJobListAction } from '../../../../actions/actions';
import { encryptData, formatDateAndTime, LoginBgImage, projectEmail, projectLogo, projectPhone, schoolName } from '../../../../util/constants';
import { serverLink } from '../../../../util/url';
import Loader from '../../../../common/loader';

function JobListing(props) {

    const [isLoading, setIsLoading] = useState(true)
    const [JobList, setJobList] = useState(props.JobList.length > 0 ? props.JobList : [])
    const [JobList2, setJobList2] = useState(props.JobList.length > 0 ? props.JobList : [])
    const controller = new AbortController();
    const [refresh, setRefresh] = useState(0)
    const active_cohort = props.activeCohort[0];

    const getData = async () => {
        const { signal } = controller.signal
        try {
            await axios.get(`${serverLink}/job_applications/current_job_openings?cohort=${active_cohort?.CohortCode}`, { signal }).then((res) => {
                setJobList(res.data);
                setJobList2(res.data);
                props.setOnJobList(res.data)
                setRefresh(1)
                setIsLoading(false)
            })
        } catch (e) {
            toast.error("network error, please try again")
        }

    }

    useEffect(() => {
        getData();
        return () => {
            controller.abort()
        }
    }, [refresh])

    const SearchJob = (e) => {
        let filtered = JobList2.filter(x => x.Position.toLowerCase().includes(e.target.value.toLowerCase()) ||
            x.Description.toLowerCase().includes(e.target.value.toLowerCase()) ||
            x.Faculty.toLowerCase().includes(e.target.value.toLowerCase()) ||
            x.Department.toLowerCase().includes(e.target.value.toLowerCase()));
        setJobList(filtered)

    }

    return isLoading ? (<Loader />) : (
        <div className="container-fluid">
            <div className="landing-box">
                <div className="landing-body">
                    <div className="">

                        <div className='text-center'>
                            <img src={projectLogo} width="90px" />
                            <h1>Welcome to {schoolName} Job Portal</h1>
                            <Link to={props.JobApplicant.length > 0 ? '/applicant/dashboard' : `/applicant/login`} className="btn btn-md btn-get-started"  > <i className='fa fa-caret-right' /><i className='fa fa-caret-right' />&nbsp;Go to Portal</Link>
                        </div>


                        <div className="card col-lg-10 offset-lg-1">
                            <div className="card-body">
                                <h3 className="card-title">Current Openings</h3>
                                <input className='form-control mt-2' placeholder='search jobs' onChange={SearchJob} />
                            </div>
                            <div className="card-body bg-light">
                                <div className="card">
                                    <div className="card-body">
                                        <ul className="mailbox list-style-none w-100 m-t-20 scrollable ps-container ps-theme-default ps-active-y" style={{ height: '860px' }}>

                                            <li>

                                                <div className="message-center chat-scroll ps-container ps-theme-default">
                                                    {
                                                        JobList.length > 0 ?
                                                            JobList.map((x, i) => {
                                                                return (
                                                                    <div href="#" className="message-item" id="chat_user_1" key={i}>

                                                                        <div className='row' key={i}>
                                                                            <div className='col-md-8'>
                                                                                <span className="user-img">
                                                                                    <img src={projectLogo} style={{ width: '90px !important' }} alt="user" className="" />
                                                                                    <span className="profile-status online pull-right" /> </span>
                                                                                <div className="mail-contnet">
                                                                                    <h4 className="message-title font-bold mb-2">{x.Position} ({x.RequisitionID})</h4>
                                                                                    <span className="font-17">
                                                                                        <div><strong>Department: {x.Department}</strong></div>
                                                                                        <strong>Closing date: {formatDateAndTime(x.ClosingDate, "date")}</strong> &nbsp;|&nbsp;
                                                                                        <strong>Status:&nbsp;&nbsp;
                                                                                            <span className={x.Status === "1" ? "badge badge-success" : "badge badge-danger"} >{x.Status === "1" ? "Open" : "Close"}
                                                                                            </span>
                                                                                        </strong>
                                                                                        &nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                        <strong>Type:&nbsp;&nbsp;
                                                                                            <span className={x.Urgent === "1" ? "badge badge-danger" : "badge badge-success"} >
                                                                                                {x.Urgent === "1" ? "Urgent" : "Normal"}
                                                                                            </span>
                                                                                        </strong>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-4 float-right' >
                                                                                <Link to={`/job/${encryptData(x.RequisitionID)}`} className="btn btn-md btn-get-started text-white w-50 pl-3 pr-3 mt-3 float-right" >View Details</Link>
                                                                            </div>
                                                                        </div>
                                                                        <br />
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            <div>
                                                                <h4>No matching record! </h4>
                                                            </div>
                                                    }
                                                </div>
                                            </li>
                                            <div className="ps-scrollbar-x-rail" style={{ left: '0px', bottom: '0px' }}><div className="ps-scrollbar-x" tabIndex={0} style={{ left: '0px', width: '0px' }} /></div><div className="ps-scrollbar-y-rail" style={{ top: '0px', right: '3px', height: '560px' }}><div className="ps-scrollbar-y" tabIndex={0} style={{ top: '0px', height: '364px' }} /></div></ul>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <br />
                        <hr />

                        <footer className='footer'>
                            <div className='col-md-12'>
                                <div className="card col-lg-10 offset-lg-1">
                                    <div className="card-body bg-dark text-light ">
                                        <h4>For Enquiries, please contact</h4>
                                        <address>
                                            <div>
                                                <a className='text-white text-monospace mb-2' href={`mailto:${projectEmail}`} >{projectEmail}</a>
                                            </div>
                                            <div>
                                                <a className='text-white text-monospace mb-2' href={`phone:${projectPhone}`} >{projectPhone}</a>
                                            </div>
                                        </address>
                                    </div>

                                </div>
                            </div>

                        </footer>

                    </div>
                </div>

            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        JobList: state.JobList,
        JobApplicant: state.JobApplicantData,
        activeCohort: state.activeCohort


    };
};
const mapDisptachToProps = (dispatch) => {
    return {
        setOnJobList: (p) => {
            dispatch(setJobListAction(p))
        }
    }
}

export default connect(mapStateToProps, mapDisptachToProps)(JobListing);

