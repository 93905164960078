import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { setPanelJobListAction} from '../../../../../actions/actions';
import Loader from '../../../../../common/loader';
import { serverLink } from '../../../../../util/url';
import Breadcrum from "../../../../../common/breadcrum/breadcrum";
import ReportTable from "../../../../common/report_table";
import {Link} from "react-router-dom";

function InterviewHRJobs(props) {

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [generalDatatable, setGeneralDatatable] = useState([]);
    const columns = [ "S/N", "Job ID", "Position", "Job Type", "Department", "Applicants", "Action" ];

    const getData = async() => {
        await axios.get(`${serverLink}/enrollment/interview/hr`,) .then((res) => {
            const data = res.data;
            let rows = [];
            if (data.length > 0) {
                setData(data);
                data.map((item, index) => {
                    rows.push([
                        index + 1,
                        item.RequisitionID,
                        item.Position,
                        item.JobType,
                        item.Department,
                        item.Applicants,
                        (
                            <>
                                <Link to={`/interview/panel/hr/applicants`}
                                      className="btn btn-sm btn-main"
                                      onClick={() => {
                                          props.setOnPanelJobData(item);
                                      }}
                                >
                                    <i className="fa fa-eye" />
                                </Link>

                            </>
                        ),
                    ]);
                });
            }
            setGeneralDatatable(rows);
            setIsLoading(false);
        })
            .catch((err) => {
                setIsLoading(false);
                toast.error("NETWORK ERROR. Please try again!");
            });
    }

    useEffect( () => {
        getData();
    }, []);

    return (
        isLoading ? <Loader/>
            :
        <div>
            <div className="container-fluid" style={{ marginLeft: '-10px'}}>
                <Breadcrum title="Interview HR Assessment" />
                <div className="row mt-3">
                    <div className="col-12">
                        <ReportTable title={"Job Positions"} columns={columns} data={generalDatatable} height="700px" />
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        AdminLoginData: state.AdminLoginData
    };
};
const mapDisptachToProps = (dispatch) => {
    return {
        setOnPanelJobData: (p) => {
            dispatch(setPanelJobListAction(p))
        },
    }
}

export default connect(mapStateToProps, mapDisptachToProps)(InterviewHRJobs);


