
import React, { useState } from 'react'
import { Route, Routes } from 'react-router';

import AdminDashboard from '../admin/pages/dashboard';
import SideBar from '../common/sidebar';
import Header from '../common/header';
import JobOpenings from '../admin/pages/job-openings/job-openings';
import InterviewScoreSettings from "../admin/pages/interview/interview-score-settings";
import InterviewPanel from "../admin/pages/interview/interview-panel";
import InterviewPanelApplicants from "../admin/pages/interview/interview-panel-applicants";
import InterviewPanelAssessment from "../admin/pages/interview/interview-panel-assessment";
import InterviewHRApplicants from "../admin/pages/interview/hr-assessment/interview-hr-applicants";
import InterviewHRJobs from "../admin/pages/interview/hr-assessment/interview-hr-jobs";
import JobApplicants from '../admin/pages/job-applications/applicants';
import ApplicationCounts from '../admin/pages/job-applications/applicants-count';
import JobApplicantDetails from '../admin/pages/job-applications/applicant-details';
import InterviewHRAssessment from "../admin/pages/interview/hr-assessment/interview-hr-assessment";
import AllStaffList from '../admin/pages/staff-list/all-staff';
import NonAcademicStaffList from '../admin/pages/staff-list/non-academic-staff';
import AcademicStaff from '../admin/pages/staff-list/academic-staff';
import EnrolmentList from '../admin/pages/enrolment/enrolment-list';
import EnrolledList from '../admin/pages/enrolment/enrolled-list';
import SettingsStaffList from '../admin/pages/settings/staff-list';
import Permissions from '../admin/pages/settings/permissions';
import PrintAssessmentSheet from "../admin/pages/interview/hr-assessment/print-assessment-sheet";
import InterviewPanelRequest from "../admin/pages/interview/interview-panel-request";
import ManagePositions from '../admin/pages/job-openings/manage-positions';
import { schoolName } from '../../util/constants';
import ManageCohorts from '../admin/pages/job-openings/cohorts';
import JobApplicationReport from "../admin/pages/reports/job-application-report";
import JobApplicationRoleReport from "../admin/pages/reports/job-application-role-report";
import AllPendingApplications from '../admin/pages/job-applications/all-pending-applications';
import InterviewPanelList from '../admin/pages/interview/interview-panel-list';


const AdminRoutes = () => {
    const [cohortList, setCohortList] = useState([])
    return (
        <div id="main-wrapper">
            <Header  cohortList={cohortList} />

            <SideBar id="sBar" />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <Routes>
                        <Route path='/' element={<AdminDashboard />} />
                        <Route path='/admin/dashboard' element={<AdminDashboard />} />
                        <Route path='/job-openings' element={<JobOpenings />} />
                        <Route path='/manage-positions' element={<ManagePositions />} />
                        <Route path='/cohorts' element={<ManageCohorts setCohortList={setCohortList} />} />

                        <Route path='/jobs/all-pending-applications' element={<AllPendingApplications />} />
                        <Route path='/jobs/pending-applications' element={<ApplicationCounts />} />
                        <Route path='/jobs/accepted-applications' element={<ApplicationCounts />} />
                        <Route path='/jobs/invited-applications' element={<ApplicationCounts />} />
                        <Route path='/jobs/rejected-applications' element={<ApplicationCounts />} />
                        <Route path='/jobs/cancelled-applications' element={<ApplicationCounts />} />
                        <Route path='/jobs/application-report' element={<JobApplicationReport />} />
                        <Route path='/jobs/application-report-by-role' element={<JobApplicationRoleReport />} />

                        <Route path='/jobs/applicants/:JobID/:Status' element={<JobApplicants />} />
                        <Route path='/jobs/applicant/:ApplicantID/:JobID/:ApplicationID' element={<JobApplicantDetails />} />

                        <Route path='/staff/all-staff' element={<AllStaffList />} />
                        <Route path='/staff/academic-staff' element={<AcademicStaff />} />
                        <Route path='/staff/non-academic-staff' element={<NonAcademicStaffList />} />

                        <Route path='/enrolment-list' element={<EnrolmentList />} />
                        <Route path='/enrolled' element={<EnrolledList />} />
                        <Route path='/enrolment/:ApplicantID/:JobID/:ApplicationID' element={<JobApplicantDetails />} />

                        <Route path='/settings/staff-list' element={<SettingsStaffList />} />
                        <Route path='/settings/permissions' element={<Permissions />} />
                        <Route path='/settings/my-permissions/:slug' element={<Permissions />} />


                        <Route path='/interview/settings' element={<InterviewScoreSettings />} />
                        <Route path='/interview/panel' element={<InterviewPanel />} />
                        <Route path='/interview/panel/applicants' element={<InterviewPanelApplicants />} />
                        <Route path='/interview/panel/assessment' element={<InterviewPanelAssessment IsHR={false} />} />
                        <Route path='/interview/hr' element={<InterviewHRJobs />} />
                        <Route path='/interview/panel/hr/applicants' element={<InterviewHRApplicants />} />
                        <Route path='/interview/hr/assessment' element={<InterviewHRAssessment />} />
                        <Route path='/interview/hr/panel/assessment' element={<InterviewPanelAssessment IsHR={true} />} />
                        <Route path='/interview/hr/print/assessment' element={<PrintAssessmentSheet />} />
                        <Route path='/interview/panel/request' element={<InterviewPanelRequest />} />
                        <Route path='/interview/panel-members' element={<InterviewPanelList />} />

                        <Route path='*' element={<AdminDashboard />} />
                    </Routes>

                </div>

                <footer className="footer text-center hideSection">
                    &copy;{schoolName} IT, {new Date().getFullYear()}.
                </footer>
            </div>
        </div>
    )
}

export default AdminRoutes;