
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { setAdmin, setAdminLoginDetails, setApplicantLoginDetails, setAppliedJobs } from '../../../../actions/actions';
import { decryptData, encryptData } from '../../../../util/constants';
import { serverLink } from '../../../../util/url';
import EnrolmentBankInfo from './pages/bank-info';
import EnrolmentEmploymentHistory from './pages/employment-history';
import EnrolmentHome from './pages/home';
import EnrolmentNextOfKin from './pages/next-of-kin';
import EnrolmentQualifications from './pages/qualification';
import EnrolmentSocialMedia from './pages/social-media';
import FinalSubmission from './pages/submit';
function EnrolDasboard(props) {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [final, setfinal] = useState(false)

    const applicant = props.JobApplicant;
    const [AppliedJobs, setAppliedJobs] = useState(props.AppliedJobs.length > 0 ? props.AppliedJobs.filter(x => x.JobID === decryptData(slug)) : []);
    const [allowSubmit, setAllowSubmit] = useState({
        Qualifications: [],
        SocialMedia: [],
        NextOfKin: []
    })


    const getAppliedJobs = () => {
        axios.get(`${serverLink}/job_applications/get_job_applications/${applicant[0]?.Email}`).then((res) => {
            if (res.data.length > 0) {
                setAppliedJobs(res.data.filter(x => x.JobID === decryptData(slug)));
                props.setOnAppliedJobs(res.data.filter(x => x.JobID === decryptData(slug)));
            } else {
                navigate('/applicant/dashboard')
            }
        })
    }

    useEffect(() => {
        getAppliedJobs();

    }, [])

    const onLoginBackToAdmin = () => {
        props.setOnAdminLoginData(props.Admin);
        props.setOnJobApplicantData([]);
        props.setOnAdmin([])
        // navigate(`/enrolment/${applicant[0]?.EntryID}/${AppliedJobs[0].JobID}/${AppliedJobs[0]?.ApplicationID}`)
        setTimeout(() => {
            window.location.href = `/enrolment/${applicant[0]?.EntryID}/${AppliedJobs[0].JobID}/${AppliedJobs[0]?.ApplicationID}`
            // window.location.reload();
        }, 200);
    }
    return (
        <div className="auth-wrapper d-flex no-block"
            style={{ backgroundColor: '#f5f5f5', }}>

            <div className='container-fluid mt-3'>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card" style={{ minHeight: '900px' }}>
                            <div className="card-body">
                                <div className='row'>
                                    <div className='col-md-2'>
                                        <h4 className="card-title">Welcome! <br />
                                            {applicant[0]?.FirstName} {applicant[0]?.MiddleName} {applicant[0]?.Surname}, </h4>
                                        <h6 className="card-subtitle">
                                            {
                                                props.Admin.length > 0 ?
                                                    <button type='button' className='btn btn-link' onClick={onLoginBackToAdmin}  >
                                                        Back to Admin Portal
                                                    </button>
                                                    :
                                                    <Link to='/applicant/dashboard/' className='btn btn-link'  >
                                                        Back to Dashboard
                                                    </Link>

                                            }

                                        </h6>
                                    </div>
                                    <div className='col-md-10'>
                                        <div className='p-10'>
                                            <div className={AppliedJobs[0]?.EnrolmentStatus === "0" ? 'alert alert-danger' : 'alert alert-success'}>
                                                <h3 className={AppliedJobs[0]?.EnrolmentStatus === "0" ? 'text-danger' : 'text-success'}>
                                                    <i className="fa fa-check-circle" />
                                                    Enrolment Status
                                                </h3>

                                                {
                                                    AppliedJobs[0]?.EnrolmentStatus === "0" ? <p>
                                                        Your enrolment is not complete <br />
                                                        Personal Details, Qualifications and Next of Kin are required to submit form
                                                    </p> : 'Your enrolment is successful'
                                                }

                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-lg-3 col-xl-2">
                                        {/* Nav tabs */}
                                        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                            <a className="nav-link active show" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="false">
                                                Home
                                            </a>
                                            <a className="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                                Qualification
                                            </a>
                                            <a className="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                                Employment History
                                            </a>


                                            <a className="nav-link" id="v-social-media-tab" data-toggle="pill" href="#v-social-media" role="tab" aria-controls="v-social-media" aria-selected="true">
                                                Media Visibility
                                            </a>

                                            {/* <a className="nav-link" id="v-bank-info-tab" data-toggle="pill" href="#v-bank-info" role="tab" aria-controls="v-bank-info" aria-selected="true">
                                                Bank Info
                                            </a> */}

                                            <a onClick={() => { setfinal(Math.random()) }} className="nav-link" id="v-next-of-kin-tab" data-toggle="pill" href="#v-next-of-kin" role="tab" aria-controls="v-next-of-kin" aria-selected="true">
                                                Next of Kin
                                            </a>
                                            {/* {
                                                allowSubmit.NextOfKin.length > 0 && allowSubmit.Qualifications.length > 0 && allowSubmit.SocialMedia.length > 0 ?
                                                    <a className={`nav-link`} id="v-final-submission-tab" data-toggle="pill" href="#v-final-submission" role="tab" aria-controls="v-final-submission" aria-selected="true"  >
                                                        Final Submission
                                                    </a>
                                                    :
                                                    <a className={`nav-link disabled`} id="v-final-submission-tab" data-toggle="pill" href="#v-final-submission" role="tab" aria-controls="v-final-submission" aria-selected="true"  >
                                                        Final Submission
                                                    </a>
                                            } */}

                                            <a className={`nav-link`} id="v-final-submission-tab" data-toggle="pill" href="#v-final-submission" role="tab" aria-controls="v-final-submission" aria-selected="true"  >
                                                Final Submission
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-lg-9 col-xl-10">
                                        <div className="tab-content" id="v-pills-tabContent" style={{ margin: '12px', padding: '30px', borderStyle: 'solid', borderWidth: '1px', borderRadius: '5px' }}>
                                            <div className="tab-pane active show" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                                <EnrolmentHome AppliedJobs={AppliedJobs} applicant={applicant}
                                                    setAllowSubmit={setAllowSubmit} allowSubmit={allowSubmit} setfinal={setfinal} />
                                            </div>
                                            <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                                <EnrolmentQualifications applicant={applicant} setAllowSubmit={setAllowSubmit} allowSubmit={allowSubmit} />
                                            </div>
                                            <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                                <EnrolmentEmploymentHistory applicant={applicant} />
                                            </div>

                                            <div className="tab-pane fade " id="v-social-media" role="tabpanel" aria-labelledby="v-social-media-tab">
                                                <EnrolmentSocialMedia applicant={applicant} />
                                            </div>
                                            {/* <div className="tab-pane fade " id="v-bank-info" role="tabpanel" aria-labelledby="v-bank-info-tab">
                                                <EnrolmentBankInfo applicant={applicant} />
                                            </div> */}
                                            <div className="tab-pane fade " id="v-next-of-kin" role="tabpanel" aria-labelledby="v-next-of-kin-tab">
                                                <EnrolmentNextOfKin applicant={applicant} setAllowSubmit={setAllowSubmit} allowSubmit={allowSubmit} />
                                            </div>
                                            <div className="tab-pane fade " id="v-final-submission" role="tabpanel" aria-labelledby="v-final-submission-tab"
                                            >

                                                <FinalSubmission applicant={applicant} AppliedJobs={AppliedJobs} getAppliedJobs={getAppliedJobs} final={final} />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        JobApplicant: state.JobApplicantData,
        JobList: state.JobList,
        AppliedJobs: state.AppliedJobs,
        Admin: state.Admin

    };
};
const mapDisptachToProps = (dispatch) => {
    return {
        setOnJobApplicantData: (p) => {
            dispatch(setApplicantLoginDetails(p))
        },
        setOnAppliedJobs: (p) => {
            dispatch(setAppliedJobs(p))
        },
        setOnAdmin: (p) => {
            dispatch(setAdmin(p))
        },
        setOnAdminLoginData: (p) => {
            dispatch(setAdminLoginDetails(p))
        }
    }
}

export default connect(mapStateToProps, mapDisptachToProps)(EnrolDasboard);
