import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { serverLink } from '../../../../../util/url';
import { useFormik } from 'formik';
import { SocialMediaValidationScehma } from '../../../../formik/validationSchema';
import { toast } from 'react-toastify';
import Loader from '../../../../../common/loader';

export default function EnrolmentSocialMedia(props) {
    const [isLoading, setIsLoading] = useState(true)
    const applicant = props.applicant
    const [SocialMedia, setSocialMedia] = useState([]);
    const controller = new AbortController();
    const [showForm, setShowForm] = useState(false)


    const getData = async () => {
        const { signal } = controller.signal

        await axios.get(`${serverLink}/enrollment/get_social_media/${applicant[0]?.EntryID}`).then((res) => {
            if (res.data.length > 0) {
                const values = res.data[0]
                const mediaList = [
                    { name: 'Facebook', media: values?.Facebook },
                    { name: 'LinkedIn', media: values?.LinkedIn },
                    { name: 'Google Scholar', media: values?.Scholar },
                    { name: 'Reasearch Gate', media: values?.ResearchGate },
                    { name: 'Academia', media: values?.Academia },
                    { name: 'ORCID', media: values?.Orcid }
                ]
                setSocialMedia(mediaList)
            }
            setIsLoading(false)
        })
    }

    useEffect(() => {
        getData();

       
    }, [])

    const RemoveMediaHandle = async (e) => {
        const dt = {
            EntryID: e.EntryID,
            ApplicantID: applicant[0]?.EntryID,
            MediaName: e.name,
            MediaValue: e.media,
        }
        try {
            await axios.put(`${serverLink}/enrollment/remove_social_handle/${e.EntryID}`, dt).then((res) => {
                if (res.data.message === "success") {
                    getData();
                    toast.success('Record removed')
                } else {
                    toast.error('Error removing record')
                }
            })
        } catch (e) {
            toast.error("network error!")
        }
    }

    const submitMedia = (values, helpers) => {
        if (values.EntryID === "") {
            try {
                axios.post(`${serverLink}/enrollment/add_social_handle`, values).then((res) => {
                    if (res.data.message === "success") {
                        formik.resetForm();
                        ResetValues();
                        toast.success('media successfully added');
                        getData();
                    }
                    else if (res.data.message === "exists") {
                        toast.error("already added");
                    }
                    else {
                        toast.error("failed to add media, please try again")
                    }
                })
            } catch (e) {
                toast.error("network error!")
            }
        }
    }
    const formik = useFormik({
        initialValues: {
            EntryID: "",
            ApplicantID: applicant[0]?.EntryID,
            MediaName: "",
            MediaValue: "",
        },
        validationSchema: SocialMediaValidationScehma,
        onSubmit: (values, helpers) => { submitMedia(values, helpers) }
    })

    const ResetValues = () => {
        formik.setValues({
            ...formik.values,
            EntryID: "",
            MediaName: "",
            MediaValue: "",
        });
    }

    const toggleForm = () => {
        if (showForm === true) {
            setShowForm(false)
        } else {
            setShowForm(true)
        }

    }


    return isLoading ? (<Loader />) : (
        <div>

            <div className='row justify-content-between'>
                <div>
                    <h2>Media Visibility (Optional)</h2>
                    <small>toggle the button to show or hide the form</small>
                    <div>
                        <strong>To update a record, select the media type and enter the new link</strong>
                    </div>
                </div>
                <div>
                    <button className='btn btn-sm btn-get-started' onClick={toggleForm}>
                        {showForm === true ? "Hide Form" : "Show Form"}
                    </button>
                </div>
            </div>
            <br />
            {
                showForm &&
                <div className='row'>
                    <div className='col-lg-8 offset-lg-2 col-sm-12'>
                        <form className="m-t-30 needs-validation" onSubmit={formik.handleSubmit} noValidate >
                            <div className="form-group">
                                <label htmlFor="MediaName">Media</label>
                                <select id="MediaName" name="MediaName"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.MediaName}
                                    className={formik.errors.MediaName && formik.touched.MediaName ? 'form-control is-invalid' : 'form-control'} required >
                                    <option value={""}>--select--</option>
                                    <option value="LinkedIn">LinkedIn</option>
                                    <option value="ResearchGate">ResearchGate</option>
                                    <option value="Academia">Academia</option>
                                    <option value="Orcid">ORCID</option>
                                    <option value="Scholar">Google Scholar</option>
                                    {/* <option value="Twitter">Twitter</option> */}
                                    <option value="Facebook">Facebook</option>

                                </select>
                                {formik.errors.MediaName && formik.touched.MediaName &&
                                    <div className='invalid-feedback'>{formik.errors.MediaName}
                                    </div>
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="MediaValue">Link </label>
                                <input type="text" id="MediaValue" name="MediaValue" placeholder="media link" onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.MediaValue}
                                    className={formik.errors.MediaValue && formik.touched.MediaValue ? 'form-control is-invalid' : 'form-control'} required
                                />
                                {formik.errors.MediaValue && formik.touched.MediaValue &&
                                    <div className='invalid-feedback'>{formik.errors.MediaValue}
                                    </div>
                                }
                            </div>

                            <button type="submit" disabled={formik.isValid === true ? false : true} className="btn btn-get-started w-100">Submit</button>
                        </form>
                    </div>
                </div>
            }

            <br />
            <div className='table-responsive'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>SN</th>
                            <th>Media Title</th>
                            <th>Media Link </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            SocialMedia.length > 0 ?
                                SocialMedia.map((x, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{x.name}</td>
                                            <td><a target={"_blank"} href={x?.media?.includes("https") === true ? x.media : `https://${x.media}`}  >{x.media} </a></td>
                                            <td>
                                                <button className='btn btn-sm btn-danger' onClick={() =>
                                                    RemoveMediaHandle(x)}>
                                                    <i className='ti-trash' />
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <tr >
                                    <td colSpan={5} >
                                        No Data
                                    </td>
                                </tr>

                        }
                    </tbody>

                </table>
            </div>
        </div>
    )
}
