import axios from 'axios';
import LoginBg from '../images/login-register.jpg'
import { serverLink } from './url';
import { toast } from 'react-toastify';
const CryptoJS = require("crypto-js");



export const LoginBgImage = LoginBg
export const shortCode = "CU"
export const schoolName = "Cosmopolitan University"
export const projectName = "Cosmopolitan University Job Management System";
export const projectCode = "COSMOHR";
export const projectPhone = "+234 805 208 0828";
export const projectEmail = "info@cosmopolitan.edu.ng";
export const projectAddress = "Cosmopolitan University, Abuja.";
export const projectLogo = "https://cosmopolitan.edu.ng/logo.png"
export const ProjectDomain = "https://www.cosmopolitan.edu.ng "
export const Admission = "https://admission.cosmopolitan.edu.ng/"
export const Fees = "https://cosmopolitan.edu.ng/admission/fees"
export const AboutUs = "https://cosmopolitan.edu.ng/about-us"
export const CourseFinder = "https://cosmopolitan.edu.ng/admission/course-finder"
export const ContactUs = "https://cosmopolitan.edu.ng/contact-us";
export const Jobs = "https://jobs.cosmopolitan.edu.ng";
export const Staff = "https://staff.cosmopolitan.edu.ng";
export const projectFacebook = "https://www.facebook.com/";
export const projectTwitter = "https://www.twitter.com/";
export const projectYoutube = "https://www.youtube.com/";
export const projectInstagram = "https://instagram.com/?lang=en";
export const projectLinkedIn = "https://linkedin.com/?lang=en";
export const googleMapAdress = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126093.79071244909!2d7.293975558335303!3d9.024392708986438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e745f4cd62fd9%3A0x53bd17b4a20ea12b!2sAbuja%2C%20Federal%20Capital%20Territory!5e0!3m2!1sen!2sng!4v1687456306974!5m2!1sen!2sng"









export const simpleFileUploadAPIKey = "b46bd7bf5e235f7a2d0d7b7602ecce10";
export const simpleFileUploadFolderName = "baze";

export const onGeneralEdit = (e, value, setter) => {
  setter({
    ...value,
    [e.target.id]: e.target.value
  })
}


export function encryptData(string, val = false) {
  try {
    let secret_key =
      val === false
        ? "BABA_AHMED_UNIVERSITY_STAFF_PORTAL" + "_ENCRYPT"
        : projectCode;
    let secret_iv =
      val === false
        ? "BABA_AHMED_UNIVERSITY_STAFF_PORTAL" + "_ENCRYPT_IV"
        : projectCode;
    // hash
    let kee = CryptoJS.SHA256(secret_key);
    let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

    kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
    ivv = CryptoJS.enc.Utf8.parse(ivv);

    let decrypted = CryptoJS.AES.encrypt(string, kee, { iv: ivv });

    let result = decrypted.toString();
    return btoa(result);
  } catch (e) {
    console.log(e)
  }
}

export function decryptData(string, val = false) {
  try {
    let secret_key =
      val === false
        ? "BABA_AHMED_UNIVERSITY_STAFF_PORTAL" + "_ENCRYPT"
        : projectCode;
    let secret_iv =
      val === false
        ? "BABA_AHMED_UNIVERSITY_STAFF_PORTAL" + "_ENCRYPT_IV"
        : projectCode;
    // hash
    let kee = CryptoJS.SHA256(secret_key);
    let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

    kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
    ivv = CryptoJS.enc.Utf8.parse(ivv);

    var decrypted = CryptoJS.AES.decrypt(atob(string), kee, { iv: ivv });

    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (e) {
    console.log(e)
  }
}



export const sendEmail = (email, subject, title, name, body, signature) => {
  const sendEmail = {
    logo: projectLogo,
    from: projectEmail,
    to: email,
    subject: subject,
    title: title,
    name: name,
    body: body,
    signature: signature,
  };
  try {
    axios.post(`${serverLink}/send_email/send`, sendEmail).then((r) => {
      console.log("email sent");
    });
  } catch (e) {
    console.log(e)
  }

  return "sent";
};


export const formatDateAndTime = (date, option) => {
  if (date !== null) {
    const user_date = new Date(date);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthNamesShort = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day =
      user_date.getDate() < 10
        ? "0" + user_date.getDate()
        : user_date.getDate();
    const hour =
      user_date.getHours() < 10
        ? "0" + user_date.getHours()
        : user_date.getHours();
    const min =
      user_date.getMinutes() < 10
        ? "0" + user_date.getMinutes()
        : user_date.getMinutes();
    const sec =
      user_date.getSeconds() < 10
        ? "0" + user_date.getSeconds()
        : user_date.getSeconds();

    let date_string = "";
    if (option === "date_and_time")
      date_string = `${day}-${monthNames[user_date.getMonth()]
        }-${user_date.getFullYear()} : ${hour}:${min}:${sec}`;
    else if (option === "date")
      date_string = `${day}-${monthNames[user_date.getMonth()]
        }-${user_date.getFullYear()}`;
    else if (option === "day") date_string = day;
    else if (option === "full_month")
      date_string = monthNames[user_date.getMonth()];
    else if (option === "short_month")
      date_string = monthNamesShort[user_date.getMonth()];
    else if (option === "year_only") date_string = user_date.getFullYear();

    return date_string;
  } else {
    return "--";
  }
};

export const formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

let monthNames = [
  "January", "February", "March", "April", "May", "June", "July",
  "August", "September", "October", "November", "December"
];
let dayOfWeekNames = [
  "Sunday", "Monday", "Tuesday",
  "Wednesday", "Thursday", "Friday", "Saturday"
];
export const format_date = (date, formatStr) => {
  if (!formatStr) {
    formatStr = 'dd/mm/yyyy';
  }
  let day = date.getDate(),
    month = date.getMonth(),
    year = date.getFullYear(),
    hour = date.getHours(),
    minute = date.getMinutes(),
    second = date.getSeconds(),
    miliseconds = date.getMilliseconds(),
    hh = twoDigitPad(hour),
    mm = twoDigitPad(minute),
    ss = twoDigitPad(second),
    EEEE = dayOfWeekNames[date.getDay()],
    EEE = EEEE.substr(0, 3),
    dd = twoDigitPad(day),
    M = month + 1,
    MM = twoDigitPad(M),
    MMMM = monthNames[month],
    MMM = MMMM.substr(0, 3),
    yyyy = year + "",
    yy = yyyy.substr(2, 2)
    ;
  return formatStr
    .replace('hh', hh).replace('h', hour)
    .replace('mm', mm).replace('m', minute)
    .replace('ss', ss).replace('s', second)
    .replace('S', miliseconds)
    .replace('dd', dd).replace('d', day)
    .replace('MMMM', MMMM).replace('MMM', MMM).replace('MM', MM).replace('M', M)
    .replace('EEEE', EEEE).replace('EEE', EEE)
    .replace('yyyy', yyyy)
    .replace('yy', yy)
    ;
}
function twoDigitPad(num) {
  return num < 10 ? "0" + num : num;
}


const date = new Date();

let day = date.getDate();
let month = date.getMonth() + 1;
let year = date.getFullYear();

export const currentDate = `${year}-${month}-${day}`;
export const FullcurrentDate = `${year}-${month}-${day} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

const days = [
  { id: 0, dayName: 'Sunday' },
  { id: 1, dayName: 'Monday' },
  { id: 2, dayName: 'Tuesday' },
  { id: 3, dayName: 'Wednesday' },
  { id: 4, dayName: 'Thursday' },
  { id: 5, dayName: 'Friday' },
  { id: 6, dayName: 'Saturday' },
]
export const Today = () => {
  const t_id = parseInt(new Date().getDay());
  let today;
  if (t_id === 6) {
    today = (days.filter(x => x.id === 6)[0].dayName)
    //setTomorrow(days.filter(x => x.id === 0)[0].dayName)
  }
  else {
    const to_day = days.filter(x => x.id === t_id)[0].dayName;
    today = to_day
  }
  return today;
}



export const currencyConverter = (amount) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'NGN',
  });
  return formatter.format(amount)
}


export const MailTemplates = (type, item) => {
  if (type === '1') {
    const message = {
      from: projectEmail,
      subject: 'INVITATION TO INTERVIEW',
      title: "Interview Invitation",
      name: item.FirstName,
      body: `<div>
      We are pleased to invite you to an interview following your application for the position of ${item?.Position}<br/>The interview is scheduled to take place as follows. 
      <br/><br/>
      Date: ${formatDateAndTime(item?.InterViewDate, "date")} <br/>
      Vanue : ${item?.InterviewVenue} <br/>Time: ${item?.InterviewTime} <br/>
      
      For further inquiries, please contact ${projectEmail}
      <br/>
      <br/>
        Kindly note that you are to fill this form before coming for the interview. You may not be attended to if you do not fill the form. <br/>
        Use this <a href='${Jobs}/interview-details/${encryptData('a-'+item.ApplicationID)}'>link</a> or copy and paste ${Jobs}/interview-details/${encryptData('a'+item.ApplicationID)} in your browser.
      <br/><br/>
      Kind regards.
      <br/>
      ${schoolName} HR Team
      </div>`
    }
    return message;
  }
  else if (type === '3') {
    const message = {
      from: projectEmail,
      subject: 'NOTICE OF REJECTION',
      title: "Rejection Notice",
      name: item.FirstName,
      body: `<div>
      We have reviewed your application for the position of <b>${item.Position}</b>, we are sorry to inform you that due to the large applications we received and the performances of other applicants, we regret to inform you that your application is not successful <br/> Sorry for any inconvenience this may have caused <br/> <br/> For further inquiries, please contact ${projectEmail}
      </div>`
    }
    return message;
  }
  else if (type === '2') {
    const message = {
      from: projectEmail,
      subject: 'OFFER OF APPOINTMENT',
      title: "Appointment Offer",
      name: item.FirstName,
      body: `<div>Congratulations, <br/> we are pleased to notify you that you have been offered appointment as <b>${item.Position}</b> in the Department of <b>${item.Department}</b> with ${schoolName}. Kindly Follow this link to fill the enrolment form before reporting at the HR <a href="${Jobs}">link</a> .<br/>You are to report at the Human Resource Department for documentation within two weeks from the date of this email. <br/>Failure to report within the specified period would render this offer void. <br/><br/>For further enquiries, please context ${projectEmail} </div>`
    }
    return message;
  }
  else if (type === '4') {
    const message = {
      from: projectEmail,
      subject: 'STAFF DETAILS',
      title: "Staff Details",
      name: item.FirstName,
      body: `<div>
      Welcome to ${schoolName}!. Your  Employee Identification Number is ${item.NewStaffID}.<br/><br/>Kindly find below valuable resources: <br/><br/>
      1. Staff Portal<br/>
      StaffID: ${item.NewStaffID}<br/>
      Password: <strong>Use the change password link on the login page to create your password.</strong><br/>
      Url : <a href="${Staff}" target="_blank" >${Staff}</a>
      You would be required to change your password before you can login.
      <br/><br/>
      2. Website : <br/> <a href="${ProjectDomain}" target="_blank">${ProjectDomain}</a>
      <br/><br/>
      3. Official Email : <br/> Your ${schoolName} email address would be sent to you shortly after receiving this email, please do ensure you use it judisiously as it would be the primary gateway of correspondence with the university.
      <br/><br/>
      3. Network Connectivity Details : <br/> Your Login details would also be sent shortly after this email, please note that our internet services are censored and monitored for quality assurance purposes.
      <br/><br/>
      <br/>If you did not recieve these emails above, are unable to login, or have any challenges using our systems, kindly call the Administrator on extension 105 or send email to ${projectEmail}.
      <br/><br/>
      Furthermore, Academic teaching staff are required to create turnitin accounts for the University e-Learning Portal (Room A24) and Turnitin accounts (Faculty Offices). 
      <br/><br/>Thank you and have a wonderful time at ${schoolName}. 
      <br/>${schoolName} Service Desk <div/>`

    }
    return message;
  }
  else if (type === '5') {
    const message = {
      from: projectEmail,
      subject: 'REQUEST FOR OFFICIAL EMAIL, NETWORK PORTAL AND ID CARD',
      title: "Details Request",
      name: 'Service Desk',
      body: `<div>Good Day, <br/> Kindly create a ${schoolName} official email, network login details and initiate ID Card printing for the staff whose details appear below,
      <br/>
      <div class="table">
      <table style="width: 100%">
        <thead>
          <tr style="background-color: #f2f2f2; border: 1px solid #ddd; padding: 12px;">
            <td>Staff ID: ${item.staff_id}</td>
          </tr>
          <tr style="border: 1px solid #ddd">
            <td>First Name: ${item.FirstName}</td>
          </tr>
          <tr style="background-color: #f2f2f2; border: 1px solid #ddd">
            <td>Middle Name: ${item.MiddleName}</td>
          </tr>
          <tr style="border: 1px solid #ddd">
            <td>Surname: ${item.Surname}</td>
          </tr>
          <tr style="background-color: #f2f2f2; border: 1px solid #ddd">
            <td>Department: ${item.Department}</td>
          </tr>
          <tr style="border: 1px solid #ddd">
            <td>Position: ${item.PositionOffered}</td>
          </tr>
          <tr style="background-color: #f2f2f2; border: 1px solid #ddd">
            <td>BloodGroup: ${item.social_media[0]?.BloodGroup}</td>
          </tr>
          <tr style="border: 1px solid #ddd">
            <td>Next of Kin Phone: ${item.next_of_kin[0]?.Phone}</td>
          </tr>
          <tr style="background-color: #f2f2f2; border: 1px solid #ddd">
          <td>Image: <br/> 
          <img style="width:80px" src="${`${serverLink}/public/uploads/${shortCode}/hr/document/${item.social_media[0]?.Image}`}"  /><br/>
          <a href="${`${serverLink}/public/uploads/${shortCode}/hr/document/${item.social_media[0]?.Image}`}" target="_blank" >${item.social_media[0]?.Image}</a>  </td>
        </tr>
        </thead>
      </table>
      </div> 
      <br/>Thank you
      <br/>
      ${schoolName} HR
      </div>`
    }
    return message;
  }
  else if (type === 'account creation') {
    const message = {
      from: projectEmail,
      subject: 'Account Created',
      title: "Account Created",
      name: item.FirstName,
      body: `<div>Your Account have beean created, please proceed to login with your email and password.</div>`

    }
    return message;
  } else if (type === 'forgot password') {
    const message = {
      from: projectEmail,
      subject: `${schoolName} HR`,
      title: "Password Recovery",
      name: item.FirstName,
      body: `<div>You have requested to change your password, kindly follow this link to recover your password <br/> 
                <a href="${Jobs}/recover-password?q=${item.Token}" >${Jobs}/recover-password?q=${item.Token}</a>.</div>
                `

    }
    return message;
  } else if (type === 'registration') {
    const message = {
      from: projectEmail,
      subject: `${schoolName} HR`,
      title: "Account Registration",
      name: item.FirstName,
      body: `<div>Your account have been created, please proceed to login and continue your application<br/>Thank You! </div>`

    }
    return message;
  }
  else if (type === 'submit application') {
    const message = {
      from: projectEmail,
      subject: `${schoolName} HR`,
      title: "Application Recieved",
      name: item.FirstName,
      body: `
      <p>Your Application has been passed to the Selection Committee. You will get email notifications as the selection process progresses. </p>
      
      <br/>
      <div><p>No need to reply to this email.</p>
      <br/> <div>Good Luck.</div><br/>
      <div>${schoolName} HR Team</div>`

    }
    return message;
  }
  else if (type === 'withdraw application') {
    const message = {
      from: projectEmail,
      subject: `${schoolName} HR`,
      title: "Application Withdrawn",
      name: item.FirstName,
      body: `
      <p>We have received your request to withdraw your application for position of <strong>${item.Position}</strong></p>
      </div>.
      <br/>
      <div><p>We application would be cancelled and would not be processed further. Thank you!</p></div>. 
      <br/> <div>Good Luck.</div><br/>
      <div>@${schoolName} HR</div>`

    }
    return message;
  }
};

export const calculateAge = (birthday) => {
  if (!birthday) {
    return "";
  } else {
    let getAge = Date.now() - new Date(birthday).getTime();
    let ageDate = new Date(getAge); // milliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

}

export function timeConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) { // If time format correct
    time = time.slice(1);  // Remove full string match value
    time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(''); // return adjusted time or original string
}

export const SalaryStepScale = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20']

export const salaryScale = [
  { val: '1', text: '01' },
  { val: '2', text: '02' },
  { val: '3', text: '03' },
  { val: '4', text: '04' },
  { val: '5', text: '05' },
  { val: '6', text: '06' },
  { val: '7', text: '07' },
  { val: '8', text: '08' },
  { val: '9', text: '09' },
  { val: '10', text: '10' },
  { val: '11', text: '11' },
  { val: '12', text: '12' },
  { val: '13', text: '13' },
  { val: '14', text: '14' },
  { val: '15', text: '15' },
  { val: '16', text: '16' },
  { val: '17', text: '17' },
  { val: '18', text: '18' },
  { val: '19', text: '19' },
  { val: '20', text: '20' },]



export const CheckPermission = (allow) => {
  if (allow === true) {

  } else {
    toast.error("sorry, you do not have permission to view this page");
    window.location.href = "/"
  }
}


var th_val = ['', 'thousand', 'million', 'billion', 'trillion'];
var dg_val = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
var tn_val = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
var tw_val = ['twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

export function convertNumbertoWords(s) {
  s = s.toString();
  s = s.replace(/[\, ]/g, '');
  if (s != parseFloat(s))
    return 'not a number ';
  var x_val = s.indexOf('.');
  if (x_val == -1)
    x_val = s.length;
  if (x_val > 15)
    return 'too big';
  var n_val = s.split('');
  var str_val = '';
  var sk_val = 0;
  for (var i = 0; i < x_val; i++) {
    if ((x_val - i) % 3 == 2) {
      if (n_val[i] == '1') {
        str_val += tn_val[Number(n_val[i + 1])] + ' ';
        i++;
        sk_val = 1;
      } else if (n_val[i] != 0) {
        str_val += tw_val[n_val[i] - 2] + ' ';
        sk_val = 1;
      }
    } else if (n_val[i] != 0) {
      str_val += dg_val[n_val[i]] + ' ';
      if ((x_val - i) % 3 == 0)
        str_val += 'hundred ';
      sk_val = 1;
    }
    if ((x_val - i) % 3 == 1) {
      if (sk_val)
        str_val += th_val[(x_val - i - 1) / 3] + ' ';
      sk_val = 0;
    }
  }
  if (x_val != s.length) {
    var y_val = s.length;
    str_val += 'point ';
    for (var i = x_val + 1; i < y_val; i++)
      str_val += dg_val[n_val[i]] + ' ';
  }
  return str_val.replace(/\s+/g, ' ');
}