import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link, useLocation, useParams } from "react-router-dom";
import Breadcrum from '../../../../common/breadcrum/breadcrum';
import ReportTable from '../../../common/report_table';
import { serverLink } from '../../../../util/url';
import Loader from '../../../../common/loader';
import { CheckPermission, formatDateAndTime } from '../../../../util/constants';


function EnrolledList(props) {
    const location = useLocation();
    const allow = props.permissionsList.length > 0 ? props.permissionsList.filter(x => x.Designation === 'superadmin' || x.Designation === 'hr').length > 0 ? true : false : false;
    CheckPermission(allow);

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [generalDatatable, setGeneralDatatable] = useState([]);
    const columns = ["S/N", "Staff ID", "Name", "Job ID", "Position", "Job Type", "Department", "Action"];
    const [count, setCount] = useState(0)

    const [applicants, setapplicants] = useState(
        props.AllJobApplications.length > 0 ?
            props.AllJobApplications.filter(x => x.ApplicationStatus.toString() === "2" && x.EnrolmentStatus.toString() === "2"
            ) : []
        //&& x.EnrolmentStatus.toString() === "1"
    );


    const getData = async () => {
        try {
            let rows = []
            
            await axios.get(`${serverLink}/job_applications/enrolled_applicants`).then((res) => {
                if (res.data.length > 0) {
                    res.data.map((item, index) => {
                        rows.push([
                            index + 1,
                            item.StaffID,
                            item.StaffName,
                            item.RequisitionID,
                            item.PositionOffered,
                            item.JobType,
                            item.Department,
                            (
                                <>
                                    <Link to={`/enrolment/${item.ApplicantID}/${item.RequisitionID}/${item.ApplicationID}`}
                                        className="btn btn-sm btn-main"
                                    >
                                        <i className="fa fa-eye" />
                                    </Link>

                                </>
                            ),
                        ]);
                    });
                }
                setGeneralDatatable(rows);
                setIsLoading(false);
            })
        } catch (error) {
            console.log(error)

        }
    }

    useEffect(() => {

        getData();
    }, [location.pathname, applicants]);

    return (
        isLoading ? <Loader />
            :
            <div>
                <div className="container-fluid" style={{ marginLeft: '-10px' }}>
                    <Breadcrum title={"Enrolled Applicants"} />
                    <div className="row mt-3">
                        <div className="col-12">
                            <ReportTable title={applicants.length + " Record(s)"} columns={columns} data={generalDatatable} height="900px" />
                        </div>
                    </div>
                </div>
            </div>
    );
}

const mapStateToProps = (state) => {
    return {
        AdminLoginData: state.AdminLoginData,
        AllJobApplications: state.AllJobApplications,
        staffList: state.staffList,
        permissionsList: state.permissionsList
    };
};



export default connect(mapStateToProps, null)(EnrolledList);


