import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {setAssessmentSettingsAction, setIntervieweeAction} from '../../../../actions/actions';
import Loader from '../../../../common/loader';
import { serverLink } from '../../../../util/url';
import Breadcrum from "../../../../common/breadcrum/breadcrum";
import ReportTable from "../../../common/report_table";
import {Link} from "react-router-dom";
import { CheckPermission } from '../../../../util/constants';

function InterviewPanelApplicants(props) {
    const allow = props.permissionsList.length > 0 ? props.permissionsList.filter(x => x.Designation === 'panelist').length > 0 ? true : false : false;
    CheckPermission(allow);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [generalDatatable, setGeneralDatatable] = useState([]);
    const columns = [ "S/N", "Applicant Name", "Gender", "State of Origin", "LGA", "Highest Qualification", "Action" ];

    const getData = async() => {
        await axios.get(`${serverLink}/enrollment/interview/panel/applicants/assessment/${props.JobData?.RequisitionID}/${props.JobData?.JobType}/${props.AdminLoginData[0]?.StaffID}`) .then((res) => {
            const dataSet = res.data;
            let rows = [];
            if (dataSet.Applicants.length > 0) {
                let data = dataSet.Applicants;
                let settingsData = dataSet.Settings;
                props.setOnInterviewSettingsData(settingsData);
                setData(data);
                data.map((item, index) => {
                    rows.push([
                        index + 1,
                        item.FirstName +" "+item.MiddleName +" "+item.Surname,
                        item.Gender,
                        item.StateOfOrigin,
                        item.LGA,
                        item.HighestQualification,
                        (
                            <>
                                <Link to={`/interview/panel/assessment`}
                                      className="btn btn-sm btn-main"
                                      onClick={() => {
                                          props.setOnIntervieweeData(item);
                                      }}
                                >
                                    <i className="fa fa-eye" />
                                </Link>

                            </>
                        ),
                    ]);
                });
            }
            setGeneralDatatable(rows);
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
                toast.error("NETWORK ERROR. Please try again!");
            });
    }

    useEffect( () => {
        getData();
    }, []);

    return (
        isLoading ? <Loader/>
            :
        <div>
            <div className="container-fluid" style={{ marginLeft: '-10px'}}>
                <Breadcrum title="Interview Panel" />
                <div className="row mt-3">
                    <div className="col-12">
                        <ReportTable title={"Job Applicants"} columns={columns} data={generalDatatable} />
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        AdminLoginData: state.AdminLoginData,
        JobData: state.panelJobList,
        permissionsList: state.permissionsList
    };
};
const mapDisptachToProps = (dispatch) => {
    return {
        setOnInterviewSettingsData: (p) => {
            dispatch(setAssessmentSettingsAction(p))
        },
        setOnIntervieweeData: (p) => {
            dispatch(setIntervieweeAction(p))
        },
    }
}

export default connect(mapStateToProps, mapDisptachToProps)(InterviewPanelApplicants);


