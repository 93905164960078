import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import Breadcrum from '../../../../common/breadcrum/breadcrum';
import ReportTable from '../../../common/report_table';
import Loader from '../../../../common/loader';
import { CheckPermission, formatDateAndTime } from '../../../../util/constants';
import { serverLink } from '../../../../util/url';
import { setAdminJobApplications, setSingleJobApplication } from '../../../../actions/actions';

function AllPendingJobApplicantions(props) {
    const allow = props.permissionsList.length > 0 ? props.permissionsList.filter(x => x.Designation === 'superadmin' || x.Designation === 'hr').length > 0 ? true : false : false;
    CheckPermission(allow);
    const location = useLocation()
    const active_cohort = props.activeCohort[0];

    const [isLoading, setIsLoading] = useState(true);
    const [applicants, setapplicants] = useState([]);


    const [generalDatatable, setGeneralDatatable] = useState([])

    const columns = ["S/N", "Name", "Job ID", "Position", "Job Type", "Department", "Application Date", "Action"];

    const getData = async () => {
        try {
            await axios.get(`${serverLink}/enrollment/get_job_applications/pending?cohort=${active_cohort?.CohortCode}`).then((res) => {
                if (res.data.length > 0) {
                    let data = res.data;
                    setapplicants(res.data)
                    let rows = []
                    if (data.length > 0) {
                        data.map((item, index) => {
                            rows.push([
                                index + 1,
                                item.FirstName + " " + item.MiddleName + " " + item.Surname,
                                item.RequisitionID,
                                item.Position,
                                item.JobType,
                                item.Department,
                                formatDateAndTime(item.InsertedDate, "date"),
                                (
                                    <>
                                        <Link onClick={() => { props.setOnSingleJobApplication([item]) }} to={`/jobs/applicant/${item.ApplicantID}/${item.RequisitionID}/${item.ApplicationID}`}
                                            className="btn btn-sm btn-main"
                                        >
                                            <i className="fa fa-eye" />
                                        </Link>

                                    </>
                                ),
                            ]);
                        });
                    }
                    setGeneralDatatable(rows);
                }
                setIsLoading(false);
            })



        } catch (error) {

        }
    }

    useEffect(() => {
        getData();
    }, [location.pathname]);

    return (
        isLoading ? <Loader />
            :
            <div>
                <div className="container-fluid" style={{ marginLeft: '-10px' }}>
                    <Breadcrum title={"All Pending Applications"} />
                    <div className="row mt-3">
                        <div className="col-12">
                            <ReportTable title={applicants.length + " Records"} columns={columns} data={generalDatatable} height="700px" />
                        </div>
                    </div>
                </div>
            </div>
    );
}

const mapStateToProps = (state) => {
    return {
        AdminLoginData: state.AdminLoginData,
        AllJobApplications: state.AllJobApplications,
        permissionsList: state.permissionsList,
        activeCohort: state.activeCohort

    };
};

const mapDisptachToProps = (dispatch) => {
    return {
        setOnSingleJobApplication: (p) => {
            dispatch(setSingleJobApplication(p))
        }
    }
}



export default connect(mapStateToProps, mapDisptachToProps)(AllPendingJobApplicantions);


